import {
  gql, QueryTuple, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import { message } from 'antd';
import { useCallback } from 'react';

export interface Comment {
  author?: string;
  authorUid?: string;
  profile: string;
  title: string;
  body: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface UpdateQuestionCommentInput {
  _id: string;
  comment: string;
}

interface UpdateQuestionCommentOutput {
  updateQuestionComment: boolean;
}

const UPDATE_QUESTION_COMMENT = gql`
  mutation UpdateQuestionComment(
    $_id: ObjectId!,
    $comment: String!,
  ) {
    updateQuestionComment(
      _id: $_id,
      comment: $comment,
    )
  }
`;

export function useUpdateQuestionComment() {
  const [updateQuestionComment] = useMutation<UpdateQuestionCommentOutput, UpdateQuestionCommentInput>(UPDATE_QUESTION_COMMENT, {
    onError: error => {
      message.error(error.message);
    },
  });
  const handleUpdateQuestionComment = useCallback(async ({
    _id,
    comment,
  }: UpdateQuestionCommentInput) => {
    const res = await updateQuestionComment({
      variables: {
        _id,
        comment,
      },
    });
    return res.data?.updateQuestionComment;
  }, [updateQuestionComment]);
  return handleUpdateQuestionComment;
}

export interface QuestionCommentsStatsInput {
  filter?: {
    dateStart: Date;
    dateEnd: Date;
  };
}

export interface QuestionCommentsStatsOutput {
  getCommentsStatistics: {
    totalCount?: number;
    commentStats?: {
      area?: string;
      count?: number;
    }[];
  };
}

const GET_QUESTION_COMMENT_STATS = gql`
  query GetCommentStatistics($filter: DateRangeFilterInputType) {
    getCommentsStatistics(filter: $filter) {
      totalCount
      commentStats {
        area
        count
      }
    }
  }
`;

export function useQuestionCommentStats(filter?: { dateStart: Date, dateEnd: Date }) {
  return useQuery<QuestionCommentsStatsOutput, QuestionCommentsStatsInput>(GET_QUESTION_COMMENT_STATS, {
    variables: {
      filter,
    },
  });
}
