import { CloseOutlined, QuestionCircleFilled, SaveOutlined } from '@ant-design/icons';
import { ApolloQueryResult } from '@apollo/client';
import {
  Button, message, Row, Space, Table, Tooltip, Typography,
} from 'antd';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  CourseConfigInfoInput,
  CourseConfigInfoOutput,
  CourseConfigInputType,
  CourseManyInfo, useCoursesManyInfo, useUpdateCourseConfig,
} from '../../../api/courses';
import { getParam } from '../../../hooks/useSearchParam';
import { WeekGoalInputProps, WeekGoalInput } from './WeekGoalInput';

const { Text } = Typography;

interface WeekGoalsTableProps {
  course: CourseManyInfo;
  courseConfig?: CourseConfigInputType;
  refetchCourseConfig?: (variables?: Partial<CourseConfigInfoInput> | undefined) => Promise<ApolloQueryResult<CourseConfigInfoOutput>>;
}

const initialCourseWeekGoals = {
  min: {
    theoreticalStudy: 0,
    theoreticalReview: 0,
    smartReview: 0,
    exam: 0,
  },
  max: {
    theoreticalStudy: 0,
    theoreticalReview: 0,
    smartReview: 0,
    exam: 0,
  },
};

export function WeekGoalsTable({
  courseConfig, refetchCourseConfig,
}: WeekGoalsTableProps) {
  const courseId = getParam('id');
  const [dirtyInputs, setDirtyInputs] = useState(false);
  const [updating, setUpdating] = useState(false);
  const handleUpdateCourseConfig = useUpdateCourseConfig();
  const [courseWeekGoals, setCourseWeekGoals] = useState(initialCourseWeekGoals);

  useEffect(() => {
    if (courseConfig?.weekGoals) {
      setCourseWeekGoals(courseConfig.weekGoals);
    }
  }, [courseConfig]);

  const handleChange = useCallback((type: string, range: 'min' | 'max', value: number) => {
    setCourseWeekGoals(prevCourseWeekGoals => {
      if (range === 'min') {
        return {
          ...prevCourseWeekGoals,
          min: {
            ...prevCourseWeekGoals.min,
            ...Object.fromEntries([[type, value]]),
          },
        };
      }
      if (range === 'max') {
        return {
          ...prevCourseWeekGoals,
          max: {
            ...prevCourseWeekGoals.max,
            ...Object.fromEntries([[type, value]]),
          },
        };
      }
      return prevCourseWeekGoals;
    });
    setDirtyInputs(true);
  }, []);

  const columns = [
    {
      title: 'Atividades',
      dataIndex: 'type',
      key: 'type',
      render: (text: string) => <Text>{text}</Text>,
    },
    {
      title: 'Mínimo',
      dataIndex: 'min',
      key: 'min',
      render: (record: WeekGoalInputProps, info: any) => {
        return <WeekGoalInput min={0} max={info.max.value} {...record} />;
      },
    },
    {
      title: 'Máximo',
      dataIndex: 'max',
      key: 'max',
      render: (record: WeekGoalInputProps, info: any) => {
        return <WeekGoalInput min={info.min.value} {...record} />;
      },
    },
  ];

  const weekGoalsData = useMemo(() => {
    return [
      {
        key: 'theoreticalStudy',
        type: (
          <Text>
            Estudo teórico&nbsp;
            <Tooltip
              title={`As metas mínimas de estudo teórico
              não influenciam no cálculo do troféu semanal, pois
              a quantidade de aulas da semana é definido pelo cronograma
              do curso e pode variar a cada semana.`}
            >
              <QuestionCircleFilled style={{ fontSize: '13px' }} />
            </Tooltip>
          </Text>
        ),
        min: {
          value: courseWeekGoals.min?.theoreticalStudy,
          onChange: (value: number) => handleChange('theoreticalStudy', 'min', value),
        },
        max: {
          value: courseWeekGoals.max?.theoreticalStudy,
          onChange: (value: number) => handleChange('theoreticalStudy', 'max', value),
        },
      },
      {
        key: 'smartReview',
        type: 'Revisão inteligente',
        min: {
          value: courseWeekGoals.min?.smartReview,
          onChange: (value: number) => handleChange('smartReview', 'min', value),
        },
        max: {
          value: courseWeekGoals.max?.smartReview,
          onChange: (value: number) => handleChange('smartReview', 'max', value),
        },
      },
      {
        key: 'theoreticalReview',
        type: 'Revisão teórica',
        min: {
          value: courseWeekGoals.min?.theoreticalReview,
          onChange: (value: number) => handleChange('theoreticalReview', 'min', value),
        },
        max: {
          value: courseWeekGoals.max?.theoreticalReview,
          onChange: (value: number) => handleChange('theoreticalReview', 'max', value),
        },
      },
      {
        key: 'exam',
        type: 'Provas',
        min: {
          value: courseWeekGoals.min?.exam,
          onChange: (value: number) => handleChange('exam', 'min', value),
        },
        max: {
          value: courseWeekGoals.max?.exam,
          onChange: (value: number) => handleChange('exam', 'max', value),
        },
      },
    ];
  }, [courseWeekGoals, handleChange]);

  const handleUndo = useCallback(() => {
    if (courseConfig) {
      setCourseWeekGoals(courseConfig.weekGoals || initialCourseWeekGoals);
      setDirtyInputs(false);
    }
  }, [courseConfig]);

  const handleUpdate = useCallback(async () => {
    if (!courseConfig) {
      return;
    }
    setUpdating(true);
    try {
      await handleUpdateCourseConfig({
        courseId,
        weekGoals: courseWeekGoals,
      });
      if (refetchCourseConfig) {
        await refetchCourseConfig({
          courseId,
        });
      }
      setDirtyInputs(false);
      message.success('Configuração de semana típica atualizada com sucesso');
    } catch (error) {
      message.error('Erro ao salvar configurações de semana típica!');
      console.error(error);
    } finally {
      setUpdating(false);
    }
  }, [courseConfig, courseId, courseWeekGoals, handleUpdateCourseConfig, refetchCourseConfig]);

  return (
    <Space direction="vertical">
      <Row>
        <Table
          size="small"
          pagination={false}
          columns={columns}
          dataSource={weekGoalsData}
        />
      </Row>
      <Row>
        <Space className="mt-2">
          <Button
            type="ghost"
            icon={<CloseOutlined />}
            disabled={!dirtyInputs}
            onClick={handleUndo}
          >
            Desfazer
          </Button>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            disabled={!dirtyInputs}
            onClick={handleUpdate}
            loading={updating}
          >
            Salvar
          </Button>
        </Space>
      </Row>
    </Space>
  );
}
