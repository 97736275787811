import {
  gql, QueryResult, useMutation, useQuery,
} from '@apollo/client';
import { message } from 'antd';
import { useCallback } from 'react';

export interface Mindmap {
  _id: string;
  url: string;
  title: string;
  subtitle: string;
  description: string;
  comment?: Comment;
}

export interface MindmapDetailsInput {
  _id: string;
}

export interface MindmapDetailsOutput {
  mindmapDetails: Mindmap;
}

const MINDMAP_DETAILS = gql`
  query MindmapDetails($_id: ObjectId!) {
    mindmapDetails(_id: $_id) {
      _id
      url
      title
      subtitle
      description
    }
  }
`;

export function useMindmapDetails(_id: string): QueryResult<MindmapDetailsOutput, MindmapDetailsInput> {
  return useQuery<MindmapDetailsOutput, MindmapDetailsInput>(MINDMAP_DETAILS, {
    variables: {
      _id,
    },
    skip: !_id,
    onError: error => {
      message.error(error.message);
    },
  });
}

interface UpdateMindmapDetailsOutput {
  updateMindmapDetails: boolean;
}

export interface UpdateMindmapDetailsInput {
  object: Omit<Partial<Mindmap>, '_id'> & { _id: string };
}

const UPDATE_TEXT_DETAILS = gql`
  mutation UpdateMindmapDetails(
    $object: BaseContentInputType!
  ) {
    updateMindmapDetails(object: $object)
  }
`;

export function useUpdateMindmapDetails() {
  const [updateMindmapDetails] = useMutation<UpdateMindmapDetailsOutput, UpdateMindmapDetailsInput>(UPDATE_TEXT_DETAILS, {
    onError: error => {
      message.error(error.message);
    },
  });
  const handleUpdateMindmapDetails = useCallback(async (mindmap: Omit<Partial<Mindmap>, '_id'> & { _id: string }) => {
    const res = await updateMindmapDetails({
      variables: {
        object: mindmap,
      },
    });
    return res.data?.updateMindmapDetails;
  }, [updateMindmapDetails]);
  return handleUpdateMindmapDetails;
}

interface LessonMindmapsInput {
  lessonId: string;
}

interface LessonMindmapsOutput {
  lessonMindmaps: Mindmap[];
}

const LESSON_MINDMAPS = gql`
  query LessonMindmaps(
    $lessonId: ObjectId!
  ) {
    lessonMindmaps(lessonId: $lessonId) {
      _id
      title
      subtitle
      description
      url
    }
  }
`;

const CREATE_MINDMAP = gql`
  mutation CreateMindmap(
    $lessonId: ObjectId!
  ) {
    createMindmap(lessonId: $lessonId)
  }
`;

export interface CreateMindmapInput {
  lessonId: string;
}

export interface CreateMindmapOutput {
  createMindmap: string;
}

const DELETE_MINDMAP = gql`
  mutation DeleteMindmap(
    $contentId: ObjectId!
  ) {
    deleteMindmap(contentId: $contentId)
  }
`;
export interface DeleteMindmapInput {
  contentId: string;
}

export interface DeleteMindmapOutput {
  deleteMindmap: boolean;
}

const REMOVE_MINDMAP_FROM_LESSON = gql`
  mutation RemoveMindmapFromLesson(
    $contentId: ObjectId!
    $lessonId: ObjectId!
  ) {
    removeMindmapFromLesson(contentId: $contentId, lessonId: $lessonId)
  }
`;
export interface RemoveMindmapFromLessonInput {
  contentId: string;
  lessonId: string;
}

export interface RemoveMindmapFromLessonOutput {
  removeMindmapFromLesson: boolean;
}

export function useMindmaps(lessonId: string) {
  const lessonMindmaps = useQuery<LessonMindmapsOutput, LessonMindmapsInput>(LESSON_MINDMAPS, {
    variables: {
      lessonId,
    },
    skip: !lessonId,
  });

  const [createNewMindmap] = useMutation<CreateMindmapOutput, CreateMindmapInput>(CREATE_MINDMAP);
  const handleCreateNewMindmapMutation = useCallback(async () => {
    const response = await createNewMindmap({
      variables: {
        lessonId,
      },
    });
    return response.data?.createMindmap;
  }, [createNewMindmap, lessonId]);

  const [deleteMindmap] = useMutation<DeleteMindmapOutput, DeleteMindmapInput>(DELETE_MINDMAP);
  const handleDeleteMindmapMutation = useCallback(async (contentId: string) => {
    const response = await deleteMindmap({
      variables: {
        contentId,
      },
    });
    return response.data?.deleteMindmap;
  }, [deleteMindmap]);

  const [removeMindmapFromLesson] = useMutation<RemoveMindmapFromLessonOutput, RemoveMindmapFromLessonInput>(REMOVE_MINDMAP_FROM_LESSON);
  const handleRemoveMindmapFromLesson = useCallback(async (contentId: string) => {
    const response = await removeMindmapFromLesson({
      variables: {
        contentId,
        lessonId,
      },
    });
    return response.data?.removeMindmapFromLesson;
  }, [lessonId, removeMindmapFromLesson]);

  return {
    lessonMindmaps,
    handleCreateNewMindmapMutation,
    handleDeleteMindmapMutation,
    handleRemoveMindmapFromLesson,
  };
}
