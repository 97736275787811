import {
  Col, Divider, Row, Typography,
} from 'antd';
import { FC } from 'react';
import { QuestionsGroupedByThemaObjectType } from '../../../../../api/user-dashboard';

const titleStyle = {
  width: '110px', fontSize: '11px', fontWeight: 500,
};

const textStyle = {
  width: '110px', fontSize: '11px', fontWeight: 400,
};

type QuestionWeekExpandedContentProps = {
  data: QuestionsGroupedByThemaObjectType[],
}

export const QuestionWeekExpandedContent: FC<QuestionWeekExpandedContentProps> = ({
  data,
}) => {
  return (
    <Col style={{
      padding: '16px',
      backgroundColor: 'rgba(65, 95, 145, 0.08)',
    }}
    >
      <Typography>Dificuldades de questões</Typography>
      <Divider style={{ margin: '8px 0px' }} />
      <Row style={{
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '8px',
      }}
      >
        <Typography style={titleStyle}>Grande área</Typography>
        <Typography style={titleStyle}>Quantidade</Typography>
        <Typography style={titleStyle}>Fáceis</Typography>
        <Typography style={titleStyle}>Médias</Typography>
        <Typography style={titleStyle}>Difíceis</Typography>
        <Typography style={titleStyle}>Total de acertos</Typography>
        <Typography style={titleStyle}>Acertos (%)</Typography>
      </Row>
      {[...data].sort((a, b) => a.classification.localeCompare(b.classification)).map(it => {
        const easy = it.difficultyByThema.find(e => e.difficulty === 'easy');
        const medium = it.difficultyByThema.find(e => e.difficulty === 'medium');
        const hard = it.difficultyByThema.find(e => e.difficulty === 'hard');

        return (
          <Row key={it.percentageThema} justify="space-between" align="middle">
            <Typography style={textStyle}>{it.classification}</Typography>
            <Typography style={textStyle}>{it.totalQuestionsByThema}</Typography>
            <Typography style={textStyle}>{`${((easy?.percentageDifficulty ?? 0) * 100).toFixed(0)}%`}</Typography>
            <Typography style={textStyle}>{`${((medium?.percentageDifficulty ?? 0) * 100).toFixed(0)}%`}</Typography>
            <Typography style={textStyle}>{`${((hard?.percentageDifficulty ?? 0) * 100).toFixed(0)}%`}</Typography>
            <Typography style={textStyle}>{it.correct}</Typography>
            <Typography style={textStyle}>{`${(it.percentageThema * 100).toFixed(0)}%`}</Typography>
          </Row>
        );
      })}
    </Col>
  );
};
