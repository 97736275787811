import { CourseLesson } from '../api/courses';

function checkPhaseOverlay(lessons: CourseLesson[]) {
  const phasesHashMap = lessons.reduce((obj, lesson) => {
    const result = { ...obj };
    result[lesson.phase] = [...(result[lesson.phase] ?? []), lesson];
    return result;
  }, {} as Record<string, CourseLesson[]>);

  const phasesRange = Object.entries(phasesHashMap).map(([phase, lesson]) => {
    const dates = lesson.map(l => (new Date(l.enableAfter)).getTime());
    return {
      phase,
      min: Math.min(...dates),
      max: Math.max(...dates),
    };
  }).sort((rangeA, rangeB) => rangeA.min - rangeB.min);

  const phasesBoolean = phasesRange.slice(0, -1).map((element, index) => element.max <= phasesRange[index + 1].min);

  const message: string[] = [];
  phasesBoolean.forEach((item, i) => {
    if (!item) {
      message.push(`As fases ${i + 1} e ${i + 2} se sobrepõe.`);
    }
  });

  return {
    isValid: phasesBoolean.every(Boolean),
    message: message.join(' '),
  };
}

function validateLessonPrerequisites(lessons: CourseLesson[]) {
  const lessonsHashMap = lessons.reduce((acc, curr) => {
    acc[curr.lessonId] = curr;
    return acc;
  }, {} as Record<string, CourseLesson>);

  const message: string[] = [];
  const isValid = lessons.every(lesson => {
    const forbiddenPrerequisites = [lesson.lessonId];

    function checkPrerequisites(prerequisites: string[]): boolean {
      if (prerequisites.length) {
        return prerequisites.every(p => {
          const l = lessonsHashMap[p];
          if (forbiddenPrerequisites.includes(p)) {
            message.push(`Referência circular na aula ${l.classification.join(' | ')}.`);
            return false;
          }
          forbiddenPrerequisites.push(p);
          if (l.prerequisites && l.prerequisites.length) {
            return checkPrerequisites(l.prerequisites ?? []);
          }
          return true;
        });
      }
      return true;
    }

    return checkPrerequisites(lesson.prerequisites ?? []);
  });

  return {
    isValid,
    message: message.join(' '),
  };
}

export function validateCoursePhases(lessons: CourseLesson[]) {
  const phases = checkPhaseOverlay(lessons);
  const prerequisites = validateLessonPrerequisites(lessons);
  return {
    isValid: phases.isValid && prerequisites.isValid,
    message: [phases.message, prerequisites.message].join(' '),
  };
}
