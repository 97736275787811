import { FC } from 'react';
import useAccount from '../hooks/useAccount';

interface PrivateComponentProps {
  clearences?: string[];
}

const PrivateComponent: FC<PrivateComponentProps> = ({ children, clearences, ...rest }) => {
  const { isAuthenticated } = useAccount();
  if (isAuthenticated(clearences, false)) {
    return (
      <>
        {children}
      </>
    );
  }

  return null;
};

export default PrivateComponent;
