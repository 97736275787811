import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { ApolloQueryResult } from '@apollo/client';
import {
  Button, Checkbox, message, Row, Space,
} from 'antd';
import {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  CourseConfigInfoInput, CourseConfigInfoOutput, ExtraActivityType, useUpdateCourseConfig,
} from '../../../api/courses';
import { getParam } from '../../../hooks/useSearchParam';

const allActivities: ExtraActivityType[] = [
  'Exam', 'Flashcard', 'MockExam', 'Question', 'SmartExam',
  'SmartReview', 'TheoreticalReview', 'TheoreticalStudy',
];

const ACTIVITY_DICT = {
  Exam: 'Prova',
  Flashcard: 'Flashcards',
  MockExam: 'Simulado Aristo',
  Question: 'Questões',
  SmartExam: 'Simulado inteligente',
  SmartReview: 'Revisão inteligente',
  TheoreticalReview: 'Revisão teórica',
  TheoreticalStudy: 'Estudo teórico',
};

type AllowedActivitiesSelectorType = {
  activities?: ExtraActivityType[] | undefined,
  refetchCourseConfig?: (variables?: Partial<CourseConfigInfoInput> | undefined) => Promise<ApolloQueryResult<CourseConfigInfoOutput>>;
}

export const AllowedActivitiesSelector: FC<AllowedActivitiesSelectorType> = ({
  activities, refetchCourseConfig,
}) => {
  const initialAllowedActivities = activities || allActivities;
  const [selectedActivities, setSelectedActivities] = useState(initialAllowedActivities);
  const courseId = getParam('id');
  const [updating, setUpdating] = useState(false);
  const handleUpdateCourseConfig = useUpdateCourseConfig();
  const isSaveDisabled = useMemo(() => {
    const selectedActivitiesCopy = [...selectedActivities];
    selectedActivitiesCopy.sort();

    const activitiesCopy = activities ? [...activities] : [];
    activitiesCopy.sort();
    return activitiesCopy.join(',') === selectedActivitiesCopy.join(',');
  }, [activities, selectedActivities]);

  useEffect(() => {
    if (activities) {
      setSelectedActivities(activities);
    }
  }, [activities]);

  const onActivityCheckClick = useCallback((e, value) => {
    setSelectedActivities(prev => {
      if (prev.includes(value)) {
        return prev.filter(x => x !== value);
      }
      return prev.concat(value);
    });
  }, []);

  const handleUndo = useCallback(() => {
    setSelectedActivities(activities ?? initialAllowedActivities);
  }, [activities, initialAllowedActivities]);

  const handleUpdate = useCallback(async () => {
    if (!activities) {
      return;
    }
    setUpdating(true);
    try {
      await handleUpdateCourseConfig({
        courseId,
        activities: selectedActivities,
      });
      if (refetchCourseConfig) {
        await refetchCourseConfig({
          courseId,
        });
      }
      message.success('Configuração de atividades permitidas atualizada com sucesso');
    } catch (error) {
      message.error('Erro ao salvar configurações de atividades permitidas!');
      console.error(error);
    } finally {
      setUpdating(false);
    }
  }, [activities, courseId, handleUpdateCourseConfig, refetchCourseConfig, selectedActivities]);

  return (
    <Space direction="vertical">
      <Row>
        <div style={{
          width: '100%',
          maxWidth: '580px',
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px 50px',
          marginTop: 5,
        }}
        >
          {
            allActivities
              .map(value => (
                <Checkbox
                  key={value}
                  style={{ marginLeft: 0 }}
                  value={value}
                  checked={selectedActivities.includes(value)}
                  onChange={e => onActivityCheckClick(e, value)}
                >
                  {ACTIVITY_DICT[value as keyof typeof ACTIVITY_DICT]}
                </Checkbox>
              ))
          }
        </div>
      </Row>
      <Row>
        <Space className="mt-2">
          <Button
            type="ghost"
            icon={<CloseOutlined />}
            disabled={isSaveDisabled || updating}
            onClick={handleUndo}
          >
            Desfazer
          </Button>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            disabled={isSaveDisabled}
            onClick={handleUpdate}
            loading={updating}
          >
            Salvar
          </Button>
        </Space>
      </Row>
    </Space>
  );
};
