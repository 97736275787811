import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Empty,
  List,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import { useCallback, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  PDFType,
  useCreatePdf,
  useDeletePdf,
  usePDFsAritoPlus,
} from '../../api/pdfs';
import EditDataWrapper from '../../components/EditDataWrapper';

const { Text } = Typography;
const { confirm } = Modal;

export function AristoPlusPDFsPage() {
  const { data, loading } = usePDFsAritoPlus();
  const [createPdfMutation, { loading: loadingCreatePdf }] = useCreatePdf();
  const [deletePdfMutation] = useDeletePdf();

  const history = useHistory();

  const pdfs = useMemo(() => {
    if (!data) {
      return undefined;
    }
    return data.aristoPlusPDFsList;
  }, [data]);

  const handleCreatePdf = useCallback(async () => {
    try {
      const { data: dataCreatePdf } = await createPdfMutation({
        refetchQueries: ['AristoPlusPDFsList'],
        awaitRefetchQueries: true,
      });
      if (dataCreatePdf) {
        history.push(`/admin/editar-pdf?id=${dataCreatePdf?.createPDF}`);
      } else {
        throw new Error('no data received');
      }
    } catch (e) {
      message.error('Erro ao criar novo PDF');
      console.error(e);
    }
  }, [createPdfMutation, history]);

  function handleDeleteModal(pdf: PDFType) {
    confirm({
      title: 'Deseja mesmo deletar esse pdf?',
      content: (
        <div>
          {`Título: ${pdf.title}`}
        </div>
      ),
      onOk: async () => {
        try {
          await deletePdfMutation({
            variables: { id: pdf._id },
            refetchQueries: ['AristoPlusPDFsList'],
            awaitRefetchQueries: true,
          });
        } catch (err) {
          console.error(err);
          message.error('Erro ao deletar o pdf');
        }
      },
      onCancel: () => { },
    });
  }

  return (
    <EditDataWrapper
      title="PDFs Aristo+"
    >
      {loading && <Spin />}
      {pdfs && pdfs.length > 0 && (
        <List
          size="small"
          header={(
            <Row justify="space-between">
              <Text strong>Lista de PDFs</Text>
              <Button
                icon={<PlusOutlined />}
                onClick={handleCreatePdf}
                loading={loadingCreatePdf}
              >
                Criar PDF
              </Button>
            </Row>
          )}
          dataSource={pdfs}
          renderItem={pdf => {
            return (
              <List.Item actions={[
                <Link to={{
                  pathname: '/admin/editar-pdf',
                  search: `?id=${pdf._id}`,
                }}
                >
                  <Space>
                    <EditOutlined />
                    Editar
                  </Space>
                </Link>,
                <Button
                  style={{ padding: 0 }}
                  type="link"
                  icon={<DeleteOutlined />}
                  danger
                  onClick={() => handleDeleteModal(pdf)}
                >
                  Deletar
                </Button>,
              ]}
              >
                <List.Item.Meta
                  title={(
                    <Space>
                      <Text>
                        {`${pdf.title} - ${pdf.subtitle || 'Sem subtítulo'}`}
                      </Text>
                    </Space>
                  )}
                  description={`Categoria: ${pdf.category} | Título: ${pdf.title}`}
                />
              </List.Item>
            );
          }}
        />
      )}
      {!loading && pdfs && pdfs.length === 0 && (
        <Empty description="Nenhum pdfs encontrado">
          <Button
            icon={<PlusOutlined />}
            onClick={handleCreatePdf}
            loading={loadingCreatePdf}
          >
            Criar PDF
          </Button>
        </Empty>
      )}
    </EditDataWrapper>
  );
}
