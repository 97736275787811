import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { ApolloQueryResult } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Space,
  Typography,
  Collapse,
  Input,
  DatePicker,
  Row,
  Table,
  message,
  Modal,
  Col,
  Drawer,
  Select,
  Checkbox,
} from 'antd';
import moment from 'moment';
import {
  ChangeEvent,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import {
  CourseLesson,
  CourseManyInfo,
  CoursesManyInfoInput,
  CoursesManyInfoOutput,
  useUpdateCourse,
} from '../../../api/courses';
import { database } from '../../../client/firebase';
import { validateCoursePhases } from '../../../utils/validateCoursePhases';
import { formatDate } from '../../../utils/formatDate';
import { AddLessonInput } from './AddLessonInput';
import { ProgressPopover, ProgressType } from './ProgressPopover';

type LessonsTableProps = {
  course: CourseManyInfo;
  refetch?: (variables?: Partial<CoursesManyInfoInput> | undefined) => Promise<ApolloQueryResult<CoursesManyInfoOutput>>;
}

interface Item {
  prerequisites?: string[];
  key: string;
  classification: string;
  phase: string;
  enableAfter: string;
  avgKnowledgeLevel?: number;
  lessonId: string;
  isPreCourse?: boolean;
  action?: 'add' | 'delete' | 'edit';
}

interface LessonEdited extends CourseLesson {
  action?: 'add' | 'delete' | 'edit';
}

const rowStatusClassNames = {
  add: 'bg-add',
  delete: 'bg-delete',
  edit: 'bg-edit',
};

const { Text } = Typography;
const { Panel } = Collapse;
const { confirm, error } = Modal;

export function LessonsTable({
  course: { _id, lessons }, refetch,
}: LessonsTableProps) {
  const { Option } = Select;
  const [lessonsState, setLessonsState] = useState<LessonEdited[]>(lessons);
  const [progress, setProgress] = useState({} as ProgressType);
  const [blockEdit, setBlockEdit] = useState(false);
  const [editingRecord, setEditingRecord] = useState<Item>({} as Item);
  const handleUpdateCourse = useUpdateCourse();
  const [updating, setUpdating] = useState(false);
  const [dirtyInputs, setDirtyInputs] = useState(false);
  const [prereqSelectorValue, setPrereqSelectorValue] = useState<string>();
  const lessonsHashMap = useMemo(() => (
    lessonsState.reduce((acc, curr) => {
      acc[curr.lessonId] = curr;
      return acc;
    }, {} as Record<string, LessonEdited>)
  ), [lessonsState]);

  const [visible, setVisible] = useState(false);
  const showPrereqDrawer = () => {
    setVisible(true);
  };
  const onClosePrereqDrawer = () => {
    setVisible(false);
  };

  useEffect(() => {
    const statusRef = database.ref('replicate-course-status');
    statusRef.on('value', status => {
      if (!status.val()) {
        return;
      }
      const statuses = Object.entries(status.val() as Record<string, ProgressType>);
      if (statuses.length > 0) {
        setBlockEdit(true);
        const currentActiveProgress = statuses.find(([key, value]) => value.courseId === _id && value.finished === false);

        if (currentActiveProgress) {
          setProgress({
            ...currentActiveProgress[1],
            key: currentActiveProgress[0],
          });
        } else {
          setBlockEdit(false);
          setProgress({} as ProgressType);
        }
        statuses.forEach(([key, value]) => {
          if (value.finished) {
            database.ref(`replicate-course-status/${key}`).remove().catch(console.error);
          }
        });
      }
    });
    return () => {
      statusRef.off('value');
    };
  }, [_id]);

  useEffect(() => {
    setLessonsState(lessons);
  }, [lessons]);

  const addNewLesson = useCallback((lessonToAdd: Item) => {
    setLessonsState(records => {
      const result = [...records];
      result.push({
        ...lessonToAdd,
        classification: lessonToAdd.classification.split(' | '),
        enableAfter: moment(lessonToAdd.enableAfter, 'DD/MM/YYYY').toISOString(),
        action: 'add',
      });
      result.sort((a, b) => (new Date(a.enableAfter)).getTime() - (new Date(b.enableAfter)).getTime());
      return result;
    });
    setDirtyInputs(true);
  }, []);

  const flagDeletion = useCallback(() => {
    setLessonsState(records => {
      const result = [...records];
      if (editingRecord.action === 'add') {
        return result.filter(item => item.lessonId !== editingRecord.lessonId);
      }
      const index = records.findIndex(r => r.lessonId === editingRecord.key);
      result.splice(index, 1, {
        ...editingRecord,
        classification: editingRecord.classification.split(' | '),
        enableAfter: moment(editingRecord.enableAfter, 'DD/MM/YYYY').toISOString(),
        action: 'delete',
      });
      return result;
    });
    setEditingRecord({} as Item);
    setDirtyInputs(true);
  }, [editingRecord]);

  const cancel = useCallback(() => {
    setDirtyInputs(false);
    setEditingRecord({} as Item);
  }, []);

  const save = useCallback(() => {
    setLessonsState(records => {
      const result = [...records];
      const index = records.findIndex(r => r.lessonId === editingRecord.key);
      result.splice(index, 1, {
        ...editingRecord,
        classification: editingRecord.classification.split(' | '),
        enableAfter: moment(editingRecord.enableAfter, 'DD/MM/YYYY').toISOString(),
        action: editingRecord.action === 'add' ? 'add' : 'edit',
      });
      return result;
    });
    setEditingRecord({} as Item);
    setDirtyInputs(true);
  }, [editingRecord]);

  const edit = useCallback((item: Item) => {
    setEditingRecord(item);
  }, []);

  const handleUndo = useCallback(() => {
    setDirtyInputs(false);
    setLessonsState(lessons);
  }, [lessons]);

  const handleUpdate = useCallback(async () => {
    setUpdating(true);
    setBlockEdit(true);
    try {
      await handleUpdateCourse({
        input: {
          _id,
          lessons: lessonsState.filter(lesson => Boolean(lesson.action)).map(({
            lessonId, classification, action, phase, enableAfter, prerequisites, isPreCourse,
          }) => ({
            lessonId,
            prerequisites,
            classification,
            action,
            phase,
            enableAfter,
            isPreCourse,
          })),
        },
      });
      if (refetch) {
        await refetch({
          ids: [_id],
        });
      }
      setDirtyInputs(false);
      message.success('Cronograma do curso atualizado com sucesso.');
      message.warn('Iniciando replicação das alterações para os usuários...');
    } catch (err) {
      console.error(err);
      setBlockEdit(false);
    } finally {
      setUpdating(false);
    }
  }, [_id, handleUpdateCourse, lessonsState, refetch]);

  const handleUpdateModal = useCallback(() => {
    const { isValid, message: errorMsg } = validateCoursePhases(lessonsState);
    if (isValid) {
      confirm({
        title: 'Deseja aplicar as alterações?',
        content: `Ao confirmar será iniciado um processo de replicação
        das alterações para todos os usuários do curso. Esse processo pode demorar e é irreversível.
        Apenas após a finalização de toda a replicação é que poderá ser feita uma nova edição nesse curso.
        `,
        onOk: handleUpdate,
        onCancel: () => { },
      });
      return;
    }
    error({
      title: 'Erro ao validar as aulas do cronograma!',
      content: `Existem erros nas aulas. ${errorMsg}`,
      onOk: () => { },
    });
  }, [handleUpdate, lessonsState]);

  const handleChangeEnableAfter = useCallback((value: moment.Moment | null, dateString: string) => {
    if (!value) {
      return;
    }
    setEditingRecord(prev => {
      return {
        ...prev,
        enableAfter: formatDate(value.toDate()),
      };
    });
    setDirtyInputs(true);
  }, []);

  const handleChangePhase = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const phase = e.target.value;
    if (phase === undefined) {
      return;
    }
    setEditingRecord(prev => {
      return {
        ...prev,
        phase,
      };
    });
    setDirtyInputs(true);
  }, []);

  const handleRemovePrerequisite = useCallback(id => {
    setEditingRecord(prev => {
      if (prev.prerequisites) {
        return { ...prev, prerequisites: prev.prerequisites.filter(item => item !== id) };
      } return prev;
    });
  }, []);

  const handleAddPrerequistes = useCallback(() => {
    setEditingRecord(prev => {
      if (prev.prerequisites) {
        if (!(prev.prerequisites.includes(String(prereqSelectorValue)))) {
          return (
            {
              ...prev,
              prerequisites: (prev.prerequisites || []).concat([String(prereqSelectorValue)]),
            }
          );
        } return prev;
      }

      return (
        {
          ...prev,
          prerequisites: [String(prereqSelectorValue)],
        }
      );
    });
  }, [prereqSelectorValue]);

  const columns = useMemo(() => {
    return [
      {
        title: 'Aula',
        dataIndex: 'classification',
        key: 'classification',
        width: '45%',
        render: (text: string) => <Text>{text}</Text>,
      },
      {
        title: 'Pré-curso?',
        dataIndex: 'isPreCourse',
        key: 'isPreCourse',
        width: '20%',
        render: (_: any, { key, isPreCourse }: Item) => {
          const isEditing = editingRecord.key === key;

          return isEditing ? (
            <Checkbox
              checked={editingRecord.isPreCourse}
              onChange={e => {
                if (e.target instanceof HTMLInputElement && document.activeElement === e.target) {
                  setEditingRecord(prev => ({
                    ...prev,
                    isPreCourse: e.target.checked,
                  }));
                } else {
                  setEditingRecord(prev => ({
                    ...prev,
                    isPreCourse: !prev.isPreCourse,
                  }));
                }
              }}
            />
          ) : (
            <Checkbox
              disabled
              checked={isPreCourse}
            />
          );
        },
      },
      {
        title: 'Fase',
        dataIndex: 'phase',
        key: 'phase',
        width: '15%',
        render: (_: any, { key, phase }: Item) => {
          return key === editingRecord.key ? (<Input defaultValue={editingRecord.phase} onBlur={handleChangePhase} />) : (<Text>{phase}</Text>);
        },
        filters: Object.keys(lessons.reduce((acc, lesson) => {
          acc[lesson.phase] = lesson;
          return acc;
        }, {} as Record<string, CourseLesson>)).map(filter => ({
          text: filter,
          value: filter,
        })),
        onFilter: (value: any, record: Item) => record.phase === value,
      },
      {
        title: 'Libera em',
        dataIndex: 'enableAfter',
        key: 'enableAfter',
        width: '20%',
        render: (_: any, { key, enableAfter }: Item) => {
          return key === editingRecord.key
            ? (
              <DatePicker
                defaultValue={moment(enableAfter, 'DD/MM/YYYY')}
                value={moment(editingRecord.enableAfter, 'DD/MM/YYYY')}
                format="DD/MM/YYYY"
                onChange={handleChangeEnableAfter}
              />
            ) : (<Text>{enableAfter}</Text>);
        },
        sorter: (a: Item, b: Item, order?: 'ascend' | 'descend' | null) => {
          if (order && order === 'ascend') {
            return moment(a.enableAfter, 'DD/MM/YYYY').isAfter(moment(b.enableAfter, 'DD/MM/YYYY')) ? 1 : -1;
          }
          return moment(b.enableAfter, 'DD/MM/YYYY').isAfter(moment(a.enableAfter, 'DD/MM/YYYY')) ? -1 : 1;
        },
      },
      {
        title: 'Ação',
        dataIndex: 'action',
        width: '5%',
        render: (_: any, item: Item) => {
          const isEditing = !!editingRecord.key;
          return (
            <Space>
              {!isEditing && !blockEdit && <Button icon={<EditOutlined />} onClick={() => edit(item)} />}
              {isEditing && (
                <>
                  <Button icon={<CheckOutlined />} onClick={save} className="color-success" />
                  <Button icon={<CloseOutlined />} onClick={cancel} />
                  <Button danger icon={<DeleteOutlined />} onClick={flagDeletion} />
                </>
              )}
            </Space>
          );
        },
      },
      {
        title: 'Requisitos',
        dataIndex: 'prerequisites',
        key: 'prerequisites',
        width: '5%',
        render: (_: any, item: Item) => (
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              showPrereqDrawer();
              edit(item);
            }}
          />
        ),
      },
    ];
  }, [edit, editingRecord, handleChangePhase, handleChangeEnableAfter,
    lessons, save, blockEdit, cancel, flagDeletion]);

  const courseLessonsData = useMemo(() => {
    return lessonsState.map(lesson => ({
      ...lesson,
      key: lesson.lessonId,
      classification: lesson.classification.join(' | '),
      phase: lesson.phase,
      prerequisites: lesson.prerequisites,
      enableAfter: formatDate(new Date(lesson.enableAfter)),
    }));
  }, [lessonsState]);

  return (
    <>
      <Drawer
        title="Adicionar pré-requisito"
        placement="right"
        onClose={onClosePrereqDrawer}
        visible={visible}
        width="25rem"
      >
        <p>
          {'Selecione os temas que deseja adicionar como pré-requisito para '}
          <b>{editingRecord.classification}</b>
        </p>
        <p style={{ margin: 0 }}><b>Tema</b></p>
        <Select
          style={{ width: '100%' }}
          placeholder="Selecione o tema"
          value={prereqSelectorValue}
          onChange={value => {
            setPrereqSelectorValue(value);
          }}
        >
          {[...lessons]
            .sort((a, b) => (a.classification.join(' | ') > b.classification.join(' | ') ? 1 : -1))
            .map(lesson => (
              <Option key={lesson.lessonId} value={lesson.lessonId}>
                {lesson.classification.join(' | ')}
              </Option>
            ))}
        </Select>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '1rem 0',
          }}
        >
          <Button
            disabled={!prereqSelectorValue}
            style={{
              width: '50%',
            }}
            onClick={handleAddPrerequistes}
          >
            Adicionar
          </Button>
        </div>
        {
          editingRecord.prerequisites ? (
            <>
              <p>
                <b>Este tema tem os seguintes pré-requisitos:</b>
              </p>
              <ol>
                {editingRecord.prerequisites.map(id => (
                  <div
                    style={{
                      display: 'flex',
                      gap: '1rem',
                      margin: '.5rem 0',
                    }}
                  >
                    <li key={id} style={{ flex: 8 }}>
                      {
                        lessonsHashMap[id].classification.join(' | ')
                      }
                    </li>
                    <Button
                      size="small"
                      style={{ marginRight: '1rem', flex: 1 }}
                      icon={<FontAwesomeIcon fontSize={2} icon={faTrashAlt} />}
                      onClick={() => {
                        handleRemovePrerequisite(id);
                      }}
                    />
                  </div>
                ))}
              </ol>
            </>
          ) : (
            <p>
              Este tema ainda não tem pré-requisitos...
            </p>
          )
        }
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '1rem 0',
          }}
        >
          <Button
            disabled={!editingRecord.prerequisites}
            style={{
              width: '50%',
            }}
            onClick={() => {
              save();
              onClosePrereqDrawer();
            }}
          >
            Salvar
          </Button>
        </div>
      </Drawer>
      <Collapse accordion>
        <Panel
          key="only"
          header={(
            <Row justify="space-between">
              <Text>Aulas</Text>
              {progress.key && (
                <ProgressPopover progress={progress} />
              )}
            </Row>
          )}
        >
          <Col className="mb-2">
            <AddLessonInput
              onConfirm={addNewLesson}
              lessonsIds={lessonsState.map(l => l.lessonId)}
              courseId={_id}
              disabled={blockEdit}
            />
          </Col>
          <Table
            expandable={{
              expandedRowRender: ({ prerequisites }) => (
                <div>
                  <p>
                    <b>Pré-requisitos:</b>
                  </p>
                  <ol>
                    {prerequisites && prerequisites.map(id => (
                      <div
                        key={id}
                        style={{
                          display: 'flex',
                          gap: '1rem',
                          margin: '.5rem 0',
                        }}
                      >
                        <li>
                          {
                            lessonsHashMap[id].classification.join(' | ')
                          }
                        </li>
                      </div>
                    ))}
                  </ol>
                </div>
              ),
              rowExpandable: record => Boolean(record.prerequisites?.length),
            }}
            size="small"
            pagination={false}
            columns={columns}
            sortDirections={['ascend', 'descend']}
            dataSource={courseLessonsData}
            rowClassName={({ action }) => (action ? rowStatusClassNames[action] : '')}
          />
        </Panel>
      </Collapse>
      <Row style={{ marginBottom: 15 }}>
        <Space className="mt-2">
          <Button
            type="ghost"
            icon={<CloseOutlined />}
            disabled={!dirtyInputs}
            onClick={handleUndo}
          >
            Desfazer
          </Button>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            disabled={!dirtyInputs}
            onClick={handleUpdateModal}
            loading={updating}
          >
            Salvar
          </Button>
        </Space>
      </Row>
    </>
  );
}
