import { Row } from 'antd';
import { FC } from 'react';
import { ForumInfo } from './ForumInfo';
import { ForumCommentContent } from './ForumCommentContent';
import { ForumListButtons } from './ForumListButtons';
import { ForumStatus } from './ForumStatus';
import { ForumCommentType } from '../../../hooks/useCommentsManagement';

interface ForumComment {
  forumComment: ForumCommentType;
  removeBorder?: boolean;
}

export const ForumListTile: FC<ForumComment> = ({
  forumComment,
  removeBorder = false,
}) => {
  return (
    <Row
      align="top"
      justify="space-between"
      style={{
        padding: '16px',
        borderBottom: removeBorder ? undefined : '1px solid #74777F',
      }}
    >
      <div style={{ width: '100%', maxWidth: '620px' }}>
        <ForumCommentContent
          user={forumComment.user}
          courseId={forumComment.courseId}
          body={forumComment.body}
          isPublic={forumComment.status.isPublic}
          limitText
        />
      </div>
      <ForumStatus
        badgeStatus={forumComment.status.answerStatus}
        createdAt={new Date(forumComment.createdAt)}
        isReserved={!!forumComment.reservedBy}
      />
      <ForumInfo
        forumContentType={forumComment.contentType}
        area={forumComment.classification[0]}
        contentId={forumComment.contentId}
        courseId={forumComment.courseId}
      />
      <ForumListButtons
        commentId={forumComment.id}
        status={forumComment.status}
        reservedBy={forumComment.reservedBy}
        area={forumComment.classification[0] ?? ''}
      />
    </Row>
  );
};
