import {
  gql, QueryResult, useMutation, useQuery,
} from '@apollo/client';
import { useCallback } from 'react';

export type BurgerMenuItem = {
  _id: string;
  title: string;
  icon: string;
  url: string;
  subgroup: string;
  order: number;
  permissions: string[];
}

interface FindBurgerMenuItemsInput {
  filter?: Partial<BurgerMenuItem>;
}

interface FindBurgerMenuItemsOutput {
  findBurgerMenuItemsAdmin: BurgerMenuItem[];
}

const FIND_BURGER_MENU_ITEMS = gql`
  query findBurgerMenuItemsAdmin($filter: FilterBurgerMenuItemsInputType) {
    findBurgerMenuItemsAdmin(filter: $filter) {
      _id
      title
      icon
      url
      subgroup
      order
      permissions
    }
  }
`;

export function useFindBurgerMenuItems(filter?: Partial<BurgerMenuItem>) {
  return useQuery<FindBurgerMenuItemsOutput, FindBurgerMenuItemsInput>(FIND_BURGER_MENU_ITEMS, {
    variables: {
      filter,
    },
  });
}

interface FindBurgerSubgroupsOutput {
  findSubgroups: string[];
}

const FIND_BURGER_SUBGROUPS = gql`
  query {
    findSubgroups
  }
`;

export function useFindSubgroups() {
  return useQuery<FindBurgerSubgroupsOutput>(FIND_BURGER_SUBGROUPS);
}

interface CreateBurgerMenuItemInput {
  input: Omit<BurgerMenuItem, '_id'>;
}

interface CreateBurgerMenuItemOutput {
  createBurgerMenuItem: string;
}

const CREATE_BURGER_MENU_ITEM = gql`
  mutation CreateBurgerMenuItem($input: CreateBurgerMenuItemInputType!) {
    createBurgerMenuItem(input: $input)
  }
`;

interface UpdateBurgerMenuItemsInput {
  input: Partial<Omit<BurgerMenuItem, '_id'>> & { _id: string[] };
}

interface UpdateBurgerMenuItemsOutput {
  updateBurgerMenuItems: number;
}

const UPDATE_BURGER_MENU_ITEMS = gql`
  mutation UpdateBurgerMenuItems($input: UpdateBurgerMenuItemsInputType!) {
    updateBurgerMenuItems(input: $input)
  }
`;

interface DeleteManyBurgerMenuItemsInput {
  ids: string[];
}

interface DeleteManyBurgerMenuItemsOutput {
  deleteManyBurgerMenuItems: number;
}

const DELETE_MANY_BURGER_ITEMS = gql`
  mutation DeleteManyBurgerMenuItems($ids: [ObjectId!]!) {
    deleteManyBurgerMenuItems(ids: $ids)
  }
`;

export function useBurgerItemsControls() {
  const [createBurgerMenuItemMutation] = useMutation<CreateBurgerMenuItemOutput, CreateBurgerMenuItemInput>(CREATE_BURGER_MENU_ITEM);
  const [updateBurgerMenuItemsMutation] = useMutation<UpdateBurgerMenuItemsOutput, UpdateBurgerMenuItemsInput>(UPDATE_BURGER_MENU_ITEMS);
  const [deleteManyBurgerItemsMutation] = useMutation<DeleteManyBurgerMenuItemsOutput, DeleteManyBurgerMenuItemsInput>(DELETE_MANY_BURGER_ITEMS);

  const handleCreateBurgerMenuItem = useCallback(async (input: Omit<BurgerMenuItem, '_id'>) => {
    const result = await createBurgerMenuItemMutation({
      variables: {
        input,
      },
    });
    return result.data?.createBurgerMenuItem;
  }, [createBurgerMenuItemMutation]);

  const handleDeleteBurgerItems = useCallback(async (ids: string[]) => {
    const result = await deleteManyBurgerItemsMutation({
      variables: {
        ids,
      },
    });
    return result.data?.deleteManyBurgerMenuItems;
  }, [deleteManyBurgerItemsMutation]);

  const handleUpdateBurgerItems = useCallback(async (input: Partial<Omit<BurgerMenuItem, '_id'>> & { _id: string[] }) => {
    const result = await updateBurgerMenuItemsMutation({
      variables: {
        input,
      },
    });
    return result.data?.updateBurgerMenuItems;
  }, [updateBurgerMenuItemsMutation]);

  return {
    handleCreateBurgerMenuItem,
    handleUpdateBurgerItems,
    handleDeleteBurgerItems,
  };
}
