import {
  gql, QueryResult, QueryTuple, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import { message } from 'antd';
import { useCallback, useState } from 'react';
import { hasAnyPrivilege } from '../utils/profile-utils';

export interface CourseObjectType {
  course: string;
  userCourse: string;
  classificationTree: string;
  status: string;
  trialEndsAt?: string;
  studyWeek?: StudyWeek;
}

export interface Graduation {
  institution: string;
  uf: string;
  yearConclusion: string;
  desiredExpertise: string;
  isGraduated: boolean;
}

export interface ProfileWork {
  inHealthArea: boolean;
  placeOfWork: string;
  weeklyHours: string;
}
export interface InstitutionTarget {
  uf: string;
  institution: string;
}

export interface StudyWeekSchema {
  theoreticalStudy: boolean;
  theoreticalReview: boolean;
  smartReview: boolean;
  examAndMocks: boolean;
}

export interface StudyWeek {
  sunday: StudyWeekSchema;
  monday: StudyWeekSchema;
  tuesday: StudyWeekSchema;
  wednesday: StudyWeekSchema;
  thursday: StudyWeekSchema;
  friday: StudyWeekSchema;
  saturday: StudyWeekSchema;
}

export interface Profile {
  _id: string;
  cpf: string;
  name: string;
  email: string;
  courses: CourseObjectType[];
  graduation: Graduation;
  questionnaireAnswered: boolean;
  institutionTarget: InstitutionTarget[];
  studyWeek: StudyWeek;
  eduzzCode: string;
  mobilePhone: string;
  uid: string;
  createdAt: string;
  updatedAt: string;
  gender: 'f' | 'm';
  work: ProfileWork;
  birthday: string;
  roles?: string[];
}

export interface ProfileOutput {
  profile: Profile;
}

const studyWeekFragment = `
  studyWeek {
    sunday {
      theoreticalStudy
      theoreticalReview
      smartReview
      examAndMocks
    }
    monday {
      theoreticalStudy
      theoreticalReview
      smartReview
      examAndMocks
    }
    tuesday {
      theoreticalStudy
      theoreticalReview
      smartReview
      examAndMocks
    }
    wednesday {
      theoreticalStudy
      theoreticalReview
      smartReview
      examAndMocks
    }
    thursday {
      theoreticalStudy
      theoreticalReview
      smartReview
      examAndMocks
    }
    friday {
      theoreticalStudy
      theoreticalReview
      smartReview
      examAndMocks
    }
    saturday {
      theoreticalStudy
      theoreticalReview
      smartReview
      examAndMocks
    }
  }
`;

export const PROFILE = gql`
  query Profile {
    profile {
      _id
      cpf
      name
      email
      courses {
        course
        userCourse
        classificationTree
        ${studyWeekFragment}
      }
      graduation {
        institution
        uf
        yearConclusion
        desiredExpertise
        isGraduated
      }
      questionnaireAnswered
      institutionTarget {
        uf
        institution
      }
      ${studyWeekFragment}
      gender
      work {
        inHealthArea
        placeOfWork
        weeklyHours
      }
      eduzzCode
      mobilePhone
      uid
      createdAt
      updatedAt
      birthday
      roles
    }
  }
`;

export const useProfile = (): QueryResult<ProfileOutput> => {
  return useQuery<ProfileOutput>(PROFILE, {
    onError: err => {
      if (err.networkError) {
        message.error('Houve um erro com a conexão ao servidor!');
        return;
      }
      message.error(err.message);
    },
    onCompleted: data => {
      if (data.profile && !hasAnyPrivilege(data.profile)) {
        message.error('Você não tem permissão para acessar a página de administrador!');
      }
    },
    fetchPolicy: 'network-only',
  });
};

export const useLazyProfile = (): QueryTuple<ProfileOutput, Record<string, any>> => {
  return useLazyQuery<ProfileOutput>(PROFILE, {
    onError: err => {
      if (err.networkError) {
        message.error('Houve um erro com a conexão ao servidor!');
        return;
      }
      message.error(err.message);
    },
    onCompleted: data => {
      if (data.profile && !hasAnyPrivilege(data.profile)) {
        message.error('Você não tem permissão para acessar a página de administrador!');
      }
    },
    fetchPolicy: 'network-only',
  });
};

const MANAGE_USER_ROLES = gql`
  mutation ManageUserRoles($userId: ObjectId!, $roles: [String!]!) {
    manageUserRoles(userId: $userId, roles: $roles)
  }
`;

interface ManageUserRolesInput {
  userId: string;
  roles: string[];
}

interface ManageUserRolesOutput {
  manageUserRoles: boolean;
}

export const useManageUserRoles = () => {
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [manageUserRoles] = useMutation<ManageUserRolesOutput, ManageUserRolesInput>(MANAGE_USER_ROLES, {
    onError: error => {
      message.error(error.message);
    },
  });

  const handleManageRoles = useCallback(async (variables: ManageUserRolesInput) => {
    setLoadingRoles(true);
    try {
      await manageUserRoles({
        variables,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingRoles(false);
    }
  }, [manageUserRoles]);
  return { handleManageRoles, loadingRoles };
};

export const PROFILE_BY_EMAIL = gql`
  query FindProfileByEmail($email: String!) {
    findProfileByEmail(email: $email) {
      _id
      cpf
      name
      email
      courses {
        course
        userCourse
        classificationTree
        status
        trialEndsAt
        ${studyWeekFragment}
      }
      graduation {
        institution
        uf
        yearConclusion
        desiredExpertise
        isGraduated
      }
      questionnaireAnswered
      institutionTarget {
        uf
        institution
      }
      ${studyWeekFragment}
      gender
      work {
        inHealthArea
        placeOfWork
        weeklyHours
      }
      eduzzCode
      mobilePhone
      uid
      createdAt
      updatedAt
      birthday
      roles
    }
  }
`;

interface ProfileByEmailInput {
  email: string;
}

interface ProfileByEmailOutput {
  findProfileByEmail: Profile;
}

export const useProfileByEmail = (email: string): QueryResult<ProfileByEmailOutput, ProfileByEmailInput> => {
  return useQuery<ProfileByEmailOutput, ProfileByEmailInput>(PROFILE_BY_EMAIL, {
    variables: {
      email,
    },
    skip: !email,
    onError: err => {
      message.error(err.message);
    },
  });
};

const MANAGE_USER_COURSE = gql`
  mutation ManageUserCourse(
    $action: String!,
    $profileId: ObjectId!,
    $userCourseId: ObjectId!
  ) {
    manageUserCourse(
      action: $action,
      profileId: $profileId,
      userCourseId: $userCourseId,
    )
  }
`;

interface ManageUserCourseInput {
  action: string;
  profileId: string;
  userCourseId: string;
}

interface ManageUserCourseOutput {
  manageUserCourse: boolean;
}

const ADD_ALL_ACTIVE_COURSES_TO_PROFILE = gql`
  mutation AddAllActiveCoursesToProfile(
    $profileId: ObjectId!, 
  ) {
    addAllActiveCoursesToProfile(
    profileId: $profileId,
   )
  }
`;

interface AddAllActiveCoursesToProfileInput {
  profileId: string;
}

interface AddAllActiveCoursesToProfileOutput {
  addAllActiveCoursesToProfile: boolean;
}

const ADD_COURSE_TO_USER = gql`
  mutation AddCourseToUser(
    $courseId: ObjectId!
    $profileId: ObjectId!,
  ) {
    addCourseToUser(
      courseId: $courseId,
      profileId: $profileId,
    )
  }
`;

interface AddCourseToUserInput {
  courseId: string;
  profileId: string;
}

interface AddCourseToUserOutput {
  addCourseToUser: boolean;
}

export const useAddAllActiveCourses = () => {
  const [addAllActiveCoursesToProfile, { data, loading }] = useMutation<AddAllActiveCoursesToProfileOutput, AddAllActiveCoursesToProfileInput>(
    ADD_ALL_ACTIVE_COURSES_TO_PROFILE, {
      onError: error => {
        message.error(error.message);
      },
    },
  );
  return {
    addAllActiveCoursesToProfile,
    data,
    loading,
  };
};

export function useManageUserCourse() {
  const [manageUserCourse] = useMutation<ManageUserCourseOutput, ManageUserCourseInput>(MANAGE_USER_COURSE, {
    onError: error => {
      message.error(error.message);
    },
  });

  const [addCourseToUser] = useMutation<AddCourseToUserOutput, AddCourseToUserInput>(ADD_COURSE_TO_USER, {
    onError: error => {
      message.error(error.message);
    },
  });
  const handleAddCourseToUser = useCallback(async ({
    courseId,
    profileId,
  }: AddCourseToUserInput) => {
    const res = await addCourseToUser({
      variables: {
        courseId,
        profileId,
      },
    });
    return res.data?.addCourseToUser;
  }, [addCourseToUser]);

  const handleManageUserCourse = useCallback(async ({
    action,
    profileId,
    userCourseId,
  }: ManageUserCourseInput) => {
    const res = await manageUserCourse({
      variables: {
        action,
        profileId,
        userCourseId,
      },
    });
    return res.data?.manageUserCourse;
  }, [manageUserCourse]);

  return { handleManageUserCourse, handleAddCourseToUser };
}
