import { Col, Row } from 'antd';
import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { getParam } from '../../hooks/useSearchParam';
import { ForumComment } from './components/ForumComment';
import { ForumCommentsProvider } from '../../contexts/ForumComments.context';

export const ForumCommentPage: FC = () => {
  const navigate = useHistory();

  const commentId = getParam('commentId');
  const area = getParam('area');

  useEffect(() => {
    if (!commentId) {
      navigate.push('/admin/forum');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Col style={{
      maxWidth: '1300px',
      margin: '16px auto',
    }}
    >
      <Row style={{ marginBottom: '16px', fontSize: '22px' }}>
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={(): void => navigate.push('/admin/forum')}
        />
      </Row>
      <ForumCommentsProvider>
        <ForumComment commentId={commentId} area={area} />
      </ForumCommentsProvider>
    </Col>
  );
};
