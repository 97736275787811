import { gql, QueryResult, useQuery } from '@apollo/client';

export interface TreeLeaf {
  name: string;
  children?: TreeLeaf[];
}

interface ClassificationTreesInput {
  treeId: string;
}

interface ClassificationTreesOutput {
  classificationTree: {
    tagTreeName: string;
    tree: TreeLeaf[];
  };
}

const CLASSIFICATION_TREES = gql`
  query ClassificationTrees ($treeId: ObjectId!) {
    classificationTree: getClassificationTreeAdmin(treeId: $treeId){
      tagTreeName
      tree {
        name
        children {
          name
          children {
            name
            children {
              name
            }
          }
        }
      }
    }
  }
`;

export function useClassificationTrees(treeId: string): QueryResult<ClassificationTreesOutput, ClassificationTreesInput> {
  return useQuery<ClassificationTreesOutput, ClassificationTreesInput>(CLASSIFICATION_TREES, {
    variables: {
      treeId,
    },
    skip: !treeId,
  });
}
