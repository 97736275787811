import { FC, useMemo } from 'react';
import { Select } from 'antd';
import { useMajorAreaFilter } from '../../../../../api/appeal';

export const MajorAreaFilter: FC<{value?: string, handleChange: (e?: string) => void }> = ({
  value,
  handleChange,
}) => {
  const { data, loading } = useMajorAreaFilter();

  const areasData = useMemo(() => data?.majorAreaFilter, [data]);

  return (
    <Select
      style={{ width: '150px' }}
      placeholder="Grande área"
      value={value}
      options={areasData?.map(it => {
        return {
          value: it,
          label: <span>{it}</span>,
        };
      })}
      onChange={handleChange}
      allowClear
      disabled={!areasData}
      loading={loading}
    />
  );
};
