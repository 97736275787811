import { FC, useContext } from 'react';
import { Button, Row } from 'antd';
import {
  faBan, faComment, faEye, faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ForumCommentsContext } from '../../../contexts/ForumComments.context';

export const ForumCommentButtonOptions: FC = () => {
  const {
    forumComment,
    commentManagementController,
    loadingComment,
    isSendingData,
  } = useContext(ForumCommentsContext);

  const isAnswering = forumComment?.status.answerStatus === 'waitingAnswer';
  // const showAnswerButton = forumComment?.status.answerStatus === 'markedAsIgnored';

  return (
    <Row>
      {isAnswering && (
        <Button
          style={{ width: '120px', borderRadius: '8px' }}
          onClick={commentManagementController.setCommentAsIgnore}
          loading={loadingComment || isSendingData}
          shape="round"
          icon={<FontAwesomeIcon icon={faBan} style={{ marginRight: '4px' }} />}
        >
          Ignorar
        </Button>
      )}
      {/* {showAnswerButton && (
        <Button
          style={{ width: '120px', borderRadius: '8px' }}
          onClick={() => commentManagementController.reserveIgnoredComment(forumComment.id)}
          loading={loadingComment || isSendingData}
          shape="round"
          icon={<FontAwesomeIcon icon={faComment} style={{ marginRight: '4px' }} />}
        >
          Responder
        </Button>
      )} */}
      <Button
        style={{ width: '160px', marginLeft: '8px', borderRadius: '8px' }}
        onClick={commentManagementController.setCommentAsPrivate}
        loading={loadingComment || isSendingData}
        shape="round"
        icon={
          <FontAwesomeIcon icon={forumComment?.status.isPublic ? faEyeSlash : faEye} style={{ marginRight: '4px' }} />
        }
      >
        {forumComment?.status.isPublic ? 'Tornar privado' : 'Tornar público'}
      </Button>
    </Row>
  );
};
