import React, { FC, useContext } from 'react';
import { Select, SelectProps } from 'antd';
import { ForumContext } from '../../../../contexts/Forum.context';

type PeriodFilterProps = {
    style?: React.CSSProperties,
}

export const PeriodFilter: FC <PeriodFilterProps> = ({
  style,
}) => {
  const { filterController } = useContext(ForumContext);

  const options : SelectProps['options'] = [
    {
      value: 'FromTheOldestToTheNewest',
      label: <span>Mais antigos</span>,
    },
    {
      value: 'FromTheNewestToTheOldest',
      label: <span>Mais novos</span>,
    },
  ];

  return (
    <Select
      style={{ width: '140px', ...style }}
      placeholder="Período"
      value={filterController.activeFilters.orderBy}
      options={options}
      onChange={filterController.updatePeriodFilter}
    />
  );
};
