/* eslint-disable import/no-duplicates */
/* eslint-disable global-require */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';

const firebaseConfig = ((): Record<string, any> => {
  switch (process.env.REACT_APP_ENV) {
    case 'HOMOLOG':
      return require('./firebase-homolog.json');
    case 'PROD':
      return require('./firebase-prod.json');
    case 'DEV':
    default:
      return require('./firebase-dev.json');
  }
})();

firebase.initializeApp(firebaseConfig);
const createUserApp = firebase.initializeApp(firebaseConfig, 'createUser');
export const createUserAuth = createUserApp.auth();

export const auth = firebase.auth();
export const storage = firebase.storage();
export const database = firebase.database();

export default firebase.app();
