import {
  DatePicker, Modal, Row, Space, Transfer,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import { FC, useCallback, useState } from 'react';
import { useBroadcastNotifications } from '../../../api/notifications';
import { useCourses } from '../../../hooks/useCourses';

interface CreateNotificationModalProps {
  visible: boolean;
  onCancel: () => void;
  onAfterConfirm: () => void;
}

export const CreateNotificationModal: FC<CreateNotificationModalProps> = (
  {
    visible, onCancel, onAfterConfirm,
  }: CreateNotificationModalProps,
) => {
  const {
    findBroadcasts: [findBroadcastsQuery],
    handleCreateBroadcastNotification,
  } = useBroadcastNotifications();

  const [loadingCreate, setLoadingCreate] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [link, setLink] = useState('');
  const [thumbnailLink, setThumbnailLink] = useState('');
  const [dueDate, setDueDate] = useState<Date>();
  const { courses, loading: coursesLoading } = useCourses();
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const handleSelectChange = useCallback((sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  }, []);

  const handleDisableOk = useCallback(() => {
    if (
      !dueDate
      || targetKeys.length === 0
      || message === ''
      || title === ''
    ) {
      return true;
    } return false;
  }, [dueDate, message, targetKeys.length, title]);

  return (
    <Modal
      width="40rem"
      title="Criar notificação"
      visible={visible}
      okButtonProps={{ disabled: handleDisableOk() }}
      confirmLoading={loadingCreate}
      onOk={async () => {
        setLoadingCreate(true);
        if (dueDate) {
          await handleCreateBroadcastNotification({
            body: {
              message,
            },
            dueDate,
            title,
            topics: courses.length === targetKeys.length ? ['all'] : targetKeys,
            authorPicture: 'https://firebasestorage.googleapis.com/v0/b/jj-dev-a2b7a.appspot.com/o'
            + '/images%2Fusers%2Faristo.png?alt=media&token=0c95ab52-af1c-43da-b793-a651854249c9',
            thumbnail: thumbnailLink,
            link,
          });
          onAfterConfirm();
        }
        setLoadingCreate(false);
        onCancel();
      }}
      onCancel={onCancel}
    >
      <Space direction="vertical">
        <Row align="middle">
          <b>Título:</b>
          <Text
            style={{
              marginLeft: '1rem',
              flex: 1,
            }}
            editable={{
              maxLength: 80,
              onChange: setTitle,
            }}
          >
            {title}
          </Text>
        </Row>
        <Row align="middle">
          <b>Mensagem:</b>
          <Text
            style={{
              marginLeft: '1rem',
              flex: 1,
            }}
            editable={{
              maxLength: 200,
              onChange: setMessage,
            }}
          >
            {message}
          </Text>
        </Row>
        <Row align="middle">
          <b style={{ marginRight: '1rem' }}>Termina em:</b>
          <DatePicker
            showTime
            showSecond={false}
            onChange={e => {
              if (e) {
                setDueDate(e.toDate());
              } else {
                setDueDate(undefined);
              }
            }}
          />
        </Row>
        <Row align="middle">
          <b>Link (opcional):</b>
          <Text
            style={{
              marginLeft: '1rem',
              flex: 1,
            }}
            editable={{
              onChange: setLink,
            }}
          >
            {link}
          </Text>
        </Row>
        <Row align="middle">
          <b>Link da thumbnail (opcional):</b>
          <Text
            style={{
              marginLeft: '1rem',
              flex: 1,
            }}
            editable={{
              onChange: setThumbnailLink,
            }}
          >
            {thumbnailLink}
          </Text>
        </Row>
        <Row>
          <Transfer
            titles={['Não selecionados', 'Selecionados']}
            pagination
            showSearch
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onChange={setTargetKeys}
            onSelectChange={handleSelectChange}
            render={item => item.title}
            dataSource={courses.map(x => {
              return (
                {
                  key: x._id,
                  title: x.title,
                }
              );
            })}
          />
        </Row>
      </Space>
    </Modal>
  );
};
