import React, { FC, useContext } from 'react';
import { Select, SelectProps } from 'antd';
import { ForumContext } from '../../../../contexts/Forum.context';
import { useCourses } from '../../../../hooks/useCourses';

type CoursesFiltersProps = {
  style?: React.CSSProperties,
}

export const CoursesFilters: FC<CoursesFiltersProps> = ({
  style,
}) => {
  const { filterController } = useContext(ForumContext);
  const { courses, loading } = useCourses();

  const options: { label: string; value: string | null }[] = courses.map((it, _) => {
    return {
      value: it._id,
      label: it.title,
    };
  });

  options.unshift({
    value: null,
    label: 'Todos os cursos',
  });

  const filterOption = (input: string, option?: { label: string; value: string | null }) => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Select
      showSearch
      filterOption={filterOption}
      style={{ width: '180px', ...style }}
      placeholder="Curso"
      dropdownStyle={{ minWidth: '300px' }}
      loading={loading}
      options={options}
      onChange={filterController.updateCourseFilter}
    />
  );
};
