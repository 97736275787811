import { FC, useContext } from 'react';
import { RedoOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ForumContext } from '../../../contexts/Forum.context';

export const UpdateListButton: FC = () => {
  const { getFiltered, isLoading, isSendingData } = useContext(ForumContext);

  return (
    <Button
      style={{ margin: '4px' }}
      icon={<RedoOutlined style={{ color: '#415F91' }} />}
      loading={isLoading || isSendingData}
      onClick={getFiltered}
    >
      Atualizar lista
    </Button>
  );
};
