import { useMemo } from 'react';
import { Lesson } from '../api/lessons';
import { useCourses } from './useCourses';

export function useLessonInCourse(lessonId: string) {
  const { courses } = useCourses();

  const lessonInCourse = useMemo(() => {
    if (!courses) {
      return undefined;
    }
    return courses.filter(course => course.lessons.find(l => l.lessonId === lessonId));
  }, [courses, lessonId]);

  return lessonInCourse;
}
