import { ArrowLeftOutlined } from '@ant-design/icons';
import { Row, Col, Typography } from 'antd';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

export const ReturnButton: FC<{ path?: string, title: string, subTitle?: string }> = ({
  path,
  title,
  subTitle,
}) => {
  const navigate = useHistory();

  const handleReturn = (): void => {
    if (path) {
      navigate.push(path!);
    }

    navigate.goBack();
  };

  return (
    <Row align="middle">
      <ArrowLeftOutlined style={{ padding: '8px', fontSize: '18px' }} onClick={handleReturn} />
      <Col style={{ marginLeft: '4px' }}>
        {subTitle && (
          <Typography style={{ fontSize: '14px', lineHeight: 1, fontWeight: 500 }}>{subTitle}</Typography>
        )}
        <Typography style={{ fontSize: '28px', lineHeight: 1, fontWeight: 400 }}>{title}</Typography>
      </Col>
    </Row>
  );
};
