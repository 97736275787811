import { Typography } from 'antd';
import moment from 'moment';
import { ForumStatusTag } from './ForumStatusTag';
import 'moment/locale/pt-br';

moment.locale('pt-br');

const { Text } = Typography;

type ForumStatusProps = {
    badgeStatus: string;
    createdAt: Date;
    expiresAt?: Date;
    isReserved?: boolean;
}

export function ForumStatus({
  badgeStatus,
  createdAt,
  expiresAt,
  isReserved = false,
} : ForumStatusProps) {
  const hasExpired = moment(expiresAt).isBefore(moment(Date()));
  const status = isReserved && !hasExpired && badgeStatus === 'waitingAnswer' ? 'answering' : badgeStatus;

  return (
    <div style={{
      display: 'flex',
      alignItems: 'flex-start',
      flexFlow: 'column',
      justifyContent: 'flex-start',
      width: '190px',
      marginRight: '16px',
      marginBottom: '16px',
    }}
    >
      <ForumStatusTag status={status} />
      <Text style={{ fontWeight: 600, fontSize: 14, marginTop: 8 }}>
        {`${moment.duration((moment(new Date()).diff(moment(createdAt)))).humanize()} atrás`}
      </Text>
      <Text style={{ fontWeight: 500, fontSize: 12 }}>
        {moment(createdAt).format('DD/MM/YYYY HH:mm')}
      </Text>
    </div>
  );
}
