import { FC } from 'react';
import { Typography } from 'antd';

export const CardComponent: FC<{ cardTitle: string }> = ({
  cardTitle,
  children,
}) => {
  return (
    <div
      style={{
        padding: '16px',
        borderRadius: '8px',
        backgroundColor: 'rgba(65, 95, 145, 0.05)',
      }}
    >
      <Typography
        style={{
          marginBottom: '16px',
          fontSize: '22px',
          color: '#44474E',
        }}
      >
        {cardTitle}
      </Typography>
      {children}
    </div>
  );
};
