import { LoadingOutlined } from '@ant-design/icons';
import { faComment, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Col, Dropdown, MenuProps, Row, Typography,
} from 'antd';
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUnlockAppeal } from '../../../../api/appeal';

type AppealButtonsProps = {
  appealId : string;
  year: number;
}

export const AppealButtons: FC<AppealButtonsProps> = ({
  appealId,
  year,
}) => {
  const [isSendingData, setIsSendingData] = useState(false);
  const navigate = useHistory();

  const [unlockAppeal] = useUnlockAppeal();

  const handleClick = (): void => {
    navigate.push(`/admin/resources/appeals?appealId=${appealId}&year=${year}`);
  };

  const handleLoading = async (): Promise<void> => {
    setIsSendingData(true);
    try {
      await unlockAppeal({
        variables: {
          appealId,
        },
      });
      navigate.push('/admin/resources/appeals');
    } catch (error) {
      console.log('Erro ao liberar recurso!');
    }
    setIsSendingData(false);
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: isSendingData ? (
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      ) : (
        <Row
          style={{ margin: '8px', alignItems: 'center' }}
        >
          <FontAwesomeIcon icon={faComment} style={{ marginRight: '8px' }} />
          <Typography>Ignorar</Typography>
        </Row>
      ),
      onClick: handleLoading,
    },
  ];

  return (
    <Col>
      <Row align="middle" style={{ }}>
        <Button
          style={{ marginRight: '16px' }}
          shape="round"
          icon={(
            <FontAwesomeIcon
              style={{ marginRight: '4px' }}
              icon={faComment}
            />
          )}
          onClick={handleClick}
        >
          Responder
        </Button>
        {/* <Dropdown
          menu={{ items }}
          placement="bottomRight"
        >
          <FontAwesomeIcon icon={faEllipsisV} size="lg" style={{ cursor: 'pointer' }} />
        </Dropdown> */}
      </Row>
    </Col>
  );
};
