import { useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import { message } from 'antd';

import { storage } from '../client/firebase';

const cache: { [k: string]: string } = {};

async function addFileURL(path: string): Promise<typeof cache> {
  if (!path) {
    throw new Error('invalid path.');
  }
  if (cache[path]) {
    return cache;
  }
  try {
    const storageUrl = await storage.ref(path).getDownloadURL();
    cache[path] = storageUrl;
  } catch (error) {
    cache[path] = '/images/broken.png';
    console.error(error);
  }
  return { ...cache };
}

async function uploadFromBase64(path: string, b64: string, fileName?: string): Promise<string> {
  const contentType = /data:([^;]*);base64,/.exec(b64)?.[1].replace('jpeg', 'jpg');
  if (!contentType) {
    throw new Error('Missing content type');
  }
  const data = b64.replace(/data:([^;]*);base64,/, '');
  // eslint-disable-next-line no-useless-escape
  const ext = contentType.replace(/[^\/]*\//, '').split('+')[0];
  const chars = atob(data);
  const bytes = new Uint8Array(Array.from({ length: chars.length }, (_, i) => chars.charCodeAt(i)));
  const snap = await storage.ref(path)
    .child(`${fileName || nanoid()}.${ext === 'jpeg' ? 'jpg' : ext}`)
    .put(bytes, { contentType });
  return snap.ref.fullPath;
}

export interface FirebaseStorageHook {
  getURL: (path: string, useCache?: boolean) => string;
  uploadFromBase64: (path: string, b64: string, fileName?: string) => Promise<string>;
  getURLAsync: (path: string, useCache?: boolean) => Promise<string>;
}

export default function useFirebaseStorageImg(): FirebaseStorageHook {
  const [hookCache, setHookCache] = useState(cache);
  return useMemo(() => {
    const getURL = (path: string): string => {
      if (hookCache[path]) {
        return hookCache[path];
      }
      addFileURL(path)
        .then(setHookCache)
        .catch(e => console.error(e));

      return '/loading.gif';
    };
    const getURLAsync = async (path: string, useCache = true): Promise<string> => {
      if (hookCache[path] && useCache) {
        return hookCache[path];
      }
      const file = await addFileURL(path);
      setHookCache(file);
      const url = await storage.ref(path).getDownloadURL();
      return url;
    };
    return { getURL, uploadFromBase64, getURLAsync };
  }, [hookCache]);
}

interface FirebaseStorageImageList {
  name: string;
  url: string;
  path: string;
}

export async function useFirebaseStorageImageList(path: string, prefix: string): Promise<FirebaseStorageImageList[]> {
  const listRef = await storage.ref(path).listAll();
  const filtered = listRef.items.filter(i => i.name.includes(prefix));
  return Promise.all(filtered.map(async item => ({
    name: item.name,
    url: await item.getDownloadURL(),
    path: item.fullPath,
  })));
}

export async function useDeleteFirebaseStorageImage(fileName: string): Promise<void> {
  const fileRef = storage.ref(fileName);
  try {
    await fileRef.delete();
    message.success('Imagem deletada com sucesso!');
  } catch (error) {
    message.error('Erro ao deletar a imagem!');
    throw error;
  }
}
