import {
  gql, QueryResult, useMutation, useQuery,
} from '@apollo/client';
import { message } from 'antd';
import { useCallback } from 'react';

export interface SetVideoScopeInput {
  contentId: string;
  lessonId: string;
  scope: string;
}
export interface SetVideoScopeOutput {
  setVideoScope: boolean;
}

const SET_VIDEO_SCOPE = gql`
  mutation SetVideoScope(
    $contentId: ObjectId!, $lessonId: ObjectId!, $scope: String!
    ) {
    setVideoScope(
      contentId: $contentId
      lessonId: $lessonId
      scope: $scope
    )
  }
`;

export function useSetVideoScope() {
  const [setScope] = useMutation<
    SetVideoScopeOutput, SetVideoScopeInput
  >(SET_VIDEO_SCOPE);

  const handleSetScope = useCallback(async ({
    contentId, lessonId, scope,
  }: SetVideoScopeInput) => {
    const res = await setScope({
      variables: {
        contentId,
        lessonId,
        scope,
      },
    });
    return res.data?.setVideoScope || false;
  }, [setScope]);

  return {
    onSetVideoScope: handleSetScope,
  };
}

export interface Video {
  _id: string;
  url: string;
  title: string;
  isOnlyTheoreticalReview?: boolean;
  liveChatLink?: string;
  liveStartsAt?: Date;
  subtitle: string;
  comment?: Comment;
  jjPlus?: {
    playlist: string;
    category: string;
    permissions: string[];
  }
  createdAt?: string;
}

export interface VideoDetailsInput {
  _id: string;
}

export interface VideoDetailsOutput {
  videoDetails: Video;
}

const VIDEO_DETAILS = gql`
  query VideoDetails($_id: ObjectId!) {
    videoDetails(_id: $_id) {
      _id
      url
      title
      subtitle
      liveChatLink
      liveStartsAt
      jjPlus {
        category
        playlist
        permissions
      }
      createdAt
    }
  }
`;

export function useVideoDetails(_id: string): QueryResult<VideoDetailsOutput, VideoDetailsInput> {
  return useQuery<VideoDetailsOutput, VideoDetailsInput>(VIDEO_DETAILS, {
    variables: {
      _id,
    },
    skip: !_id,
    onError: error => {
      message.error(error.message);
    },
  });
}

interface UpdateVideoDetailsOutput {
  updateVideoDetails: boolean;
}

export interface UpdateVideoDetailsInput extends Omit<Partial<Video>, '_id'> {
  object: Omit<Partial<Video>, '_id'> & { _id: string };
}

const UPDATE_VIDEO_DETAILS = gql`
  mutation UpdateVideoDetails(
    $object: VideoInputType!
  ) {
    updateVideoDetails(object: $object)
  }
`;

export function useUpdateVideoDetails() {
  const [updateVideoDetails] = useMutation<UpdateVideoDetailsOutput, UpdateVideoDetailsInput>(UPDATE_VIDEO_DETAILS, {
    onError: error => {
      message.error(error.message);
    },
  });
  const handleUpdateVideoDetails = useCallback(async (
    video: Omit<Partial<Video>, '_id'> & { _id: string },
  ) => {
    const res = await updateVideoDetails({
      variables: {
        object: video,
      },
    });
    return res.data?.updateVideoDetails;
  }, [updateVideoDetails]);
  return handleUpdateVideoDetails;
}

interface LessonVideosInput {
  lessonId?: string;
}

interface LessonVideosOutput {
  lessonVideos: Video[];
}

const LESSON_VIDEOS = gql`
  query LessonVideos(
    $lessonId: ObjectId!
  ) {
    lessonVideos(lessonId: $lessonId) {
      _id
      title
      subtitle
      isOnlyTheoreticalReview
      url
    }
  }
`;

const CREATE_VIDEO = gql`
  mutation CreateVideo(
    $lessonId: ObjectId
  ) {
    createVideo(lessonId: $lessonId)
  }
`;

export interface CreateVideoInput {
  lessonId?: string;
}

export interface CreateVideoOutput {
  createVideo: string;
}

const DELETE_VIDEO = gql`
  mutation DeleteVideo(
    $contentId: ObjectId!
  ) {
    deleteVideo(contentId: $contentId)
  }
`;
export interface DeleteVideoInput {
  contentId: string;
}

export interface DeleteVideoOutput {
  deleteVideo: boolean;
}

const REMOVE_VIDEO_FROM_LESSON = gql`
  mutation RemoveVideoFromLesson(
    $contentId: ObjectId!
    $lessonId: ObjectId!
  ) {
    removeVideoFromLesson(contentId: $contentId, lessonId: $lessonId)
  }
`;
export interface RemoveVideoFromLessonInput {
  contentId: string;
  lessonId: string;
}

export interface RemoveVideoFromLessonOutput {
  removeVideoFromLesson: boolean;
}

export function useVideos(lessonId?: string) {
  const lessonVideos = useQuery<LessonVideosOutput, LessonVideosInput>(LESSON_VIDEOS, {
    variables: {
      lessonId,
    },
    skip: !lessonId,
  });

  const [createNewVideo] = useMutation<CreateVideoOutput, CreateVideoInput>(CREATE_VIDEO);
  const handleCreateNewVideoMutation = useCallback(async () => {
    const response = await createNewVideo({
      variables: {
        lessonId,
      },
    });
    return response.data?.createVideo;
  }, [createNewVideo, lessonId]);

  const [deleteVideo] = useMutation<DeleteVideoOutput, DeleteVideoInput>(DELETE_VIDEO);
  const handleDeleteVideoMutation = useCallback(async (contentId: string) => {
    const response = await deleteVideo({
      variables: {
        contentId,
      },
    });
    return response.data?.deleteVideo;
  }, [deleteVideo]);

  const [removeVideoFromLesson] = useMutation<RemoveVideoFromLessonOutput, RemoveVideoFromLessonInput>(REMOVE_VIDEO_FROM_LESSON);
  const handleRemoveVideoFromLesson = useCallback(async (contentId: string) => {
    if (!lessonId) {
      return undefined;
    }
    const response = await removeVideoFromLesson({
      variables: {
        contentId,
        lessonId,
      },
    });
    return response.data?.removeVideoFromLesson;
  }, [lessonId, removeVideoFromLesson]);

  return {
    lessonVideos,
    handleCreateNewVideoMutation,
    handleDeleteVideoMutation,
    handleRemoveVideoFromLesson,
  };
}
