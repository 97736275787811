import { Col } from 'antd';
import { FC } from 'react';
import {
  DragDropContext, DropResult, Droppable, DroppableProvided,
} from 'react-beautiful-dnd';

type DraggableComponentProps = {
  handleDragEnd: (e: DropResult) => Promise<void>;
}

export const DraggableComponent: FC<DraggableComponentProps> = ({
  children,
  handleDragEnd,
}) => {
  return (
    <Col>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="planners">
          {(provided: DroppableProvided): JSX.Element => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {children}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Col>
  );
};
