import React, { FC } from 'react';
import {
  Route, Redirect, RouteProps,
} from 'react-router-dom';
import useAccount from '../hooks/useAccount';

interface PrivateRouteProps extends RouteProps {
  clearences?: string[];
}

const PrivateRoute: FC<PrivateRouteProps> = ({ component, clearences, ...rest }) => {
  const { isAuthenticated } = useAccount();
  if (isAuthenticated(clearences)) {
    return (
      <Route {...rest} component={component} />
    );
  }

  return (
    <Redirect to={clearences ? '/admin/forbiden' : '/'} />
  );
};

export default PrivateRoute;
