import { FC } from 'react';
import { Col, Row, Typography } from 'antd';
import { FileOutlined, CalendarOutlined } from '@ant-design/icons';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ExamInfoProps = {
  institution: string;
  year: number;
  area: string;
}

const textStyle = {
  fontSize: '14px',
  fontWeight: 400,
};

export const ExamInfo: FC<ExamInfoProps> = ({
  institution,
  year,
  area,
}) => {
  return (
    <Col style={{ width: '240px' }}>
      <Row>
        <div style={{ width: '20px', height: '20px', marginRight: '2px' }}>
          <FileOutlined />
        </div>
        <Typography style={textStyle}>{institution}</Typography>
      </Row>
      <Row>
        <div style={{ width: '20px', height: '20px', marginRight: '2px' }}>
          <CalendarOutlined />
        </div>
        <Typography style={textStyle}>{year}</Typography>
      </Row>
      <Row>
        <div style={{ width: '20px', height: '20px', marginRight: '2px' }}>
          <FontAwesomeIcon icon={faGraduationCap} />
        </div>
        <Typography style={textStyle}>{area}</Typography>
      </Row>
    </Col>
  );
};
