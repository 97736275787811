import {
  DeleteOutlined, DeleteRowOutlined, EditOutlined, PlusOutlined,
} from '@ant-design/icons';
import {
  List,
  Space,
  Spin,
  Typography,
  Modal,
  message,
  Row,
  Button,
  Empty,
} from 'antd';
import { useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { IText, useTexts } from '../../api/texts';
import EditDataWrapper from '../../components/EditDataWrapper';
import { getParam } from '../../hooks/useSearchParam';

const { Text } = Typography;
const { confirm } = Modal;

export function TextsPage() {
  const lessonId = getParam('aula');
  const history = useHistory();
  const [loadingCreate, setLoadingCreate] = useState(false);
  const { state } = useLocation<{ title: string } | undefined>();

  const {
    lessonTexts: { data, loading: queryLoading, refetch },
    handleCreateNewTextMutation,
    handleDeleteTextMutation,
    handleRemoveTextFromLesson,
  } = useTexts(lessonId);

  const texts = useMemo(() => {
    if (queryLoading || !data?.lessonTexts) {
      return undefined;
    }
    return data.lessonTexts;
  }, [data, queryLoading]);

  async function handleCreateText() {
    setLoadingCreate(true);
    try {
      const textId = await handleCreateNewTextMutation();
      await refetch({
        lessonId,
      });
      setLoadingCreate(false);
      history.push({
        pathname: 'editar-apostila',
        search: `?id=${textId}`,
      });
    } catch (err) {
      console.error(err);
      message.error('Erro ao criar uma nova apostila!');
      setLoadingCreate(false);
    }
  }

  function handleDeleteModal(text: Pick<IText, '_id' | 'title' | 'subtitle'>) {
    confirm({
      title: 'Deseja mesmo deletar esse text?',
      content: (
        <>
          <div>
            {`Título: ${text.title}`}
          </div>
          <div>
            {`Subtítulo: ${text.subtitle}`}
          </div>
        </>
      ),
      onOk: async () => {
        try {
          await handleDeleteTextMutation(text._id);
          await refetch({
            lessonId,
          });
        } catch (err) {
          console.error(err);
          message.error('Erro ao deletar a apostila');
        }
      },
      onCancel: () => { },
    });
  }

  function handleRemoveFromLessonModal(text: Pick<IText, '_id' | 'title' | 'subtitle'>) {
    confirm({
      title: `Ao remover um conteúdo de uma aula ele ainda continuará aparecendo para outras aulas que o utilizem.
      Deseja mesmo remover a apostila dessa aula?`,
      content: (
        <>
          <div>
            {`Título: ${text.title}`}
          </div>
          <div>
            {`Subtítulo: ${text.subtitle}`}
          </div>
        </>
      ),
      onOk: async () => {
        try {
          await handleRemoveTextFromLesson(text._id);
          await refetch({
            lessonId,
          });
        } catch (err) {
          console.error(err);
          message.error('Erro ao remover a apostila');
        }
      },
      onCancel: () => { },
    });
  }

  return (
    <EditDataWrapper
      title="Apostilas"
      searchKey="aula"
      placeholder="Buscar por aula"
    >
      {queryLoading && <Spin />}
      {texts && texts.length > 0 && (
        <List
          size="small"
          dataSource={texts}
          header={(
            <Row justify="space-between">
              <Text strong>
                Lista de apostilas
                {state?.title ? ` de ${state?.title} ` : ''}
              </Text>
              <Button
                icon={<PlusOutlined />}
                onClick={handleCreateText}
                loading={loadingCreate}
              >
                Criar apostila
              </Button>
            </Row>
          )}
          renderItem={text => {
            const search = `?id=${text._id}`;
            return (
              <List.Item actions={[
                <Link to={{
                  pathname: '/admin/editar-apostila',
                  search,
                }}
                >
                  <Space>
                    <EditOutlined />
                    Editar
                  </Space>
                </Link>,
                <Button
                  style={{ padding: 0 }}
                  type="link"
                  icon={<DeleteRowOutlined />}
                  onClick={() => handleRemoveFromLessonModal(text)}
                >
                  Remover
                </Button>,
                <Button
                  style={{ padding: 0 }}
                  type="link"
                  icon={<DeleteOutlined />}
                  danger
                  onClick={() => handleDeleteModal(text)}
                >
                  Deletar
                </Button>,
              ]}
              >
                <List.Item.Meta
                  title={`${text.title || 'Sem título'} - ${text.subtitle || 'Sem subtítulo'}`}
                  description={text.description}
                />
              </List.Item>
            );
          }}
        />
      )}
      {texts && texts.length === 0 && (
        <Empty description="Nenhuma apostila encontrada">
          <Button
            icon={<PlusOutlined />}
            onClick={handleCreateText}
            loading={loadingCreate}
          >
            Criar apostila
          </Button>
        </Empty>
      )}
    </EditDataWrapper>
  );
}
