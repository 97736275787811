import React, {
  FC, useCallback, useMemo, useState, useEffect,
} from 'react';
import {
  Button,
  Col, Modal, Row, Space, Tooltip, Typography,
} from 'antd';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import ClassificationTreeCascader from '../../components/ClassificationTreeCascader';
import EditDataWrapper from '../../components/EditDataWrapper';
import { UpdateFlashcardDetailsInput, useFlashcardDetails, useUpdateFlashcardDetails } from '../../api/flashcard';
import EditableMarkdown from '../../components/EditableMarkdown';
import { getParam } from '../../hooks/useSearchParam';

const { confirm } = Modal;
const { Title, Text } = Typography;

const EditFlashcardPage: FC = () => {
  const flashcardId = getParam('id');
  const updateFlashcardDetailsMutation = useUpdateFlashcardDetails();

  const [flashcardFrontEdit, setFlashcardFrontEdit] = useState('');
  const [flashcardBackEdit, setFlashcardBackEdit] = useState('');
  const [prefixEdit, setPrefixEdit] = useState('');
  const [dirtyInputs, setDirtyInputs] = useState(false);

  const {
    data, loading: queryLoading, refetch: refetchFlashcardDetailsQuery,
  } = useFlashcardDetails(flashcardId);

  const flashcardDetails = useMemo(() => {
    if (queryLoading || !data?.flashcardDetails) {
      return undefined;
    }
    return { ...data.flashcardDetails };
  }, [data, queryLoading]);

  useEffect(() => {
    setFlashcardFrontEdit(flashcardDetails?.front ?? '');
    setFlashcardBackEdit(flashcardDetails?.back ?? '');
    setPrefixEdit(flashcardDetails?.prefix ?? '');
  }, [flashcardDetails]);

  const flashcardClassification = useMemo(() => {
    if (flashcardDetails) {
      return flashcardDetails.classification;
    }
    return [];
  }, [flashcardDetails]);

  const handleUndo = useCallback(() => {
    setDirtyInputs(false);
    setFlashcardFrontEdit(flashcardDetails?.front ?? '');
    setFlashcardBackEdit(flashcardDetails?.back ?? '');
    setPrefixEdit(flashcardDetails?.prefix ?? '');
  }, [flashcardDetails?.back, flashcardDetails?.front, flashcardDetails?.prefix]);

  const handleUpdate = useCallback(async ({
    _id, front, back, classification, prefix,
  }: UpdateFlashcardDetailsInput) => {
    confirm({
      title: 'Tem certeza que deseja salvar as alterações?',
      onOk: async () => {
        await updateFlashcardDetailsMutation({
          _id,
          front,
          back,
          classification,
          prefix,
        });
        if (refetchFlashcardDetailsQuery) {
          await refetchFlashcardDetailsQuery({ _id });
        }
        setDirtyInputs(false);
      },
      onCancel: () => { },
    });
  }, [updateFlashcardDetailsMutation, refetchFlashcardDetailsQuery]);

  const handleSelectClassificationChange = useCallback((e: (string | number)[]) => {
    confirm({
      title: 'Tem certeza que deseja alterar a classificação?',
      content: (
        <>
          <div>
            {`Você está alterando a classificação de: ${flashcardClassification.join(' / ')}`}
          </div>
          para:
          <div>
            {e.join(' / ')}
          </div>
        </>
      ),
      onOk: () => handleUpdate({
        _id: flashcardId,
        classification: e as string[],
      }),
      onCancel: () => { },
    });
  }, [handleUpdate, flashcardClassification, flashcardId]);

  return (
    <EditDataWrapper
      title="Editar flashcards"
      placeholder="Pesquisar por id"
      loading={queryLoading}
      searchKey="id"
    >
      {flashcardDetails && (
        <>
          <Title level={3}>Flashcard</Title>
          <Row className="py-1" align="middle">
            <Tooltip title={flashcardDetails.classification.join(' / ')}>
              <Text strong>
                Classificação:&nbsp;
              </Text>
              <ClassificationTreeCascader
                treeId={flashcardDetails.tagTree}
                onChange={handleSelectClassificationChange}
                currentValue={flashcardDetails.classification}
                value={flashcardClassification}
              />
            </Tooltip>
          </Row>
          <Row className="py-1">
            <Col span={24}>
              <Text strong>
                Frente:
              </Text>
              <EditableMarkdown
                size="small"
                contentId={flashcardDetails._id}
                externalState={flashcardFrontEdit}
                externalSetStateFn={value => {
                  setDirtyInputs(true);
                  setFlashcardFrontEdit(value);
                }}
                imagesStorageInfo={{ imagesFolder: 'images/flashcards', imagesSuffix: 'front' }}
              >
                {flashcardFrontEdit}
              </EditableMarkdown>
            </Col>
          </Row>
          <Row className="py-1">
            <Col span={24}>
              <Text strong>
                Verso:
              </Text>
              <EditableMarkdown
                size="small"
                contentId={flashcardDetails._id}
                externalState={flashcardBackEdit}
                externalSetStateFn={value => {
                  setDirtyInputs(true);
                  setFlashcardBackEdit(value);
                }}
                imagesStorageInfo={{ imagesFolder: 'images/flashcards', imagesSuffix: 'back' }}
              >
                {flashcardBackEdit}
              </EditableMarkdown>
            </Col>
          </Row>
          <Row className="py-1">
            <Text strong>
              Prefixo:
            </Text>
            <Text
              style={{ marginLeft: '8px' }}
              editable={{
                onChange: value => {
                  setPrefixEdit(value);
                  setDirtyInputs(true);
                },
              }}
            >
              {prefixEdit}
            </Text>
          </Row>
          <Row>
            <Space className="mt-2">
              <Button
                type="ghost"
                icon={<CloseOutlined />}
                disabled={!dirtyInputs}
                onClick={handleUndo}
              >
                Desfazer
              </Button>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                disabled={!dirtyInputs}
                onClick={() => handleUpdate({
                  _id: flashcardDetails._id,
                  front: flashcardFrontEdit,
                  back: flashcardBackEdit,
                  prefix: prefixEdit,
                })}
              >
                Salvar
              </Button>
            </Space>
          </Row>
        </>
      )}
    </EditDataWrapper>
  );
};

export default EditFlashcardPage;
