import {
  FC, useCallback, useContext, useEffect,
} from 'react';
import { Col } from 'antd';
import { ForumCommentCard } from './ForumCommentCard';
import { ForumCommentEditor } from './forum-comment-editor/ForumCommentEditor';
import { ForumCommentsContext } from '../../../contexts/ForumComments.context';

type ForumCommentProps = {
  commentId: string;
  area: string;
}

export const ForumComment: FC<ForumCommentProps> = ({
  commentId,
  area,
}) => {
  const {
    forumComment,
    getComment,
    loadingComment,
    parentComment,
    commentManagementController,
  } = useContext(ForumCommentsContext);

  const load = useCallback(async () => {
    await getComment(commentId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentId]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <Col>
      <ForumCommentCard
        comment={forumComment}
        parentComment={parentComment}
        isLoading={loadingComment}
        area={area}
      />
      {forumComment !== undefined && (
        <ForumCommentEditor
          comment={forumComment}
          confirmLocalAnswer={commentManagementController.changeCommentLocalStatus}
        />
      )}
    </Col>
  );
};
