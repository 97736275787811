import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import Routes from './Routes';
import './App.css';
import client from './client/apollo';
import { GlobalStorage } from './contexts/Global.context';

library.add(fas);

const App: FC = () => {
  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <GlobalStorage>
          <Routes />
        </GlobalStorage>
      </ApolloProvider>
    </BrowserRouter>
  );
};

export default App;
