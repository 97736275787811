import React, { FC } from 'react';
import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';

interface ForbiddenProps { }

const Forbidden: FC<ForbiddenProps> = () => {
  const history = useHistory();
  return (
    <Result
      status="403"
      title="Ops..."
      subTitle="Parece que você não tem permissão para acessar essa página 😐"
      extra={(
        <Button
          type="primary"
          onClick={() => history.push('/admin')}
        >
          Voltar para home
        </Button>
      )}
    />
  );
};

export default Forbidden;
