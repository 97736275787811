import { FC, useState } from 'react';
import {
  Button, Input, Modal, Row, Select, SelectProps, Space, Typography,
} from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

type AddExamDialogProps = {
  institution: string;
  uf: string;
  open: boolean;
  close: () => void;
}

export const AddExamDialog: FC<AddExamDialogProps> = ({
  institution,
  uf,
  open,
  close,
}) => {
  const navigate = useHistory();

  const [questionNumber, setQuestionNumber] = useState<number>();
  const [examType, setExamType] = useState<string>();
  const [majorArea, setMajorArea] = useState<string>();
  const [year, setYear] = useState<number | undefined>();
  const currentYear = moment().year();

  const years = Array.from({ length: 20 }, (_, i) => currentYear - i);
  const options: SelectProps['options'] = years.map(it => {
    return {
      value: it,
      label: <span>{it}</span>,
    };
  });

  const handleConfirm = (): void => {
    close();
    navigate.push(
      // eslint-disable-next-line max-len
      `/admin/resources/new-appeal?institution=${institution}&uf=${uf}&year=${year}&examType=${examType}&questionNumber=${questionNumber}&area=${majorArea}&createExam=True`,
    );
  };

  const disabled = !questionNumber || !examType || !majorArea || !year;

  return (
    <Modal
      style={{ maxWidth: '360px' }}
      title={`Criar prova em ${institution}`}
      open={open}
      onCancel={close}
      footer={(
        <Row>
          <Button disabled={disabled} onClick={handleConfirm}>Adicionar Prova</Button>
        </Row>
      )}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <div>
          <Typography>Qual o ano da prova</Typography>
          <Select
            style={{ width: '100%' }}
            placeholder="Ano"
            value={year}
            options={options}
            onChange={e => setYear(e)}
            allowClear
          />
        </div>
        <div>
          <Typography>Qual o número da questão</Typography>
          <Input
            placeholder="número da questão"
            value={questionNumber}
            type="number"
            min={0}
            max={120}
            onChange={e => setQuestionNumber(Number(e.target.value))}
          />
        </div>
        <div>
          <Typography>Qual o tipo de prova</Typography>
          <Input
            placeholder="tipo de prova"
            value={examType}
            onChange={e => setExamType(e.target.value)}
          />
        </div>
        <div>
          <Typography>Qual a grande área</Typography>
          <Input
            style={{ width: '100%' }}
            placeholder="grande área"
            value={majorArea}
            onChange={e => setMajorArea(e.target.value)}
          />
        </div>
      </Space>
    </Modal>
  );
};
