import React, { FC, useMemo } from 'react';
import { Cascader } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useClassificationTrees } from '../api/classification-trees';

interface ClassificationTreeCascaderProps {
  treeId: string;
  currentValue?: string[];
  onChange: (value: (string | number)[]) => void;
  value: string[];
}

const ClassificationTreeCascader: FC<ClassificationTreeCascaderProps> = ({
  treeId, onChange, currentValue = [], value,
}) => {
  const { data, loading } = useClassificationTrees(treeId);
  const classificationData = useMemo(() => {
    if (data && data.classificationTree) {
      return data.classificationTree.tree;
    }
    return [];
  }, [data]);

  if (loading) {
    return <LoadingOutlined />;
  }

  return (
    <Cascader
      options={classificationData}
      onChange={onChange}
      fieldNames={{ label: 'name', value: 'name', children: 'children' }}
      defaultValue={currentValue}
      value={value}
      placeholder="Selecione a classificação"
    />
  );
};

export default ClassificationTreeCascader;
