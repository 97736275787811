import { FC, useState } from 'react';
import { Button, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { ForumCommentContent } from './ForumCommentContent';
import { ForumCommentWithReplies } from './ForumCommentWithReplies';
import { ForumCommentType, ForumCommentWithRepliesType } from '../../../hooks/useCommentsManagement';

type ForumCommentCardProps = {
  forumComment: ForumCommentType,
  parentComment?: ForumCommentWithRepliesType,
}

export const ForumcommentExpandableContent: FC<ForumCommentCardProps> = ({
  forumComment,
  parentComment,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const toggleExpanded = (): void => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Col>
      { isExpanded ? (
        <ForumCommentWithReplies
          commentId={forumComment.id}
          parentComment={parentComment}
        />
      ) : (
        <ForumCommentContent
          user={forumComment.user}
          courseId={forumComment.courseId}
          body={forumComment.body}
          isPublic={forumComment.status.isPublic}
        />
      )}
      { forumComment.parent && (
        <Button
          type="text"
          onClick={toggleExpanded}
          style={{ color: '#415F91', marginTop: '8px' }}
          icon={<FontAwesomeIcon icon={faComments} style={{ marginRight: '4px' }} />}
        >
          {isExpanded ? 'fechar conversa' : 'abrir conversa'}
        </Button>
      )}
    </Col>
  );
};
