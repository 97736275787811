import {
  Col, Divider, Row, Typography,
} from 'antd';
import { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { QuestionWeekExpandedContent } from './QuestionWeekExpandedContent';
import { QuestionsGroupedByThemaObjectType } from '../../../../../api/user-dashboard';

const textStyle = {
  fontSize: '16px',
  fontWeight: 400,
  width: '160px',
};

type QuestionWeekExpandableCardProps = {
  week: string;
  qnt: number;
  hits: number;
  miss: number;
  acc: string;
  themes: QuestionsGroupedByThemaObjectType[],
}

export const QuestionWeekExpandableCard : FC<QuestionWeekExpandableCardProps> = ({
  week,
  acc,
  hits,
  miss,
  qnt,
  themes,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCard = (): void => {
    setIsOpen(!isOpen);
  };

  const formatDate = (dateString: string): string => {
    moment.locale('pt-br');
    const date = moment(dateString, 'YYYYMMDD');
    let formattedDate = date.format('ddd. DD/MM/YYYY');
    formattedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

    return formattedDate;
  };

  return (
    <Col>
      <Row
        style={{
          cursor: 'pointer',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: isOpen ? '48px' : '36px',
        }}
        onClick={handleCard}
      >
        <Typography style={textStyle}>{formatDate(week)}</Typography>
        <Typography style={textStyle}>{qnt}</Typography>
        <Typography style={textStyle}>{hits}</Typography>
        <Typography style={textStyle}>{miss}</Typography>
        <Typography style={textStyle}>{`${acc}%`}</Typography>
        {isOpen ? (
          <FontAwesomeIcon width="16px" height="16px" icon={faChevronUp} />
        ) : (
          <FontAwesomeIcon icon={faChevronDown} />
        )}
      </Row>
      <Divider style={{ margin: '4px 0px' }} />
      {isOpen && (
        <QuestionWeekExpandedContent data={themes} />
      )}
    </Col>
  );
};
