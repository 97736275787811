import { FC, useMemo } from 'react';
import {
  Col, Image, Row, Typography,
} from 'antd';
import { CardComponent } from './CardComponent';
import { useWeeklyAchievementsProgress } from '../../../api/user-dashboard';

const Trophy: FC<{image: string, alt: string, text: string, total?: number}> = ({
  image,
  alt,
  text,
  total,
}) => {
  return (
    <Col style={{
      textAlign: 'center',
    }}
    >
      <Image
        src={image}
        alt={alt}
        width="80px"
      />
      <Typography style={{ fontSize: '16px', fontWeight: 500, margin: '6px 0px' }}>
        {text}
      </Typography>
      <Typography style={{
        fontSize: '45px',
        fontWeight: 400,
        lineHeight: 1,
      }}
      >
        {total ?? 0}
      </Typography>
    </Col>
  );
};

export const TrophiesCard: FC<{courseId: string, profileId: string}> = ({
  courseId,
  profileId,
}) => {
  const { data } = useWeeklyAchievementsProgress({ courseId, profileId });

  const achievementData = useMemo(() => data?.getWeeklyAchievementsProgressV2, [data]);

  return (
    <CardComponent
      cardTitle="Troféus conquistados"
    >
      <Row style={{
        justifyContent: 'space-evenly',
        marginTop: '20px',
      }}
      >
        <Trophy
          image="/images/trofeu_ouro.png"
          alt="trofeu ouro"
          text="OURO"
          total={achievementData?.course.gold}
        />
        <Trophy
          image="/images/trofeu_prata.png"
          alt="trofeu prata"
          text="PRATA"
          total={achievementData?.course.silver}
        />
        <Trophy
          image="/images/trofeu_bronze.png"
          alt="trofeu bronze"
          text="BRONZE"
          total={achievementData?.course.bronze}
        />
      </Row>
    </CardComponent>
  );
};
