import { EditOutlined, FileExcelOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Col,
  Divider,
  Input,
  Form,
  PageHeader,
  Row,
  Typography,
  Button,
  Select,
  Space,
  Empty,
  List,
  Tag,
  Switch,
  Layout,
} from 'antd';
import {
  useMemo, useState, useCallback, useContext,
} from 'react';
import { CSVLink } from 'react-csv';
import { Link, useHistory } from 'react-router-dom';
import { Institution, useGetInstitutionList } from '../../api/institutions';
import {
  Question,
  QuestionsInput,
  useGetExamTypeList,
  useQuestions,
} from '../../api/question';
import ClassificationTreeCascader from '../../components/ClassificationTreeCascader';
import { CourseContext } from '../../contexts/Courses.context';
import { CommentTag } from './components/CommentTag';
import { DifficultyTag } from './components/DifficultyTag';

const { Title, Text } = Typography;
const { Option } = Select;
const { Content } = Layout;

function QuestionListItem({
  _id,
  examOrder,
  institution,
  year,
  classification,
  complement,
  examType,
  body,
  difficulty,
  isOutdated,
  isOpenToAppeal,
  comment,
  uf,
}: Question) {
  const search = `?id=${_id}`;

  return (
    <List.Item actions={[
      <Link to={{
        pathname: '/admin/editar-questao',
        search,
      }}
      >
        <Space>
          <EditOutlined />
          Editar
        </Space>
      </Link>,
    ]}
    >
      <List.Item.Meta
        title={(
          <Space>
            <Text>
              {`Questão ${examOrder} - ${institution} - ${uf} - ${year}`}
            </Text>
            {isOutdated && <Tag color="brown">Desatualizada</Tag>}
            {isOpenToAppeal && <Tag color="orange">Passível de recurso</Tag>}
            <DifficultyTag difficulty={difficulty} />
            <CommentTag comment={Boolean(comment)} />
            <Tag>{`Complemento ${complement}`}</Tag>
            <Tag>{examType}</Tag>
          </Space>
        )}
        description={`${classification[0]?.leaf.join(' | ') || 'Sem classificação'} - ${body.substring(0, 100)}...`}
      />
    </List.Item>
  );
}

export function QuestionsPage() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [institutions, setInstitutions] = useState<Institution[]>([]);
  const [courseDetailedTrees, setCourseDetailedTrees] = useState('');

  const { courses, loading: coursesLoading } = useContext(CourseContext);

  const { data, loading } = useGetInstitutionList();
  const { data: examTypeData, loading: examTypeLoading } = useGetExamTypeList();
  const [questionsQuery, { data: questionsData, loading: questionsLoading }] = useQuestions();

  const questions = useMemo(() => {
    if (!questionsData || questionsLoading) {
      return [];
    }
    return questionsData.questions;
  }, [questionsData, questionsLoading]);

  const questionsToCsv = useMemo(() => {
    if (questions.length) {
      return questions.map(q => ({
        id: q._id,
        body: q.body,
        item_a: q.choices[0] || '',
        item_b: q.choices[1] || '',
        item_c: q.choices[2] || '',
        item_d: q.choices[3] || '',
        item_e: q.choices[4] || '',
        institution: q.institution,
        state: q.uf,
        year: q.year,
        difficulty: q.difficulty || '',
        classification: q.classification[0]?.leaf.join(' | ') || 'Sem classificação',
      }));
    }
    return [];
  }, [questions]);

  const ufsList = useMemo(() => {
    if (!data || loading) {
      return undefined;
    }
    const result = [...data.getInstitutionListAdmin];
    result.sort((a, b) => {
      const ufA = a.uf.toUpperCase();
      const ufB = b.uf.toUpperCase();
      if (ufA < ufB) {
        return -1;
      }
      if (ufA > ufB) {
        return 1;
      }
      return 0;
    });
    return result;
  }, [data, loading]);

  const examTypeList = useMemo(() => {
    if (!examTypeData || examTypeLoading) {
      return undefined;
    }
    return examTypeData.getExamTypeList;
  }, [examTypeData, examTypeLoading]);

  const handleSelectUf = useCallback((uf: string) => {
    form.setFields([{ name: 'institution', value: undefined }]);
    const inst = ufsList?.find(ufItem => ufItem.uf === uf)?.institutions ?? [];
    const result = [...inst];
    result.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setInstitutions(result);
  }, [form, ufsList]);

  const handleSelectCourse = useCallback((courseId: string) => {
    form.setFields([{ name: 'classification', value: undefined }]);
    setCourseDetailedTrees(courses.find(c => c._id === courseId)?.detailedClassificationTree ?? '');
  }, [courses, form]);

  const handleFinish = (values: Omit<QuestionsInput['filter'], 'classification'> & { classification?: string[], course?: string }) => {
    let classification;
    if (values.course) {
      classification = {
        detailedTreeId: courseDetailedTrees,
      };
    }
    if (values.classification && values.classification.length > 0) {
      classification = {
        ...classification,
        leaf: values.classification,
      };
    }
    const filter = {
      ...values,
      classification,
      _id: values._id || undefined,
    };
    delete filter.course;
    questionsQuery({
      variables: {
        filter,
      },
    });
  };

  return (
    <div style={{ maxWidth: '1120px', margin: '0 auto' }}>
      <PageHeader
        title="Questões"
        onBack={() => history.goBack()}
      >
        <Content style={{
          minHeight: '500px',
        }}
        >
          <Title level={5}>Filtros</Title>
          <Form form={form} onFinish={handleFinish}>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item name="_id" label="Id">
                  <Input placeholder="Id da questão" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="uf" label="UF">
                  {ufsList && !loading ? (
                    <Select placeholder="UF" allowClear onChange={handleSelectUf}>
                      {ufsList.map(ufItem => <Option key={ufItem.uf} value={ufItem.uf}>{ufItem.uf}</Option>)}
                    </Select>
                  ) : (
                    <LoadingOutlined />
                  )}
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item name="institution" label="Instituição">
                  <Select placeholder="Instituição" allowClear>
                    {institutions.map(institution => <Option key={institution.name} value={institution.name}>{institution.name}</Option>)}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item name="difficulty" label="Dificuldade">
                  <Select allowClear placeholder="Dificuldade">
                    <Option value="easy">Fácil</Option>
                    <Option value="medium">Média</Option>
                    <Option value="hard">Difícil</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="comments" label="Comentadas">
                  <Select allowClear placeholder="Comentadas">
                    <Option value="yes">Sim</Option>
                    <Option value="no">Não</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item name="complement" label="Complemento">
                  <Input placeholder="Complemento" />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item name="course" label="Curso">
                  {courses && !coursesLoading ? (
                    <Select allowClear placeholder="Curso" onChange={handleSelectCourse}>
                      {courses.map(course => (
                        <Option key={course._id} value={course._id}>
                          {course.title}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <LoadingOutlined />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between" align="top">
              <Col span={10}>
                <Form.Item name="classification" label="Classificação">
                  <ClassificationTreeCascader
                    treeId={courseDetailedTrees}
                    value={[]}
                    onChange={() => { }}
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="year" label="Ano">
                  <Select allowClear placeholder="Ano">
                    {Array.from({ length: ((new Date()).getFullYear() - 2004 + 1) }, (v, i) => 2005 + i).map(year => (
                      <Option key={year} value={year}>{year}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="examType" label="Tipo">
                  {examTypeList && !examTypeLoading ? (
                    <Select placeholder="Tipo" allowClear>
                      {examTypeList.map(examType => <Option key={examType} value={examType}>{examType}</Option>)}
                    </Select>
                  ) : (
                    <LoadingOutlined />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between" align="top">
              <Space direction="horizontal">
                <Button type="primary" htmlType="submit" loading={questionsLoading}>
                  Buscar
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields();
                  }}
                >
                  Limpar filtros
                </Button>
              </Space>
            </Row>
          </Form>
          {questionsToCsv.length > 0 && (
            <Row style={{ flex: 1, justifyContent: 'flex-end' }}>
              <CSVLink
                data={questionsToCsv}
                filename={`questoes-${new Date().getTime()}`}
                separator="|"
              >
                <Button
                  type="primary"
                  icon={<FileExcelOutlined />}
                  className="download-csv"
                >
                  Download .csv
                </Button>
              </CSVLink>
            </Row>
          )}
          <Divider />
          {questions.length > 0 && (
            <List
              size="small"
              dataSource={questions}
              renderItem={question => <QuestionListItem {...question} />}
            />
          )}
          {questions.length === 0 && !questionsLoading && <Empty description="Nenhuma questão por aqui..." />}
        </Content>
      </PageHeader>
    </div>
  );
}
