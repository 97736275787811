import { FC, useEffect, useState } from 'react';
import { Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { PerformanceDashboard } from './PerformanceDashboard';
import { Profile, useProfileByEmail } from '../../api/profile';
import EditDataWrapper from '../../components/EditDataWrapper';
import { getParam } from '../../hooks/useSearchParam';

const UserDashboardPage: FC = () => {
  const email = getParam('email');
  const [profile, setProfile] = useState<Profile>();
  const { data, loading: queryLoading } = useProfileByEmail(email);

  useEffect(() => {
    if (data) {
      setProfile(data.findProfileByEmail || null);
    }
  }, [data]);

  return (
    <EditDataWrapper
      title="Consultar usuários"
      placeholder="Pesquisar pelo email"
      loading={queryLoading}
      searchKey="email"
    >
      <div style={{ maxWidth: '1100px', margin: '0 auto' }}>
        {queryLoading && (
          <Row style={{ justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <LoadingOutlined style={{ margin: '20px auto', fontSize: '42px' }} />
          </Row>
        )}
        {profile && !queryLoading && (
          <PerformanceDashboard profile={profile} />
        )}
      </div>
    </EditDataWrapper>
  );
};

export default UserDashboardPage;
