/* eslint-disable no-plusplus */
import { FC, useMemo } from 'react';
import {
  Col, Row, Typography,
} from 'antd';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { CardComponent } from './CardComponent';
import { usePresences, useStudentAttendanceStatus } from '../../../api/user-dashboard';

const SmallCard: FC<{ title: string, content: string;}> = ({
  title,
  content,
}) => {
  return (
    <div style={{
      width: '100%',
      borderRadius: '8px',
      padding: '8px 16px',
      backgroundColor: 'rgba(65, 95, 145, 0.08)',
    }}
    >
      <Typography style={{
        fontSize: '16px',
        fontWeight: 500,
        marginBottom: '8px',
      }}
      >
        {title.toUpperCase()}
      </Typography>
      <Typography style={{
        fontSize: '45px',
        fontWeight: 400,
        lineHeight: 1,
      }}
      >
        {content}
      </Typography>
    </div>
  );
};

export const FrequencyCard: FC<{profileId: string, courseId: string}> = ({
  profileId,
  courseId,
}) => {
  const { data: presencesData, loading: presencesLoading } = usePresences({ profileId, courseId });
  const { data: attendanceData } = useStudentAttendanceStatus({ profileId, courseId });

  const currentMonth = moment().month();
  const currentYear = moment().year();
  const presences = useMemo(() => {
    if (!presencesData) {
      return [];
    }

    return presencesData.getPresenceDates.filter((date: string) => {
      const presenceDate = moment(date);
      return presenceDate.month() === currentMonth && presenceDate.year() === currentYear;
    });
  }, [presencesData, currentMonth, currentYear]);

  const attendance = useMemo(() => attendanceData?.studentAttendanceStatus, [attendanceData]);

  const generateCalendarMatrix = (): string[][] => {
    const firstDayOfMonth = moment().startOf('month').day();
    const totalDaysInMonth = moment().daysInMonth();
    const calendarMatrix = [['S', 'T', 'Q', 'Q', 'S', 'S', 'D']];

    let week = Array(7).fill('');
    let dayCounter = 1;

    const adjustedFirstDay = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;

    for (let i = adjustedFirstDay; i > 0; i--) {
      week[i] = '';
    }

    for (let i = adjustedFirstDay; i < 7; i++) {
      week[i] = dayCounter.toString();
      dayCounter++;
    }

    calendarMatrix.push(week);

    while (dayCounter <= totalDaysInMonth) {
      week = Array(7).fill('');
      for (let i = 0; i < 7 && dayCounter <= totalDaysInMonth; i++) {
        week[i] = dayCounter.toString();
        dayCounter++;
      }
      calendarMatrix.push(week);
    }

    return calendarMatrix;
  };

  const calendar = useMemo(() => generateCalendarMatrix(), []);

  return (
    <CardComponent
      cardTitle="Frequência"
    >
      {presencesLoading ? (
        <Row style={{ justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <LoadingOutlined style={{ margin: '20px auto', fontSize: '42px' }} />
        </Row>
      ) : (
        <div style={{ marginBottom: '16px' }}>
          {calendar.map((week, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Row key={`${index}-week`} style={{ justifyContent: 'space-between', marginBottom: index === 0 ? '8px' : '4px' }}>
                {week.map((day, i) => {
                  const formattedDay = `${currentYear}${(currentMonth + 1).toString().padStart(2, '0')}${day.padStart(2, '0')}`;
                  const isPresent = presences.includes(formattedDay);
                  const backgroundColor = index !== 0 && isPresent ? '#B1F1C1' : 'transparent';
                  const color = index !== 0 && isPresent ? '#00210E' : '#44474E';
                  return (
                    <Col
                      style={{
                        height: '24px',
                        width: '24px',
                        borderRadius: '12px',
                        backgroundColor,
                        color,
                        fontSize: index === 0 ? '16px' : '14px',
                        fontWeight: index === 0 ? 'bold' : 600,
                        textAlign: 'center',
                      }}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${i}-week`}
                    >
                      {day}
                    </Col>
                  );
                })}
              </Row>
            );
          })}
        </div>
      )}
      <Row>
        <Col span={12} style={{ padding: '2px' }}>
          <SmallCard
            title="Presença"
            content={`${attendance?.attendanceDays ?? 0}/${attendance?.currentDay ?? 0}`}
          />
        </Col>
        <Col span={12} style={{ padding: '2px' }}>
          <SmallCard
            title="Frequência"
            content={`${((attendance?.frequency ?? 0) * 100).toFixed(0)}%`}
          />
        </Col>
      </Row>
    </CardComponent>
  );
};
