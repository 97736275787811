import { FC } from 'react';
import { Col, Row } from 'antd';
import { ForumStatus } from './ForumStatus';
import { ForumInfo } from './ForumInfo';
import { ForumcommentExpandableContent } from './ForumCommentExpandableContent';
import { ForumCommentButtonOptions } from './ForumCommentButtonOptions';
import { ForumCommentType, ForumCommentWithRepliesType } from '../../../hooks/useCommentsManagement';
import { ForumCommentCardSkeleton } from './skeletons/ForumCommentCardSkeleton';

type ForumCommentCardProps = {
  comment?: ForumCommentType;
  parentComment?: ForumCommentWithRepliesType;
  area: string;
  isLoading: boolean;
}

export const ForumCommentCard:FC<ForumCommentCardProps> = ({
  comment,
  parentComment,
  area,
  isLoading,
}) => {
  return (
    <Col style={{
      padding: '16px',
      borderRadius: '16px',
      backgroundColor: '#F9F9FF',
      margin: '16px auto',
    }}
    >
      {(isLoading || !comment) && (
        <ForumCommentCardSkeleton />
      )}

      {comment && !isLoading && (
      <>
        <Row style={{ marginBottom: '8px' }}>
          <div style={{
            width: '100%', maxWidth: '790px', marginRight: '16px', marginBottom: '16px',
          }}
          >
            <ForumcommentExpandableContent forumComment={comment} parentComment={parentComment} />
          </div>
          <ForumStatus
            badgeStatus={comment.status.answerStatus}
            createdAt={new Date(comment.createdAt)}
          />
          <ForumInfo
            forumContentType={comment.contentType}
            area={area}
            contentId={comment.contentId}
            courseId={comment.courseId}
          />
        </Row>
        <ForumCommentButtonOptions />
      </>
      )}
    </Col>
  );
};
