import { InputNumber } from 'antd';

export interface WeekGoalInputProps {
  value: number;
  onChange: (v: number | null) => void;
  min?: number,
  max?: number,
}

export function WeekGoalInput({
  value, onChange, min, max,
}: WeekGoalInputProps) {
  return <InputNumber min={min ?? 1} max={max ?? 7} value={value} onChange={e => onChange(e ?? 0)} />;
}
