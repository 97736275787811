import { FC, useMemo, useState } from 'react';
import { Button, Divider, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { CardComponent } from '../CardComponent';
import { QuestionWeekContent } from './questions/QuestionWeekContent';
import { WeekBarDisplay } from './WeekBarDisplay';
import { useStudentAnswersGroupedByWeek } from '../../../../api/user-dashboard';

// eslint-disable-next-line no-shadow
enum buttonEnum {
  Questions = 'Questions',
}

type WeekActivitesProps = {
  courseId: string;
    endDate: Date;
    profileId: string;
    startDate: Date;
}

export const WeekActivities: FC<WeekActivitesProps> = ({
  courseId,
  endDate,
  profileId,
  startDate,
}) => {
  const [activeButton, setActiveButton] = useState<buttonEnum | undefined>(buttonEnum.Questions);
  const [activeBar, setActiveBar] = useState(0);

  const { data: questionsQuery, loading } = useStudentAnswersGroupedByWeek({
    courseId,
    endDate,
    profileId,
    startDate,
  });

  const questions = useMemo(() => questionsQuery?.studentAnswersGroupedByWeek, [questionsQuery]);

  const selectedWeek = useMemo(() => questions?.find(it => it.week === activeBar), [questions, activeBar]);

  const handleSelectBar = (value: number): void => {
    setActiveBar(value);
  };

  const handleButton = (value: buttonEnum): void => {
    if (value === activeButton) {
      setActiveButton(undefined);
      return;
    }

    setActiveButton(value);
  };

  return (
    <CardComponent
      cardTitle="Atividade do Aluno por Semana"
    >
      <Row style={{ gap: '8px' }}>
        <Button
          style={{
            borderRadius: '8px',
            backgroundColor: activeButton === buttonEnum.Questions ? undefined : 'transparent',
          }}
          type={activeButton === buttonEnum.Questions ? 'primary' : undefined}
          onClick={() => handleButton(buttonEnum.Questions)}
        >
          questões feitas
        </Button>
      </Row>
      {activeButton === buttonEnum.Questions && (
      <>
        {!loading ? (
          <WeekBarDisplay
            data={questions || []}
            action={handleSelectBar}
            selectedBar={activeBar}
          />
        ) : (
          <Row style={{ justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <LoadingOutlined />
          </Row>
        )}
        <Divider style={{ margin: '8px 0px' }} />
        {activeButton === buttonEnum.Questions && selectedWeek && (
        <QuestionWeekContent
          profileId={profileId}
          courseId={courseId}
          startDate={selectedWeek.startDate}
          endDate={selectedWeek.finishDate}
        />
        )}
      </>
      )}
    </CardComponent>
  );
};
