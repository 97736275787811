import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  FileExcelOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  List,
  message,
  Row,
  Space,
  Spin,
  Typography,
  Modal,
  Empty,
  Upload,
  UploadProps,
  Dropdown,
  MenuProps,
  Table,
} from 'antd';
import { usePapaParse } from 'react-papaparse';
import {
  ChangeEvent, useMemo, useRef, useState,
} from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Flashcard, useFlashcards } from '../../api/flashcard';
import EditDataWrapper from '../../components/EditDataWrapper';
import { getParam } from '../../hooks/useSearchParam';

const { Text } = Typography;
const { confirm } = Modal;

const _1MB = 1000000;

type ParsedCSVFlashcard = {
  frente: string;
  verso: string;
  prefixo: string | undefined;
}

export function FlashcardsPage() {
  const lessonId = getParam('aula');
  const history = useHistory();
  const [loadingCreate, setLoadingCreate] = useState(false);
  const { state } = useLocation<{ title: string } | undefined>();
  const {
    lessonFlashcards: { data, loading: queryLoading, refetch },
    handleCreateNewFlashcardMutation,
    handleDeleteFlashcardMutation,
    handleCreateManyFlashcardsMutation,
  } = useFlashcards(lessonId);
  const [parsedCSV, setParsedCSV] = useState<ParsedCSVFlashcard[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { readString } = usePapaParse();

  const flashcards = useMemo(() => {
    if (queryLoading || !data?.lessonFlashcards) {
      return undefined;
    }
    return data.lessonFlashcards;
  }, [data, queryLoading]);

  async function handleCreateFlashcard() {
    setLoadingCreate(true);
    try {
      const flashcardId = await handleCreateNewFlashcardMutation();
      await refetch({
        lessonId,
      });
      setLoadingCreate(false);
      history.push({
        pathname: 'editar-flashcard',
        search: `?id=${flashcardId}`,
      });
    } catch (err) {
      console.error(err);
      message.error('Erro ao criar um novo flashcard!');
      setLoadingCreate(false);
    }
  }

  async function handleCreateManyFlashcards() {
    setLoadingCreate(true);
    try {
      await handleCreateManyFlashcardsMutation(parsedCSV.map(p => ({
        front: p.frente,
        back: p.verso,
        prefix: p.prefixo,
      })));
      await refetch({
        lessonId,
      });
      message.success('Flashcards criados com sucesso!');
    } catch (err) {
      console.error(err);
      message.error('Erro ao processar flashcards. Atualize a página para conferir.');
    } finally {
      setModalOpen(false);
      setLoadingCreate(false);
    }
  }

  async function parseFlashcardsFromFile(csvText: string) {
    readString<ParsedCSVFlashcard>(csvText, {
      header: true,
      worker: true,
      complete: results => {
        const result = results.data;
        result.filter(d => Boolean(d.frente) && Boolean(d.verso));
        setParsedCSV(result.filter(d => Boolean(d.frente) && Boolean(d.verso)));
      },
    });
  }

  function onCSVUpload(e: ChangeEvent<HTMLInputElement>) {
    const fileReader = new FileReader();
    if (e.target.files) {
      fileReader.onload = f => {
        if (f.target && f.target.result) {
          parseFlashcardsFromFile(String(f.target.result));
          setModalOpen(true);
        }
      };
      if (e.target.files[0].size > _1MB) {
        message.error('Tamanho máximo (1Mb) excedido!');
      }
      fileReader.readAsText(e.target.files[0]);
    }
  }

  function handleDeleteModal(flashcard: Pick<Flashcard, '_id' | 'front' | 'back'>) {
    confirm({
      title: 'Deseja mesmo deletar esse flashcard?',
      content: (
        <>
          <div>
            {`Frente: ${flashcard.front}`}
          </div>
          <div>
            {`Verso: ${flashcard.back}`}
          </div>
        </>
      ),
      onOk: async () => {
        try {
          await handleDeleteFlashcardMutation(flashcard._id);
          await refetch({
            lessonId,
          });
        } catch (err) {
          console.error(err);
          message.error('Erro ao deletar o flashcard');
        }
      },
      onCancel: () => { },
    });
  }

  const downloadTemplate: MenuProps['onClick'] = e => {
    if (e.key === 'baixar-template') {
      const url = URL.createObjectURL(
        new File(
          ['frente,verso,prefixo', '\n'],
          'flashcards-template.csv',
          { type: 'text/csv' },
        ),
      );
      window.open(url, '_blank');
    }
  };

  return (
    <>
      <EditDataWrapper
        title="Flashcards"
        searchKey="aula"
        placeholder="Buscar por aula"
      >
        {queryLoading && <Spin />}
        {flashcards && flashcards.length > 0 && (
          <List
            size="small"
            header={(
              <Row justify="space-between">
                <Text strong>
                  Lista de flashcards
                  {state?.title ? ` de ${state?.title} ` : ''}
                </Text>
                <Space direction="horizontal">
                  <Dropdown.Button
                    menu={{
                      items: [{ key: 'baixar-template', label: 'Baixar template' }],
                      onClick: downloadTemplate,
                    }}
                    type="primary"
                    icon={<EllipsisOutlined />}
                    onClick={() => {
                      if (inputFileRef.current) {
                        inputFileRef.current.click();
                      }
                    }}
                  >
                    <Space>
                      <FileExcelOutlined />
                      <Text style={{ color: 'white' }}>
                        Importar .csv
                      </Text>
                    </Space>
                    <input
                      ref={inputFileRef}
                      type="file"
                      accept=".csv"
                      onChange={onCSVUpload}
                      hidden
                    />
                  </Dropdown.Button>
                  <Button
                    icon={<PlusOutlined />}
                    onClick={handleCreateFlashcard}
                    loading={loadingCreate}
                  >
                    Criar flashcard
                  </Button>
                </Space>
              </Row>
            )}
            dataSource={flashcards}
            renderItem={flashcard => {
              const search = `?id=${flashcard._id}`;
              return (
                <List.Item actions={[
                  <Link to={{
                    pathname: '/admin/editar-flashcard',
                    search,
                  }}
                  >
                    <Space>
                      <EditOutlined />
                      Editar
                    </Space>
                  </Link>,
                  <Button
                    style={{ padding: 0 }}
                    type="link"
                    icon={<DeleteOutlined />}
                    danger
                    onClick={() => handleDeleteModal(flashcard)}
                  >
                    Deletar
                  </Button>,
                ]}
                >
                  <List.Item.Meta
                    title={flashcard.front}
                    description={flashcard.classification.join(' | ')}
                  />
                </List.Item>
              );
            }}
          />
        )}
        {flashcards && flashcards.length === 0 && (
          <Empty description="Nenhum flashcard encontrado">
            <Space direction="horizontal">
              <Dropdown.Button
                menu={{
                  items: [{ key: 'baixar-template', label: 'Baixar template' }],
                  onClick: downloadTemplate,
                }}
                type="primary"
                icon={<EllipsisOutlined />}
                onClick={() => {
                  if (inputFileRef.current) {
                    inputFileRef.current.click();
                  }
                }}
              >
                <Space>
                  <FileExcelOutlined />
                  <Text style={{ color: 'white' }}>
                    Importar .csv
                  </Text>
                </Space>
                <input
                  ref={inputFileRef}
                  type="file"
                  accept=".csv"
                  onChange={onCSVUpload}
                  hidden
                />
              </Dropdown.Button>
              <Button
                icon={<PlusOutlined />}
                onClick={handleCreateFlashcard}
                loading={loadingCreate}
              >
                Criar flashcard
              </Button>
            </Space>
          </Empty>
        )}
      </EditDataWrapper>
      <Modal
        title="Confira os flashcards"
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        onOk={handleCreateManyFlashcards}
        cancelText="Cancelar"
        width={700}
        centered
        confirmLoading={loadingCreate}
      >
        <Text>Confira se os flashcards foram identificados corretamente.</Text>
        <Text>
          { parsedCSV.length === 1
            ? ' (1 flashcard)'
            : ` (${parsedCSV.length} flashcards)` }
        </Text>
        <Table
          size="small"
          scroll={{ y: 300 }}
          columns={[
            { key: 'frente', title: 'Frente', dataIndex: 'frente' },
            { key: 'verso', title: 'Verso', dataIndex: 'verso' },
            { key: 'prefixo', title: 'Prefixo', dataIndex: 'prefixo' },
          ]}
          dataSource={parsedCSV}
        />
      </Modal>
    </>
  );
}
