import { faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Col, Divider, message, Row,
  Select,
  SelectProps,
  Typography,
} from 'antd';
import {
  FC, useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { getParam } from '../../../hooks/useSearchParam';
import { useAppealFinishedList, useCreateAppealByAdmin, useEditRespondedAppeal } from '../../../api/appeal';
import { AristoMarkdownEditor } from '../../../components/AristoMarkdownEditor';
import Markdown from '../../../components/Markdown';
import { ReturnButton } from '../components/ReturnButton';

export const AppealCommentCreation: FC = () => {
  const createExam = getParam('createExam');
  const createQuestion = getParam('createComment');
  const appealId = getParam('appealId');
  const uf = getParam('uf');
  const year = getParam('year');
  const majorArea = getParam('area');
  const institutionName = getParam('institution');

  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { data, loading, error } = useAppealFinishedList([appealId]);
  const [editRespondedAppeal] = useEditRespondedAppeal();
  const [createAppealByAdmin] = useCreateAppealByAdmin();

  const questionNumber = getParam('questionNumber');
  const examType = getParam('examType');
  const qAppeal = getParam('canAppeal');

  const navigate = useHistory();

  const [isAppealAccepted, setIsAppealAccepted] = useState<string | undefined>(qAppeal !== '' ? qAppeal : undefined);

  const options: SelectProps['options'] = [
    {
      value: 'true',
      label: <span>Sim</span>,
    },
    {
      value: 'false',
      label: <span>Não</span>,
    },
  ];

  useEffect(() => {
    if (data && !error && !!data.appealFinishedList.length) {
      const appeal = data.appealFinishedList[0];

      setComment(appeal.moderatorResponse);
      setIsAppealAccepted(appeal.isAppealAccepted ? 'true' : 'false');
    }
  }, [data, error]);

  const handleCreateQuestion = async (): Promise<void> => {
    setIsLoading(true);
    try {
      await createAppealByAdmin({
        variables: {
          input: {
            examVersion: examType,
            institution: {
              institutionName,
              uf,
            },
            isAppealAccepted: isAppealAccepted === 'true',
            majorArea,
            questionNumber: Number(questionNumber),
            response: comment,
            year: Number(year),
          },
        },
      });
      navigate.goBack();
      message.success(!createExam ? 'Comentário criado!' : 'Prova e comentário criado!');
    } catch (e) {
      message.error(`Erro ao criar ${!createExam ? 'o comentário' : 'a prova'}!`);
    }
    setIsLoading(false);
  };

  const handleEditAppeal = async (): Promise<void> => {
    setIsLoading(true);
    try {
      await editRespondedAppeal({
        variables: {
          appealId,
          editRespondedAppealInput: {
            isAppealAccepted: isAppealAccepted === 'true',
            response: comment,
          },
        },
      });
      navigate.goBack();
      message.success('Comentário editado!');
    } catch (e) {
      message.error('Erro ao editar o comentário!');
    }
    setIsLoading(false);
  };

  const handleSend = async (): Promise<void> => {
    if (createExam.length || createQuestion.length) {
      await handleCreateQuestion();
      return;
    }

    await handleEditAppeal();
  };

  const title = createExam.length ? `Questão ${questionNumber} - Prova tipo ${examType}` : undefined;

  const disabled = !comment.length || isLoading || loading || isAppealAccepted === undefined;

  return (
    <Col style={{ maxWidth: '1200px', margin: '0px auto' }}>
      <ReturnButton title={title ?? ''} subTitle={`${institutionName} / ${year}`} />
      <Col style={{
        padding: '16px',
        borderRadius: '16px',
        border: '1px solid black',
        margin: '16px 0px',
      }}
      >
        <Typography style={{ marginBottom: '8px' }}>Cabe Recurso</Typography>
        <Select
          style={{ width: '100%' }}
          placeholder="escolha uma opção"
          options={options}
          value={isAppealAccepted}
          onChange={setIsAppealAccepted}
        />
        <Divider style={{ margin: ' 16px 0px' }} />
        <Row wrap={false}>
          <div style={{ width: '50%' }}>
            <AristoMarkdownEditor
              body={comment}
              onUpdate={e => setComment(e)}
              disabled={isLoading || loading}
            />
          </div>
          <div style={{ width: '50%' }}>
            <Markdown
              style={{
                marginTop: '40px',
                minHeight: '300px',
                padding: '16px',
              }}
              saveOnStorage={false}
            >
              {comment}
            </Markdown>
          </div>
        </Row>
        <Row justify="end">
          <Button
            disabled={disabled}
            shape="round"
            icon={<FontAwesomeIcon icon={faComment} style={{ marginRight: '4px' }} />}
            onClick={handleSend}
          >
            Responder
          </Button>
        </Row>
      </Col>
    </Col>
  );
};
