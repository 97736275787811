import { FlagOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { FC } from 'react';

const { Text } = Typography;

type ForumStatusTagProps = {
  status?: string | null;
}

type CustomTagProps = {
  textColor : string;
  tagColor: string;
}

const CustomTag: FC<CustomTagProps> = ({
  children, textColor, tagColor,
}) => {
  return (
    <div style={{
      display: 'inline-block',
      boxSizing: 'border-box',
      overflow: 'auto',
      borderRadius: '100px',
      backgroundColor: tagColor,
      padding: '4px 8px',
    }}
    >
      <FlagOutlined style={{ fontWeight: 600, color: textColor }} />
      <Text style={{ marginLeft: '8px', fontWeight: 600, color: textColor }}>
        {children}
      </Text>
    </div>
  );
};

export function ForumStatusTag({ status }: ForumStatusTagProps) {
  switch (status) {
    case 'waitingAnswer':
      return (
        <CustomTag tagColor="#C9E6FF" textColor="#001E2F">
          Aguardando resposta
        </CustomTag>
      );
    case 'answering':
      return (
        <CustomTag tagColor="#F5E388" textColor="#211B00">
          Em resposta
        </CustomTag>
      );
    case 'answered':
      return (
        <CustomTag tagColor="#B1F1C1" textColor="#00210E">
          Respondida
        </CustomTag>
      );
    case 'markedAsIgnored':
      return (
        <CustomTag tagColor="#FFDADB" textColor="#00210E">
          Ignorada
        </CustomTag>
      );
    default:
      return (
        <CustomTag tagColor="yellow" textColor="#00210E">
          Sem status
        </CustomTag>
      );
  }
}
