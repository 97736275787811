import { FC, useMemo } from 'react';
import { Col, Divider, Typography } from 'antd';
import { CardComponent } from './CardComponent';
import { useTargetInstitutionToStudent } from '../../../api/user-dashboard';

export const InstitutionsCard: FC<{profileId: string, courseId: string}> = ({
  profileId,
  courseId,
}) => {
  const { data } = useTargetInstitutionToStudent({ profileId, courseId });

  const institutions = useMemo(() => data?.targetInstitutionToStudent, [data]);

  return (
    <CardComponent
      cardTitle="Instituições prioritárias"
    >
      <Col>
        <Typography style={{ fontWeight: 500 }}>QUANTIDADE</Typography>
        <Typography style={{
          margin: '6px 0px',
          fontSize: '45px',
          fontWeight: 400,
          lineHeight: 1,
        }}
        >
          {institutions?.length}
        </Typography>
        <Divider style={{ margin: '8px 0px' }} />
        <Typography style={{ fontSize: '11px', fontWeight: 500, marginBottom: '16px' }}>
          Nome
        </Typography>
        <div style={{
          height: '110px',
          overflow: 'hidden',
          overflowY: 'auto',
        }}
        >
          {institutions && institutions.map((it, index) => {
            return (
              <Typography
                key={it}
                style={{
                  fontSize: '12px',
                  fontWeight: 500,
                  lineHeight: 1,
                  margin: '8px 0px',
                }}
              >
                {`${index + 1}°: ${it}`}
              </Typography>
            );
          })}
        </div>
      </Col>
    </CardComponent>
  );
};
