import { TableProps } from 'antd';
import Table from 'antd/es/table';
import React, {
  FC, useCallback, useRef, useState,
} from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

interface DraggableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  index: number;
  moveRow: (dragIndex: number, howIndex: number) => void;
}

const type = 'DraggableBodyRow';

const DraggableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}: DraggableBodyRowProps) => {
  const ref = useRef<HTMLTableRowElement>(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? 'drop-over-downward' : 'drop-over-upward',
      };
    },
    drop: (item: { index: number }) => {
      moveRow(item.index, index);
    },
  });

  const [, drag] = useDrag({
    type,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

const components = {
  body: {
    row: DraggableBodyRow,
  },
};

interface SortableTableType extends TableProps<any> {
  columns: any[],
  tableData: any[],
  setTableData: React.Dispatch<any>,
  moveRowCustom?: (dragIndex: number, hoverIndex: number) => void,
}

export const SortableTable: FC<SortableTableType> = ({
  columns, tableData, setTableData, moveRowCustom, ...props
}) => {
  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = tableData[dragIndex];
      setTableData((prev: any) => {
        const prevCopy = [...prev];
        prevCopy.splice(dragIndex, 1);
        prevCopy.splice(hoverIndex, 0, dragRow);
        return prevCopy;
      });
    }, [setTableData, tableData],
  );
  return (
    <DndProvider backend={HTML5Backend}>
      <Table
        {...props}
        columns={columns}
        dataSource={tableData}
        components={components}
        onRow={(_, index) => {
          const attr = {
            index,
            moveRow: moveRowCustom || moveRow,
          };
          return attr as React.HTMLAttributes<any>;
        }}
      />
    </DndProvider>
  );
};
