import {
  gql, QueryResult, useMutation, useQuery,
} from '@apollo/client';
import { message } from 'antd';
import { useCallback } from 'react';

export interface Text {
  _id: string;
  url: string;
  title: string;
  subtitle: string;
  description: string;
  comment?: Comment;
}

export interface IText extends Text {}

export interface TextDetailsInput {
  _id: string;
}

export interface TextDetailsOutput {
  textDetails: Text;
}

const TEXT_DETAILS = gql`
  query TextDetails($_id: ObjectId!) {
    textDetails(_id: $_id) {
      _id
      url
      title
      subtitle
      description
    }
  }
`;

export function useTextDetails(_id: string): QueryResult<TextDetailsOutput, TextDetailsInput> {
  return useQuery<TextDetailsOutput, TextDetailsInput>(TEXT_DETAILS, {
    variables: {
      _id,
    },
    skip: !_id,
    onError: error => {
      message.error(error.message);
    },
  });
}

interface UpdateTextDetailsOutput {
  updateTextDetails: boolean;
}

export interface UpdateTextDetailsInput extends Omit<Partial<Text>, '_id'> {
  object: Omit<Partial<Text>, '_id'> & { _id: string };
}

const UPDATE_TEXT_DETAILS = gql`
  mutation UpdateTextDetails(
    $object: BaseContentInputType!
  ) {
    updateTextDetails(object: $object)
  }
`;

export function useUpdateTextDetails() {
  const [updateTextDetails] = useMutation<UpdateTextDetailsOutput, UpdateTextDetailsInput>(UPDATE_TEXT_DETAILS, {
    onError: error => {
      message.error(error.message);
    },
  });
  const handleUpdateTextDetails = useCallback(async (text: Omit<Partial<Text>, '_id'> & { _id: string }) => {
    const res = await updateTextDetails({
      variables: {
        object: text,
      },
    });
    return res.data?.updateTextDetails;
  }, [updateTextDetails]);
  return handleUpdateTextDetails;
}

interface LessonTextsInput {
  lessonId: string;
}

interface LessonTextsOutput {
  lessonTexts: Text[];
}

const LESSON_TEXTS = gql`
  query LessonTexts(
    $lessonId: ObjectId!
  ) {
    lessonTexts(lessonId: $lessonId) {
      _id
      title
      subtitle
      description
      url
    }
  }
`;

const CREATE_TEXT = gql`
  mutation CreateText(
    $lessonId: ObjectId!
  ) {
    createText(lessonId: $lessonId)
  }
`;

export interface CreateTextInput {
  lessonId: string;
}

export interface CreateTextOutput {
  createText: string;
}

const DELETE_TEXT = gql`
  mutation DeleteText(
    $contentId: ObjectId!
  ) {
    deleteText(contentId: $contentId)
  }
`;
export interface DeleteTextInput {
  contentId: string;
}

export interface DeleteTextOutput {
  deleteText: boolean;
}

const REMOVE_TEXT_FROM_LESSON = gql`
  mutation RemoveTextFromLesson(
    $contentId: ObjectId!
    $lessonId: ObjectId!
  ) {
    removeTextFromLesson(contentId: $contentId, lessonId: $lessonId)
  }
`;
export interface RemoveTextFromLessonInput {
  contentId: string;
  lessonId: string;
}

export interface RemoveTextFromLessonOutput {
  removeTextFromLesson: boolean;
}

export function useTexts(lessonId: string) {
  const lessonTexts = useQuery<LessonTextsOutput, LessonTextsInput>(LESSON_TEXTS, {
    variables: {
      lessonId,
    },
    skip: !lessonId,
  });

  const [createNewText] = useMutation<CreateTextOutput, CreateTextInput>(CREATE_TEXT);
  const handleCreateNewTextMutation = useCallback(async () => {
    const response = await createNewText({
      variables: {
        lessonId,
      },
    });
    return response.data?.createText;
  }, [createNewText, lessonId]);

  const [deleteText] = useMutation<DeleteTextOutput, DeleteTextInput>(DELETE_TEXT);
  const handleDeleteTextMutation = useCallback(async (contentId: string) => {
    const response = await deleteText({
      variables: {
        contentId,
      },
    });
    return response.data?.deleteText;
  }, [deleteText]);

  const [removeTextFromLesson] = useMutation<RemoveTextFromLessonOutput, RemoveTextFromLessonInput>(REMOVE_TEXT_FROM_LESSON);
  const handleRemoveTextFromLesson = useCallback(async (contentId: string) => {
    const response = await removeTextFromLesson({
      variables: {
        contentId,
        lessonId,
      },
    });
    return response.data?.removeTextFromLesson;
  }, [lessonId, removeTextFromLesson]);

  return {
    lessonTexts,
    handleCreateNewTextMutation,
    handleDeleteTextMutation,
    handleRemoveTextFromLesson,
  };
}
