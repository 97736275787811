import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { CopyOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Drawer, DrawerProps, Modal, Upload, Typography, Button, message, Col,
} from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import useFirebaseStorageImg, {
  useDeleteFirebaseStorageImage,
  useFirebaseStorageImageList,
} from '../hooks/useFirebaseStorageImg';
import { getBase64 } from '../utils/file-utils';

interface ImagesControlProps extends DrawerProps {
  contentId: string;
  firebaseStorageInfo: {
    imagesFolder: string;
    imagesSuffix?: string;
  };
}

function generateFileName(contentId: string, imagesCount: number, suffix?: string): string {
  let fileName = `${contentId}`;
  if (suffix) {
    fileName = fileName.concat(`_${suffix}`);
  }
  if (imagesCount >= 1) {
    fileName = fileName.concat(`_${imagesCount + 1}`);
  }
  return fileName;
}

const { Text } = Typography;

const ImagesControl: FC<ImagesControlProps> = ({
  visible, onClose, contentId, firebaseStorageInfo,
}) => {
  const { imagesFolder, imagesSuffix } = firebaseStorageInfo;

  /** States */
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [imagesCount, setImagesCount] = useState(0);

  const storageHook = useFirebaseStorageImg();

  const handleGetImagesOnComments = useCallback(async () => {
    const imagesList = await useFirebaseStorageImageList(imagesFolder, generateFileName(contentId, 0, imagesSuffix));
    setFileList(imagesList.map(image => ({
      name: image.path,
      url: image.url,
      status: 'done',
      uid: image.name,
    })));
    setImagesCount(imagesList.length);
  }, [imagesFolder, contentId, imagesSuffix]);

  useEffect(() => {
    handleGetImagesOnComments();
  }, [handleGetImagesOnComments]);

  const handlePreview = useCallback(async (file: UploadFile<any>) => {
    if (!file.url) {
      return;
    }
    setPreviewImage(file.url);
    setPreviewVisible(true);
    setPreviewTitle(file.name);
  }, []);

  const handleRemove = useCallback(async (file: UploadFile<any>) => {
    try {
      await useDeleteFirebaseStorageImage(file.name);
    } catch (error) {
      console.error(error);
    } finally {
      setFileList(prev => {
        return prev.filter(item => item.uid !== file.uid);
      });
      setImagesCount(prev => prev - 1);
    }
  }, []);

  const handleUpload = useCallback(async (file: RcFile) => {
    const base64 = await getBase64(file);
    const upload = await storageHook.uploadFromBase64(
      imagesFolder,
      String(base64),
      generateFileName(contentId, imagesCount, imagesSuffix),
    );
    const url = await storageHook.getURLAsync(upload);
    setFileList(prev => {
      return [...prev, {
        ...file,
        name: upload,
        status: 'done',
        url,
      }];
    });
    setImagesCount(prev => prev + 1);
    return upload;
  }, [storageHook, imagesFolder, contentId, imagesCount, imagesSuffix]);

  const handleCopy = useCallback((text: string) => {
    navigator.clipboard.writeText(`![](${text})`);
    message.success('Texto copiado para a área de transferência!');
  }, []);

  return (
    <>
      <Drawer
        title="Carregar imagens"
        width={720}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Upload
          accept="image/jpeg, image/png"
          action={handleUpload}
          listType="picture-card"
          itemRender={(originalNode, file) => {
            return (
              <>
                {originalNode}
                <div
                  style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                >
                  <Button
                    icon={<CopyOutlined />}
                    onClick={() => handleCopy(file.name)}
                  />
                </div>
              </>
            );
          }}
          fileList={fileList}
          customRequest={() => { }}
          onRemove={handleRemove}
          onPreview={handlePreview}
        >
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        </Upload>
      </Drawer>
      <Modal
        visible={previewVisible}
        width={800}
        title={(
          <>
            <Text style={{ paddingRight: '8px' }}>
              {previewTitle}
            </Text>
            <Button
              icon={<CopyOutlined />}
              onClick={() => handleCopy(previewTitle)}
            />
          </>
        )}
        onOk={() => setPreviewVisible(prev => !prev)}
        onCancel={() => setPreviewVisible(prev => !prev)}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};

export default ImagesControl;
