import {
  EditOutlined,
  EyeFilled, LoadingOutlined, OrderedListOutlined, PlusOutlined,
} from '@ant-design/icons';
import {
  Col,
  Divider,
  Input,
  Form,
  PageHeader,
  Row,
  Typography,
  Button,
  Select,
  Space,
  Empty,
  List,
  Tag,
  Switch,
  Layout,
  Dropdown,
  Menu,
  Modal,
  message,
  Tooltip,
} from 'antd';
import React, {
  useMemo, useState, useCallback, useContext, Dispatch,
} from 'react';
import { Link } from 'react-router-dom';
import { Institution, useGetInstitutionList } from '../../api/institutions';
import { Question, QuestionsInput, useQuestions } from '../../api/question';
import ClassificationTreeCascader from '../../components/ClassificationTreeCascader';
import { CourseContext } from '../../contexts/Courses.context';
import { CommentTag } from '../questions/components/CommentTag';
import { DifficultyTag } from '../questions/components/DifficultyTag';

const { Title, Text } = Typography;
const { Option } = Select;
const { Content } = Layout;
const { info } = Modal;

interface editingQuestionsInterface {
  handleEditQuestions: (questions: Question[]) => void,
  editingQuestions: Question[],
}

export function AddQuestionPage({ handleEditQuestions, editingQuestions }: editingQuestionsInterface) {
  const [form] = Form.useForm();
  const [institutions, setInstitutions] = useState<Institution[]>([]);
  const [courseDetailedTrees, setCourseDetailedTrees] = useState('');
  const { courses, loading: coursesLoading } = useContext(CourseContext);

  const { data, loading } = useGetInstitutionList();
  const [questionsQuery, { data: questionsData, loading: questionsLoading }] = useQuestions();

  const questions = useMemo(() => {
    if (!questionsData || questionsLoading) {
      return [];
    }
    return questionsData.questions;
  }, [questionsData, questionsLoading]);

  const ufsList = useMemo(() => {
    if (!data || loading) {
      return undefined;
    }
    const result = [...data.getInstitutionListAdmin];
    result.sort((a, b) => {
      const ufA = a.uf.toUpperCase();
      const ufB = b.uf.toUpperCase();
      if (ufA < ufB) {
        return -1;
      }
      if (ufA > ufB) {
        return 1;
      }
      return 0;
    });
    return result;
  }, [data, loading]);

  const handleIsInLesson = useCallback((questionId: string) => {
    return editingQuestions.map(q => q._id).includes(questionId);
  }, [editingQuestions]);

  const handleSelectUf = useCallback((uf: string) => {
    form.setFields([{ name: 'institution', value: undefined }]);
    const inst = ufsList?.find(ufItem => ufItem.uf === uf)?.institutions ?? [];
    const result = [...inst];
    result.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setInstitutions(result);
  }, [form, ufsList]);

  const handleSelectCourse = useCallback((courseId: string) => {
    form.setFields([{ name: 'classification', value: undefined }]);
    setCourseDetailedTrees(courses.find(c => c._id === courseId)?.detailedClassificationTree ?? '');
  }, [courses, form]);

  const handleViewQuestion = useCallback(question => {
    const {
      examOrder, institution, uf, year, body, choices,
    } = question;
    const alternatives = ['a)', 'b)', 'c)', 'd)', 'e)'];
    info({
      title: `Questão ${examOrder} - ${institution} - ${uf} - ${year}`,
      content: (
        <>
          <p>{body}</p>
          {choices.map((choice: string, index: number) => <p key={choice}>{`${alternatives[index]}  ${choice}`}</p>)}
        </>
      ),
      width: '50%',
    });
  }, []);

  const handleFinish = (values: Omit<QuestionsInput['filter'], 'classification'> & { classification?: string[], course?: string }) => {
    let classification;
    if (values.course) {
      classification = {
        detailedTreeId: courseDetailedTrees,
      };
    }
    if (values.classification && values.classification.length > 0) {
      classification = {
        ...classification,
        leaf: values.classification,
      };
    }
    const filter = {
      ...values,
      classification,
      ...(values.complement ? { complement: '1' } : { complement: undefined }),
    };
    delete filter.course;
    questionsQuery({
      variables: {
        filter,
      },
    });
  };

  return (
    <div style={{ maxWidth: '1120px', margin: '0 auto' }}>
      <Content style={{
        minHeight: '500px',
      }}
      >
        <Title level={5}>Filtros</Title>
        <Form form={form} onFinish={handleFinish}>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item name="_id" label="Id">
                <Input placeholder="Id da questão" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="uf" label="UF">
                {ufsList && !loading ? (
                  <Select placeholder="UF" allowClear onChange={handleSelectUf}>
                    {ufsList.map(ufItem => <Option key={ufItem.uf} value={ufItem.uf}>{ufItem.uf}</Option>)}
                  </Select>
                ) : (
                  <LoadingOutlined />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="institution" label="Instituição">
                <Select placeholder="Instituição" allowClear>
                  {institutions.map(institution => <Option key={institution.name} value={institution.name}>{institution.name}</Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="comments" label="Comentadas">
                <Select allowClear placeholder="Comentadas">
                  <Option value="yes">Sim</Option>
                  <Option value="no">Não</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="difficulty" label="Dificuldade">
                <Select allowClear placeholder="Dificuldade">
                  <Option value="easy">Fácil</Option>
                  <Option value="medium">Média</Option>
                  <Option value="hard">Difícil</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="year" label="Ano">
                <Select allowClear placeholder="Ano">
                  {Array.from({ length: ((new Date()).getFullYear() - 2004) }, (v, i) => 2005 + i).map(year => (
                    <Option key={year} value={year}>{year}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" align="top">
            <Col span={8}>
              <Form.Item name="course" label="Curso">
                {courses && !coursesLoading ? (
                  <Select allowClear placeholder="Curso" onChange={handleSelectCourse}>
                    {courses.map(course => (
                      <Option key={course._id} value={course._id}>
                        {course.title}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <LoadingOutlined />
                )}
              </Form.Item>
            </Col>
            <Form.Item name="classification" label="Classificação">
              <ClassificationTreeCascader
                treeId={courseDetailedTrees}
                value={[]}
                onChange={() => { }}
              />
            </Form.Item>
            <Form.Item name="complement" label="Apenas R1" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Space direction="horizontal">
              <Button type="primary" htmlType="submit" loading={questionsLoading}>
                Buscar
              </Button>
              <Button
                onClick={() => {
                  form.resetFields();
                }}
              >
                Limpar filtros
              </Button>
            </Space>
          </Row>
        </Form>
        <Divider />
        {questions.length > 0 && (
          <List
            size="small"
            dataSource={questions}
            renderItem={question => {
              const {
                examOrder, institution, uf, year,
                difficulty, comment, complement,
                classification, body, _id, correctChoice,
              } = question;
              const isQuestionInLesson = handleIsInLesson(question._id);
              return (
                <List.Item actions={[
                  <Dropdown overlay={(
                    <Menu>

                      <Menu.Item>
                        <Button
                          type="link"
                          onClick={() => handleViewQuestion(question)}
                        >
                          <Space>
                            <EyeFilled />
                            Visualizar
                          </Space>
                        </Button>
                      </Menu.Item>

                      <Menu.Item>
                        <Tooltip title={isQuestionInLesson ? 'Essa questão já se encontra na lista!' : ''} placement="left">
                          <Button
                            disabled={isQuestionInLesson}
                            type="link"
                            onClick={() => handleEditQuestions([...editingQuestions, question])}
                          >
                            <Space>
                              <PlusOutlined />
                              Adicionar
                            </Space>
                          </Button>
                        </Tooltip>
                      </Menu.Item>

                      <Menu.Item>
                        <Button
                          type="link"
                        >
                          <Link to={`/admin/editar-questao?id=${_id}`}>
                            <Space>
                              <EditOutlined />
                              Editar
                            </Space>
                          </Link>
                        </Button>
                      </Menu.Item>

                    </Menu>
                  )}
                  >
                    <Button>
                      <Space>
                        <OrderedListOutlined />
                        Ação
                      </Space>
                    </Button>
                  </Dropdown>,
                ]}
                >
                  <List.Item.Meta
                    title={(
                      <Space>
                        <Text>
                          {`Questão ${examOrder} - ${institution} - ${uf} - ${year}`}
                        </Text>
                        <DifficultyTag difficulty={difficulty} />
                        <CommentTag comment={Boolean(comment)} />
                        <Tag>{complement === '1' ? 'R1' : complement}</Tag>
                        {correctChoice === -1 && <Tag color="volcano">Anulada</Tag>}
                      </Space>
                    )}
                    description={`${(classification[0].leaf ?? []).length > 0
                      ? (classification[0].leaf ?? []).join(' | ')
                      : 'Sem classificação'} - ${body.substr(0, 100)}...`}
                  />
                </List.Item>
              );
            }}
          />
        )}
        {questions.length === 0 && !questionsLoading && <Empty description="Nenhuma questão por aqui..." />}
      </Content>
    </div>
  );
}
