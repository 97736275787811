import {
  DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined,
} from '@ant-design/icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ColumnsType } from 'antd/es/table';
import {
  List,
  message,
  Space,
  Spin,
  Typography,
  Modal,
  Row,
  Button,
  Empty,
  Checkbox,
  Table,
  Switch,
} from 'antd';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  useSetVideoScope,
  useUpdateVideoDetails, useVideoDetails, useVideos, Video,
} from '../../api/videos';
import EditDataWrapper from '../../components/EditDataWrapper';
import { getParam } from '../../hooks/useSearchParam';
import { SortableTable } from '../../components/SortableTable';
import { useUpdateLessonContents } from '../../api/lessons';

interface DataType {
  key: string,
  videoName: Video,
  onlyReview: Video,
  actions: Video,
}

const { Text } = Typography;
const { confirm } = Modal;

// ID aula, tipo, array de ids / => theoreticalReviewVideos

export type AlignType = 'center' | 'left' | 'right';

export function VideosPage() {
  const [loadingCheckbox, setLoadingCheckbox] = useState(false);
  const { handleUpdateLessonContents } = useUpdateLessonContents();
  const [updateOrderLoading, setUpdateOrderLoading] = useState(false);
  const lessonId = getParam('aula');
  const history = useHistory();
  const [loadingCreate, setLoadingCreate] = useState(false);
  const { state } = useLocation<{ title: string } | undefined>();
  const { onSetVideoScope } = useSetVideoScope();
  const [currLoadingCheckbox, setCurrLoadingCheckbox] = useState<string>();
  const [tableData, setTableData] = useState<DataType[]>([]);
  const [previousOrder, setPreviousOrder] = useState('-');
  const [currOrder, setCurrOrder] = useState('-');

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = tableData[dragIndex];
      setTableData(prev => {
        const onlyReviewLen = prev.filter(x => x.onlyReview.isOnlyTheoreticalReview).length;
        if (
          (dragIndex < onlyReviewLen && hoverIndex >= onlyReviewLen)
          || (dragIndex >= onlyReviewLen && hoverIndex < onlyReviewLen)) {
          setCurrOrder(prev.map(x => x.key).join('-'));
          return prev;
        }
        const prevCopy = [...prev];
        prevCopy.splice(dragIndex, 1);
        prevCopy.splice(hoverIndex, 0, dragRow);
        setCurrOrder(prevCopy.map(x => x.key).join('-'));
        return prevCopy;
      });
    }, [tableData],
  );

  const {
    lessonVideos: { data, loading: queryLoading, refetch },
    handleCreateNewVideoMutation,
    handleDeleteVideoMutation,
  } = useVideos(lessonId);

  const handleCreateVideo = useCallback(async () => {
    setLoadingCreate(true);
    try {
      const videoId = await handleCreateNewVideoMutation();
      await refetch({
        lessonId,
      });
      setLoadingCreate(false);
      history.push({
        pathname: 'editar-video',
        search: `?id=${videoId}`,
      });
    } catch (err) {
      console.error(err);
      message.error('Erro ao criar um novo video!');
      setLoadingCreate(false);
    }
  }, [handleCreateNewVideoMutation, history, lessonId, refetch]);

  const handleOnlyReviewCheckbox = useCallback(async (e, vid: Video) => {
    setCurrLoadingCheckbox(vid._id);
    try {
      await onSetVideoScope({
        lessonId,
        contentId: vid._id,
        scope: e.target.checked ? 'theoreticalReview' : 'all',
      });
      await refetch({
        lessonId,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setCurrLoadingCheckbox('');
    }
  }, [lessonId, onSetVideoScope, refetch]);

  const handleDeleteModal = useCallback((video: Pick<Video, '_id' | 'title' | 'subtitle'>) => {
    confirm({
      title: 'Deseja mesmo deletar esse video?',
      content: (
        <>
          <div>
            {`Título: ${video.title}`}
          </div>
          <div>
            {`Subtítulo: ${video.subtitle}`}
          </div>
        </>
      ),
      onOk: async () => {
        try {
          await handleDeleteVideoMutation(video._id);
          await refetch({
            lessonId,
          });
        } catch (err) {
          console.error(err);
          message.error('Erro ao deletar o video');
        }
      },
      onCancel: () => { },
    });
  }, [handleDeleteVideoMutation, lessonId, refetch]);

  const videos = useMemo(() => {
    if (queryLoading || !data?.lessonVideos) {
      return undefined;
    }
    return data.lessonVideos;
  }, [data, queryLoading]);

  useEffect(() => {
    if (videos) {
      setTableData(
        videos
          .map(vid => {
            return (
              {
                key: vid._id,
                videoName: vid,
                onlyReview: vid,
                actions: vid,
              }
            );
          })
          .sort((a, b) => {
            return (a.onlyReview && !b.onlyReview ? -1 : 1);
          }),
      );
    }
  }, [videos]);

  const columns = useMemo(() => (
    [
      {
        title: <b>{`Vídeos de ${state?.title ? ` ${state?.title} ` : ''}`}</b>,
        key: 'videoName',
        dataIndex: 'videoName',
        width: '75%',
        render: ({ title, subtitle, url }: Video) => (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '.5rem',
          }}
          >
            <Text>{`${title} - ${subtitle}`}</Text>
            <Text style={{ color: 'gray', fontSize: '.8rem', maxWidth: 600 }}>{`(${url})`}</Text>
          </div>
        ),
      },
      {
        title: 'Somente revisão?',
        key: 'onlyReview',
        dataIndex: 'onlyReview',
        width: '5%',
        align: 'center' as AlignType,
        render: (vid: Video) => {
          const { isOnlyTheoreticalReview, _id } = vid;
          return (
            currLoadingCheckbox === _id
              ? <FontAwesomeIcon icon={faSpinner} spin />
              : <Checkbox checked={isOnlyTheoreticalReview} onClick={e => handleOnlyReviewCheckbox(e, vid)} />
          );
        },
      },
      {
        title: () => (
          <Button
            style={{ flex: 1 }}
            icon={<PlusOutlined />}
            onClick={handleCreateVideo}
            loading={loadingCreate}
          >
            Criar video
          </Button>
        ),
        key: 'actions',
        dataIndex: 'actions',
        width: '20%',
        align: 'center' as AlignType,
        render: (vid: Video) => {
          const { _id } = vid;
          const search = `?id=${_id}`;
          return (
            <div
              style={{
                display: 'flex',
                gap: '1rem',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Link
                to={{
                  pathname: '/admin/editar-video',
                  search,
                }}
              >
                <Space>
                  <EditOutlined />
                  Editar
                </Space>
              </Link>
              <Button
                style={{ padding: 0 }}
                type="link"
                icon={<DeleteOutlined />}
                danger
                onClick={() => handleDeleteModal(vid)}
              >
                Deletar
              </Button>
            </div>
          );
        },
      },
    ]
  ), [currLoadingCheckbox, handleCreateVideo, handleDeleteModal, handleOnlyReviewCheckbox, loadingCreate, state?.title]);

  const onUpdateOrderClick = useCallback(async () => {
    setUpdateOrderLoading(true);
    await handleUpdateLessonContents({
      lessonId,
      contentIds: tableData.filter(x => x.actions.isOnlyTheoreticalReview).map(x => x.key),
      contentType: 'theoreticalReviewVideos',
    });
    await handleUpdateLessonContents({
      lessonId,
      contentIds: tableData.filter(x => !x.actions.isOnlyTheoreticalReview).map(x => x.key),
      contentType: 'videos',
    });
    setPreviousOrder(tableData.map(x => x.key).join('-'));
    setUpdateOrderLoading(false);
  }, [handleUpdateLessonContents, lessonId, tableData]);

  return (
    <EditDataWrapper
      title="Videos"
      searchKey="aula"
      placeholder="Buscar por aula"
    >
      {queryLoading && <Spin />}
      {videos && videos.length > 0 && (
        <SortableTable
          columns={columns}
          setTableData={setTableData}
          tableData={tableData}
          moveRowCustom={moveRow}
          summary={() => (
            <Table.Summary.Row style={{ backgroundColor: '#fafafa' }}>
              <Table.Summary.Cell index={2} colSpan={8}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                >
                  <Button
                    style={{ margin: '0 10px' }}
                    icon={<SaveOutlined />}
                    disabled={(currOrder === previousOrder) || updateOrderLoading}
                    onClick={onUpdateOrderClick}
                    loading={updateOrderLoading}
                  >
                    Salvar ordem
                  </Button>
                </div>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      )}
      {videos && videos.length === 0 && (
        <Empty description="Nenhum video encontrado">
          <Button
            icon={<PlusOutlined />}
            onClick={handleCreateVideo}
            loading={loadingCreate}
          >
            Criar vídeo
          </Button>
        </Empty>
      )}
    </EditDataWrapper>
  );
}
