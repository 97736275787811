import { FC } from 'react';
import { Col, Image, Typography } from 'antd';
import { AppealObjectType } from '../../../../api/appeal';

type AppealContentProps = {
  data?: AppealObjectType;
  year: number;
  limitText?: boolean;
}

export const AppealContent: FC<AppealContentProps> = ({
  data,
  year,
  limitText = false,
}) => {
  const title = `[${data?.institution} - ${year}] Questão ${data?.question.questionNumber} - Prova ${data?.examVersion}`;

  return (
    <Col style={{
      flexGrow: 1,
      maxWidth: '500px',
      marginRight: '16px',
      maxHeight: limitText ? '10em' : 'none',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    }}
    >
      <Typography style={{ fontSize: '14px', fontWeight: 600 }}>{data?.profile ?? ''}</Typography>
      <Typography style={{ fontSize: '14px', fontWeight: 700 }}>{title}</Typography>
      <Typography.Paragraph>{data?.question.description ?? ''}</Typography.Paragraph>
      {data?.question.questionPrintsUrls?.map(e => <Image style={{ margin: '4px' }} src={e} width="80px" height="120px" />)}
    </Col>
  );
};
