import {
  DeleteOutlined, EditOutlined, EyeFilled, OrderedListOutlined, PlusOutlined, SaveOutlined, UndoOutlined,
} from '@ant-design/icons';
import {
  Button,
  List,
  message,
  Row,
  Space,
  Spin,
  Typography,
  Modal,
  Empty,
  Divider,
  Tag,
  Drawer,
  Dropdown,
  Menu,
} from 'antd';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useUpdateLessonContents } from '../../api/lessons';
import { Question, useLessonQuestions } from '../../api/question';
import EditDataWrapper from '../../components/EditDataWrapper';
import { getParam } from '../../hooks/useSearchParam';
import { CommentTag } from '../questions/components/CommentTag';
import { DifficultyTag } from '../questions/components/DifficultyTag';
import { AddQuestionPage } from './AddQuestionPage';

const { Text } = Typography;
const { info } = Modal;

export function LessonQuestionsPage() {
  const lessonId = getParam('aula');
  const { data, loading, refetch } = useLessonQuestions(lessonId);
  const [editingQuestions, setEditingQuestions] = useState(data?.lessonQuestions || []);
  const [loadingSave, setLoadingSave] = useState(false);
  const { state } = useLocation<{ title: string } | undefined>();
  const { handleUpdateLessonContents } = useUpdateLessonContents();

  useEffect(() => {
    setEditingQuestions(data?.lessonQuestions || []);
  }, [data?.lessonQuestions]);

  const handleDisableSaveButton = useMemo(() => {
    if (loading) {
      return true;
    }
    const originalIds = data?.lessonQuestions.map(e => e._id);
    const editingIds = editingQuestions.map(e => e._id);
    if (originalIds?.sort().join(',') === editingIds.sort().join(',')) {
      return true;
    }
    return false;
  }, [data?.lessonQuestions, editingQuestions, loading]);

  const [visible, setVisible] = useState(false);
  const showAddQuestionDrawer = useCallback(() => {
    setVisible(true);
  }, []);
  const onCloseAddQuestionDrawer = useCallback(() => {
    setVisible(false);
  }, []);

  const handleViewQuestion = useCallback(({
    examOrder, institution, uf, year, body, choices,
  }: Question) => {
    const alternatives = ['a)', 'b)', 'c)', 'd)', 'e)'];
    info({
      title: `Questão ${examOrder} - ${institution} - ${uf} - ${year}`,
      content: (
        <>
          <p>{body}</p>
          {choices.map((choice: string, index: number) => <p key={choice}>{`${alternatives[index]}  ${choice}`}</p>)}
        </>
      ),
      width: '50%',
    });
  }, []);

  async function handleSave() {
    setLoadingSave(true);
    try {
      await handleUpdateLessonContents({ lessonId, contentType: 'questions', contentIds: editingQuestions.map(quest => quest._id) });
    } catch (err) {
      console.error(err);
      message.error('Erro ao salvar questões!');
    } finally {
      await refetch();
      setLoadingSave(false);
    }
  }

  return (
    <EditDataWrapper
      title="Questões"
      searchKey="aula"
      placeholder="Buscar por aula"
    >
      <Drawer
        placement="right"
        title="Adicionar questao"
        onClose={onCloseAddQuestionDrawer}
        visible={visible}
        width="50rem"
      >
        <AddQuestionPage
          handleEditQuestions={(q: Question[]) => setEditingQuestions(q)}
          editingQuestions={editingQuestions}
        />
      </Drawer>
      <Row justify="space-between">
        <Text strong>
          Lista de questões
          {state?.title ? ` de ${state?.title} ` : ''}
        </Text>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <Button
            icon={<PlusOutlined />}
            onClick={showAddQuestionDrawer}
            loading={loadingSave}
          >
            Adicionar questão
          </Button>
          <Space style={{ marginLeft: '1.5rem' }}>
            <Button
              icon={<UndoOutlined />}
              onClick={() => setEditingQuestions(data?.lessonQuestions ?? [])}
              loading={loadingSave}
              disabled={handleDisableSaveButton}
            >
              Desfazer alterações
            </Button>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              onClick={handleSave}
              loading={loadingSave}
              disabled={handleDisableSaveButton}
            >
              Salvar alterações
            </Button>
          </Space>
        </div>
      </Row>
      <Divider />
      {loading && <Spin />}
      {editingQuestions && editingQuestions.length > 0 && (
        <List
          size="small"
          dataSource={editingQuestions}
          renderItem={question => {
            const {
              examOrder, institution, uf, year, difficulty,
              comment, complement, classification,
              body, _id, correctChoice,
            } = question;
            return (
              <List.Item actions={[
                <Dropdown overlay={(
                  <Menu>
                    <Menu.Item>
                      <Button
                        style={{ padding: 0 }}
                        type="link"
                        icon={<EyeFilled />}
                        onClick={() => handleViewQuestion(question)}
                      >
                        Visualizar
                      </Button>
                    </Menu.Item>

                    <Menu.Item>
                      <Button
                        style={{ padding: 0 }}
                        type="link"
                        icon={<DeleteOutlined />}
                        danger
                        onClick={() => setEditingQuestions(prev => prev.filter(item => item._id !== _id))}
                      >
                        Deletar
                      </Button>
                    </Menu.Item>

                    <Menu.Item>
                      <Link to={`/admin/editar-questao?id=${_id}`}>
                        <Space>
                          <EditOutlined />
                          Editar
                        </Space>
                      </Link>
                    </Menu.Item>

                  </Menu>
                )}
                >
                  <Button
                    style={{ padding: 0 }}
                    type="link"
                    icon={<OrderedListOutlined />}
                  >
                    Ação
                  </Button>
                </Dropdown>,
              ]}
              >
                <List.Item.Meta
                  title={(
                    <Space>
                      <Text>
                        {`Questão ${examOrder} - ${institution} - ${uf} - ${year}`}
                      </Text>
                      <DifficultyTag difficulty={difficulty} />
                      <CommentTag comment={Boolean(comment)} />
                      <Tag>{complement === '1' ? 'R1' : complement}</Tag>
                      {correctChoice === -1 && <Tag color="volcano">Anulada</Tag>}
                    </Space>
                  )}
                  description={`${(classification[0]?.leaf ?? []).length > 0
                    ? (classification[0].leaf ?? []).join(' | ')
                    : 'Sem classificação'} - ${body.substring(0, 101)}...`}
                />
              </List.Item>
            );
          }}
        />
      )}
      {editingQuestions.length === 0 && !loading && <Empty description="Nenhuma questão por aqui..." />}
    </EditDataWrapper>
  );
}
