import { FC, useMemo } from 'react';
import {
  Col, Row, Typography,
} from 'antd';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { QuestionWeekExpandableCard } from './QuestionWeekExpandableCard';
import { useStudentAnsweredQuestions } from '../../../../../api/user-dashboard';

const titleStyle = {
  fontSize: '16px',
  fontWeight: 500,
  width: '160px',
};

type QuestionWeekContentProps = {
  courseId: string;
    endDate: Date;
    profileId: string;
    startDate: Date;
}

export const QuestionWeekContent: FC<QuestionWeekContentProps> = ({
  courseId,
  endDate,
  profileId,
  startDate,
}) => {
  const { data, loading } = useStudentAnsweredQuestions({
    profileId,
    courseId,
    startDate,
    endDate,
  });

  const questions = useMemo(() => {
    if (!data?.getStudentAnsweredQuestions) {
      return [];
    }

    return data.getStudentAnsweredQuestions.slice().sort((a, b) => {
      const dateA = moment(a.calendarDate, 'YYYYMMDD').toDate();
      const dateB = moment(b.calendarDate, 'YYYYMMDD').toDate();
      return dateA.getTime() - dateB.getTime();
    });
  }, [data]);

  return (
    <Col>
      <Row justify="space-between" style={{ height: '30px' }}>
        <Typography style={titleStyle}>Dia</Typography>
        <Typography style={titleStyle}>Quantidade</Typography>
        <Typography style={titleStyle}>Acertos</Typography>
        <Typography style={titleStyle}>Erros</Typography>
        <Typography style={titleStyle}>%</Typography>
        <div style={{ width: '16px' }} />
      </Row>
      {loading && (
        <Row style={{ justifyContent: 'center', alignItems: 'center', height: '100px' }}>
          <LoadingOutlined style={{ margin: '20px auto', fontSize: '42px' }} />
        </Row>
      )}
      {!loading && questions && questions.map(it => {
        return (
          <QuestionWeekExpandableCard
            key={it.calendarDate}
            week={it.calendarDate}
            qnt={it.totalQuestions}
            hits={it.correct}
            miss={it.wrong}
            acc={(it.percentage * 100).toFixed(0)}
            themes={it.themas}
          />
        );
      })}
    </Col>
  );
};
