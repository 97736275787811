import { LoadingOutlined } from '@ant-design/icons';
import {
  Popover,
  Space,
  Progress,
  Typography,
} from 'antd';
import moment from 'moment';
import { memo } from 'react';

export type ProgressType = {
  key: string;
  courseId: string;
  docsProcessed: number;
  finished: boolean;
  previewTotal: number;
  startedAt?: number;
  finishedAt?: number;
}

type ProgressPopoverProps = {
  progress: ProgressType;
}

const { Text } = Typography;

export function ProgressPopover({
  progress,
}: ProgressPopoverProps) {
  return (
    <Popover content={(
      <Space direction="vertical">
        <Text>{`${progress.docsProcessed} de ${progress.previewTotal} documentos atualizados`}</Text>
        <Progress percent={Math.round((progress.docsProcessed * 100) / progress.previewTotal)} status="active" />
        <Text>{`Tempo decorrido: ${moment().diff(moment(progress.startedAt), 'minutes')} min`}</Text>
      </Space>
    )}
    >
      <Space>
        <LoadingOutlined />
        <Text>Atualização em andamento...</Text>
      </Space>
    </Popover>
  );
}

export const MemoProgress = memo(ProgressPopover);
