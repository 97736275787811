import { gql, useQuery } from '@apollo/client';

export interface UsersOnlineInput {
  filter?: {
    dateStart: Date;
    dateEnd: Date;
  };
}

export interface UsersOnlineOutput {
  onlineUsersHistory: {
    date: Date;
    count: number;
  }[];
}

const GET_USER_ONLINE_HISTORY = gql`
  query OnlineUsersHistory($filter: DateRangeFilterInputType) {
    onlineUsersHistory(filter: $filter) {
      date
      count
    }
  }
`;

export function useOnlineUserHistory(filter?: { dateStart: Date, dateEnd: Date }) {
  return useQuery<UsersOnlineOutput, UsersOnlineInput>(GET_USER_ONLINE_HISTORY, {
    variables: {
      filter,
    },
  });
}
