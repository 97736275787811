import {
  FC, useCallback, useEffect, useState,
} from 'react';
import {
  Button, Row, message,
} from 'antd';
import useCommentsManagement, { AnswerStatusType, ForumCommentType } from '../../../../hooks/useCommentsManagement';
import Markdown from '../../../../components/Markdown';
import { AristoMarkdownEditor } from '../../../../components/AristoMarkdownEditor';

type ForumCommentEditorProps = {
  comment?: ForumCommentType;
  confirmLocalAnswer: (status: AnswerStatusType) => void;
}

export const ForumCommentEditor: FC<ForumCommentEditorProps> = ({
  comment,
  confirmLocalAnswer,
}) => {
  const [replyBody, setReplyBody] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { replyCommentAsModerator, getCommentsByIds } = useCommentsManagement();

  const getModeratorCommentReply = useCallback(async () => {
    try {
      if (comment && comment.children.length && comment.status.answerStatus === 'answered') {
        console.log(comment.children);
        const comments = await getCommentsByIds(comment.children);
        setReplyBody(comments.find(it => it.status.isFromModerator)?.body ?? '');
      }
    } catch (error) {
      message.error('Erro ao pegar a resposta do moderador!');
    }
  }, [getCommentsByIds, setReplyBody, comment]);

  useEffect(() => {
    getModeratorCommentReply();
  }, [getModeratorCommentReply]);

  const sendComment = async (): Promise<void> => {
    setIsLoading(true);
    try {
      if (comment) {
        await replyCommentAsModerator({
          body: replyBody,
          contentId: comment.contentId,
          courseId: comment.courseId,
          parentId: comment.id,
        });
        confirmLocalAnswer('answered');
        message.success('Comentário respondido com sucesso!');
      } else {
        message.error('Falha ao enviar comentário!');
      }
    } catch (error) {
      message.error('Erro ao responder comentário!');
    }
    setIsLoading(false);
  };

  const handleUpdateBody = (value: string): void => {
    setReplyBody(value);
  };

  return (
    <div
      style={{
        padding: '16px',
        borderRadius: '16px',
        backgroundColor: '#F9F9FF',
      }}
    >
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}
      >
        <div style={{ minWidth: '280px', flexGrow: 1, flexBasis: 1 }}>
          <AristoMarkdownEditor
            body={replyBody}
            onUpdate={handleUpdateBody}
            disabled={comment?.status.answerStatus !== 'waitingAnswer'}
          />
        </div>
        <div style={{ minWidth: '280px', flexGrow: 1, flexBasis: 1 }}>
          <Markdown
            style={{
              marginTop: '40px',
              minHeight: '300px',
              padding: '16px',
            }}
            saveOnStorage={false}
          >
            {replyBody}
          </Markdown>
        </div>
      </div>
      <Row style={{ width: '100%', justifyContent: 'flex-end' }}>
        <Button
          style={{
            width: '140px',
            marginTop: '24px',
            backgroundColor: '#415f91',
          }}
          type="primary"
          loading={comment !== undefined && replyBody !== '' && isLoading}
          onClick={sendComment}
          shape="default"
          disabled={replyBody === '' || comment?.status.answerStatus !== 'waitingAnswer'}
        >
          Responder
        </Button>
      </Row>
    </div>
  );
};
