import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import {
  BoldOutlined,
  CheckOutlined,
  CloseOutlined,
  EditFilled,
  ItalicOutlined,
  LinkOutlined,
  PictureOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  RollbackOutlined,
  SaveOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import {
  Button, Col, Row, Input, Modal, Space, Typography,
} from 'antd';
import Markdown from './Markdown';
import ImagesControl from './ImagesControl';

interface EditableMarkdownProps {
  children: string;
  externalState?: string;
  externalSetStateFn?: (value: string) => void;
  contentId: string;
  imagesStorageInfo: {
    imagesFolder: string;
    imagesSuffix?: string;
  };
  onSave?: (value: string) => Promise<void>;
  size?: 'small' | 'medium' | 'large';
  allowMarkdownShortcuts?: boolean;
}

type MarkdownTagTypes = 'h1' | 'h2' | 'bold' | 'italic' | 'link' | 'list';

const { TextArea } = Input;
const { confirm, info } = Modal;
const { Text } = Typography;

const sizes = {
  small: 10,
  medium: 20,
  large: 30,
};

const EditableMarkdown: FC<EditableMarkdownProps> = ({
  children,
  contentId,
  imagesStorageInfo,
  onSave,
  size = 'large',
  externalState,
  externalSetStateFn,
  allowMarkdownShortcuts = false,
}) => {
  const [edit, setEdit] = useState(false);
  const [contentEdited, setContentEdited] = useState(children);
  const [imagesVisible, setImagesVisible] = useState(false);
  const [selectedText, setSelectedText] = useState<string>('');
  const [cursorPosition, setCursorPosition] = useState<{start: number, end: number} | null>(null);
  const [editHistory, setEditHistory] = useState<string[]>([]);
  const [currentEditIndex, setCurrentEditIndex] = useState<number>(-1);

  useEffect(() => {
    setContentEdited(children);
  }, [children]);

  const addToEditHistory = (content: string) => {
    const newEditHistory = editHistory.slice(0, currentEditIndex + 1);
    newEditHistory.push(content);
    setEditHistory(newEditHistory);
    setCurrentEditIndex(newEditHistory.length - 1);
  };

  const handleUndo = useCallback(() => {
    if (currentEditIndex > 0) {
      const newIndex = currentEditIndex - 1;
      setCurrentEditIndex(newIndex);
      setContentEdited(editHistory[newIndex]);
    }
  }, [currentEditIndex, editHistory]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'z') {
        event.preventDefault();
        handleUndo();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentEditIndex, editHistory, handleUndo]);

  const handleTextSelection = (e: React.SyntheticEvent<HTMLTextAreaElement, Event>) => {
    setSelectedText('');
    const inputElement = e.target as HTMLInputElement;
    const startIndex = inputElement.selectionStart;
    const endIndex = inputElement.selectionEnd;
    if (startIndex != null && endIndex != null) {
      const selection = inputElement.value.substring(startIndex, endIndex);
      setSelectedText(selection);
      setCursorPosition({ start: startIndex, end: endIndex });
    }
  };

  const handleConvertMarkdown = (tag: MarkdownTagTypes) => {
    if (!selectedText.length) {
      return;
    }

    let markdownText = '';

    switch (tag) {
      case 'h1':
        markdownText = `## ${selectedText}`;
        break;
      case 'h2':
        markdownText = `# ${selectedText}`;
        break;
      case 'bold':
        markdownText = `**${selectedText}**`;
        break;
      case 'italic':
        markdownText = `*${selectedText}*`;
        break;
      case 'link':
        markdownText = `[ADICIONE A DESCRIÇÃO DO LINK AQUI](${selectedText})`;
        break;
      case 'list':
        markdownText = `- ${selectedText}`;
        break;
      default:
        break;
    }

    if (cursorPosition && onSave) {
      const { start, end } = cursorPosition;
      const textBefore = contentEdited.slice(0, start);
      const textAfter = contentEdited.slice(end);
      const newReplyBody = `${textBefore}${markdownText}${textAfter}`;
      setContentEdited(newReplyBody);
      setContentEdited(newReplyBody);
      addToEditHistory(newReplyBody);
      setSelectedText('');
      setCursorPosition(null);
    }
  };

  const handleSave = useCallback(() => {
    confirm({
      title: 'Tem certeza que deseja salvar?',
      onOk: async () => {
        if (onSave) {
          await onSave(contentEdited);
          setEdit(false);
        }
      },
      onCancel: () => { },
    });
  }, [contentEdited, onSave]);

  const handleModalInfo = useCallback(() => {
    info({
      title: 'Dicas rápidas de escrita em markdown',
      content: (
        <>
          <p>- Titulo grande: # Titulo</p>
          <p>- Titulo médio: ## Titulo</p>
          <p>- Titulo pequeno: ### Titulo</p>
          <p>- **Negrito**</p>
          <p>- *Itálico*</p>
          <p>- Lista:</p>
          <p>&nbsp;&nbsp;- Item 1</p>
          <p>&nbsp;&nbsp;- Item 2</p>
          <p>- Links: [texto](https://www.site.com)</p>
          <p>- Imagem: ![ ](caminho_da_imagem.jpg) OBS: (utilizar o botão de upload de imagens)</p>
          <a href="https://www.markdownguide.org/cheat-sheet/" target="_blank" rel="noreferrer">
            Referência: https://www.markdownguide.org/cheat-sheet/
          </a>
        </>
      ),
      onOk: () => { },
      width: 1000,
    });
  }, []);

  if (!edit) {
    return (
      <Row style={{ marginTop: '0.5em' }}>
        <Col>
          <Button
            icon={children ? <EditFilled /> : <PlusOutlined />}
            onClick={() => setEdit(prev => !prev)}
          >
            {children ? 'Editar' : 'Criar'}
          </Button>
          <Markdown style={{ marginTop: '0.5em' }}>
            {children || 'Vazio'}
          </Markdown>
        </Col>
      </Row>
    );
  }
  return (
    <>
      <Row gutter={20} style={{ marginTop: '0.5em' }}>
        <Col span={12}>
          {allowMarkdownShortcuts ? (
            <Col>
              <Space>
                <Button
                  icon={<CloseOutlined />}
                  title="Fechar"
                  onClick={() => setEdit(prev => !prev)}
                >
                  Cancelar
                </Button>
                {onSave ? (
                  <Button
                    icon={<SaveOutlined />}
                    type="primary"
                    title="Salvar"
                    onClick={handleSave}
                  >
                    Salvar
                  </Button>
                ) : (
                  <Button
                    icon={<CheckOutlined />}
                    type="primary"
                    title="OK"
                    onClick={() => setEdit(false)}
                  >
                    OK
                  </Button>
                )}
              </Space>
              <Row justify="space-between">
                {allowMarkdownShortcuts && (
                <Row>
                  <Button
                    type="text"
                    style={{ margin: '4px' }}
                    icon={<BoldOutlined />}
                    onClick={() => handleConvertMarkdown('bold')}
                  />
                  <Button
                    type="text"
                    style={{ margin: '4px' }}
                    icon={<ItalicOutlined />}
                    onClick={() => handleConvertMarkdown('italic')}
                  />
                  <Button
                    type="text"
                    style={{ margin: '4px' }}
                    icon={<LinkOutlined />}
                    onClick={() => handleConvertMarkdown('link')}
                  />
                  <Button
                    type="text"
                    style={{ margin: '4px' }}
                    icon={<UnorderedListOutlined />}
                    onClick={() => handleConvertMarkdown('list')}
                  />
                  <Button
                    type="text"
                    style={{ margin: '4px' }}
                    onClick={() => handleConvertMarkdown('h1')}
                  >
                    <Text style={{ fontWeight: 'bold' }}>H1</Text>
                  </Button>
                  <Button
                    type="text"
                    style={{ margin: '4px' }}
                    onClick={() => handleConvertMarkdown('h2')}
                  >
                    <Text style={{ fontWeight: 'bold' }}>H2</Text>
                  </Button>
                  <Button
                    type="text"
                    style={{ margin: '4px' }}
                    icon={<RollbackOutlined />}
                    disabled={currentEditIndex <= 0}
                    onClick={() => handleUndo()}
                  />
                </Row>
                )}
                <Space>
                  <Button
                    type="text"
                    icon={<PictureOutlined />}
                    style={{ margin: '4px' }}
                    onClick={() => setImagesVisible(true)}
                  >
                    Imagens
                  </Button>
                  <Button
                    icon={<QuestionCircleOutlined />}
                    type="text"
                    onClick={handleModalInfo}
                  />
                </Space>
              </Row>
            </Col>
          ) : (
            <Row justify="space-between">
              <Space>
                <Button
                  icon={<CloseOutlined />}
                  title="Fechar"
                  onClick={() => setEdit(prev => !prev)}
                >
                  Cancelar
                </Button>
                {onSave ? (
                  <Button
                    icon={<SaveOutlined />}
                    type="primary"
                    title="Salvar"
                    onClick={handleSave}
                  >
                    Salvar
                  </Button>
                ) : (
                  <Button
                    icon={<CheckOutlined />}
                    type="primary"
                    title="OK"
                    onClick={() => setEdit(false)}
                  >
                    OK
                  </Button>
                )}
              </Space>
              <Space>
                <Button
                  type="text"
                  icon={<PictureOutlined />}
                  style={{ margin: '4px' }}
                  onClick={() => setImagesVisible(true)}
                >
                  Imagens
                </Button>
                <Button
                  icon={<QuestionCircleOutlined />}
                  type="text"
                  onClick={handleModalInfo}
                />
              </Space>
            </Row>
          )}
          <TextArea
            rows={sizes[size]}
            value={externalState ?? contentEdited}
            onSelect={handleTextSelection}
            onChange={e => {
              const text = e.target.value;
              addToEditHistory(text);
              if (externalSetStateFn) {
                externalSetStateFn(text);
              }
              setContentEdited(text);
            }}
          />
        </Col>
        <Col span={edit ? 12 : 24}>
          <Row
            align="middle"
            style={{ marginBottom: '0.2em', height: '2rem' }}
          >
            Preview:
          </Row>
          <div style={{ maxHeight: 650, overflow: 'auto' }}>
            {edit ? (
              <Markdown>
                {externalState ?? contentEdited}
              </Markdown>
            ) : (
              <Markdown>
                {children || ''}
              </Markdown>
            )}
          </div>
        </Col>
      </Row>
      <ImagesControl
        visible={imagesVisible}
        onClose={() => setImagesVisible(false)}
        contentId={contentId}
        firebaseStorageInfo={imagesStorageInfo}
      />
    </>
  );
};

export default EditableMarkdown;
