import {
  DeleteOutlined, DeleteRowOutlined, EditOutlined, PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  List,
  message,
  Row,
  Space,
  Spin,
  Typography,
  Modal,
  Empty,
} from 'antd';
import { useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Mindmap, useMindmaps } from '../../api/mindmaps';
import EditDataWrapper from '../../components/EditDataWrapper';
import { getParam } from '../../hooks/useSearchParam';

const { Text } = Typography;
const { confirm } = Modal;

export function MindmapsPage() {
  const lessonId = getParam('aula');
  const history = useHistory();
  const [loadingCreate, setLoadingCreate] = useState(false);
  const { state } = useLocation<{ title: string } | undefined>();

  const {
    lessonMindmaps: { data, loading: queryLoading, refetch },
    handleCreateNewMindmapMutation,
    handleDeleteMindmapMutation,
    handleRemoveMindmapFromLesson,
  } = useMindmaps(lessonId);

  const mindmaps = useMemo(() => {
    if (queryLoading || !data?.lessonMindmaps) {
      return undefined;
    }
    return data.lessonMindmaps;
  }, [data, queryLoading]);

  async function handleCreateMindmap() {
    setLoadingCreate(true);
    try {
      const mindmapId = await handleCreateNewMindmapMutation();
      await refetch({
        lessonId,
      });
      setLoadingCreate(false);
      history.push({
        pathname: 'editar-mindmap',
        search: `?id=${mindmapId}`,
      });
    } catch (err) {
      console.error(err);
      message.error('Erro ao criar um novo mindmap!');
      setLoadingCreate(false);
    }
  }

  function handleDeleteModal(mindmap: Pick<Mindmap, '_id' | 'title' | 'subtitle'>) {
    confirm({
      title: 'Deseja mesmo deletar esse mindmap?',
      content: (
        <>
          <div>
            {`Título: ${mindmap.title}`}
          </div>
          <div>
            {`Subtítulo: ${mindmap.subtitle}`}
          </div>
        </>
      ),
      onOk: async () => {
        try {
          await handleDeleteMindmapMutation(mindmap._id);
          await refetch({
            lessonId,
          });
        } catch (err) {
          console.error(err);
          message.error('Erro ao deletar o mindmap');
        }
      },
      onCancel: () => { },
    });
  }

  function handleRemoveFromLessonModal(mindmap: Pick<Mindmap, '_id' | 'title' | 'subtitle'>) {
    confirm({
      title: `Ao remover um conteúdo de uma aula ele ainda continuará aparecendo para outras aulas que o utilizem.
      Deseja mesmo remover o mindmap dessa aula?`,
      content: (
        <>
          <div>
            {`Título: ${mindmap.title}`}
          </div>
          <div>
            {`Subtítulo: ${mindmap.subtitle}`}
          </div>
        </>
      ),
      onOk: async () => {
        try {
          await handleRemoveMindmapFromLesson(mindmap._id);
          await refetch({
            lessonId,
          });
        } catch (err) {
          console.error(err);
          message.error('Erro ao remover o mindmap da aula');
        }
      },
      onCancel: () => { },
    });
  }

  return (
    <EditDataWrapper
      title="Mindmaps"
      searchKey="aula"
      placeholder="Buscar por aula"
    >
      {queryLoading && <Spin />}
      {mindmaps && mindmaps.length > 0 && (
        <List
          size="small"
          header={(
            <Row justify="space-between">
              <Text strong>
                Lista de mindmaps
                {state?.title ? ` de ${state?.title} ` : ''}
              </Text>
              <Button
                icon={<PlusOutlined />}
                onClick={handleCreateMindmap}
                loading={loadingCreate}
              >
                Criar mindmap
              </Button>
            </Row>
          )}
          dataSource={mindmaps}
          renderItem={mindmap => {
            const search = `?id=${mindmap._id}`;
            return (
              <List.Item actions={[
                <Link to={{
                  pathname: '/admin/editar-mindmap',
                  search,
                }}
                >
                  <Space>
                    <EditOutlined />
                    Editar
                  </Space>
                </Link>,
                <Button
                  style={{ padding: 0 }}
                  type="link"
                  icon={<DeleteRowOutlined />}
                  onClick={() => handleRemoveFromLessonModal(mindmap)}
                >
                  Remover
                </Button>,
                <Button
                  style={{ padding: 0 }}
                  type="link"
                  icon={<DeleteOutlined />}
                  danger
                  onClick={() => handleDeleteModal(mindmap)}
                >
                  Deletar
                </Button>,
              ]}
              >
                <List.Item.Meta
                  title={`${mindmap.title || 'Sem título'} - ${mindmap.subtitle || 'Sem subtítulo'}`}
                  description={mindmap.description}
                />
              </List.Item>
            );
          }}
        />
      )}
      {mindmaps && mindmaps.length === 0 && (
        <Empty description="Nenhum mindmap encontrado">
          <Button
            icon={<PlusOutlined />}
            onClick={handleCreateMindmap}
            loading={loadingCreate}
          >
            Criar mindmap
          </Button>
        </Empty>
      )}
    </EditDataWrapper>
  );
}
