import { FC } from 'react';
import AppealsList from './AppealsList';
import { getParam } from '../../../hooks/useSearchParam';
import { AppealAnswer } from './AppealAnswer';

const AppealsFlowPage: FC = () => {
  const appealId = getParam('appealId');

  if (appealId) {
    return (
      <AppealAnswer />
    );
  }

  return (
    <AppealsList />
  );
};

export default AppealsFlowPage;
