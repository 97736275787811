import { PlusOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Row, Col, Select, Input, DatePicker, Space, Button, Typography, message, Checkbox,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { SelectValue } from 'antd/lib/select';
import moment from 'moment';
import {
  ChangeEvent, useCallback, useMemo, useState,
} from 'react';
import { useLessons } from '../../../api/lessons';
import { formatDate } from '../../../utils/formatDate';

type AddLessonInputProps = {
  onConfirm: (value: Item) => void;
  lessonsIds: string[];
  courseId: string;
  disabled: boolean;
}

interface Item {
  key: string;
  classification: string;
  phase: string;
  enableAfter: string;
  avgKnowledgeLevel?: number;
  lessonId: string;
  isPreCourse?: boolean;
}

const { Option } = Select;
const { Text } = Typography;

export function AddLessonInput({
  onConfirm, lessonsIds, courseId, disabled,
}: AddLessonInputProps) {
  const { courseLessons: { data, loading } } = useLessons(courseId);
  const [isAddingLesson, setIsAddingLesson] = useState(false);
  const [lessonToAdd, setLessonToAdd] = useState({} as Item);

  const lessonsFiltered = useMemo(() => {
    if (!data || loading) {
      return [];
    }
    const result = [...data.courseLessons];
    return result.filter(lesson => !lessonsIds.includes(lesson._id));
  }, [data, lessonsIds, loading]);

  const handleChangeEnableAfter = useCallback((value: moment.Moment | null, dateString: string) => {
    if (!value) {
      return;
    }
    setLessonToAdd(prev => {
      return {
        ...prev,
        enableAfter: formatDate(value.toDate()),
      };
    });
  }, []);

  const handleChangeIsPrecourse = useCallback((e: CheckboxChangeEvent) => {
    setLessonToAdd(prev => {
      return {
        ...prev,
        isPreCourse: e.target.checked,
      };
    });
  }, []);

  const handleChangePhase = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const phase = e.target.value;
    if (phase === undefined) {
      return;
    }
    setLessonToAdd(prev => {
      return {
        ...prev,
        phase,
      };
    });
  }, []);

  const handleChangeLesson = useCallback((_id: SelectValue) => {
    if (!_id) {
      return;
    }
    setLessonToAdd(prev => {
      return {
        ...prev,
        key: _id as string,
        lessonId: _id as string,
        classification: lessonsFiltered.find(l => _id === l._id)?.classification.join(' | ') ?? '',
      };
    });
  }, [lessonsFiltered]);

  const handleConfirm = useCallback(() => {
    if (!lessonToAdd.classification || !lessonToAdd.enableAfter || !lessonToAdd.phase) {
      message.warn('Faltam dados para incluir a aula!');
      return;
    }

    onConfirm(lessonToAdd);
    setIsAddingLesson(false);
    setLessonToAdd({} as Item);
  }, [lessonToAdd, onConfirm]);

  return isAddingLesson ? (
    <Row gutter={16} align="bottom">
      <Col span={11}>
        <Space direction="vertical">
          Aula
          {loading && <LoadingOutlined />}
        </Space>
        {!loading && (
          <Select style={{ width: '100%' }} placeholder="Classificação - Título" onChange={handleChangeLesson}>
            {lessonsFiltered.map(lesson => (
              <Option
                key={lesson._id}
                value={lesson._id}
                title={lesson.lessonTitle}
              >
                <Text>
                  {lesson.classification.join(' | ')}
                </Text>
                {' - '}
                <Text type="secondary">
                  {lesson.lessonTitle}
                </Text>
              </Option>
            ))}
          </Select>
        )}
      </Col>
      <Col span={2}>
        <div style={{ marginBottom: '0.1px', lineHeight: '2' }}>
          Pré-curso?
          <Col span={1}>
            <Checkbox checked={lessonToAdd.isPreCourse} onChange={handleChangeIsPrecourse} />
          </Col>
        </div>
      </Col>

      <Col span={3}>
        Fase
        <Input style={{ width: '100%' }} onBlur={handleChangePhase} />
      </Col>
      <Col span={5}>
        Libera em
        <DatePicker
          style={{ width: '100%' }}
          format="DD/MM/YYYY"
          {...(lessonToAdd.enableAfter && { value: moment(lessonToAdd.enableAfter, 'DD/MM/YYYY') })}
          onChange={handleChangeEnableAfter}
        />
      </Col>
      <Col span={3}>
        <Space>
          <Button icon={<PlusOutlined />} onClick={handleConfirm} className="color-success" />
          <Button
            icon={<CloseOutlined />}
            onClick={() => {
              setIsAddingLesson(false);
              setLessonToAdd({} as Item);
            }}
          />
        </Space>
      </Col>
    </Row>
  ) : (
    <Button icon={<PlusOutlined />} onClick={() => setIsAddingLesson(true)} disabled={disabled}>Adicionar aula no cronograma</Button>
  );
}
