import { QueryResult, gql, useQuery } from '@apollo/client';

const GET_WEEKLY_ACHIEVEMENTS_PROGRESS_V2 = gql`
 query GetWeeklyAchievementsProgressV2(
    $profileId: ObjectId!,
    $courseId: ObjectId!
  ) {
    getWeeklyAchievementsProgressV2(
      profileId: $profileId,
      courseId: $courseId
    ) {
      course {
        progress
        title
        total
        bronze
        silver
        gold
      }
      general {
        progress
        title
        total
        bronze
        silver
        gold
      }
      preCourse {
        progress
        title
        total
        bronze
        silver
        gold
      }
    }
  }
`;

export interface WeeklyAchievementsProgressData {
  progress: number;
  total: number;
  bronze: number;
  silver: number;
  gold: number;
  title: string;
}

export interface WeeklyAchievementsProgress {
  course: WeeklyAchievementsProgressData;
  general: WeeklyAchievementsProgressData;
  preCourse: WeeklyAchievementsProgressData;
}

export interface WeeklyAchievementsProgressOutput {
  getWeeklyAchievementsProgressV2: WeeklyAchievementsProgress;
}

export interface WeeklyAchievementsProgressInput {
  profileId: string;
  courseId: string;
}

export function useWeeklyAchievementsProgress({
  profileId,
  courseId,
}: WeeklyAchievementsProgressInput): QueryResult<WeeklyAchievementsProgressOutput> {
  return useQuery<WeeklyAchievementsProgressOutput, WeeklyAchievementsProgressInput>(GET_WEEKLY_ACHIEVEMENTS_PROGRESS_V2, {
    variables: {
      profileId,
      courseId,
    },
  });
}

const STUDENT_PRESENCES_QUERY = gql`
  query GetPresenceDates($profileId: ObjectId!, $courseId: ObjectId!) {
    getPresenceDates(profileId: $profileId, courseId: $courseId)
  }
`;

export interface GetPresencesOutput {
  getPresenceDates: string[];
}

export interface GetPresencesInput {
  profileId: string;
  courseId: string;
}

export function usePresences({
  profileId,
  courseId,
}: GetPresencesInput): QueryResult<GetPresencesOutput> {
  return useQuery<GetPresencesOutput>(STUDENT_PRESENCES_QUERY, {
    variables: {
      profileId,
      courseId,
    },
  });
}

const STUDY_WEEK_QUERY = gql`
  query studyWeekToStudent(
    $profileId: ObjectId!,
    $courseId: ObjectId!
  ) {
    studyWeekToStudent (
      profileId: $profileId,
      courseId: $courseId
    ) {
      monday {
        theoreticalStudy
        theoreticalReview
        smartReview
        examAndMocks
      }
      tuesday {
        theoreticalStudy
        theoreticalReview
        smartReview
        examAndMocks
      }
      wednesday {
        theoreticalStudy
        theoreticalReview
        smartReview
        examAndMocks
      }
      thursday {
        theoreticalStudy
        theoreticalReview
        smartReview
        examAndMocks
      }
      friday {
        theoreticalStudy
        theoreticalReview
        smartReview
        examAndMocks
      }
      saturday {
        theoreticalStudy
        theoreticalReview
        smartReview
        examAndMocks
      }
      sunday {
        theoreticalStudy
        theoreticalReview
        smartReview
        examAndMocks
      }
    }
  }
`;

export interface StudyFragment {
  theoreticalStudy: boolean;
  theoreticalReview: boolean;
  smartReview: boolean;
  examAndMocks: boolean;
}

export interface StudyWeek {
  monday: StudyFragment;
  tuesday: StudyFragment;
  wednesday: StudyFragment;
  thursday: StudyFragment;
  friday: StudyFragment;
  saturday: StudyFragment;
  sunday: StudyFragment;
}

export interface GetStudyWeekInput {
  profileId: string;
  courseId: string;
}

export interface GetStudyWeekOutput {
  studyWeekToStudent: StudyWeek;
}

export function useStudyWeek({
  profileId,
  courseId,
}: GetStudyWeekInput): QueryResult<GetStudyWeekOutput> {
  return useQuery<GetStudyWeekOutput>(STUDY_WEEK_QUERY, {
    variables: { profileId, courseId },
  });
}

const TARGET_INSTITUTION_TO_STUDENT_QUERY = gql`
  query TargetInstitutionToStudent(
    $profileId: ObjectId!,
    $courseId: ObjectId!
  ) {
    targetInstitutionToStudent(
      profileId: $profileId,
      courseId: $courseId
    )
  }
`;

export interface TargetInstitutionToStudentOutput {
  targetInstitutionToStudent: string[];
}

export interface TargetInstitutionToStudentInput {
  profileId: string;
  courseId: string;
}

export function useTargetInstitutionToStudent({
  profileId,
  courseId,
}: TargetInstitutionToStudentInput): QueryResult<TargetInstitutionToStudentOutput> {
  return useQuery<TargetInstitutionToStudentOutput, TargetInstitutionToStudentInput>(TARGET_INSTITUTION_TO_STUDENT_QUERY, {
    variables: { profileId, courseId },
  });
}

const STUDENT_ANSWERS_GROUPED_BY_WEEK_QUERY = gql`
  query studentAnswersGroupedByWeek(
    $profileId: ObjectId!,
    $courseId: ObjectId!,
    $startDate: DateTime!,
    $endDate: DateTime!
  ) {
    studentAnswersGroupedByWeek(
      profileId: $profileId,
      courseId: $courseId,
      startDate: $startDate,
      endDate: $endDate
    ) {
      week
      year
      totalQuestions
      correct
      startDate
      finishDate
    }
  }
`;

export interface StudentAnswersGroupedByWeekObjectType {
  week: number;
  year: number;
  totalQuestions: number;
  correct: number;
  startDate: Date;
  finishDate: Date;
}

export interface StudentAnswersGroupedByWeekOutput {
  studentAnswersGroupedByWeek: StudentAnswersGroupedByWeekObjectType[];
}

export interface StudentAnswersGroupedByWeekInput {
  profileId: string;
  courseId: string;
  startDate: Date;
  endDate: Date;
}

export function useStudentAnswersGroupedByWeek({
  profileId,
  courseId,
  startDate,
  endDate,
}: StudentAnswersGroupedByWeekInput): QueryResult<StudentAnswersGroupedByWeekOutput> {
  return useQuery<StudentAnswersGroupedByWeekOutput, StudentAnswersGroupedByWeekInput>(STUDENT_ANSWERS_GROUPED_BY_WEEK_QUERY, {
    variables: {
      profileId, courseId, startDate, endDate,
    },
  });
}

const STUDENT_ANSWERED_QUESTIONS_QUERY = gql`
  query StudentAnsweredQuestions(
    $profileId: ObjectId!,
    $courseId: ObjectId!,
    $startDate: DateTime!,
    $endDate: DateTime!
  ) {
    getStudentAnsweredQuestions(
      profileId: $profileId,
      courseId: $courseId,
      startDate: $startDate,
      endDate: $endDate
    ) {
      calendarDate
      totalQuestions
      correct
      wrong
      percentage
      themas {
        classification
        totalQuestionsByThema
        correct
        percentageThema
        difficultyByThema {
          difficulty
          percentageDifficulty
          totalQuestionsByDifficulty
        }
      }
    }
  }
`;

export interface DifficultyByThemaObjectType {
  difficulty: string;
  percentageDifficulty: number;
  totalQuestionsByDifficulty: number;
}

export interface QuestionsGroupedByThemaObjectType {
  classification: string;
  totalQuestionsByThema: number;
  correct: number;
  percentageThema: number;
  difficultyByThema: DifficultyByThemaObjectType[];
}

export interface StudentAnsweredQuestionsObjectType {
  calendarDate: string;
  totalQuestions: number;
  correct: number;
  wrong: number;
  percentage: number;
  themas: QuestionsGroupedByThemaObjectType[];
}

export interface StudentAnsweredQuestionsOutput {
  getStudentAnsweredQuestions: StudentAnsweredQuestionsObjectType[];
}

export interface StudentAnsweredQuestionsInput {
  profileId: string;
  courseId: string;
  startDate: Date;
  endDate: Date;
}

export function useStudentAnsweredQuestions({
  profileId,
  courseId,
  startDate,
  endDate,
}: StudentAnsweredQuestionsInput): QueryResult<StudentAnsweredQuestionsOutput> {
  return useQuery<StudentAnsweredQuestionsOutput>(
    STUDENT_ANSWERED_QUESTIONS_QUERY,
    {
      variables: {
        profileId,
        courseId,
        startDate,
        endDate,
      },
    },
  );
}

export const STUDENT_ATTENDANCE_STATUS_QUERY = gql`
  query StudentAttendanceStatus(
    $profileId: ObjectId!,
    $courseId: ObjectId!
  ) {
    studentAttendanceStatus(
      profileId: $profileId,
      courseId: $courseId
    ) {
      frequency
      attendanceDays
      currentDay
    }
  }
`;

export interface StudentAttendanceStatusObjectType {
  frequency: number;
  attendanceDays: number;
  currentDay: number;
}

export interface StudentAttendanceStatusOutput {
  studentAttendanceStatus: StudentAttendanceStatusObjectType;
}

export interface StudentAttendanceStatusInput {
  profileId: string;
  courseId: string;
}

export function useStudentAttendanceStatus({
  profileId,
  courseId,
}: StudentAttendanceStatusInput): QueryResult<StudentAttendanceStatusOutput> {
  return useQuery<StudentAttendanceStatusOutput, StudentAttendanceStatusInput>(
    STUDENT_ATTENDANCE_STATUS_QUERY,
    {
      variables: {
        profileId,
        courseId,
      },
    },
  );
}
