import { Tag } from 'antd';

type DifficultyTagProps = {
  difficulty?: string | null;
}

export function DifficultyTag({ difficulty }: DifficultyTagProps) {
  if (difficulty === 'easy') {
    return <Tag color="green">Questão fácil</Tag>;
  }

  if (difficulty === 'medium') {
    return <Tag color="gold">Questão média</Tag>;
  }

  if (difficulty === 'hard') {
    return <Tag color="red">Questão difícil</Tag>;
  }

  return <Tag color="default">Dificuldade desconhecida</Tag>;
}
