import { FC } from 'react';
import {
  Layout, Row,
} from 'antd';

import { HeaderComponent } from '../components/HeaderComponent';
import { PagesRoutes } from './Pages.routes';

interface HomePageProps { }

const {
  Header, Content,
} = Layout;

const HomePage: FC<HomePageProps> = () => {
  return (
    <Layout className="full-height">
      <Layout>
        <HeaderComponent />
        <Content style={{
          padding: '1rem',
          overflow: 'auto',
          background: '#FFF',
        }}
        >
          <Row style={{
            padding: '0 1rem 1rem 1rem',
            minHeight: '100%',
          }}
          >
            <PagesRoutes />
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default HomePage;
