import {
  ApolloClient, ApolloLink, HttpLink, InMemoryCache,
} from '@apollo/client';

import { setContext } from '@apollo/client/link/context';
import firebase from './firebase';

const uri = String(process.env.REACT_APP_API_URL);

const httpLink = new HttpLink({
  uri,
});

const authLink = setContext(async (_, { headers }) => {
  const { currentUser } = firebase.auth();

  if (!currentUser) {
    return {
      headers,
    };
  }

  let token = '';
  try {
    token = await currentUser.getIdToken();
  } catch (error) {
    console.error(error);
  }

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache({ addTypename: false }),
});

export default client;
