import {
  createContext, ReactNode, useEffect, useMemo,
} from 'react';
import { Course, CourseManyInfo, useCoursesManyInfo } from '../api/courses';

interface CourseContextType {
  courses: CourseManyInfo[];
  loading: boolean;
}

export const CourseContext = createContext({} as CourseContextType);

interface CourseContextProviderProps {
  children?: ReactNode;
}

export function CourseContextProvider({
  children,
}: CourseContextProviderProps) {
  const [coursesManyInfoQuery, {
    data, loading,
  }] = useCoursesManyInfo();

  useEffect(() => {
    coursesManyInfoQuery({
      variables: {
        ids: [],
      },
    });
  }, [coursesManyInfoQuery]);

  const courses = useMemo(() => {
    if (!data || loading) {
      return [];
    }
    return [...data.coursesManyInfo].sort((a, b) => (b.title > a.title ? -1 : 1));
  }, [data, loading]);

  return (
    <CourseContext.Provider value={{ courses, loading }}>
      {children}
    </CourseContext.Provider>
  );
}
