import {
  DatePicker, Row, Space, Transfer,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useBroadcastNotifications } from '../../api/notifications';
import EditDataWrapper from '../../components/EditDataWrapper';
import { useCourses } from '../../hooks/useCourses';

interface EditBroadcastNotificationProps { }

export function EditBroadcastNotification(props: EditBroadcastNotificationProps) {
  const {
    findBroadcasts: [findBroadcastsQuery, { data, loading }],
    handleCreateBroadcastNotification,
  } = useBroadcastNotifications();

  useEffect(() => {
    findBroadcastsQuery({
      variables: {
        filter: {},
      },
    });
  }, [findBroadcastsQuery]);

  const notificationData = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return data?.findBroadcasts.filter(x => x._id === params.get('id'));
  }, [data?.findBroadcasts]);

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [dueDate, setDueDate] = useState<Date>();
  const [link, setLink] = useState('');
  const [thumbnailLink, setThumbnailLink] = useState('');
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const { courses, loading: coursesLoading } = useCourses();

  const handleSelectChange = useCallback((sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  }, []);

  useEffect(() => {
    if (notificationData) {
      const {
        title: _title,
        body: _body,
        dueDate: _dueDate,
        link: _link,
        thumbnail: _thumbnail,
        topics,
      } = notificationData[0];
      setTitle(_title);
      setMessage(_body.message);
      setDueDate(_dueDate);
      if (_link) {
        setLink(_link);
      }
      if (_thumbnail) {
        setThumbnailLink(_thumbnail);
      }
      if (topics[0] === 'all') {
        setTargetKeys(courses.map(x => x._id));
      } else {
        setTargetKeys(topics);
      }
    }
  }, [courses, notificationData]);

  return (
    <EditDataWrapper
      title="Editar item do menu de hamburguer"
      searchKey="id"
      placeholder="Buscar por id do item"
    // loading={loading}
    >
      <Space direction="vertical">
        <Row align="middle">
          <b>Título:</b>
          <Text
            style={{ marginLeft: '1rem', flex: 1 }}
            editable={{
              onChange: setTitle,
            }}
          >
            {title}
          </Text>
        </Row>
        <Row align="middle">
          <b>Mensagem:</b>
          <Text
            style={{ marginLeft: '1rem', flex: 1 }}
            editable={{
              onChange: setMessage,
            }}
          >
            {message}
          </Text>
        </Row>
        <Row align="middle">
          <b style={{ marginRight: '1rem' }}>Termina em:</b>
          <DatePicker
            showTime
            showSecond={false}
            defaultValue={moment(dueDate)}
            onChange={e => {
              if (e) {
                setDueDate(e.toDate());
              } else {
                setDueDate(undefined);
              }
            }}
          />
        </Row>
        <Row align="middle">
          <b>Link (opcional):</b>
          <Text
            style={{ marginLeft: '1rem', flex: 1 }}
            editable={{
              onChange: setLink,
            }}
          >
            {link}
          </Text>
        </Row>
        <Row align="middle">
          <b>Link da thumbnail (opcional):</b>
          <Text
            style={{ marginLeft: '1rem', flex: 1 }}
            editable={{
              onChange: setThumbnailLink,
            }}
          >
            {thumbnailLink}
          </Text>
        </Row>
        <Row>
          <Transfer
            titles={['Não selecionados', 'Selecionados']}
            pagination
            showSearch
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onChange={setTargetKeys}
            onSelectChange={handleSelectChange}
            render={item => item.title}
            dataSource={courses.map(x => {
              return (
                {
                  key: x._id,
                  title: x.title,
                }
              );
            })}
          />
        </Row>
      </Space>
    </EditDataWrapper>
  );
}
