import {
  AppstoreAddOutlined,
  BlockOutlined,
  ContainerOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  OrderedListOutlined,
  PartitionOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  ReadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Empty,
  List, Menu, message, Modal, Row, Select, Space, Spin, Typography,
} from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import {
  ReactNode, useCallback, useMemo, useState,
} from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Lesson, useLessons } from '../../api/lessons';
import EditDataWrapper from '../../components/EditDataWrapper';
import { useCourses } from '../../hooks/useCourses';
import { getParam } from '../../hooks/useSearchParam';
import { LessonReplicationButton } from './LessonReplicationButton';

const { Text } = Typography;
const { confirm, info } = Modal;
const { Option } = Select;

interface RedirectToLessonContentProps {
  pathname: string;
  search: string;
  icon?: ReactNode;
  state?: {
    title: string;
  };
  title: string;
}

function RedirectToLessonContent({
  pathname, search, icon, state, title,
}: RedirectToLessonContentProps) {
  return (
    <Link to={{
      pathname,
      search,
      state,
    }}
    >
      <Space>
        {icon}
        {title}
      </Space>
    </Link>
  );
}

export function LessonsPage() {
  const [loadingCreate, setLoadingCreate] = useState(false);
  const courseId = getParam('curso');
  const { state } = useLocation<{ title: string } | undefined>();
  const history = useHistory();
  const { courses } = useCourses();
  const [replicationTargetCourseId, setReplicationTargetCourseId] = useState('');

  const {
    courseLessons: { data, loading: queryLoading, refetch },
    handleCreateNewLesson,
    handleDeleteLessonMutation,
  } = useLessons(courseId);

  const {
    courseLessons: {
      data: replicationData,
      loading: loadingReplicationData,
      refetch: refetchReplicationData,
    },
  } = useLessons(replicationTargetCourseId);

  const lessons = useMemo(() => {
    if (queryLoading || !data?.courseLessons) {
      return undefined;
    }
    return data.courseLessons;
  }, [data, queryLoading]);

  const handleCreateLesson = useCallback(async () => {
    setLoadingCreate(true);
    try {
      const lessonId = await handleCreateNewLesson();
      await refetch({
        courseId,
      });
      setLoadingCreate(false);
      history.push({
        pathname: 'editar-aula',
        search: `?id=${lessonId}`,
      });
    } catch (err) {
      console.error(err);
      message.error('Erro ao criar uma nova aula!');
      setLoadingCreate(false);
    }
  }, [courseId, handleCreateNewLesson, history, refetch]);

  function handleDeleteModal(lesson: Lesson) {
    confirm({
      title: 'Deseja mesmo deletar essa aula?',
      content: (
        <>
          <div>
            {`Título: ${lesson.lessonTitle}`}
          </div>
          <div>
            {`Classificação: ${lesson.classification}`}
          </div>
        </>
      ),
      onOk: async () => {
        try {
          await handleDeleteLessonMutation(lesson._id);
          await refetch({
            courseId,
          });
        } catch (err) {
          if (err.graphQLErrors[0].extensions.exception.name === 'COURSE_HAS_LESSON') {
            message.error(`Aula não pode ser deletada pois esta presente nos cursos: ${err.message}`);
          } else if (err.graphQLErrors[0].extensions.exception.name === 'LESSON_HAS_PREREQUISITE') {
            message.error(`Aula não pode ser deletada pois esta como pré-requisito nas aulas: ${err.message}`);
          } else {
            message.error('Erro ao deletar a aula');
          }
        }
      },
      onCancel: () => { },
    });
  }

  return (
    <EditDataWrapper
      title="Aulas"
      searchKey="curso"
      placeholder="Buscar por id do curso"
    >
      {queryLoading && <Spin />}
      {lessons && lessons.length > 0 && (
        <List
          size="small"
          header={(
            <Row justify="space-between">
              <Text strong>
                Lista de aulas
                {state?.title ? ` do curso ${state?.title}` : ''}
              </Text>
              <Button
                icon={<PlusOutlined />}
                onClick={handleCreateLesson}
                loading={loadingCreate}
              >
                Criar aula
              </Button>
            </Row>
          )}
          dataSource={lessons}
          renderItem={lesson => {
            const search = `?aula=${lesson._id}`;
            return (
              <List.Item actions={[
                <LessonReplicationButton lesson={lesson} />,
                <Dropdown overlay={(
                  <Menu>
                    <Menu.Item>
                      <RedirectToLessonContent
                        title="Dados"
                        pathname="/admin/editar-aula"
                        search={`?id=${lesson._id}`}
                        icon={<ContainerOutlined />}
                      />
                    </Menu.Item>
                    <SubMenu title="Conteúdos" icon={<AppstoreAddOutlined />}>
                      <Menu.Item>
                        <RedirectToLessonContent
                          title="Vídeos"
                          pathname="/admin/videos"
                          search={search}
                          icon={<PlayCircleOutlined />}
                          state={{ title: lesson.lessonTitle }}
                        />
                      </Menu.Item>
                      <Menu.Item>
                        <RedirectToLessonContent
                          title="Mindmaps"
                          pathname="/admin/mindmaps"
                          search={search}
                          icon={<PartitionOutlined />}
                          state={{ title: lesson.lessonTitle }}
                        />
                      </Menu.Item>
                      <Menu.Item>
                        <RedirectToLessonContent
                          title="Apostilas"
                          pathname="/admin/apostilas"
                          search={search}
                          icon={<ReadOutlined />}
                          state={{ title: lesson.lessonTitle }}
                        />
                      </Menu.Item>
                      <Menu.Item>
                        <RedirectToLessonContent
                          title="Flashcards"
                          pathname="/admin/flashcards"
                          search={search}
                          icon={<BlockOutlined />}
                          state={{ title: lesson.lessonTitle }}
                        />
                      </Menu.Item>
                      <Menu.Item>
                        <RedirectToLessonContent
                          title="Questões"
                          pathname="/admin/questoes-aula"
                          search={search}
                          icon={<OrderedListOutlined />}
                          state={{ title: lesson.lessonTitle }}
                        />
                      </Menu.Item>
                    </SubMenu>
                  </Menu>
                )}
                >
                  <Button type="link" style={{ padding: 0 }}>
                    <Space>
                      <EditOutlined />
                      Editar
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>,
                <Button
                  style={{ padding: 0 }}
                  type="link"
                  icon={<DeleteOutlined />}
                  danger
                  onClick={() => handleDeleteModal(lesson)}
                >
                  Deletar
                </Button>,
              ]}
              >
                <List.Item.Meta
                  title={lesson.lessonTitle}
                  description={lesson.classification.join(' | ')}
                />
              </List.Item>
            );
          }}
        />
      )}
      {lessons && lessons.length === 0 && (<Text>Nenhuma aula encontrada</Text>)}
      {lessons && lessons.length === 0 && (
        <Empty description="Nenhuma aula encontrada">
          <Button
            icon={<PlusOutlined />}
            onClick={handleCreateLesson}
            loading={loadingCreate}
          >
            Criar aula
          </Button>
        </Empty>
      )}
    </EditDataWrapper>
  );
}
