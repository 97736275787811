import {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Button,
  Checkbox,
  Col, Divider, Modal, Rate, Row, Select, Space, Tag, Tooltip, Typography,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useQuestionDetails, useUpdateQuestionDetails, UpdateQuestionDetailsInput } from '../../api/question';
import ClassificationTreeCascader from '../../components/ClassificationTreeCascader';
import EditComment from './components/EditComment';
import EditDataWrapper from '../../components/EditDataWrapper';
import { getParam } from '../../hooks/useSearchParam';
import { DifficultyTag } from './components/DifficultyTag';
import EditableMarkdown from '../../components/EditableMarkdown';

const { Option } = Select;
const { confirm } = Modal;
const { Title, Text } = Typography;

function getChoiceStringFromNumber(choice: number): string {
  if (choice === -1) {
    return 'ANULADA';
  }
  return String.fromCharCode(97 + choice).toUpperCase();
}

const EditQuestionPage: FC = () => {
  const questionId = getParam('id');
  const [saveLoading, setSaveLoading] = useState(false);
  const {
    data, loading: queryLoading, refetch: refetchQuestionDetailsQuery,
  } = useQuestionDetails(questionId);
  const updateQuestionDetailsMutation = useUpdateQuestionDetails();

  const questionDetails = useMemo(() => {
    if (queryLoading || !data?.questionDetails) {
      return undefined;
    }
    return data.questionDetails;
  }, [data, queryLoading]);

  const [isOutdatedValue, setIsOutdatedValue] = useState(questionDetails?.isOutdated);
  const [isOpenToAppealValue, setIsOpenToAppealValue] = useState(questionDetails?.isOpenToAppeal);
  const [questionChoices, setQuestionChoices] = useState(questionDetails?.choices);

  useEffect(() => {
    setQuestionChoices(questionDetails?.choices);
  }, [questionDetails?.choices]);

  useEffect(() => {
    setIsOutdatedValue(questionDetails?.isOutdated);
  }, [questionDetails?.isOutdated]);

  useEffect(() => {
    setIsOpenToAppealValue(questionDetails?.isOpenToAppeal);
  }, [questionDetails?.isOpenToAppeal]);

  const questionCorrectChoice = useMemo(() => {
    if (questionDetails && questionDetails.correctChoice !== undefined) {
      return getChoiceStringFromNumber(questionDetails.correctChoice);
    }
    return '';
  }, [questionDetails]);

  const questionClassification = useMemo(() => {
    if (questionDetails) {
      return questionDetails.classification;
    }
    return [];
  }, [questionDetails]);

  const handleUpdateModalOk = useCallback(async ({
    _id, correctChoice, classification, body, isOpenToAppeal, isOutdated,
  }: UpdateQuestionDetailsInput) => {
    await updateQuestionDetailsMutation({
      _id,
      correctChoice,
      classification,
      body,
      isOpenToAppeal,
      isOutdated,
    });
    if (refetchQuestionDetailsQuery) {
      await refetchQuestionDetailsQuery({ _id });
    }
  }, [updateQuestionDetailsMutation, refetchQuestionDetailsQuery]);

  const handleToggleIsOutdated = useCallback(() => {
    confirm({
      title: 'Tem certeza que deseja realizar esta ação?',
      content: `Você está definindo esta questão como ${isOutdatedValue ? '' : 'des'}atualizada.`,
      onOk: async () => {
        await handleUpdateModalOk({
          _id: questionId,
          isOutdated: !isOutdatedValue,
        });
        setIsOutdatedValue(prev => !prev);
      },
      onCancel: () => null,
    });
  }, [handleUpdateModalOk, isOutdatedValue, questionId]);

  const handleToggleIsOpenToAppeal = useCallback(() => {
    confirm({
      title: 'Tem certeza que deseja realizar esta ação?',
      content: `Você está definindo esta questão como ${isOpenToAppealValue ? 'fechada' : 'aberta'} para recursos.`,
      onOk: async () => {
        await handleUpdateModalOk({
          _id: questionId,
          isOpenToAppeal: !isOpenToAppealValue,
        });
        setIsOpenToAppealValue(prev => !prev);
      },
      onCancel: () => null,
    });
  }, [handleUpdateModalOk, isOpenToAppealValue, questionId]);

  const handleSelectCorrectChoiceChange = useCallback((e: string) => {
    confirm({
      title: 'Tem certeza que deseja alterar a resposta?',
      content: `Você está alterando a resposta de: ${questionCorrectChoice}
        para ${getChoiceStringFromNumber(Number(e))}`,
      onOk: () => handleUpdateModalOk({
        _id: questionId,
        correctChoice: Number(e),
      }),
      onCancel: () => { },
    });
  }, [handleUpdateModalOk, questionCorrectChoice, questionId]);

  const handleSelectClassificationChange = useCallback((e: (string | number)[], treeId: string) => {
    confirm({
      title: 'Tem certeza que deseja alterar a classificação?',
      content: (
        <>
          <div>
            {`Você está alterando a classificação de: ${questionClassification.find(c => c.detailedTreeId === treeId)?.leaf.join(' / ')}`}
          </div>
          para:
          <div>
            {e.join(' / ')}
          </div>
        </>
      ),
      onOk: () => handleUpdateModalOk({
        _id: questionId,
        classification: questionClassification.map(c => {
          if (c.detailedTreeId === treeId) {
            return {
              leaf: e as string[],
              detailedTreeId: treeId,
            };
          }
          return c;
        }),
      }),
      onCancel: () => { },
    });
  }, [handleUpdateModalOk, questionClassification, questionId]);

  const handleSaveChoices = useCallback(async ({
    _id, choices,
  }: UpdateQuestionDetailsInput) => {
    setSaveLoading(true);
    await updateQuestionDetailsMutation({
      _id,
      choices,
    });
    if (refetchQuestionDetailsQuery) {
      await refetchQuestionDetailsQuery({ _id });
    }
    setSaveLoading(false);
  }, [refetchQuestionDetailsQuery, updateQuestionDetailsMutation]);

  const handleChoiceEdit = useCallback((e, index) => {
    setQuestionChoices(prev => {
      if (prev) {
        const arr = [...prev];
        arr[index] = e;
        return arr;
      }
      return prev;
    });
  }, []);

  return (
    <EditDataWrapper
      title="Editar questões"
      placeholder="Pesquisar por id"
      loading={queryLoading}
      searchKey="id"
    >
      {questionDetails && (
        <>
          <Space align="baseline">
            <Title level={3}>
              Questão
            </Title>
            {questionDetails.isOutdated && <Tag color="brown">Desatualizada</Tag>}
            {questionDetails.isOpenToAppeal && <Tag color="orange">Passível de recurso</Tag>}
            <DifficultyTag difficulty={questionDetails.difficulty} />
          </Space>
          <Row className="py-1">
            <Col span={24}>
              <Row align="middle" justify="space-between" className="mb-1">
                <Text strong>
                  {`[${questionDetails.institution} - ${questionDetails.uf} -
                  ${questionDetails.year} - Questão ${questionDetails.examOrder}]`}
                </Text>
                <Space>
                  <Tooltip title={((questionDetails.rating?.avgRating ?? 0) * 5).toFixed(2)}>
                    <Space>
                      <Rate
                        disabled
                        defaultValue={Math.round((questionDetails.rating?.avgRating ?? 0) * 5)}
                      />
                      <Text>{`(${(questionDetails.rating?.nRating ?? 0)})`}</Text>
                    </Space>
                  </Tooltip>
                </Space>
              </Row>
              <EditableMarkdown
                contentId={questionId}
                imagesStorageInfo={{ imagesFolder: 'images/questions', imagesSuffix: 'body' }}
                onSave={body => handleUpdateModalOk({ _id: questionId, body })}
              >
                {questionDetails.body}
              </EditableMarkdown>
            </Col>
          </Row>
          <Space direction="vertical">
            {questionChoices && questionChoices.map((choice, index) => {
              const totalAnswers = questionDetails.answersProportion.reduce((sum, curr) => sum + curr, 0);
              const choiceAnswers = questionDetails.answersProportion[index];
              const key = index;
              return (
                <Row key={key}>
                  <Space>
                    {`${getChoiceStringFromNumber(index)})`}
                    &nbsp;
                    <Text
                      editable={{
                        onChange: e => handleChoiceEdit(e, index),
                      }}
                    >
                      {choice}
                    </Text>
                    <Tooltip title={`${choiceAnswers} / ${totalAnswers}`}>
                      <Tag>
                        {`${Math.round((choiceAnswers * 100) / totalAnswers)} %`}
                      </Tag>
                    </Tooltip>
                  </Space>
                </Row>
              );
            })}
            <Button
              loading={saveLoading}
              icon={<SaveOutlined />}
              disabled={questionDetails.choices.join(',') === questionChoices?.join(',')}
              onClick={() => {
                handleSaveChoices({
                  _id: questionId,
                  choices: questionChoices,
                });
              }}
            >
              Salvar alternativas
            </Button>
          </Space>
          <Row className="py-1" align="middle">
            Resposta correta:&nbsp;
            <Select
              defaultValue={String(questionDetails.correctChoice)}
              style={{ width: 100 }}
              onChange={handleSelectCorrectChoiceChange}
              value={questionCorrectChoice}
            >
              {questionDetails.choices.map((_, index) => {
                const key = index;
                return (
                  <Option key={key} value={String(index)}>
                    {`${getChoiceStringFromNumber(index)})`}
                  </Option>
                );
              })}
              <Option value="-1">ANULADA</Option>
            </Select>
          </Row>
          Classificações:&nbsp;
          <Row className="py-1" align="middle">
            {questionDetails.classification.map(classification => (
              <Tooltip key={classification.detailedTreeId} title={classification.leaf.join(' / ')}>
                <span>
                  <ClassificationTreeCascader
                    treeId={classification.detailedTreeId}
                    onChange={e => handleSelectClassificationChange(e, classification.detailedTreeId)}
                    currentValue={classification.leaf}
                    value={classification.leaf}
                  />
                </span>
              </Tooltip>
            ))}
          </Row>
          <Row className="py-1" align="middle">
            <Checkbox
              checked={isOutdatedValue}
              onChange={handleToggleIsOutdated}
            >
              Questão desatualizada
            </Checkbox>
          </Row>
          <Row className="py-1" align="middle">
            <Checkbox
              checked={isOpenToAppealValue}
              onChange={handleToggleIsOpenToAppeal}
            >
              Questão passível de recurso
            </Checkbox>
          </Row>
          <Divider />
          <EditComment
            comment={questionDetails.comment}
            questionId={questionId}
            refetchQuestionDetalisQuery={refetchQuestionDetailsQuery}
          />
        </>
      )}
    </EditDataWrapper>
  );
};

export default EditQuestionPage;
