import { FC } from 'react';
import { Col } from 'antd';
import { ForumCommentContent } from './ForumCommentContent';
import { ForumCommentType, ForumCommentWithRepliesType } from '../../../hooks/useCommentsManagement';

type ForumCommentWithRepliesProps = {
  commentId: string,
  parentComment?: ForumCommentWithRepliesType;
}

export const ForumCommentWithReplies: FC<ForumCommentWithRepliesProps> = ({
  commentId,
  parentComment,
}) => {
  return (
    parentComment ? (
      <Col>
        <ForumCommentContent
          body={parentComment.body}
          courseId={parentComment.courseId}
          user={parentComment.user}
          isPublic={parentComment.status.isPublic}
        />
        {parentComment.children.map((it, _) => {
          return (
            <div style={{
              padding: '8px',
              backgroundColor: it.id === commentId ? '#C9E6FF' : undefined,
              borderLeft: '1px solid #74777F',
              marginTop: '8px',
            }}
            >
              <ForumCommentContent
                key={`ForumComment-${it.id}`}
                body={it.body}
                courseId={it.courseId}
                user={it.user}
                isPublic={it.status.isPublic}
              />
            </div>
          );
        })}
      </Col>
    ) : <p>Comentário não encontrado</p>
  );
};
