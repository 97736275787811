import { FC, useMemo, useState } from 'react';
import {
  Input, Modal, UploadProps, message,
} from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { FilePdfOutlined, InboxOutlined } from '@ant-design/icons';

export type FileData = {
  fileName: string;
  base64: string,
  mimeType: string,
  url?: string,
  title: string,
  subtitle?: string,
};

type AddPdfModalProps = {
  isOpen: boolean;
  close: () => void;
  onSave: (value: FileData) => Promise<void>;
  shouldUpload?: boolean;
}

export const AddPdfModal: FC<AddPdfModalProps> = ({
  isOpen,
  close,
  onSave,
  shouldUpload = false,
}) => {
  const [pdf, setPdf] = useState<FileData>({
    fileName: '',
    title: '',
    subtitle: '',
    base64: '',
    mimeType: '',
  });

  const handleClose = (): void => {
    close();
    setPdf({
      fileName: '',
      title: '',
      subtitle: '',
      base64: '',
      mimeType: '',
    });
  };

  const props: UploadProps = {
    multiple: false,
    disabled: pdf.title === '',
    maxCount: 1,
    beforeUpload: async file => {
      await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64File = reader.result;
          setPdf(prev => ({
            ...prev,
            fileName: file.name,
            mimeType: file.type,
            base64: (base64File as string).replace(/data:([^;]*);base64,/, ''),
          }));
          if (shouldUpload) {
            resolve(file);
          } else {
            reader.onerror = error => reject(error);
          }
        };
        reader.onerror = error => reject(error);
      });
    },
    onChange(info) {
      const { status } = info.file;

      if (status === 'done') {
        const uploadedFile = info.file.response;

        if (uploadedFile && uploadedFile.url) {
          setPdf(prev => ({
            ...prev,
            url: uploadedFile.url,
          }));
        }

        message.success(`Upload de ${info.file.name} realizado com sucesso.`);
      } else if (status === 'error') {
        console.log(`Upload de ${info.file.name} falhou.`);
      }
    },
    onDrop(e) {
      console.log('Arquivos selecionados', e.dataTransfer.files);
    },
    onRemove() {
      setPdf(prev => ({ ...prev, url: '', base64: '' }));
    },
  };

  const disableSaveButton = useMemo(() => {
    return pdf.title === '' || pdf.base64 === '' || pdf.url === '';
  }, [pdf]);

  return (
    <Modal
      title="Adicione o Planner"
      open={isOpen}
      okButtonProps={{
        disabled: disableSaveButton,
      }}
      onOk={async () => {
        await onSave(pdf);
        handleClose();
      }}
      onCancel={handleClose}
      okText="Salvar"
      cancelText="Cancelar"
    >
      <Input
        style={{ margin: '5px' }}
        placeholder="Insira o título"
        value={pdf.title}
        onChange={e => setPdf(prev => ({ ...prev, title: e.target.value }))}
      />
      <Input
        style={{ margin: '5px' }}
        placeholder="Insira o subtítulo"
        value={pdf.subtitle}
        onChange={e => setPdf(prev => ({ ...prev, subtitle: e.target.value }))}
      />
      <Dragger style={{ margin: '5px' }} {...props}>
        {pdf.base64 ? (
          <>
            <p className="ant-upload-drag-icon">
              <FilePdfOutlined style={{ color: 'red' }} />
            </p>
            <p className="ant-upload-text">{pdf.fileName}</p>
          </>
        ) : (
          <>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Clique aqui ou arraste um arquivo para fazer o upload</p>
            <p className="ant-upload-hint">
              Suporte a upload múltiplo ou único. É estritamente proibido o upload de arquivos para outros fins.
            </p>
          </>
        )}
      </Dragger>
    </Modal>
  );
};
