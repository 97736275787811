import React, { FC } from 'react';
import {
  TeamOutlined,
  OrderedListOutlined,
  BlockOutlined,
  AppstoreAddOutlined,
  ReadOutlined,
  PartitionOutlined, PlayCircleOutlined, DashboardOutlined,
  DesktopOutlined, DeploymentUnitOutlined, PlusCircleOutlined, MenuOutlined,
  SettingOutlined, BellFilled, MessageOutlined,
  AppstoreOutlined,
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScale, faComment } from '@fortawesome/free-solid-svg-icons';
import { Menu } from 'antd';
import { Link, useHistory } from 'react-router-dom';

interface NavigationMenuProps { }

const { SubMenu } = Menu;

const NavigationMenu: FC<NavigationMenuProps> = () => {
  const history = useHistory();
  return (
    <Menu mode="horizontal" theme="dark" selectedKeys={[history.location.pathname]}>
      <Menu.Item key="/admin/dashboard" icon={<DashboardOutlined />}>
        Dashboard
        <Link to="/admin/dashboard" />
      </Menu.Item>
      <Menu.Item key="/admin/forum" icon={<MessageOutlined />}>
        Fórum
        <Link to="/admin/forum" />
      </Menu.Item>
      <SubMenu key="/admin/resources" icon={<FontAwesomeIcon icon={faBalanceScale} />} title="Recursos">
        <Menu.Item key="/admin/resources/bank" icon={<AppstoreOutlined />}>
          Banco de Recursos
          <Link to="/admin/resources/bank" />
        </Menu.Item>
        <Menu.Item key="/admin/resources/appeals" icon={<FontAwesomeIcon icon={faComment} />}>
          Solicitações
          <Link to="/admin/resources/appeals" />
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="/admin/editar-usuarios" icon={<TeamOutlined />}>
        Usuários
        <Link to="/admin/editar-usuarios" />
      </Menu.Item>
      <Menu.Item key="/admin/alunos" icon={<TeamOutlined />}>
        Alunos
        <Link to="/admin/alunos" />
      </Menu.Item>
      <Menu.Item key="/admin/cursos" icon={<DesktopOutlined />}>
        Cursos
        <Link to="/admin/cursos" />
      </Menu.Item>
      <Menu.Item key="/admin/aulas" icon={<DeploymentUnitOutlined />}>
        Aulas
        <Link to="/admin/aulas" />
      </Menu.Item>

      <SubMenu key="contents" icon={<AppstoreAddOutlined />} title="Editar conteúdos">
        <Menu.Item key="/admin/questoes" icon={<OrderedListOutlined />}>
          Questões
          <Link to="/admin/questoes" />
        </Menu.Item>
        <Menu.Item key="/admin/editar-flashcard" icon={<BlockOutlined />}>
          Flashcards
          <Link to="/admin/editar-flashcard" />
        </Menu.Item>
        <Menu.Item key="/admin/editar-mindmap" icon={<PartitionOutlined />}>
          Mindmaps
          <Link to="/admin/editar-mindmap" />
        </Menu.Item>
        <Menu.Item key="/admin/editar-apostila" icon={<ReadOutlined />}>
          Apostilas
          <Link to="/admin/editar-apostila" />
        </Menu.Item>
        <Menu.Item key="/admin/editar-video" icon={<PlayCircleOutlined />}>
          Videos
          <Link to="/admin/editar-video" />
        </Menu.Item>
      </SubMenu>
      <SubMenu key="aristo-plus" icon={<PlusCircleOutlined />} title="+Aristo">
        <Menu.Item key="/admin/aristo-plus/videos" icon={<PlayCircleOutlined />}>
          Videos
          <Link to="/admin/aristo-plus/videos" />
        </Menu.Item>
        <Menu.Item key="/admin/aristo-plus/pdfs" icon={<ReadOutlined />}>
          PDFs
          <Link to="/admin/aristo-plus/pdfs" />
        </Menu.Item>
      </SubMenu>
      <SubMenu key="configuracoes" icon={<SettingOutlined />} title="Configurações">
        <Menu.Item key="/admin/configuracao/burger-menus" icon={<MenuOutlined />}>
          Burger menu
          <Link to="/admin/configuracao/burger-menus" />
        </Menu.Item>
        <Menu.Item key="/admin/configuracao/notificacoes" icon={<BellFilled />}>
          Notificações
          <Link to="/admin/configuracao/notificacoes" />
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
};

export default NavigationMenu;
