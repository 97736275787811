import { Tag } from 'antd';

type CommentTagProps = {
  comment?: boolean;
}

export function CommentTag({ comment }: CommentTagProps) {
  if (!comment) {
    return <Tag color="default">Nenhum comentário</Tag>;
  }

  if (comment) {
    return <Tag color="blue">Comentada</Tag>;
  }

  return null;
}
