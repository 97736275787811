import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {
  Button,
  Space,
  Typography,
  Collapse,
  Row,
  Col,
  message,
  Input,
} from 'antd';
import {
  useCallback,
  useState,
  useEffect,
  FC,
} from 'react';
import { Draggable, DraggableProvided, DropResult } from 'react-beautiful-dnd';
import { AddPdfModal, FileData } from './AddPdfModal';
import {
  PdfType, useCreateAndAddToCoursePdf, useFetchPlanners, useRemovePlannerFromCourse, useUpdatePlanner, useUpdatePlannerIndex,
} from '../../../api/pdfs';
import { DraggableComponent } from '../../../components/DraggableComponent';

type PlannersTableProps = {
  courseId: string;
}

const { Text } = Typography;
const { Panel } = Collapse;

export type TemplateOutput = {
  label: string,
  timeToDo: number,
  startsAt: string,
  finishesAt: string,
  _id: string,
} | undefined;

type PlannerListItemProps = {
  planner: PdfType,
  providedPlanner: DraggableProvided,
  editPlanner: (id: string, title?: string, subtitle?: string) => Promise<void>,
  removePlanner: (id: string) => Promise<void>
}

const PlannerListItem: FC<PlannerListItemProps> = ({
  planner,
  providedPlanner,
  editPlanner,
  removePlanner,
}) => {
  const [isEditting, setIsEditting] = useState<boolean>(false);
  const [updateValue, setUpdateValue] = useState({
    title: planner.title,
    subtitle: planner.subtitle,
  });
  const [isLoading, setIsLoading] = useState(false);

  const toggleEdit = () => {
    setUpdateValue({
      title: planner.title,
      subtitle: planner.subtitle,
    });
    setIsEditting(!isEditting);
  };

  const handleUpdatePlanner = async (id: string) => {
    setIsLoading(true);
    await editPlanner(id, updateValue.title, updateValue.subtitle);
    setIsLoading(false);
    toggleEdit();
  };

  const handleTitle = (title: string) => {
    setUpdateValue(prev => ({ ...prev, title }));
  };

  const handleSubtitle = (subtitle: string) => {
    setUpdateValue(prev => ({ ...prev, subtitle }));
  };

  return (
    <Row
      wrap={false}
      ref={providedPlanner.innerRef}
      {...providedPlanner.draggableProps}
      {...providedPlanner.dragHandleProps}
    >
      <div style={{ width: '100%', margin: '5px 0px', border: '1px solid #d9d9d9' }}>
        {isEditting ? (
          <Row wrap={false} style={{ width: '100%', alignItems: 'center' }}>
            <Input style={{ width: '50%', marginLeft: '5px' }} value={updateValue.title} onChange={e => handleTitle(e.target.value)} />
            <Input style={{ width: '50%', marginLeft: '5px' }} value={updateValue.subtitle} onChange={e => handleSubtitle(e.target.value)} />
            <Space style={{ padding: '10px', width: '100px' }}>
              <Button danger icon={<CloseOutlined />} loading={isLoading} onClick={() => toggleEdit()} />
              <Button icon={<SaveOutlined />} loading={isLoading} onClick={() => handleUpdatePlanner(planner._id)} />
            </Space>
          </Row>
        ) : (
          <Row wrap={false} style={{ width: '100%', alignItems: 'center' }}>
            <Text style={{ width: '50%', paddingLeft: '5px' }}>{planner.title}</Text>
            <Text style={{ width: '50%', paddingLeft: '5px' }}>{planner?.subtitle ?? ''}</Text>
            <Space style={{ padding: '10px', width: '100px' }}>
              <Button icon={<EditOutlined />} onClick={() => toggleEdit()} />
              <Button danger icon={<DeleteOutlined />} onClick={() => removePlanner(planner._id)} />
            </Space>
          </Row>
        )}
      </div>
    </Row>
  );
};

export const PlannersTable: FC<PlannersTableProps> = ({
  courseId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { handleCreateAndAddPDFtoCourse } = useCreateAndAddToCoursePdf();
  const { handleRemovePlannerFromCourse } = useRemovePlannerFromCourse();
  const { handleUpdatePlanner } = useUpdatePlanner();
  const { handleUpdatePlannerIndex } = useUpdatePlannerIndex();
  const { refetch: refetchPlanners } = useFetchPlanners(courseId);

  const [planners, setPlanners] = useState<PdfType[]>([]);

  const getPlanners = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await refetchPlanners();
      if (data) {
        setPlanners(data.fetchPlannersForCourse);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }, [refetchPlanners]);

  const createPlanner = useCallback(
    async (value: FileData): Promise<void> => {
      setIsLoading(true);
      try {
        await handleCreateAndAddPDFtoCourse({
          courseId,
          title: value.title,
          subtitle: value.subtitle,
          mimetype: value.mimeType,
          base64: value.base64,
        });
        message.success('Planner criado!');
        await getPlanners();
      } catch (error) {
        message.error('Não foi possível criar o planner!');
      }
      setIsLoading(false);
    }, [courseId, getPlanners, handleCreateAndAddPDFtoCourse],
  );

  const removePlanner = useCallback(async (_id: string): Promise<void> => {
    setIsLoading(true);
    try {
      await handleRemovePlannerFromCourse(courseId, _id);
      const newPlannersList = planners.filter(it => it._id !== _id);
      setPlanners(newPlannersList);
      message.success('Planner removido com sucesso!');
    } catch (error) {
      message.error('Não foi possível remover o planner!');
    }
    setIsLoading(false);
  }, [courseId, handleRemovePlannerFromCourse, planners]);

  const editPlanner = async (_id: string, title?: string, subtitle?: string): Promise<void> => {
    try {
      let selectedPlannerIndex = planners.findIndex(it => it._id === _id);
      if (selectedPlannerIndex !== -1) {
        const updatedPlanner = {
          ...planners[selectedPlannerIndex],
          title: title ?? planners[selectedPlannerIndex].title,
          subtitle: subtitle ?? planners[selectedPlannerIndex].subtitle,
        };
        const updatedPlanners = [...planners];
        updatedPlanners[selectedPlannerIndex] = updatedPlanner;

        await handleUpdatePlanner({
          plannerId: _id,
          title: updatedPlanner.title,
          subtitle: updatedPlanner.subtitle,
        });

        setPlanners(updatedPlanners);

        message.success('Planner editado com sucesso!');
      } else {
        message.error('Planner não encontrado!');
      }
    } catch (error) {
      message.error('Não foi possível editar o planner!');
    }
  };

  const handleChangeIndex = async (e: DropResult): Promise<void> => {
    const newPlannersList = [...planners];
    const index = newPlannersList.findIndex(it => it._id === e.draggableId);
    const plannerToMove = newPlannersList.splice(index, 1)[0];
    if (e.destination) {
      newPlannersList.splice(e.destination?.index, 0, plannerToMove);
      try {
        await handleUpdatePlannerIndex({
          courseId,
          plannersIds: newPlannersList.map(it => it._id),
        });
        setPlanners(newPlannersList);
        message.success('Ordem dos planners atualizada!');
      } catch (error) {
        message.error('Erro ao enviar a ordem atual dos planners!');
      }
    }
  };

  useEffect(() => {
    getPlanners();
  }, [getPlanners]);

  return (
    <>
      <Collapse accordion>
        <Panel
          key="only"
          header={(
            <Row justify="space-between">
              <Text>Planners</Text>
            </Row>
          )}
        >
          <Col className="mb-2" style={{ marginBottom: '20px' }}>
            <AddPdfModal
              isOpen={isModalOpen}
              close={() => setIsModalOpen(false)}
              onSave={createPlanner}
            />
            <Button
              loading={isLoading}
              icon={<PlusOutlined />}
              onClick={() => setIsModalOpen(true)}
            >
              Adicionar planner
            </Button>
          </Col>
          <Row
            wrap={false}
            style={{ width: '100%' }}
          >
            <Text style={{ width: '46%', fontWeight: 'bold' }}>Título</Text>
            <Text style={{ width: '46%', fontWeight: 'bold' }}>Subtítulo</Text>
            <Text style={{ width: '100px', fontWeight: 'bold' }}>Ações</Text>
          </Row>
          {planners.length ? (
            <DraggableComponent handleDragEnd={handleChangeIndex}>
              {planners.map((planner, index) => (
                <Draggable
                  key={planner._id}
                  draggableId={planner._id}
                  index={index}
                  isDragDisabled={isLoading}
                >
                  {(providedPlanner: DraggableProvided): JSX.Element => (
                    <PlannerListItem
                      providedPlanner={providedPlanner}
                      planner={planner}
                      editPlanner={editPlanner}
                      removePlanner={removePlanner}
                    />
                  )}
                </Draggable>
              ))}
            </DraggableComponent>
          ) : (
            <Row justify="center" style={{ marginTop: '10px' }}>
              <h4>Ainda não existem planners para este curso!</h4>
            </Row>
          )}

        </Panel>
      </Collapse>
    </>
  );
};
