import {
  Button, Input, message, Modal, Row,
} from 'antd';
import { FC, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCancelAppeal } from '../../../../api/appeal';

type AppealCancelDialogProps = {
  appealId: string;
  open: boolean;
  close: () => void;
}

export const AppealCancelDialog: FC<AppealCancelDialogProps> = ({
  appealId,
  open,
  close,
}) => {
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useHistory();

  const [cancelAppeal] = useCancelAppeal();

  const handleCancel = async (): Promise<void> => {
    setLoading(true);
    try {
      await cancelAppeal({
        variables: {
          appealId,
          reasonForCancellation: reason,
        },
      });
      message.success('Recurso cancelado com sucesso!');
      close();
      navigate.push('/admin/resources/appeals');
    } catch (error) {
      message.error('Erro ao cancelar o recurso!');
    }
    setLoading(false);
  };

  const disable = useMemo(() => loading || !reason.length, [loading, reason]);

  return (
    <Modal
      style={{ maxWidth: '360px' }}
      title="Justifique o cancelamento"
      open={open}
      onCancel={close}
      footer={(
        <Row>
          <Button disabled={disable} onClick={handleCancel}>Confirmar</Button>
        </Row>
      )}
    >
      <Input
        style={{ width: '100%' }}
        aria-label="Sua justificativa"
        placeholder="justificativa ..."
        value={reason}
        onChange={e => setReason(e.target.value)}
      />
    </Modal>
  );
};
