import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button, Image, Table,
} from 'antd';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useBroadcastNotifications } from '../../api/notifications';
import EditDataWrapper from '../../components/EditDataWrapper';
import { useCourses } from '../../hooks/useCourses';
import { AlignType } from '../videos/VideosPage';
import { CreateNotificationModal } from './_components/CreateNotificationModal.component';

interface BroadcastNotificationsProps { }

export function BroadcastNotifications(props: BroadcastNotificationsProps) {
  const {
    findBroadcasts: [findBroadcastsQuery, { data, loading, refetch }],
  } = useBroadcastNotifications();

  useEffect(() => {
    findBroadcastsQuery({
      variables: {
        filter: {},
      },
    });
  }, [findBroadcastsQuery]);

  const { courses, loading: coursesLoading } = useCourses();
  const history = useHistory();

  const courseIdToTitle = useMemo(() => {
    return (
      courses.reduce((acc, curr) => {
        return (
          {
            ...acc,
            [curr._id]: curr.title,
          }
        );
      }, {} as Record<string, string>)
    );
  }, [courses]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const columns = useMemo(() => (
    [
      {
        title: <b>Título</b>,
        width: '20%',
        key: 'title',
        dataIndex: 'title',
      },
      {
        title: <b>Mensagem</b>,
        width: '40%',
        key: 'message',
        dataIndex: 'message',
      },
      {
        title: <b>Expira em</b>,
        width: '10%',
        key: 'duedate',
        dataIndex: 'duedate',
        render: (date: Date) => (
          String(moment(date).format('DD/MM/YYYY à\\s HH:MM'))
        ),
      },
      {
        title: <b>Thumbnail</b>,
        width: '10%',
        key: 'thumbnail',
        dataIndex: 'thumbnail',
        render: (link: string) => (
          <Image src={link} />
        ),
      },
      {
        title: () => (
          <Button
            style={{ flex: 1 }}
            icon={<PlusOutlined />}
            onClick={() => setIsModalOpen(true)}
          >
            Criar notificação
          </Button>
        ),
        width: '20%',
        key: 'actions',
        dataIndex: 'actions',
        align: 'center' as AlignType,
        render: (_id: string) => {
          const search = `?id=${_id}`;
          return (
            <div
              style={{
                display: 'flex',
                gap: '1rem',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                style={{ padding: 0 }}
                title="Em breve"
                type="link"
                icon={<EditOutlined />}
                disabled
                onClick={() => history.push(`/admin/configuracao/editar-notificacao${search}`)}
              >
                Editar
              </Button>
              <Button
                style={{ padding: 0 }}
                title="Em breve"
                type="link"
                icon={<DeleteOutlined />}
                disabled
                danger
                onClick={() => { console.log('open'); }}
              >
                Deletar
              </Button>
            </div>
          );
        },
      },
    ]
  ), [history]);

  const tableData = useMemo(() => {
    if (data?.findBroadcasts) {
      return (
        data.findBroadcasts
          .map(notification => {
            return (
              {
                createdAt: notification.createdAt,
                key: notification._id,
                title: notification.title,
                message: notification.body.message,
                thumbnail: notification.thumbnail,
                duedate: notification.dueDate,
                actions: notification._id,
                topics: notification.topics,
              }
            );
          })
          .sort((a, b) => (moment(a.createdAt).format('x') > moment(b.createdAt).format('x') ? -1 : 1))
      );
    } return [];
  }, [data?.findBroadcasts]);

  return (
    <>
      <EditDataWrapper
        title="Notificações"
      >
        <CreateNotificationModal
          visible={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          onAfterConfirm={() => {
            if (refetch) {
              refetch({
                filter: {},
              });
            }
          }}
        />
        <Table
          loading={loading}
          columns={columns}
          dataSource={tableData}
          expandable={{
            expandedRowRender: ({ topics }) => (
              <div>
                <p>
                  <b>Destino(s)</b>
                </p>
                <ol>
                  {topics[0] === 'all' ? ('Todos os cursos') : (
                    topics && topics.map(topic => (
                      <div
                        key={topic}
                        style={{
                          display: 'flex',
                          gap: '1rem',
                          margin: '.5rem 0',
                        }}
                      >
                        <li>{courseIdToTitle[topic]}</li>
                      </div>
                    ))
                  )}
                </ol>
              </div>
            ),
            rowExpandable: record => Boolean(record.topics?.length),
          }}
        />
      </EditDataWrapper>
    </>
  );
}
