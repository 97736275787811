import {
  CloseOutlined,
  LoadingOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {
  Button, message, Row, Space, Tag, Typography,
} from 'antd';
import {
  useState, useEffect, useCallback,
} from 'react';
import { Lesson, useLessonDetails, useUpdateLessonDetails } from '../../api/lessons';
import ClassificationTreeCascader from '../../components/ClassificationTreeCascader';
import EditDataWrapper from '../../components/EditDataWrapper';
import { useLessonInCourse } from '../../hooks/useLessonInCourse';
import { getParam } from '../../hooks/useSearchParam';

type EditLessonPageProps = {}

const { Paragraph, Text } = Typography;

export function EditLessonPage(props: EditLessonPageProps) {
  const lessonId = getParam('id');
  const lessonInCourse = useLessonInCourse(lessonId);
  const {
    data: lessonDetailsData,
    loading: queryLoading,
    refetch: refetchLessonDetailsQuery,
  } = useLessonDetails(lessonId);
  const updateLessonDetailsMutation = useUpdateLessonDetails();

  const [lesson, setLesson] = useState({} as Lesson);
  const [dirtyInputs, setDirtyInputs] = useState(false);
  const [mutationLoading, setMutationLoading] = useState(false);

  useEffect(() => {
    if (lessonDetailsData) {
      const { lessonDetails } = lessonDetailsData;
      setLesson(lessonDetails);
    }
  }, [lessonDetailsData]);

  const handleUpdate = useCallback(async () => {
    setMutationLoading(true);
    try {
      await updateLessonDetailsMutation({
        ...lesson,
      });
      if (refetchLessonDetailsQuery) {
        await refetchLessonDetailsQuery({ _id: lesson._id });
      }
      message.success('Dados salvos com sucesso.');
    } catch (error) {
      console.error(error);
    } finally {
      setMutationLoading(false);
      setDirtyInputs(false);
    }
  }, [lesson, refetchLessonDetailsQuery, updateLessonDetailsMutation]);

  const handleUndo = useCallback(() => {
    if (lessonDetailsData?.lessonDetails) {
      setLesson(lessonDetailsData.lessonDetails);
      setDirtyInputs(false);
    }
  }, [lessonDetailsData?.lessonDetails]);

  return (
    <EditDataWrapper
      title="Aula"
      searchKey="id"
      placeholder="Buscar por id da aula"
      loading={queryLoading}
    >
      {queryLoading && <LoadingOutlined />}
      {lessonDetailsData && (
        <Space direction="vertical">
          <Row>
            Título:
            <Paragraph
              style={{ marginLeft: '1rem', flex: 1, width: '100%' }}
              editable={{
                onChange: (lessonTitle: string) => {
                  setDirtyInputs(true);
                  setLesson(prevLesson => ({
                    ...prevLesson,
                    lessonTitle,
                  }));
                },
              }}
            >
              {lesson.lessonTitle}
            </Paragraph>
          </Row>
          <Row>
            <Space>
              <Text>
                Classificação:
              </Text>
              {lesson.classificationTree && (
                <ClassificationTreeCascader
                  treeId={lesson.classificationTree}
                  onChange={classification => {
                    setDirtyInputs(true);
                    setLesson(prevLesson => ({
                      ...prevLesson,
                      classification: classification as string[],
                    }));
                  }}
                  value={lesson.classification}
                />
              )}
            </Space>
          </Row>
          <Row className="py-2">
            <Space>
              <Button
                type="ghost"
                icon={<CloseOutlined />}
                disabled={!dirtyInputs}
                onClick={handleUndo}
              >
                Desfazer
              </Button>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                disabled={!dirtyInputs}
                onClick={handleUpdate}
                loading={mutationLoading}
              >
                Salvar
              </Button>
            </Space>
          </Row>
          {!lessonInCourse && <Tag className="mb-2" color="volcano">Essa aula ainda não está presente em nenhum curso</Tag>}
          {lessonInCourse && lessonInCourse.length > 0 && (
            <>
              <Text style={{ fontWeight: 'bold' }}>Conteúdo presente nos cursos:</Text>
              {lessonInCourse.map(course => (
                <Tag key={course._id} color="#108ee9">{course.title}</Tag>
              ))}
            </>
          )}
        </Space>
      )}
    </EditDataWrapper>
  );
}
