import { FC, useMemo } from 'react';
import { Row } from 'antd';

import { AppealInfo } from './AppealInfo';
import { ExamInfo } from './ExamInfo';
import { AppealContent } from './AppealContent';
import { AppealObjectType } from '../../../../api/appeal';
import { AppealButtons } from './AppealButton';
import { useProfile } from '../../../../api/profile';

type AppealListCardProps = {
  data?: AppealObjectType;
  year: number,
}

export const AppealListCard: FC<AppealListCardProps> = ({
  data,
  year,
}) => {
  const { data: profileData } = useProfile();

  const showButtons = useMemo(() => {
    // eslint-disable-next-line max-len
    return (data && (data?.status === 'pending')) || (data?.moderator && data.moderator.moderatorId === profileData?.profile._id);
  }, [profileData, data]);

  return (
    <Row>
      <AppealContent data={data} year={year} limitText />
      <AppealInfo
        status={data?.status ?? 'finished'}
        createdAt={data?.createdAt ?? new Date()}
        expiresAt={data?.appealEndDate ?? new Date()}
      />
      <ExamInfo
        institution={data?.institution ?? ''}
        year={year}
        area={data?.question.majorArea ?? ''}
      />
      {showButtons && data?.status !== 'canceled' && data?.status !== 'finished' && (
        <AppealButtons appealId={data?._id ?? ''} year={year} />
      )}
    </Row>
  );
};
