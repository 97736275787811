import { FC } from 'react';
import { Col, Row, Typography } from 'antd';
import { UserType } from '../../../hooks/useCommentsManagement';
import { useCourses } from '../../../hooks/useCourses';
import Markdown from '../../../components/Markdown';

const { Text, Paragraph } = Typography;

type ForumCommentContentProps = {
  user: UserType;
  courseId: string;
  body: string;
  isPublic: boolean;
  limitText?: boolean;
}

export const ForumCommentContent: FC<ForumCommentContentProps> = ({
  user,
  courseId,
  body,
  isPublic,
  limitText = false,
}) => {
  const { courses } = useCourses();

  const courseName: string = courses.find(it => it._id === courseId)?.title ?? '';

  return (
    <Col style={{ width: '100%' }}>
      <Row>
        <Text style={{
          fontWeight: 600,
          fontSize: '14px',
        }}
        >
          {`${user.name} | ${courseName}`}
        </Text>
        {!isPublic && (
          <Text
            style={{
              color: '#3B0712',
              backgroundColor: '#FFDADB',
              width: '70px',
              textAlign: 'center',
              borderRadius: '50px',
              marginLeft: '4px',
            }}
          >
            privado
          </Text>
        )}
      </Row>
      <Markdown
        style={{
          marginRight: '8px',
          marginTop: '4px',
          wordWrap: 'break-word',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxHeight: limitText ? '6.3em' : 'none',
        }}
        saveOnStorage={false}
      >
        {body}
      </Markdown>
    </Col>
  );
};
