import { useCallback } from 'react';
import {
  StopOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import {
  Row,
  Col,
  Typography,
  Modal,
  Space,
  Dropdown,
  Menu,
} from 'antd';
import moment from 'moment';
import {
  CourseObjectType, Profile, useManageUserCourse, useProfileByEmail,
} from '../../../api/profile';
import { getParam } from '../../../hooks/useSearchParam';
import { StudyWeekTable } from './StudyWeekTable';

type UserCoursesProps = {
  userProfile: Profile;
  course: CourseObjectType & { finishesAt?: Date };
}

const { Text } = Typography;
const { confirm } = Modal;

export function UserCourse({
  userProfile, course,
}: UserCoursesProps) {
  const email = getParam('email');
  const { handleManageUserCourse } = useManageUserCourse();
  const {
    loading: queryLoadingProfile,
    refetch: refetchUserProfileByEmail,
  } = useProfileByEmail(email);

  const handleManageCourseModalOk = useCallback(async (userCourseId: string, action: string) => {
    if (!userProfile) {
      return;
    }
    try {
      await handleManageUserCourse({
        profileId: userProfile._id,
        userCourseId,
        action,
      });
      if (refetchUserProfileByEmail && !queryLoadingProfile) {
        await refetchUserProfileByEmail({ email: userProfile.email });
      }
    } catch (error) {
      console.error(error);
    }
  }, [handleManageUserCourse, queryLoadingProfile, refetchUserProfileByEmail, userProfile]);

  const handleModal = useCallback((userCourseId: string, action: string) => {
    if (['block', 'allow'].includes(action)) {
      confirm({
        title: `Tem certeza que deseja
          ${action === 'block' ? 'bloquear' : ''}
          ${action === 'allow' ? 'liberar' : ''} esse curso para o usuário?`,
        onOk: () => handleManageCourseModalOk(
          userCourseId,
          action,
        ),
        onCancel: () => { },
      });
    }
    if (['remove'].includes(action)) {
      confirm({
        title: 'Tem certeza que deseja remover esse curso do usuário?',
        content: 'Todo o histórico do usuário será perdido e essa ação não poderá ser desfeita',
        onOk: () => handleManageCourseModalOk(
          userCourseId,
          action,
        ),
        onCancel: () => { },
      });
    }
  }, [handleManageCourseModalOk]);
  const isFinished = moment(course.finishesAt).isSameOrAfter(new Date(), 'day');

  return (
    <Space direction="vertical">
      <Row justify="space-between" align="middle">
        {
          course.status === 'paid' && (
            <Text className="color-success">
              Status: Pago
            </Text>
          )
        }
        {
          course.status === 'blocked' && (
            <Text className="color-danger">
              Status: Bloqueado
            </Text>
          )
        }
        {
          course.status === 'trial' && (
            <Col>
              <Row className="color-warning">
                Status: Trial
              </Row>
              {course.trialEndsAt && `Fim do período gratuito:
                    ${moment(course.trialEndsAt).format('DD[/]MM[/]YYYY')}`}
            </Col>
          )
        }
        <Space>
          <Dropdown.Button
            trigger={['click']}
            overlay={(
              <Menu onClick={e => handleModal(course.userCourse, e.key as string)}>
                <Menu.Item
                  {
                  ...(course.status === 'paid' ? {
                    key: 'block',
                    danger: true,
                    icon: <StopOutlined />,
                  } : {
                    key: 'allow',
                    icon: <CheckCircleOutlined />,
                  })
                  }
                >
                  {course.status === 'paid' ? 'Bloquear acesso' : 'Liberar acesso'}
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item key="remove" danger icon={<DeleteOutlined />}>Remover curso</Menu.Item>
              </Menu>
            )}
          />
        </Space>
      </Row>
      <Row>
        {course.finishesAt
          && (
          <Text className={isFinished ? 'color-success' : 'color-danger'}>
            Fim do curso:
            {' '}
            {moment(course.finishesAt).format('DD[/]MM[/]YYYY')}
          </Text>
          )}
      </Row>

      {
        course.studyWeek && (
          <Row className="mt-2">
            <StudyWeekTable studyWeek={course.studyWeek} />
          </Row>
        )
      }
    </Space>
  );
}
