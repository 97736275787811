import {
  FC, useMemo, useRef, useState,
} from 'react';
import {
  Button, Col, Input, Pagination, Row,
} from 'antd';
import { LoadingOutlined, MenuOutlined, SearchOutlined } from '@ant-design/icons';
import { ResourcesSmallCard } from './components/ResourcesSmallCard';
import { getParam } from '../../../hooks/useSearchParam';
import { ResourcesExamSmallCard } from './components/ResourcesExamsSmallCard';
import { AddExamDialog } from './components/AddExamDialog';
import { VisibleAppealList } from './components/VisibleAppealList';
import { useAllInstitutions } from '../../../api/appeal';
import { ReturnButton } from '../components/ReturnButton';
import { CreateCommentDialog } from './components/CreateCommentDialog';

const AppealsBankPage: FC = () => {
  const institutionId = getParam('institution');
  const year = getParam('year');
  const [createExamModalOpen, setCreateModalExamOpen] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [intitutionYear, setInstitutionYear] = useState(Number(year));
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState<number>();

  const {
    data: institutionsListData,
    loading: institutionsListLoading,
  } = useAllInstitutions(debouncedSearch, currentPage - 1, 21);

  const institutionList = useMemo(() => {
    const data = institutionsListData?.allInstitutions;
    if (data) {
      setTotal(data.totalInstitutions);
    }
    return data;
  }, [institutionsListData]);

  const selectedInstitution = useMemo(() => {
    if (institutionList && institutionId) {
      return institutionList.institutions.filter(it => it._id === institutionId);
    }

    return [];
  }, [institutionId, institutionList]);

  const debounceTimeoutRef = useRef<number| null>(null);

  const handleSearchChange = (value: string) => {
    setSearch(value);
    setCurrentPage(1);
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = window.setTimeout(() => {
      setDebouncedSearch(value);
    }, 300);
  };

  if (institutionId && year && !!selectedInstitution.length) {
    const appeals = selectedInstitution[0].appeals.filter(it => it.year === intitutionYear);

    return (
      <Col style={{ maxWidth: '1200px', margin: '0px auto' }}>
        <CreateCommentDialog
          institution={selectedInstitution[0].institution}
          uf={selectedInstitution[0].uf}
          year={year}
          open={createExamModalOpen}
          close={() => setCreateModalExamOpen(false)}
        />
        <Row justify="space-between" style={{ marginBottom: '16px' }}>
          <ReturnButton
            // path={`/admin/resources/bank?institution=${institutionId}`}
            title="Recursos Visíveis"
            subTitle={`${selectedInstitution[0].institution} / ${year}`}
          />
          <Button shape="round" icon={<MenuOutlined />} onClick={() => setCreateModalExamOpen(true)}>Adicionar Comentário</Button>
        </Row>
        <VisibleAppealList
          key={institutionId}
          institution={selectedInstitution[0].institution}
          year={Number(year)}
          appealsList={appeals[0].appealId}
        />
      </Col>
    );
  }

  if (institutionId && !!selectedInstitution.length) {
    return (
      <Col style={{ maxWidth: '1200px', margin: '0px auto' }}>
        <AddExamDialog
          institution={selectedInstitution[0].institution}
          uf={selectedInstitution[0].uf}
          open={createExamModalOpen}
          close={() => setCreateModalExamOpen(false)}
        />
        <Row justify="space-between" style={{ marginBottom: '16px' }}>
          <ReturnButton
            // path="/admin/resources/bank"
            title={selectedInstitution[0].institution}
          />
          <Button shape="round" icon={<MenuOutlined />} onClick={() => setCreateModalExamOpen(true)}>Adicionar Prova</Button>
        </Row>
        <Row>
          {selectedInstitution.map(it => {
            return it.appeals.map(appeal => {
              return (
                <ResourcesExamSmallCard
                  id={it._id}
                  institution={`${it.uf} - ${it.institution}`}
                  fullName={it.title}
                  year={appeal.year}
                  setYear={(e): void => setInstitutionYear(e)}
                />
              );
            });
          })}
        </Row>
      </Col>
    );
  }

  return (
    <Col style={{ maxWidth: '1200px', margin: '0px auto 16px auto' }}>
      <ReturnButton
        title="Banco de Recursos"
      />
      <Input
        placeholder="Pesquisar Instituição"
        prefix={<SearchOutlined />}
        style={{ margin: '16px 0px' }}
        value={search}
        onChange={e => handleSearchChange(e.target.value)}
      />
      {institutionsListLoading ? (
        <Row>
          {Array.from({ length: 21 }).map(it => {
            return (
              <Col
                style={{
                  width: '160px',
                  height: '140px',
                  padding: '16px 8px',
                  borderRadius: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  backgroundColor: 'rgba(65, 95, 145, 0.11)',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  cursor: 'pointer',
                  margin: '4px',
                }}
              />
            );
          })}
        </Row>
      ) : (
        <div>
          <Row style={{ justifyContent: 'flex-start' }}>
            {institutionList && institutionList.institutions && !institutionsListLoading && institutionList.institutions.map(it => {
              return (
                <ResourcesSmallCard
                  key={it._id}
                  data={it}
                />
              );
            })}
          </Row>
        </div>
      )}
      <Pagination
        style={{ margin: '16px 4px' }}
        simple
        pageSize={21}
        current={currentPage}
        total={total}
        onChange={e => setCurrentPage(e)}
      />
    </Col>
  );
};

export default AppealsBankPage;
