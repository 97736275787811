import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  List,
  message,
  Space,
  Spin,
  Typography,
  Modal,
  Row,
  Button,
  Empty,
} from 'antd';
import { useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useVideosJJPlus } from '../../api/jjplus';
import { useVideos, Video } from '../../api/videos';
import EditDataWrapper from '../../components/EditDataWrapper';
import { getParam } from '../../hooks/useSearchParam';

const { Text } = Typography;
const { confirm } = Modal;

export function AristoPlusVideosPage() {
  const history = useHistory();
  const [loadingCreate, setLoadingCreate] = useState(false);
  const { state } = useLocation<{ title: string } | undefined>();

  const {
    data,
    loading,
    refetch,
  } = useVideosJJPlus();

  const { handleCreateNewVideoMutation, handleDeleteVideoMutation } = useVideos();

  const videos = useMemo(() => {
    if (loading || !data || !data.jjPlusVideos) {
      return [];
    }
    return data.jjPlusVideos;
  }, [data, loading]);

  async function handleCreateVideo() {
    setLoadingCreate(true);
    try {
      const videoId = await handleCreateNewVideoMutation();
      await refetch();
      setLoadingCreate(false);
      history.push({
        pathname: '/admin/editar-video',
        search: `?id=${videoId}`,
      });
    } catch (err) {
      console.error(err);
      message.error('Erro ao criar um novo video!');
      setLoadingCreate(false);
    }
  }

  function handleDeleteModal(video: Pick<Video, '_id' | 'title' | 'subtitle'>) {
    confirm({
      title: 'Deseja mesmo deletar esse video?',
      content: (
        <>
          <div>
            {`Título: ${video.title}`}
          </div>
          <div>
            {`Subtítulo: ${video.subtitle}`}
          </div>
        </>
      ),
      onOk: async () => {
        try {
          await handleDeleteVideoMutation(video._id);
          await refetch();
        } catch (err) {
          console.error(err);
          message.error('Erro ao deletar o video');
        }
      },
      onCancel: () => { },
    });
  }

  return (
    <EditDataWrapper
      title="Videos +Aristo"
    >
      {loading && <Spin />}
      {videos && videos.length > 0 && (
        <List
          size="small"
          header={(
            <Row justify="space-between">
              <Text strong>
                Lista de videos
                {state?.title ? ` de ${state?.title} ` : ''}
              </Text>
              <Button
                icon={<PlusOutlined />}
                onClick={handleCreateVideo}
                loading={loadingCreate}
              >
                Criar video
              </Button>
            </Row>
          )}
          dataSource={videos}
          renderItem={video => {
            const search = `?id=${video._id}`;
            return (
              <List.Item actions={[
                <Link to={{
                  pathname: '/admin/editar-video',
                  search,
                }}
                >
                  <Space>
                    <EditOutlined />
                    Editar
                  </Space>
                </Link>,
                <Button
                  style={{ padding: 0 }}
                  type="link"
                  icon={<DeleteOutlined />}
                  danger
                  onClick={() => handleDeleteModal(video)}
                >
                  Deletar
                </Button>,
              ]}
              >
                <List.Item.Meta
                  title={(
                    <Space>
                      <Text>
                        {`${video.title} - ${video.subtitle}`}
                      </Text>
                    </Space>
                  )}
                  description={`Categoria: ${video.jjPlus?.category} | Playlist: ${video.jjPlus?.playlist}`}
                />
              </List.Item>
            );
          }}
        />
      )}
      {!loading && videos && videos.length === 0 && (
        <Empty description="Nenhum video encontrado">
          <Button
            icon={<PlusOutlined />}
            onClick={handleCreateVideo}
            loading={loadingCreate}
          >
            Criar vídeo
          </Button>
        </Empty>
      )}
    </EditDataWrapper>
  );
}
