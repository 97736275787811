import { PlusOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Row, Col, DatePicker, Space, Button, Typography, message, Cascader,
} from 'antd';
import moment from 'moment';
import {
  useCallback, useMemo, useState,
} from 'react';
import { ExamDiscriminatorType, ExamsObjectType } from '../../../api/courses';
import { MockExamTemplateType } from '../../../api/exams';
import { generateRandomKey } from '../../../utils/generate-random-key';
import { getMomentObjectFromInterval } from '../../../utils/time-utils';
import { TemplateOutput } from './ExamsTable';

type AddExamInputProps = {
  onConfirm: (value: ExamsObjectType) => void;
  allTemplates: MockExamTemplateType[];
  disabled?: boolean;
  loading?: boolean;
  getTemplateById: (id: string) => TemplateOutput;
  alreadyAdded: string[];
}

interface ExamOptionInterface {
  value: string | number;
  label: string;
  children?: ExamOptionInterface[];
}

const { Text } = Typography;

export function AddExamInput({
  allTemplates, disabled = false, loading = false, onConfirm, getTemplateById, alreadyAdded,
}: AddExamInputProps) {
  const [isAddingExam, setIsAddingExam] = useState(false);
  const [examToAdd, setExamToAdd] = useState<ExamsObjectType>({} as ExamsObjectType);

  const currTemplate = useMemo(() => {
    return examToAdd.template ? getTemplateById(examToAdd.template) : {} as TemplateOutput;
  }, [examToAdd.template, getTemplateById]);

  const examOptions: ExamOptionInterface[] = [
    {
      value: 'exam',
      label: 'Prova na íntegra',
    },
    {
      value: 'smart_exam',
      label: 'Simulado inteligente',
    },
    {
      value: 'mock',
      label: 'Simulado Aristo',
      children: allTemplates
        .map(template => ({ value: template._id, label: `${template.title} - ${template.subtitle}` }))
        .filter(x => !alreadyAdded.includes(x.value)),
    },
  ];

  const handleChangeStartsAt = useCallback((value: moment.Moment | null, dateString: string) => {
    if (!value) {
      return;
    }
    setExamToAdd(prev => {
      return {
        ...prev,
        startsAt: value ? value.startOf('day').toISOString() : null,
      };
    });
  }, []);

  const handleChangeFinishesAt = useCallback((value: moment.Moment | null, dateString: string) => {
    if (!value) {
      return;
    }
    setExamToAdd(prev => {
      return {
        ...prev,
        finishesAt: value ? value.endOf('day').toISOString() : null,
      };
    });
  }, []);

  const handleChangeActivityType = useCallback((values: any) => {
    if (!values) {
      return;
    }
    setExamToAdd(prev => {
      if (values.length === 2) {
        return {
          ...prev,
          type: values[0] as ExamDiscriminatorType,
          template: values[1],
        };
      }
      return {
        ...prev,
        type: values[0] as ExamDiscriminatorType,
        template: null,
      };
    });
  }, []);

  const handleConfirm = useCallback(() => {
    const randomKey = generateRandomKey();
    if (examToAdd.type !== 'mock' && (!examToAdd.type
      || !examToAdd.startsAt
      || !examToAdd.finishesAt)) {
      message.warn('Faltam dados para incluir a aula!');
      return;
    }
    if (examToAdd.type === 'mock' && alreadyAdded.includes(examToAdd.template || '')) {
      message.warn('Modelo já utilizado neste curso!');
      return;
    }
    if (examToAdd.type !== 'mock') {
      onConfirm({ ...examToAdd, key: randomKey });
    } else if (currTemplate) {
      onConfirm({
        _id: currTemplate._id,
        finishesAt: currTemplate.finishesAt,
        startsAt: currTemplate.startsAt,
        template: currTemplate._id,
        type: 'mock',
        key: randomKey,
      });
    }
    setIsAddingExam(false);
    setExamToAdd({} as ExamsObjectType);
  }, [alreadyAdded, currTemplate, examToAdd, onConfirm]);

  const activityToAddStartDateIsAfterFinishDate = useMemo(() => {
    return moment(examToAdd.startsAt).isAfter(moment(examToAdd.finishesAt));
  }, [examToAdd.finishesAt, examToAdd.startsAt]);

  return isAddingExam ? (
    <Row gutter={16} align="bottom">
      <Col span={5}>
        Atividade
        <Space direction="vertical">
          {loading && <LoadingOutlined />}
        </Space>
        {!loading && (
          <Cascader
            style={{ width: '100%' }}
            options={examOptions}
            onChange={handleChangeActivityType}
            placeholder="Selecione uma atividade"
          />
        )}
      </Col>
      <Col span={5}>
        <Row style={{ width: '100%' }}>
          Inicia em
        </Row>
        <Row style={{ width: '100%' }}>
          {
            examToAdd.type !== 'mock' ? (
              <DatePicker
                style={{
                  width: '100%',
                  backgroundColor: activityToAddStartDateIsAfterFinishDate ? '#FFC9CC' : '',
                }}
                format="DD/MM/YYYY"
                value={examToAdd.startsAt ? moment(examToAdd.startsAt) : undefined}
                onChange={handleChangeStartsAt}
                allowClear={false}
                placeholder="Selecione a data"
              />
            ) : (
              <Text style={{ width: '100%' }}>
                {currTemplate ? moment(currTemplate.startsAt).format('DD/MM/YY') : '-'}
              </Text>
            )
          }
        </Row>
      </Col>
      <Col span={5}>
        <Row style={{ width: '100%' }}>
          Termina em
        </Row>
        <Row style={{ width: '100%' }}>
          {
            examToAdd.type !== 'mock' ? (
              <DatePicker
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
                disabledDate={d => !d || d.isSameOrBefore(examToAdd.startsAt)}
                value={examToAdd.finishesAt ? moment(examToAdd.finishesAt) : undefined}
                allowClear={false}
                onChange={handleChangeFinishesAt}
                placeholder="Selecione a data"
              />
            ) : (
              <Text style={{ width: '100%' }}>
                {currTemplate ? moment(currTemplate.finishesAt).format('DD/MM/YY') : '-'}
              </Text>
            )
          }
        </Row>
      </Col>
      {
        examToAdd.type === 'mock' && (
          <Col span={3}>
            <Row style={{ width: '100%' }}>
              Duração
            </Row>
            <Row style={{ width: '100%' }}>
              <Text style={{ width: '100%' }}>
                {currTemplate
                  && currTemplate.timeToDo <= 86400000
                  ? getMomentObjectFromInterval(currTemplate.timeToDo)?.format('HH:mm')
                  : '+24h'}
              </Text>
            </Row>
          </Col>
        )
      }
      <Col span={3}>
        <Space>
          <Button
            icon={<PlusOutlined />}
            onClick={handleConfirm}
            disabled={activityToAddStartDateIsAfterFinishDate}
            className="color-success"
          />
          <Button
            icon={<CloseOutlined />}
            onClick={() => {
              setIsAddingExam(false);
              setExamToAdd({} as ExamsObjectType);
            }}
          />
        </Space>
      </Col>
    </Row>
  ) : (
    <Button icon={<PlusOutlined />} onClick={() => setIsAddingExam(true)} disabled={disabled}>Adicionar atividade no cronograma</Button>
  );
}
