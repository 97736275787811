import { FC } from 'react';
import { ForumList } from './components/ForumList';
import { ForumContextProvider } from '../../contexts/Forum.context';

export const ForumMainPage: FC = () => {
  return (
    <ForumContextProvider>
      <ForumList />
    </ForumContextProvider>
  );
};
