import { FC, useMemo, useState } from 'react';
import {
  PageHeader, Row, Statistic, Typography, Space, DatePicker, Button, Divider,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
  CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis,
} from 'recharts';
import { useQuestionCommentStats } from '../../api/comment';
import PrivateComponent from '../../components/PrivateComponent';
import { useOnlineUserHistory } from '../../api/users';

const { Title } = Typography;
const { RangePicker } = DatePicker;

interface DashboardPageProps { }

function format(number: number) {
  return new Intl.NumberFormat('pt-BR').format(number);
}

function dateFormatter(value: any) {
  const date = new Date(value);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hour = date.getHours();
  return `${day}/${month}/${year} ${hour}h`;
}

const DashboardPage: FC<DashboardPageProps> = () => {
  const [filter, setFilter] = useState<{ dateStart: Date, dateEnd: Date }>();
  const { data, loading } = useQuestionCommentStats(filter);
  const { data: onlineUsersDate, loading: onlineUsersLoading } = useOnlineUserHistory(filter);

  const commentsStatistics = useMemo(() => {
    if (!data || !data.getCommentsStatistics || loading) {
      return undefined;
    }
    return data.getCommentsStatistics;
  }, [data, loading]);

  const onlineUsersHistory = useMemo(() => {
    if (!onlineUsersDate || !onlineUsersDate.onlineUsersHistory || onlineUsersLoading) {
      return undefined;
    }
    return onlineUsersDate.onlineUsersHistory.map(item => ({
      ...item,
      date: new Date(item.date).getTime(),
    }));
  }, [onlineUsersDate, onlineUsersLoading]);

  return (
    <div style={{ maxWidth: '1120px', margin: '0 auto', height: '100%' }}>
      <Row justify="center" align="stretch" style={{ height: '100%' }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
        >
          <PageHeader title="Dashboard" />
          <PrivateComponent clearences={['admin']}>
            <Row>
              <Space direction="vertical">
                <Title level={5}>Estatísticas dos comentários</Title>
                <Space>
                  <RangePicker onChange={dates => {
                    if (dates && dates[0] && dates[1]) {
                      setFilter({
                        dateStart: dates[0].toDate(),
                        dateEnd: dates[1].toDate(),
                      });
                    } else {
                      setFilter(undefined);
                    }
                  }}
                  />
                </Space>
              </Space>
            </Row>
            {loading ? (
              <LoadingOutlined />
            ) : (
              <Row style={{ marginTop: '1rem' }}>
                <div style={{ marginLeft: '2rem' }}>
                  <Statistic title="Total" value={format(commentsStatistics?.totalCount ?? 0)} />
                </div>
                <Space direction="horizontal" size="large">
                  {[...commentsStatistics?.commentStats ?? []].sort((a, b) => ((a.area || '') > (b.area || '') ? 1 : -1))
                    .map(stat => (stat.count && stat.area && (
                      <div style={{ marginLeft: '2rem' }}>
                        <Statistic
                          key={stat.area}
                          title={stat.area}
                          value={format(stat.count)}
                        />
                      </div>
                    )))}
                </Space>
              </Row>
            )}
            <Divider />
          </PrivateComponent>
          {onlineUsersLoading ? (
            <LoadingOutlined />
          ) : (
            <PrivateComponent clearences={['admin']}>
              <Title level={5}>Estatísticas de usuários online</Title>
              {onlineUsersHistory && (
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    width={500}
                    height={500}
                    data={onlineUsersHistory}
                    margin={{
                      top: 5,
                      right: 20,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="date"
                      tickFormatter={dateFormatter}
                      domain={['auto, auto']}
                      type="number"
                    />
                    <YAxis />
                    <Tooltip labelFormatter={dateFormatter} />
                    <Legend />
                    <Line
                      name="Usuários online"
                      type="monotone"
                      dataKey="count"
                      stroke="#0473E3"
                      activeDot={{ r: 6 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              )}
            </PrivateComponent>
          )}
        </div>
      </Row>
    </div>
  );
};

export default DashboardPage;
