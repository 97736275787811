import React, { FC, useContext } from 'react';
import { Select, SelectProps } from 'antd';
import { ForumContext } from '../../../../contexts/Forum.context';

type ContentTypeFilterProps = {
  style?: React.CSSProperties,
}

export const ContentTypeFilter: FC <ContentTypeFilterProps> = ({
  style,
}) => {
  const { filterController } = useContext(ForumContext);
  const options : SelectProps['options'] = [
    {
      value: 'Question',
      label: <span>Questão</span>,
    },
    {
      value: 'Video',
      label: <span>Vídeo</span>,
    },
    {
      value: 'Flashcard',
      label: <span>Flashcard</span>,
    },
    {
      value: 'MindMap',
      label: <span>Mapa mental</span>,
    },
    {
      value: 'ParetoGuide',
      label: <span>Guia Pareto</span>,
    }, {
      value: 'Resources',
      label: <span>Recursos</span>,
    },
  ];

  options.unshift({
    value: null,
    label: 'Todos os tipos',
  });

  return (
    <Select
      style={{ width: '160px', ...style }}
      placeholder="Tipo de conteúdo"
      options={options}
      onChange={filterController.updateContentFilter}
    />
  );
};
