import {
  gql, QueryResult, useMutation, useQuery,
} from '@apollo/client';
import { message } from 'antd';
import { useCallback } from 'react';

export interface Flashcard {
  _id: string;
  front: string;
  back: string;
  classification: string[];
  tagTree: string;
  comment: Comment;
  createdBy: string;
  answer: number;
  createdAt: Date;
  updatedAt: Date;
  prefix?: string;
}

export interface FlashcardDetailsInput {
  _id: string;
}

export interface FlashcardDetailsOutput {
  flashcardDetails: Flashcard;
}

const FLASHCARD_DETAILS = gql`
  query FlashcardDetails(
    $_id: ObjectId!
  ) {
    flashcardDetails(
      _id: $_id
    ) {
      _id
      front
      back
      classification
      tagTree
      createdAt
      updatedAt
      prefix
    }
  }
`;

export function useFlashcardDetails(_id: string): QueryResult<FlashcardDetailsOutput, FlashcardDetailsInput> {
  return useQuery<FlashcardDetailsOutput, FlashcardDetailsInput>(FLASHCARD_DETAILS, {
    variables: {
      _id,
    },
    skip: !_id,
    onError: error => {
      message.error(error.message);
    },
  });
}

export interface UpdateFlashcardDetailsInput {
  _id: string;
  front?: string;
  back?: string;
  prefix?: string;
  classification?: string[];
}

interface UpdateFlashcardDetailsOutput {
  updateFlashcardDetails: boolean;
}

const UPDATE_FLASHCARD_DETAILS = gql`
  mutation UpdateFlashcardDetails(
    $_id: ObjectId!,
    $front: String,
    $back: String,
    $classification: [String!],
    $prefix: String,
  ) {
    updateFlashcardDetails(
      _id: $_id,
      front: $front,
      back: $back,
      classification: $classification,
      prefix: $prefix
    )
  }
`;

export function useUpdateFlashcardDetails() {
  const [updateFlashcardDetails] = useMutation<UpdateFlashcardDetailsOutput, UpdateFlashcardDetailsInput>(UPDATE_FLASHCARD_DETAILS, {
    onError: error => {
      message.error(error.message);
    },
  });
  const handleUpdateFlashcardDetails = useCallback(async ({
    _id,
    front,
    back,
    classification,
    prefix,
  }: UpdateFlashcardDetailsInput) => {
    const res = await updateFlashcardDetails({
      variables: {
        _id,
        front,
        back,
        classification,
        prefix,
      },
    });
    return res.data?.updateFlashcardDetails;
  }, [updateFlashcardDetails]);
  return handleUpdateFlashcardDetails;
}

interface LessonFlashcardsInput {
  lessonId: string;
}

interface LessonFlashcardsOutput {
  lessonFlashcards: Pick<Flashcard, '_id' | 'front' | 'back' | 'classification' | 'prefix'>[];
}

const LESSON_FLASHCARDS = gql`
  query LessonFlashcards(
    $lessonId: ObjectId!
  ) {
    lessonFlashcards(lessonId: $lessonId) {
      _id
      front
      back
      classification
    }
  }
`;

const CREATE_FLASHCARD = gql`
  mutation CreateFlashcard(
    $lessonId: ObjectId!
  ) {
    createFlashcard(lessonId: $lessonId)
  }
`;

export interface CreateFlashcardInput {
  lessonId: string;
}

export interface CreateFlashcardOutput {
  createFlashcard: string;
}

const DELETE_FLASHCARD = gql`
  mutation DeleteFlashcard(
    $flashcardId: ObjectId!
  ) {
    deleteFlashcard(flashcardId: $flashcardId)
  }
`;
export interface DeleteFlashcardInput {
  flashcardId: string;
}

export interface DeleteFlashcardOutput {
  deleteFlashcard: boolean;
}

const CREATE_MANY_FLASHCARDS = gql`
  mutation CreateManyFlashcards(
    $lessonId: ObjectId!
    $flashcards: [FlashcardInputType!]!
  ) {
    createManyFlashcards(lessonId: $lessonId, flashcards: $flashcards)
  }
`;

export interface CreateManyFlashcardsInput {
  lessonId: string;
  flashcards: {
    front: string;
    back: string;
    prefix?: string | undefined;
  }[];
}

export interface CreateManyFlashcardsOutput {
  createManyFlashcards: number;
}

export function useFlashcards(lessonId: string) {
  const lessonFlashcards = useQuery<LessonFlashcardsOutput, LessonFlashcardsInput>(LESSON_FLASHCARDS, {
    variables: {
      lessonId,
    },
    skip: !lessonId,
  });

  const [createNewFlashcard] = useMutation<CreateFlashcardOutput, CreateFlashcardInput>(CREATE_FLASHCARD);
  const handleCreateNewFlashcardMutation = useCallback(async () => {
    const response = await createNewFlashcard({
      variables: {
        lessonId,
      },
    });
    return response.data?.createFlashcard;
  }, [createNewFlashcard, lessonId]);

  const [createManyFlashcards] = useMutation<CreateManyFlashcardsOutput, CreateManyFlashcardsInput>(CREATE_MANY_FLASHCARDS);
  const handleCreateManyFlashcardsMutation = useCallback(async (flashcards: CreateManyFlashcardsInput['flashcards']) => {
    const response = await createManyFlashcards({
      variables: {
        lessonId,
        flashcards,
      },
    });
    return response.data?.createManyFlashcards;
  }, [createManyFlashcards, lessonId]);

  const [deleteFlashcard] = useMutation<DeleteFlashcardOutput, DeleteFlashcardInput>(DELETE_FLASHCARD);
  const handleDeleteFlashcardMutation = useCallback(async (flashcardId: string) => {
    const response = await deleteFlashcard({
      variables: {
        flashcardId,
      },
    });
    return response.data?.deleteFlashcard;
  }, [deleteFlashcard]);

  return {
    lessonFlashcards,
    handleCreateNewFlashcardMutation,
    handleDeleteFlashcardMutation,
    handleCreateManyFlashcardsMutation,
  };
}
