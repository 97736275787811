import { useMemo, useState } from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconName, IconDefinition, findIconDefinition,
} from '@fortawesome/fontawesome-svg-core';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import {
  Button, Empty, List, Row, Space, Spin, Typography, Modal, message,
} from 'antd';
import { Link } from 'react-router-dom';
import EditDataWrapper from '../../components/EditDataWrapper';
import { CreateBurgerItemModal } from './CreateBurgerItemModal';
import { BurgerMenuItem, useBurgerItemsControls, useFindBurgerMenuItems } from '../../api/burger-menu';

const { Text } = Typography;
const { confirm } = Modal;

type BurgerMenusProps = {}

export function BurgerMenus(props: BurgerMenusProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, loading, refetch } = useFindBurgerMenuItems();
  const { handleDeleteBurgerItems } = useBurgerItemsControls();

  const burgerItems = useMemo(() => {
    if (!data || loading) {
      return [];
    }
    return { ...data }.findBurgerMenuItemsAdmin;
  }, [data, loading]);

  function handleDeleteModal(item: BurgerMenuItem) {
    confirm({
      title: 'Deseja mesmo deletar esse item?',
      content: (
        <>
          <div>
            {`Título: ${item.title}`}
          </div>
          <div>
            {`Url: ${item.url}`}
          </div>
        </>
      ),
      onOk: async () => {
        try {
          await handleDeleteBurgerItems([item._id]);
          await refetch();
        } catch (err) {
          console.error(err);
          message.error('Erro ao deletar o item');
        }
      },
      onCancel: () => { },
    });
  }

  return (
    <>
      <EditDataWrapper
        title="Itens no menu de hamburguer"
      >
        {loading && <Spin />}
        {burgerItems.length > 0 && (
          <List
            size="small"
            header={(
              <Row justify="space-between">
                <Text strong>
                  Lista de itens do menu de hamburguer
                </Text>
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => setIsModalOpen(true)}
                >
                  Criar item
                </Button>
              </Row>
            )}
            dataSource={[...burgerItems].sort((a, b) => a.order - b.order)}
            renderItem={item => {
              const search = `?id=${item._id}`;
              const iconDefinition: IconDefinition = findIconDefinition({ prefix: 'fas', iconName: item.icon as IconName });
              return (
                <List.Item actions={[
                  <Link to={{
                    pathname: '/admin/configuracao/editar-burger-menu',
                    search,
                  }}
                  >
                    <Space>
                      <EditOutlined />
                      Editar
                    </Space>
                  </Link>,
                  <Button
                    style={{ padding: 0 }}
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteModal(item)}
                    danger
                  >
                    Deletar
                  </Button>,
                ]}
                >
                  <List.Item.Meta
                    title={`${item.title} - ${item.order}`}
                    description={item.url}
                    avatar={<FontAwesomeIcon icon={iconDefinition || faCircle} size="2x" />}
                  />
                </List.Item>
              );
            }}
          />
        )}
        {!loading && burgerItems.length === 0 && (
          <Empty description="Nenhum item encontrado">
            <Button
              icon={<PlusOutlined />}
              onClick={() => setIsModalOpen(true)}
            >
              Criar item
            </Button>
          </Empty>
        )}
      </EditDataWrapper>
      <CreateBurgerItemModal onCancel={() => setIsModalOpen(false)} visible={isModalOpen} afterCreate={() => refetch()} />
    </>
  );
}
