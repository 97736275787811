import { FC } from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';
import { useHistory } from 'react-router-dom';

type ResourcesExamSmallCardProps = {
  id: string;
  institution: string;
  fullName: string;
  year: number;
  setYear: (e: number) => void;
}

export const ResourcesExamSmallCard: FC<ResourcesExamSmallCardProps> = ({
  id,
  institution,
  fullName,
  year,
  setYear,
}) => {
  const navigate = useHistory();

  const handleClick = (): void => {
    setYear(year);
    navigate.push(`/admin/resources/bank?institution=${id}&year=${year}`);
  };

  const color = '#001B3E';

  return (
    <Col
      style={{
        width: '160px',
        height: '140px',
        padding: '16px 8px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: 'rgba(65, 95, 145, 0.11)',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        cursor: 'pointer',
        margin: '4px',
      }}
      onClick={handleClick}
    >
      <div>
        <Typography.Title
          ellipsis={{ rows: 2 }}
          style={{
            fontSize: '16px',
            fontWeight: 500,
            color,
          }}
        >
          {institution}
        </Typography.Title>
        <Typography.Paragraph
          ellipsis={{ rows: 2 }}
          style={{
            fontSize: '12px',
            fontWeight: 500,
            color,
          }}
        >
          {fullName}
        </Typography.Paragraph>
      </div>
      <Row>
        <CalendarOutlined />
        <Typography.Title
          ellipsis={{ rows: 1 }}
          style={{ margin: '0px 0px 0px 4px', fontSize: '14px', color }}
        >
          {year}
        </Typography.Title>
      </Row>
    </Col>
  );
};
