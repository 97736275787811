import { FC } from 'react';
import { Col, Row } from 'antd';

const style = {
  height: '120px',
  marginRight: '16px',
  backgroundColor: '#f5f5f5',
};

export const ForumListTileSkeleton: FC = () => {
  return (
    <Col>
      {Array.from({ length: 3 }, (_, i) => (
        <Row
          key={`Skeleton${i}`}
          style={{
            padding: '16px',
            borderBottom: i === 2 ? undefined : '1px solid #74777F',
          }}
        >
          <div style={{ width: '43%', ...style }} />
          <div style={{ width: '180px', ...style }} />
          <div style={{ width: '200px', ...style }} />
          <div style={{ width: '160px', ...style }} />
        </Row>
      ))}
    </Col>
  );
};
