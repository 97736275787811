import { CopyOutlined } from '@ant-design/icons';
import {
  Button, Checkbox, message, Modal, Select,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Text from 'antd/lib/typography/Text';
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { CopyOptions, Lesson, useCopyLessonContents } from '../../api/lessons';
import ClassificationTreeCascader from '../../components/ClassificationTreeCascader';
import { useCourses } from '../../hooks/useCourses';

type LessonReplicationButtonType = {
  lesson: Lesson,
}

const initialSelectedResources = {
  videos: true,
  texts: true,
  mindmaps: true,
  flashcards: true,
  questions: true,
  theoreticalReviewContent: true,
};

const resourcesDict = {
  videos: 'Videoaulas',
  texts: 'Apostilas',
  mindmaps: 'Mapas-mentais',
  flashcards: 'Flashcards',
  questions: 'Questões',
  theoreticalReviewContent: 'Vídeos da RT',
};

export const LessonReplicationButton: FC<LessonReplicationButtonType> = ({
  lesson,
}) => {
  const { courses } = useCourses();
  const [targetCourseId, setTargetCourseId] = useState('');
  const [selectedResources, setSelectedResources] = useState<CopyOptions>(initialSelectedResources);
  const [classification, setClassification] = useState<string[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [replicationLoading, setReplicationLoading] = useState(false);
  const { Option } = Select;
  const { handleCopyLessonContents } = useCopyLessonContents();

  const selectedCourseTreeId = useMemo(() => {
    return courses.filter(course => course._id === targetCourseId)[0]?.classificationTree;
  }, [courses, targetCourseId]);

  const onResourceCheck = useCallback((e: CheckboxChangeEvent, value: string) => {
    setSelectedResources(prev => {
      const hasOnlyOneMarked = Object.values(prev).filter(x => x).length === 1;
      return {
        ...prev,
        [value]: hasOnlyOneMarked ? true : e.target.checked,
      };
    });
  }, []);

  const onReplicateClick = useCallback(async () => {
    setReplicationLoading(true);
    await handleCopyLessonContents({
      classification,
      courseId: targetCourseId,
      lessonId: lesson._id,
      copyOptions: selectedResources,
    });
    setModalVisible(false);
    setReplicationLoading(false);
    message.success('Aula replicada com sucesso.');
  }, [classification, handleCopyLessonContents, lesson._id, selectedResources, targetCourseId]);

  return (
    <>
      <Button
        style={{ padding: 0, color: 'lightseagreen' }}
        type="link"
        icon={<CopyOutlined />}
        onClick={() => setModalVisible(true)}
      >
        Replicar
      </Button>
      <Modal
        title={`Replicando ${lesson.lessonTitle}`}
        visible={modalVisible}
        onOk={onReplicateClick}
        okText="Replicar"
        okButtonProps={{
          disabled: !targetCourseId || !classification.length,
        }}
        cancelText="Cancelar"
        confirmLoading={replicationLoading}
        onCancel={() => setModalVisible(false)}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            flexFlow: 'column',
            gap: 12,
          }}
        >
          <div style={{
            width: '100%',
            display: 'flex',
            flexFlow: 'column',
          }}
          >
            <Text>Curso de destino</Text>
            <Select
              style={{ width: '100%' }}
              onChange={async e => {
                setTargetCourseId(e as string);
              }}
              placeholder="Selecione o curso de destino..."
            >
              {
                courses.map(course => (
                  <Option key={course._id} value={course._id}>
                    {course.title}
                  </Option>
                ))
              }
            </Select>
          </div>
          <div style={{
            width: '100%',
            display: 'flex',
            flexFlow: 'column',
          }}
          >
            <Text>Classificação da aula</Text>
            <ClassificationTreeCascader
              treeId={selectedCourseTreeId}
              onChange={classif => {
                setClassification(classif.map(String));
              }}
              value={classification}
            />
          </div>
          <div style={{
            width: '100%',
            display: 'flex',
            flexFlow: 'column',
          }}
          >
            <Text>Materiais a replicar</Text>
            <div style={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '10px 50px',
              marginTop: 5,
            }}
            >
              {
                Object.keys(initialSelectedResources)
                  .map(value => (
                    <Checkbox
                      style={{ marginLeft: 0 }}
                      value={value}
                      checked={selectedResources[value as keyof typeof selectedResources]}
                      onChange={e => onResourceCheck(e, value)}
                    >
                      {resourcesDict[value as keyof typeof resourcesDict]}
                    </Checkbox>
                  ))
              }
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
