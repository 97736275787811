/* eslint-disable max-len */
import { FC, useMemo } from 'react';
import { Col, Row, Typography } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { CardComponent } from './CardComponent';
import { StudyFragment, useStudyWeek } from '../../../api/user-dashboard';

const iconCheckStyle = { color: '#415F91', fontSize: '20px' };
const iconMinustyle = { fontSize: '20px' };

const WeekRow: FC<{ data?: StudyFragment }> = ({ data }) => {
  return (
    <>
      <Col>
        {data != null && data.theoreticalStudy ? <FontAwesomeIcon icon={faCheckCircle} style={iconCheckStyle} /> : <MinusOutlined style={iconMinustyle} />}
      </Col>
      <Col>
        {data != null && data.smartReview ? <FontAwesomeIcon icon={faCheckCircle} style={iconCheckStyle} /> : <MinusOutlined style={iconMinustyle} />}
      </Col>
      <Col>
        {data != null && data.theoreticalReview ? <FontAwesomeIcon icon={faCheckCircle} style={iconCheckStyle} /> : <MinusOutlined style={iconMinustyle} />}
      </Col>
      <Col>
        {data != null && data.examAndMocks ? <FontAwesomeIcon icon={faCheckCircle} style={iconCheckStyle} /> : <MinusOutlined style={iconMinustyle} />}
      </Col>
    </>
  );
};

export const TypicalWeekCard: FC<{profileId: string, courseId: string}> = ({
  profileId,
  courseId,
}) => {
  const { data } = useStudyWeek({ profileId, courseId });

  const studyWeek = useMemo(() => data?.studyWeekToStudent, [data]);

  const map = {
    monday: 'S',
    tuesday: 'T',
    wednesday: 'Q',
    thursday: 'Q',
    friday: 'S',
    saturday: 'S',
    sunday: 'D',
  };

  return (
    <CardComponent cardTitle="Semana típica">
      <Row style={{ justifyContent: 'space-between' }}>
        <Col style={{ width: '135px' }}>
          <Typography style={{ fontSize: '14px', fontWeight: 500, marginBottom: '8px' }}>Atividades</Typography>
          <Typography style={{ fontSize: '14px', fontWeight: 400 }}>Estudo teórico</Typography>
          <Typography style={{ fontSize: '14px', fontWeight: 400 }}>Revisão inteligente</Typography>
          <Typography style={{ fontSize: '14px', fontWeight: 400 }}>Revisão teórica</Typography>
          <Typography style={{ fontSize: '14px', fontWeight: 400 }}>Provas</Typography>
        </Col>
        {Object.keys(map).map(day => (
          <Col key={day} style={{ width: '24px', alignItems: 'center', justifyContent: 'center' }}>
            <Typography style={{ fontSize: '14px', fontWeight: 500, marginBottom: '8px' }}>{map[day as keyof typeof map]}</Typography>
            <WeekRow data={!studyWeek ? undefined : studyWeek[day as keyof typeof map]} />
          </Col>
        ))}
      </Row>
    </CardComponent>
  );
};
