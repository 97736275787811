import { gql, useMutation, useQuery } from '@apollo/client';
import { message } from 'antd';

export type PDFType = {
  _id: string;
  url: string;
  title: string;
  subtitle?: string;
  category: string;
  description?: string;
  aristoPlus?: {
    permissions: string[];
  };
  createdAt?: string;
  updatedAt?: string;
}

interface PDFsAristoPlusManyOutput {
  aristoPlusPDFsList: PDFType[];
}

const PDFS_ARISTO_PLUS = gql`
  query AristoPlusPDFsList {
    aristoPlusPDFsList {
      _id
      url
      title
      subtitle
      aristoPlus {
        permissions
      }
      createdAt
     }
  }
`;

export function usePDFsAritoPlus() {
  return useQuery<PDFsAristoPlusManyOutput>(PDFS_ARISTO_PLUS);
}

const CREATE_PDF = gql`
    mutation {
      createPDF
    }
  `;

export function useCreatePdf() {
  return useMutation<{ createPDF: string }>(CREATE_PDF);
}

export interface PDFDetailsInput {
  _id: string;
}

export interface PDFDetailsOutput {
  aristoPlusPDF: PDFType;
}

const PDF_DETAILS = gql`
  query AristoPlusPDF($_id: ObjectId!) {
    aristoPlusPDF(id: $_id) {
      _id
      url
      title
      subtitle
      category
      aristoPlus {
        permissions
      }

    }
  }
`;

export function usePdfDetails(_id: string) {
  return useQuery<PDFDetailsOutput, PDFDetailsInput>(PDF_DETAILS, {
    variables: {
      _id,
    },
    skip: !_id,
    onError: error => {
      message.error(error.message);
    },
  });
}

interface UpdatePdfDetailsOutput {
  updatePDF: boolean;
}

export interface UpdatePdfDetailsInput {
  object: Omit<Partial<PDFType>, '_id' | 'aristoPlus' | 'createdAt' | 'updatedAt'> & {
    _id: string;
    permissions?: string[];
  };
}

const UPDATE_PDF_DETAILS = gql`
  mutation UpdatePdfDetails(
    $object: PDFInputType!
  ) {
    updatePDF(pdfInfo: $object)
  }
`;

export function useUpdatePdfDetails() {
  return useMutation<UpdatePdfDetailsOutput, UpdatePdfDetailsInput>(UPDATE_PDF_DETAILS, {
    onError: error => {
      message.error(error.message);
    },
  });
}

const DELETE_PDF = gql`
    mutation DeletePDF($id: ObjectId!) {
      deletePDF(id: $id)
    }
  `;

export function useDeletePdf() {
  return useMutation<{ deletePDF: boolean }, { id: string }>(DELETE_PDF);
}

const CREATE_PLANNER_AND_ADD_TO_COURSE = gql`
  mutation createPlannerAndAddToCourse(
    $base64: String!,
    $mimetype: String!,
    $title: String!,
    $courseId: ObjectId!,
    $subtitle: String) {
    createPlannerAndAddToCourse(
      base64: $base64,
      mimetype: $mimetype,
      title: $title,
      courseId: $courseId,
      subtitle: $subtitle,
    )
  }
`;

export interface CreatePlannerAndAddToCourseInput {
  courseId: string;
  title: string;
  subtitle?: string;
  mimetype: string;
  base64: string;
}

export function useCreateAndAddToCoursePdf() {
  const [createPDFMutation] = useMutation<{ id: string }, CreatePlannerAndAddToCourseInput>(CREATE_PLANNER_AND_ADD_TO_COURSE, {
    onError: error => {
      throw new Error();
    },
  });
  const handleCreateAndAddPDFtoCourse = async (input: CreatePlannerAndAddToCourseInput) => {
    await createPDFMutation({
      variables: input,
    });
  };

  return {
    handleCreateAndAddPDFtoCourse,
  };
}

const FETCH_PLANNERS_FOR_COURSE = gql`
  query fetchPlannersForCourse($courseId: ObjectId!) {
    fetchPlannersForCourse(courseId: $courseId) {
      _id
      url
      title
      subtitle
    }
  }
`;

export type PdfType = {
  _id: string;
  title: string;
  subtitle?: string;
  url: string;
}

export interface FetchPlannersForCourseOutput {
  fetchPlannersForCourse: PdfType[];
}

export interface FetchPlannersForCourseInput {
  courseId: string;
}

export function useFetchPlanners(courseId: string) {
  return useQuery<FetchPlannersForCourseOutput, FetchPlannersForCourseInput>(FETCH_PLANNERS_FOR_COURSE, {
    variables: {
      courseId,
    },
  });
}

const REMOVE_PLANNER_FROM_COURSE = gql`
  mutation removePlannerFromCourse($courseId: ObjectId!, $plannerId: ObjectId!) {
    removePlannerFromCourse(courseId: $courseId, plannerId: $plannerId)
  }
`;

export function useRemovePlannerFromCourse() {
  const [removePlanner] = useMutation<{ deletePdf: boolean }, { courseId: string, plannerId: string }>(REMOVE_PLANNER_FROM_COURSE);

  const handleRemovePlannerFromCourse = async (courseId: string, plannerId: string) => {
    await removePlanner({
      variables: {
        courseId,
        plannerId,
      },
    });
  };

  return {
    handleRemovePlannerFromCourse,
  };
}

const UPDATE_PLANNER = gql`
  mutation updatePlanner(
    $plannerId: ObjectId!,
    $title: String,
    $subtitle: String,
  ) {
    updatePlanner(
      plannerId: $plannerId,
      title: $title,
      subtitle: $subtitle,
    )
  }
`;

export interface UpdatePlannerInput {
  plannerId: string;
  title?: string;
  subtitle?: string;
}

export interface UpdatePlannerOutput {
  updatedPdf: boolean;
}

export function useUpdatePlanner() {
  const [updatePlanner] = useMutation<UpdatePlannerOutput, UpdatePlannerInput>(UPDATE_PLANNER);

  const handleUpdatePlanner = async (value: UpdatePlannerInput) => {
    await updatePlanner({
      variables: value,
    });
  };

  return {
    handleUpdatePlanner,
  };
}

const UPDATE_PLANNER_INDEX = gql`
  mutation updatePlannerIndex(
    $courseId: ObjectId!,
    $plannersIds: [ObjectId!]!,
  ) {
    updatePlannerIndex(
      courseId: $courseId
      plannersIds: $plannersIds
    )
  }
`;

export interface UpdatePlannerIndexInput {
  courseId: string;
  plannersIds: string[];
}

export interface UpdatePlannerIndexOutput {
  plannersUpdated: boolean;
}

export function useUpdatePlannerIndex() {
  const [updatePlannerIndex] = useMutation<UpdatePlannerIndexOutput, UpdatePlannerIndexInput>(UPDATE_PLANNER_INDEX);

  const handleUpdatePlannerIndex = async (input: UpdatePlannerIndexInput) => {
    await updatePlannerIndex({
      variables: input,
    });
  };

  return {
    handleUpdatePlannerIndex,
  };
}
