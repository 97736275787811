import { useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import { message } from 'antd';

import { storage } from '../client/firebase';

const cache: { [k: string]: string } = {};

async function addFileURL(path: string): Promise<typeof cache> {
  if (!path) {
    throw new Error('invalid path.');
  }
  if (cache[path]) {
    return cache;
  }
  try {
    const storageUrl = await storage.ref(path).getDownloadURL();
    cache[path] = storageUrl;
  } catch (error) {
    cache[path] = '/pdf/pdf-broken.pdf';
    console.error(error);
  }
  return { ...cache };
}

async function removeFileURL(path: string): Promise<typeof cache> {
  if (!path) {
    throw new Error('invalid path.');
  }
  if (cache[path]) {
    delete cache[path];
  }
  return { ...cache };
}

async function uploadFromBase64(path: string, b64: string, fileName?: string): Promise<string> {
  const contentType = /data:([^;]*);base64,/.exec(b64)?.[1];
  if (!contentType) {
    throw new Error('Missing content type');
  }
  const data = b64.replace(/data:([^;]*);base64,/, '');
  // eslint-disable-next-line no-useless-escape
  const ext = contentType.replace(/[^\/]*\//, '').split('+')[0];
  const chars = atob(data);
  const bytes = new Uint8Array(Array.from({ length: chars.length }, (_, i) => chars.charCodeAt(i)));
  const snap = await storage.ref(path)
    .child(`${fileName || nanoid()}.${ext}`)
    .put(bytes, { contentType });
  return snap.ref.fullPath;
}

export interface FirebaseStorageHook {
  getURL: (path: string) => string;
  uploadFromBase64: (path: string, b64: string, fileName?: string) => Promise<string>;
  getURLAsync: (path: string, useCache?: boolean) => Promise<string>;
  deleteFileFromStorage: (path: string) => Promise<void>;
  getURLAsyncLoading: boolean;
}

export default function useFirebaseStorage(): FirebaseStorageHook {
  const [hookCache, setHookCache] = useState(cache);
  const [loading, setLoading] = useState(false);
  return useMemo(() => {
    const getURL = (path: string): string => {
      if (hookCache[path]) {
        return hookCache[path];
      }
      addFileURL(path)
        .then(setHookCache)
        .catch(e => console.error(e));

      return '';
    };
    const getURLAsync = async (path: string, useCache = true): Promise<string> => {
      setLoading(true);
      if (hookCache[path] && useCache) {
        setLoading(false);
        return hookCache[path];
      }
      try {
        const updatedCache = await addFileURL(path);
        setHookCache(updatedCache);
        const url = await storage.ref(path).getDownloadURL();
        setLoading(false);
        return url;
      } catch (error) {
        console.error(error);
        setLoading(false);
        return '/pdf/pdf-broken.pdf';
      }
    };
    const deleteFileFromStorage = async (path: string): Promise<void> => {
      const fileRef = storage.ref(path);
      try {
        await fileRef.delete();
        const updatedCache = await removeFileURL(path);
        setHookCache(updatedCache);
        message.success('Arquivo deletado com sucesso!');
      } catch (error) {
        message.error('Erro ao deletar a arquivo!');
        throw error;
      }
    };
    return {
      getURL,
      uploadFromBase64,
      getURLAsync,
      deleteFileFromStorage,
      getURLAsyncLoading: loading,
    };
  }, [hookCache, loading]);
}
