import { ReactNode, createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import useCommentsManagement, { AnswerStatusType, ForumCommentType, ForumCommentWithRepliesType } from '../hooks/useCommentsManagement';

type ForumCommentsContextType = {
  forumComment: ForumCommentType | undefined;
  parentComment: ForumCommentWithRepliesType | undefined;
  loadingComment: boolean;
  isSendingData: boolean;
  getComment: (commentId: string) => Promise<void>;
  commentManagementController: {
    setCommentAsIgnore: () => Promise<void>;
    setCommentAsPrivate: ()=> Promise<void>;
    changeCommentLocalStatus: (status: AnswerStatusType) => void;
    reserveIgnoredComment: (commentId: string) => Promise<void>;
  };
}

export const ForumCommentsContext = createContext({} as ForumCommentsContextType);

interface ForumCommentsProviderProps {
  children?: ReactNode
}

export function ForumCommentsProvider({
  children,
}: ForumCommentsProviderProps) {
  const [forumComment, setForumComment] = useState<ForumCommentType>();
  const [parentComment, setParentComment] = useState<ForumCommentWithRepliesType>();
  const [loadingComment, setLoadingComment] = useState<boolean>(true);
  const [isSendingData, setIsSendingData] = useState<boolean>(false);

  const navigate = useHistory();

  const {
    getCommentsByIds,
    privateComment,
    ignoreComment,
  } = useCommentsManagement();

  const { reserveComment } = useCommentsManagement();

  const getComment = async (commentId: string): Promise<void> => {
    setLoadingComment(true);
    try {
      const comment = await getCommentsByIds([commentId]);
      setForumComment(comment[0]);
      if (comment.length && comment[0].parent) {
        const parent = await getCommentsByIds([comment[0].parent]);
        if (parent.length) {
          const newParent: ForumCommentWithRepliesType = {
            ...parent[0],
            children: [comment[0]],
          };
          setParentComment(newParent);
        }
      }
      setLoadingComment(false);
    } catch (error) {
      message.error('Comentário não encontrado');
      navigate.push('/admin/forum');
    }
  };

  const changeCommentLocalStatus = (status: AnswerStatusType): void => {
    if (forumComment) {
      const newComment: ForumCommentType = {
        ...forumComment,
        status: {
          answerStatus: status,
          isFromModerator: forumComment.status.isFromModerator,
          isPublic: forumComment.status.isPublic,
        },
      };
      setForumComment(newComment);
    }
  };

  const setCommentAsIgnore = async () => {
    setIsSendingData(true);
    if (forumComment?.id) {
      try {
        await ignoreComment(forumComment.id);
        changeCommentLocalStatus('markedAsIgnored');
        setIsSendingData(false);
        message.success('Comentário ignorado com sucesso!');
      } catch (error) {
        setIsSendingData(false);
        message.error('Erro ao ignorar o comentário');
      }
    }
  };

  const setCommentAsPrivate = async () => {
    setIsSendingData(true);
    if (forumComment?.id) {
      const { isPublic } = forumComment.status;

      try {
        await privateComment(forumComment.id, !isPublic);
        if (forumComment) {
          const newCommentState: ForumCommentType = {
            ...forumComment,
            status: {
              ...forumComment.status,
              isPublic: !isPublic,
            },
          };

          setForumComment(newCommentState);

          if (parentComment) {
            setParentComment(prev => {
              if (!prev) { return prev; }

              return {
                ...prev,
                children: prev.children.map(child => {
                  if (child.id === forumComment.id) {
                    return {
                      ...child,
                      status: {
                        ...child.status,
                        isPublic: !isPublic,
                      },
                    };
                  }
                  return child;
                }),
              };
            });
          }
        }
        message.success(`Comentário ${isPublic ? 'público' : 'privado'} com sucesso!`);
      } catch (error) {
        message.error(`Erro ao ${isPublic ? 'privar' : 'tornar público'} o comentário`);
      }
    }
    setIsSendingData(false);
  };

  const reserveIgnoredComment = async (commentId: string) => {
    try {
      await reserveComment(commentId);
      changeCommentLocalStatus('waitingAnswer');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ForumCommentsContext.Provider value={{
      forumComment,
      parentComment,
      loadingComment,
      isSendingData,
      getComment,
      commentManagementController: {
        setCommentAsIgnore,
        setCommentAsPrivate,
        changeCommentLocalStatus,
        reserveIgnoredComment,
      },
    }}
    >
      {children}
    </ForumCommentsContext.Provider>
  );
}
