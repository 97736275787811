import { message } from 'antd';
import { auth } from '../client/firebase';
import { forumApi } from '../services/api';

type ResponseStatus = 'Success' | 'Error';

export interface RequestResponse<T> {
  data: T;
  status: ResponseStatus;
}

export type PaginatedList<T> = {
  currentPage: number;
  total: number;
  items: T[];
}

export interface CreateCommentRequest {
  body: string;
  parentId?: string;
  contentId: string;
  courseId: string;
}

export interface ReplyCommentAsModeratorRequest {
  body: string;
  parentId: string;
  contentId: string;
  courseId: string;
}

export type AnswerStatusType = 'waitingAnswer' | 'answered' | 'markedAsIgnored' | 'answering';

export type ContentType = 'Question' | 'Flashcard' | 'MindMap' | 'Video' | 'ParetoGuide' | 'Resources';

export type CommentAnswerStatusType = {
  answerStatus: AnswerStatusType;
  isPublic: boolean;
  isFromModerator: boolean;
}

export type UserType = {
  _id: string;
  name: string;
  externalId: string;
  userName: string;
}

export type ReservedByType = {
  expiresAt: string;
  userName: string;
  userId: string;
}

export type ForumCommentType = {
  id: string;
  user: UserType,
  parent?: string;
  level: number;
  contentId: string,
  courseId: string;
  classification: string[];
  contentType: ContentType;
  area: string;
  body: string;
  status: CommentAnswerStatusType;
  createdAt: string;
  reservedBy?: ReservedByType;
  children: string[];
}

export type ForumCommentWithRepliesType = {
  id: string;
  user: UserType,
  parent?: string;
  level: number;
  contentId: string,
  courseId: string;
  classification: string[];
  contentType: ContentType;
  area: string;
  body: string;
  status: CommentAnswerStatusType;
  createdAt: string;
  reservedBy?: ReservedByType;
  children: ForumCommentType[];
}

export type PeriodFilterType = 'FromTheOldestToTheNewest' | 'FromTheNewestToTheOldest';

export interface ListCommentByFilterRequest {
  answerStatus: AnswerStatusType,
  contentType?: ContentType;
  bigArea?: string;
  courseId?: string;
  orderBy: PeriodFilterType;
  perPage: number;
  page?: number;
}

export interface CommentsManagementHook {
  privateComment: (commentId: string, isPublic: boolean) => Promise<void>;
  ignoreComment: (commentId: string) => Promise<void>;
  reserveComment: (commentId: string) => Promise<void>;
  createComment: (createCommentRequest: CreateCommentRequest) => Promise<void>;
  replyCommentAsModerator: (replyCommentRequest: ReplyCommentAsModeratorRequest) => Promise<void>;
  getCommentsByIds: (commentId: string[]) => Promise<ForumCommentType[]>;
  listForumComments: (request: ListCommentByFilterRequest) => Promise<PaginatedList<ForumCommentType>>;
  getAvailableBigAreas: () => Promise<string[]>;
}

export default function useCommentsManagement(): CommentsManagementHook {
  const adminHost = '/v1/admin/comment';
  const forumHost = '/v1/forum';

  const privateComment = async (commentId: string, isPublic: boolean): Promise<void> => {
    const token = await auth.currentUser?.getIdToken();
    const result = await forumApi.patch<RequestResponse<void>>(`${adminHost}/${commentId}/status/is-public`, {
      isPublic,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (result.data.status !== 'Success') {
      throw Error();
    }
  };

  const ignoreComment = async (commentId: string): Promise<void> => {
    const token = await auth.currentUser?.getIdToken();

    const result = await forumApi.post<RequestResponse<void>>(`${adminHost}/${commentId}/status/ignore`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (result.data.status !== 'Success') {
      throw Error();
    }
  };

  const reserveComment = async (commentId: string): Promise<void> => {
    const token = await auth.currentUser?.getIdToken();
    const result = await forumApi.post<RequestResponse<void>>(`${adminHost}/${commentId}/reserve-comment-for/me`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (result.data.status !== 'Success') {
      throw Error();
    }
  };

  const createComment = async (createCommentRequest: CreateCommentRequest): Promise<void> => {
    const token = await auth.currentUser?.getIdToken();
    try {
      const result = await forumApi.post<RequestResponse<void>>(`${adminHost}/creation`, {
        createCommentRequest,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (result.data.status !== 'Success') {
        throw Error();
      }

      message.success('Comentário respondido com sucesso!');
    } catch (error) {
      message.error('Erro ao responder comentário!');
    }
  };

  const replyCommentAsModerator = async (replyCommentRequest: ReplyCommentAsModeratorRequest): Promise<void> => {
    const token = await auth.currentUser?.getIdToken();
    await forumApi.post<RequestResponse<void>>(`${adminHost}/${replyCommentRequest.parentId}/reply/as-moderator`,
      {
        body: replyCommentRequest.body,
        contentId: replyCommentRequest.contentId,
        courseId: replyCommentRequest.courseId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  };

  const getCommentsByIds = async (commentsIds: string[]): Promise<ForumCommentType[]> => {
    const token = await auth.currentUser?.getIdToken();
    let res: ForumCommentType[] = [];

    const result = await forumApi.get<RequestResponse<ForumCommentType[]>>(`${adminHost}/list-by-ids`, {
      params: {
        ids: commentsIds,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (result.data.status !== 'Success') {
      throw Error();
    }

    if (result) {
      res = result.data.data;
    }

    return res;
  };

  const listForumComments = async (request: ListCommentByFilterRequest): Promise<PaginatedList<ForumCommentType>> => {
    const token = await auth.currentUser?.getIdToken();
    let res: PaginatedList<ForumCommentType> = {
      currentPage: 1,
      total: 0,
      items: [],
    };

    const filters = Object.fromEntries(Object.entries(request).filter(Boolean));

    const result = await forumApi.get<RequestResponse<PaginatedList<ForumCommentType>>>(`${adminHost}/list-by-filter`, {
      params: filters,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (result.data.status !== 'Success') {
      throw Error();
    }

    if (result) {
      res = result.data.data;
    }

    return res;
  };

  const getAvailableBigAreas = async (): Promise<string[]> => {
    const token = await auth.currentUser?.getIdToken();

    let res: string[] = [];

    const result = await forumApi.get<RequestResponse<string[]>>(`${forumHost}/available-big-areas`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (result.data.status !== 'Success') {
      throw Error();
    }

    if (result) {
      res = result.data.data;
    }

    return res;
  };

  return {
    privateComment,
    ignoreComment,
    reserveComment,
    createComment,
    replyCommentAsModerator,
    getCommentsByIds,
    listForumComments,
    getAvailableBigAreas,
  };
}
