/* eslint-disable react/destructuring-assignment */
import { Image } from 'antd';
import React, { FC, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import useFirebaseStorageImg from '../hooks/useFirebaseStorageImg';

interface MarkdownProps {
  children: string;
  style?: React.CSSProperties;
  saveOnStorage?: boolean;
}

const Markdown: FC<MarkdownProps> = ({ children, style, saveOnStorage = true }) => {
  const storage = useFirebaseStorageImg();
  const getImage = useCallback((uri: string) => storage.getURL(uri), [storage]);

  return (
    <div style={style}>
      <ReactMarkdown
        linkTarget="_blank"
        transformImageUri={saveOnStorage ? getImage : undefined}
        components={{
          img: (props: any): JSX.Element => <Image width={200} src={props.src} alt={props.alt} style={props.style} />,
        }}
      >
        {children}
      </ReactMarkdown>
    </div>
  );
};

export default Markdown;
