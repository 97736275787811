import { FC } from 'react';
import { Row } from 'antd';

export const ForumCommentCardSkeleton: FC = () => {
  return (
    <Row>
      <div
        style={{
          height: '200px', width: '63%', marginRight: '16px', backgroundColor: '#f5f5f5',
        }}
      />
      <div
        style={{
          height: '80px', width: '180px', marginRight: '16px', backgroundColor: '#f5f5f5',
        }}
      />
      <div
        style={{
          height: '80px', width: '200px', marginRight: '16px', backgroundColor: '#f5f5f5',
        }}
      />
    </Row>
  );
};
