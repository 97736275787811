import React, { FC, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { Spin } from 'antd';
import PrivateRoute from './components/PrivateRoute';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import useAccount from './hooks/useAccount';

const Routes: FC = () => {
  const history = useHistory();
  const { loadingCredentials, isAuthenticated } = useAccount();

  useEffect(() => {
    const redirectPaths = [
      '/',
      '/login',
    ];
    if (isAuthenticated() && redirectPaths.indexOf(history.location.pathname) !== -1) {
      history.replace('/admin');
    }

    if (!isAuthenticated() && !loadingCredentials) {
      history.replace('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, loadingCredentials]);

  if (loadingCredentials) {
    return (
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw',
      }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <Switch>
      <Route exact path="/" component={LoginPage} />
      <Route path="/login" component={LoginPage} />
      <PrivateRoute path="/admin" component={HomePage} />
    </Switch>
  );
};

export default Routes;
