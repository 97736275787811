import {
  Typography,
  Table,
  Row,
  Col,
  Space,
} from 'antd';
import { CheckCircleFilled, MinusCircleOutlined } from '@ant-design/icons';
import { useMemo } from 'react';

const { Text } = Typography;

type TypicalWeekDay = {
  theoreticalStudy: boolean;
  theoreticalReview: boolean;
  smartReview: boolean;
  examAndMocks: boolean;
}

type TypicalWeek = {
  sunday: TypicalWeekDay;
  monday: TypicalWeekDay;
  tuesday: TypicalWeekDay;
  wednesday: TypicalWeekDay;
  thursday: TypicalWeekDay;
  friday: TypicalWeekDay;
  saturday: TypicalWeekDay;
}

type StudyWeekProps = {
  studyWeek: TypicalWeek;
}

function CheckBall({ isDay }: { isDay: boolean }) {
  return (
    <Row justify="center">
      {isDay ? (<CheckCircleFilled style={{ fontSize: '16px' }} />) : (<MinusCircleOutlined style={{ fontSize: '16px' }} />)}
    </Row>
  );
}

const columns = [
  {
    title: 'Atividades',
    dataIndex: 'type',
    key: 'type',
    render: (text: string) => <Text>{text}</Text>,
  },
  {
    title: 'Segunda',
    dataIndex: 'monday',
    key: 'monday',
    render: (isDay: boolean) => <CheckBall isDay={isDay} />,
  },
  {
    title: 'Terça',
    dataIndex: 'tuesday',
    key: 'tuesday',
    render: (isDay: boolean) => <CheckBall isDay={isDay} />,
  },
  {
    title: 'Quarta',
    dataIndex: 'wednesday',
    key: 'wednesday',
    render: (isDay: boolean) => <CheckBall isDay={isDay} />,
  },
  {
    title: 'Quinta',
    dataIndex: 'thursday',
    key: 'thursday',
    render: (isDay: boolean) => <CheckBall isDay={isDay} />,
  },
  {
    title: 'Sexta',
    dataIndex: 'friday',
    key: 'friday',
    render: (isDay: boolean) => <CheckBall isDay={isDay} />,
  },
  {
    title: 'Sábado',
    dataIndex: 'saturday',
    key: 'saturday',
    render: (isDay: boolean) => <CheckBall isDay={isDay} />,
  },
  {
    title: 'Domingo',
    dataIndex: 'sunday',
    key: 'sunday',
    render: (isDay: boolean) => <CheckBall isDay={isDay} />,
  },
];

export function StudyWeekTable({
  studyWeek,
}: StudyWeekProps) {
  const deserializedStudyWeek = useMemo(() => {
    const theoreticalStudy = Object.entries(studyWeek).map(([key, value]) => ([key, value.theoreticalStudy]));
    const theoreticalReview = Object.entries(studyWeek).map(([key, value]) => ([key, value.theoreticalReview]));
    const smartReview = Object.entries(studyWeek).map(([key, value]) => ([key, value.smartReview]));
    const examAndMocks = Object.entries(studyWeek).map(([key, value]) => ([key, value.examAndMocks]));
    return {
      theoreticalStudy: Object.fromEntries(theoreticalStudy) as Record<keyof TypicalWeek, boolean>,
      theoreticalReview: Object.fromEntries(theoreticalReview) as Record<keyof TypicalWeek, boolean>,
      smartReview: Object.fromEntries(smartReview) as Record<keyof TypicalWeek, boolean>,
      examAndMocks: Object.fromEntries(examAndMocks) as Record<keyof TypicalWeek, boolean>,
    };
  }, [studyWeek]);

  const typicalWeekData = useMemo(() => {
    return [
      {
        key: 'theoreticalStudy',
        type: 'Estudo teórico',
        ...deserializedStudyWeek.theoreticalStudy,
      },
      {
        key: 'smartReview',
        type: 'Revisão inteligente',
        ...deserializedStudyWeek.smartReview,
      },
      {
        key: 'theoreticalReview',
        type: 'Revisão teórica',
        ...deserializedStudyWeek.theoreticalReview,
      },
      {
        key: 'examAndMocks',
        type: 'Provas',
        ...deserializedStudyWeek.examAndMocks,
      },
    ];
  }, [deserializedStudyWeek]);
  return (
    <Space direction="vertical">
      <Text>Semana típica:</Text>
      <Table
        dataSource={typicalWeekData}
        columns={columns}
        pagination={false}
        size="small"
      />
    </Space>
  );
}
