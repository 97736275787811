import { gql, useQuery } from '@apollo/client';

export type Permission = {
  _id: string;
  name: string;
  courses: string[];
  description?: string;
}

export interface PermissionsQueryOutput {
  listPermissions: Permission[];
}

const PERMISSIONS_QUERY = gql`
  query ListPermissions {
    listPermissions {
      _id
      name
      courses
      description
    }
  }
`;

export function usePermissions() {
  return useQuery<PermissionsQueryOutput>(PERMISSIONS_QUERY);
}
