import { Select, SelectProps } from 'antd';
import { FC, useState } from 'react';

export const PeriodFilter: FC<{value: number, handleChange: (e: number) => void}> = ({
  value,
  handleChange,
}) => {
  const options : SelectProps['options'] = [
    {
      value: -1,
      label: <span>Mais antigos</span>,
    },
    {
      value: 1,
      label: <span>Mais novos</span>,
    },
  ];

  return (
    <Select
      style={{ width: '140px' }}
      placeholder="Período"
      value={value}
      options={options}
      onChange={handleChange}
    />
  );
};
