import React, {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Button,
  Col,
  message, Row, Space, Tooltip, Typography,
} from 'antd';
import { EyeOutlined, SaveOutlined } from '@ant-design/icons';
import {
  useMindmapDetails, useUpdateMindmapDetails,
} from '../../api/mindmaps';
import EditDataWrapper from '../../components/EditDataWrapper';
import { getParam } from '../../hooks/useSearchParam';
import FilesControl from '../../components/FilesControl';
import { ContentInLessonTag } from '../../components/ContentInLessonTag';
import useFirebaseStorage from '../../hooks/useFirebaseStorage';

interface EditMindmapPageProps { }

const { Text, Paragraph } = Typography;

const EditMindmapPage: FC<EditMindmapPageProps> = () => {
  const { getURL } = useFirebaseStorage();
  const mindmapId = getParam('id');

  const {
    data: mindmapDetailsData,
    loading: queryLoading,
    refetch: refetchMindmapDetailsQuery,
  } = useMindmapDetails(mindmapId);
  const updateMindmapDetailsMutation = useUpdateMindmapDetails();

  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [url, setUrl] = useState('');
  const [description, setDescription] = useState('');
  const [mutationLoading, setMutationLoading] = useState(false);
  const [fileUpdated, setFileUpdated] = useState(false);

  useEffect(() => {
    if (mindmapDetailsData) {
      const { mindmapDetails } = mindmapDetailsData;
      setTitle(mindmapDetails.title);
      setSubtitle(mindmapDetails.subtitle);
      setDescription(mindmapDetails.description);
      setUrl(mindmapDetails.url);
      setFileUpdated(false);
    }
  }, [mindmapDetailsData]);

  const updateDisabled = useMemo(() => {
    return (title === mindmapDetailsData?.mindmapDetails.title)
      && (subtitle === mindmapDetailsData?.mindmapDetails.subtitle)
      && (description === mindmapDetailsData?.mindmapDetails.description)
      && (url === mindmapDetailsData?.mindmapDetails.url);
  }, [description,
    mindmapDetailsData?.mindmapDetails.description,
    mindmapDetailsData?.mindmapDetails.subtitle,
    mindmapDetailsData?.mindmapDetails.title,
    mindmapDetailsData?.mindmapDetails.url,
    subtitle,
    title,
    url]);

  const handleCreateUpdateObject = useCallback(() => {
    return {
      ...(title !== mindmapDetailsData?.mindmapDetails.title && { title }),
      ...(subtitle !== mindmapDetailsData?.mindmapDetails.subtitle && { subtitle }),
      ...(description !== mindmapDetailsData?.mindmapDetails.description && { description }),
      ...(url !== mindmapDetailsData?.mindmapDetails.url && { url }),
    };
  }, [description,
    mindmapDetailsData?.mindmapDetails.description,
    mindmapDetailsData?.mindmapDetails.subtitle,
    mindmapDetailsData?.mindmapDetails.title,
    mindmapDetailsData?.mindmapDetails.url,
    subtitle,
    title,
    url]);

  const handleUpdate = useCallback(async () => {
    setMutationLoading(true);
    try {
      await updateMindmapDetailsMutation({
        _id: mindmapId,
        ...handleCreateUpdateObject(),
      });
      if (refetchMindmapDetailsQuery) {
        await refetchMindmapDetailsQuery({ _id: mindmapId });
      }
      setFileUpdated(false);
      message.success('Dados atualizados com sucesso!');
    } catch (error) {
      console.error(error);
    } finally {
      setMutationLoading(false);
    }
  }, [handleCreateUpdateObject, mindmapId, refetchMindmapDetailsQuery, updateMindmapDetailsMutation]);

  return (
    <EditDataWrapper
      title="Editar mindmaps"
      searchKey="id"
      placeholder="Pesquisar por id"
      loading={queryLoading}
    >
      {mindmapDetailsData && (
        <Space direction="vertical">
          <Row>
            Título:
            <Text
              style={{ marginLeft: '1rem', flex: 1 }}
              editable={{
                onChange: setTitle,
              }}
            >
              {title}
            </Text>
          </Row>
          <Row>
            Subtítulo:
            <Text
              style={{ marginLeft: '1rem', flex: 1 }}
              editable={{
                onChange: setSubtitle,
              }}
            >
              {subtitle}
            </Text>
          </Row>
          <Row>
            Descrição:
            <Paragraph
              style={{ marginLeft: '1rem', flex: 1, width: '100%' }}
              editable={{
                onChange: setDescription,
              }}
            >
              {description}
            </Paragraph>
          </Row>
          <Row>
            <Text>
              Arquivo:
            </Text>
            <Paragraph
              style={{ marginLeft: '1rem', flex: 1, width: '100%' }}
              editable={{
                onChange: setUrl,
              }}
            >
              {url}
            </Paragraph>
            {url && (
              <Button style={{ margin: 0 }} type="link" href={getURL(url)} target="_blank" icon={<EyeOutlined />}>
                Preview
              </Button>
            )}
          </Row>
          <ContentInLessonTag contentId={mindmapId} contentType="Mindmap" />
          <Row className="py-2">
            <Button
              type="primary"
              icon={<SaveOutlined />}
              onClick={handleUpdate}
              loading={mutationLoading}
              disabled={updateDisabled}
            >
              Salvar alterações
            </Button>
          </Row>
        </Space>
      )}
    </EditDataWrapper>
  );
};

export default EditMindmapPage;
