import React, { FC, useContext } from 'react';
import { Select, SelectProps } from 'antd';
import { ForumContext } from '../../../../contexts/Forum.context';

type CommentStatusFilterProps = {
    style?: React.CSSProperties,
}

export const CommentStatusFilter: FC <CommentStatusFilterProps> = ({
  style,
}) => {
  const { filterController } = useContext(ForumContext);

  const options : SelectProps['options'] = [
    {
      value: 'waitingAnswer',
      label: <span>Aguardando resposta</span>,
    },
    {
      value: 'markedAsIgnored',
      label: <span>Ignorado</span>,
    },
    {
      value: 'answered',
      label: <span>Respondido</span>,
    },
  ];

  return (
    <Select
      style={{ width: '180px', ...style }}
      placeholder="Status"
      options={options}
      value={filterController.activeFilters.answerStatus}
      onChange={filterController.updateCommentStatusFilter}
    />
  );
};
