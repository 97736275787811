import { FC } from 'react';
import { Col, Row, Typography } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { ContentType } from '../../../hooks/useCommentsManagement';

const { Text } = Typography;

type ForumInfoProps = {
  forumContentType: ContentType;
  area: string;
  contentId: string;
  courseId: string;
}

const getUrl = (commentType: ContentType, id: string, courseId: string) : string => {
  const baseUrl = process.env.REACT_APP_ENV === 'PROD' ? 'https://aristoclass.com.br/viewer' : 'https://jj-dev-a2b7a.web.app/viewer';

  let url;

  switch (commentType) {
    case 'Question':
      url = `${baseUrl}/questao`;
      break;
    case 'Video':
      url = `${baseUrl}/video`;
      break;
    case 'Flashcard':
      url = `${baseUrl}/flashcard`;
      break;
    default:
      url = `${baseUrl}/questao`;
  }

  return `${url}?id=${id}&courseId=${courseId}`;
};

const label = (value: ContentType) => {
  switch (value) {
    case 'Question':
      return 'Questão';
    case 'Video':
      return 'Vídeo';
    case 'Flashcard':
      return 'Flashcard';
    case 'MindMap':
      return 'Mapa-mental';
    case 'ParetoGuide':
      return 'Guia Pareto';
    case 'Resources':
      return 'Recurso';
    default:
      return 'Questão';
  }
};

const openInNewTab = (forumContentType: ContentType, id : string, courseId: string) => {
  window.open(getUrl(forumContentType, id, courseId), '_blank', 'noreferrer');
};

export const ForumInfo: FC<ForumInfoProps> = ({
  forumContentType,
  area,
  contentId,
  courseId,
}) => {
  return (
    <Col style={{ width: '220px', marginRight: '16px', marginBottom: '16px' }}>
      <Row>
        <FileTextOutlined />
        <Text style={{ marginLeft: '0.5rem' }}>{label(forumContentType)}</Text>
      </Row>
      <Row wrap={false}>
        <FontAwesomeIcon icon={faGraduationCap} />
        <Text style={{ marginLeft: '0.5rem' }}>{area}</Text>
      </Row>
      <Row>
        <FontAwesomeIcon icon={faCode} />
        <Text
          onClick={() => openInNewTab(forumContentType, contentId, courseId)}
          style={{
            marginLeft: '0.5rem',
            textDecorationLine: 'underline',
            cursor: 'pointer',
          }}
        >
          {contentId}
        </Text>
      </Row>
    </Col>
  );
};
