import { useContext } from 'react';
import {
  Button, Col, Pagination, Row,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RedoOutlined } from '@ant-design/icons';
import { ForumListTile } from './ForumListTile';
import { ForumContext } from '../../../contexts/Forum.context';
import { ContentTypeFilter } from './forum-filters/ContentTypeFilter';
import { BigAreaFilter } from './forum-filters/BigAreaFilter';
import { CommentStatusFilter } from './forum-filters/CommentStatusFilter';
import { PeriodFilter } from './forum-filters/PeriodFilter';
import { ForumListTileSkeleton } from './skeletons/ForumListTileSekeleton';
import { CoursesFilters } from './forum-filters/CoursesFilter';
import { UpdateListButton } from './UpdateListButton';

export function ForumList() {
  const {
    commentsList,
    getFiltered,
    isLoading,
    isSendingData,
    pageController,
  } = useContext(ForumContext);

  return (
    <Col style={{ maxWidth: '1300px', margin: '0 auto' }}>
      <Row justify="space-between">
        <Row style={{ marginBottom: '16px' }}>
          <PeriodFilter style={{ margin: '4px' }} />
          <CommentStatusFilter style={{ margin: '4px' }} />
          <ContentTypeFilter style={{ margin: '4px' }} />
          <BigAreaFilter style={{ margin: '4px' }} />
          <CoursesFilters style={{ margin: '4px' }} />
          <Button
            style={{ margin: '4px' }}
            loading={isLoading || isSendingData}
            onClick={getFiltered}
          >
            Filtrar
          </Button>
        </Row>
        <UpdateListButton />
      </Row>
      <Col
        style={{
          marginTop: '8px',
          padding: '16px',
          borderRadius: '16px',
          backgroundColor: '#F9F9FF',
        }}
      >
        {isLoading && <ForumListTileSkeleton />}

        {(!isLoading && (!commentsList || !commentsList.items.length)) && (
          <Row justify="center">Não existem comentários</Row>
        )}

        {(!isLoading && commentsList && !!commentsList.items.length) && commentsList.items.map((it, index) => {
          return (
            <ForumListTile
              key={it.id}
              forumComment={it}
              removeBorder={commentsList?.items.length - 1 === index}
            />
          );
        })}
      </Col>
      {commentsList && !!commentsList.items.length && (
        <Pagination
          style={{ margin: '16px 4px' }}
          simple
          pageSize={30}
          current={pageController.currentPage}
          total={commentsList.total}
          onChange={pageController.changePage}
        />
      )}
    </Col>
  );
}
