import { EyeOutlined, LoadingOutlined, SaveOutlined } from '@ant-design/icons';
import {
  message, Space, Row, Button, Typography, Select,
} from 'antd';
import {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import { usePdfDetails, useUpdatePdfDetails } from '../../api/pdfs';
import { usePermissions } from '../../api/permissions';
import EditDataWrapper from '../../components/EditDataWrapper';
import useFirebaseStorage from '../../hooks/useFirebaseStorage';
import { getParam } from '../../hooks/useSearchParam';

const { Text, Paragraph } = Typography;
const { Option } = Select;

export function EditPdfPage() {
  const { getURL } = useFirebaseStorage();
  const pdfId = getParam('id');

  const {
    data: pdfDetailsData,
    loading: queryLoading,
  } = usePdfDetails(pdfId);

  const [updatePdfDetailsMutation, { loading: loadingUpdatePdf }] = useUpdatePdfDetails();

  const {
    data: permissionData,
    loading: permissionLoading,
  } = usePermissions();

  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [category, setCategory] = useState('');
  const [url, setUrl] = useState('');
  const [permissions, setPermissions] = useState<string[]>([]);

  useEffect(() => {
    if (pdfDetailsData && !permissionLoading && !queryLoading) {
      const { aristoPlusPDF } = pdfDetailsData;
      setTitle(aristoPlusPDF.title);
      setSubtitle(aristoPlusPDF.subtitle ?? '');
      setCategory(aristoPlusPDF.category);
      setUrl(aristoPlusPDF.url);
      setPermissions(aristoPlusPDF.aristoPlus?.permissions ?? []);
    }
  }, [pdfDetailsData, permissionLoading, queryLoading]);

  const titleChanged = pdfDetailsData?.aristoPlusPDF.title !== title;
  const subtitleChanged = pdfDetailsData?.aristoPlusPDF.subtitle !== subtitle;
  const categoryChanged = pdfDetailsData?.aristoPlusPDF.category !== category;
  const urlChanged = pdfDetailsData?.aristoPlusPDF.url !== url;
  const permissionsChanged = useMemo(() => {
    const serverPermissions = pdfDetailsData?.aristoPlusPDF.aristoPlus?.permissions ?? [];
    if (serverPermissions.length !== permissions.length) {
      return true;
    }
    if (serverPermissions.filter(p => !permissions.includes(p)).length > 0) {
      return true;
    }
    if (permissions.filter(p => !serverPermissions.includes(p)).length > 0) {
      return true;
    }
    return false;
  }, [pdfDetailsData, permissions]);

  const isUpdateEnabled = titleChanged || subtitleChanged || categoryChanged || urlChanged || permissionsChanged;

  const handleUpdate = useCallback(async () => {
    try {
      await updatePdfDetailsMutation({
        variables: {
          object: {
            _id: pdfId,
            ...(titleChanged && { title }),
            ...(subtitleChanged && { subtitle }),
            ...(urlChanged && { url }),
            ...(categoryChanged && { category }),
            ...(permissionsChanged && { permissions }),
          },
        },
        refetchQueries: ['AristoPlusPDF'],
        awaitRefetchQueries: true,
      });
      message.success('Dados atualizados com sucesso!');
    } catch (error) {
      console.error(error);
      message.error('Erro ao atualizar pdf.');
    }
  }, [category,
    categoryChanged,
    pdfId,
    permissions,
    permissionsChanged,
    subtitle,
    subtitleChanged,
    title,
    titleChanged,
    updatePdfDetailsMutation,
    url,
    urlChanged]);

  return (
    <EditDataWrapper
      title="Editar mindmaps"
      searchKey="id"
      placeholder="Pesquisar por id"
      loading={queryLoading}
    >
      {pdfDetailsData && (
        <Space direction="vertical">
          <Row>
            Título:
            <Text
              style={{ marginLeft: '1rem', flex: 1 }}
              editable={{
                onChange: setTitle,
              }}
            >
              {title}
            </Text>
          </Row>
          <Row>
            Subtítulo:
            <Text
              style={{ marginLeft: '1rem', flex: 1 }}
              editable={{
                onChange: setSubtitle,
              }}
            >
              {subtitle}
            </Text>
          </Row>
          <Row>
            Categoria:
            <Paragraph
              style={{ marginLeft: '1rem', flex: 1, width: '100%' }}
              editable={{
                onChange: setCategory,
              }}
            >
              {category}
            </Paragraph>
          </Row>
          <Row>
            <Text>
              Arquivo:
            </Text>
            <Paragraph
              style={{ marginLeft: '1rem', flex: 1, width: '100%' }}
              editable={{
                onChange: setUrl,
              }}
            >
              {url}
            </Paragraph>
            {url && (
              <Button style={{ margin: 0 }} type="link" href={getURL(url)} target="_blank" icon={<EyeOutlined />}>
                Preview
              </Button>
            )}
          </Row>
          <Row align="middle">
            Visibilidade:
            {permissionLoading ? (
              <LoadingOutlined style={{ marginLeft: '1rem' }} />
            ) : (
              <Select
                placeholder="Visibilidade"
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                defaultValue={pdfDetailsData?.aristoPlusPDF.aristoPlus?.permissions ?? []}
                value={permissions}
                onChange={value => setPermissions(value)}
              >
                {permissionData && permissionData.listPermissions.map(p => (
                  <Option
                    key={p._id}
                    value={p._id}
                    title={`${p.name} - ${p.description}`}
                  >
                    {p.name}
                  </Option>
                ))}
              </Select>
            )}
          </Row>
          <Row className="py-2">
            <Button
              type="primary"
              icon={<SaveOutlined />}
              onClick={handleUpdate}
              loading={loadingUpdatePdf}
              disabled={!isUpdateEnabled}
            >
              Salvar alterações
            </Button>
          </Row>
        </Space>
      )}
    </EditDataWrapper>
  );
}
