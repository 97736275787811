import { FC } from 'react';
import { Col, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { GroupedInstitutionAppealDataObjectType } from '../../../../api/appeal';

type ResourcesSmallCardProps = {
  data: GroupedInstitutionAppealDataObjectType;
}

export const ResourcesSmallCard: FC<ResourcesSmallCardProps> = ({
  data,
}) => {
  const navigate = useHistory();

  const handleClick = (): void => {
    navigate.push(`/admin/resources/bank?institution=${data._id}`);
  };

  const color = '#001B3E';

  return (
    <Col
      style={{
        width: '160px',
        height: '140px',
        padding: '16px 8px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: 'rgba(65, 95, 145, 0.11)',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        cursor: 'pointer',
        margin: '4px',
      }}
      onClick={handleClick}
    >
      <div>
        <Typography.Title
          ellipsis={{ rows: 2 }}
          style={{
            fontSize: '16px',
            fontWeight: 500,
            color,
          }}
        >
          {`${data.institution} - ${data.uf}`}
        </Typography.Title>
        <Typography.Paragraph
          ellipsis={{ rows: 2 }}
          style={{
            fontSize: '12px',
            fontWeight: 500,
            color,
          }}
        >
          {data.title}
        </Typography.Paragraph>
      </div>
      <Typography.Title
        ellipsis={{ rows: 1 }}
        style={{ fontSize: '14px', color }}
      >
        {`${data.appealsCount} recursos`}
      </Typography.Title>
    </Col>
  );
};
