import { gql, QueryResult, useQuery } from '@apollo/client';
import { Video } from './videos';

interface VideosJJPlusCategoriesOutput {
  categories: string[];
}

const VIDEOS_JJ_PLUS_CATEGORIES = gql`
  query JJPlusCategories {
    categories: jjPlusCategories
  }
`;

export function useJJPlusCategories(): QueryResult<VideosJJPlusCategoriesOutput> {
  return useQuery<VideosJJPlusCategoriesOutput>(VIDEOS_JJ_PLUS_CATEGORIES);
}

interface VideosJJPlusPlaylistsOutput {
  playlists: string[];
}

const VIDEOS_JJ_PLUS_PLAYLISTS = gql`
  query JJPlusPlaylists {
    playlists: jjPlusPlaylists
  }
`;

export function useJJPlusPlaylists(): QueryResult<VideosJJPlusPlaylistsOutput> {
  return useQuery<VideosJJPlusPlaylistsOutput>(VIDEOS_JJ_PLUS_PLAYLISTS);
}

interface VideosJJPlusManyOutput {
  jjPlusVideos: Video[];
}

const VIDEOS_JJ_PLUS = gql`
  query JJPlusVideos {
    jjPlusVideos {
      _id
      url
      title
      subtitle
      jjPlus {
        category
        playlist
        permissions
      }
      createdAt
     }
  }
`;

export function useVideosJJPlus(): QueryResult<VideosJJPlusManyOutput> {
  return useQuery<VideosJJPlusManyOutput>(VIDEOS_JJ_PLUS);
}
