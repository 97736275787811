/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FC, useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Select, SelectProps, message,
} from 'antd';
import { ForumContext } from '../../../../contexts/Forum.context';
import useCommentsManagement from '../../../../hooks/useCommentsManagement';

type BigAreaFilterProps = {
    style?: React.CSSProperties,
}

export const BigAreaFilter: FC <BigAreaFilterProps> = ({
  style,
}) => {
  const { filterController } = useContext(ForumContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { getAvailableBigAreas } = useCommentsManagement();
  const [filters, setFilters] = useState<string[]>([]);

  const load = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await getAvailableBigAreas();
      setFilters(res);
    } catch (error) {
      message.error('Erro ao buscar filtro de grande área');
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    load();
  }, []);

  const options : SelectProps['options'] = filters.map((it, _) => {
    return {
      value: it,
      label: <span>{it}</span>,
    };
  });

  options.unshift({
    value: null,
    label: 'Todas',
  });

  return (
    <Select
      style={{ width: '200px', ...style }}
      placeholder="Grande área"
      options={options}
      loading={isLoading}
      onChange={filterController.updateBigAreaFilter}
    />
  );
};
