import React, {
  FC, useCallback, useContext, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  Layout, Form, Input, Button, Row, Card, Alert, message,
} from 'antd';
import useAccount from '../hooks/useAccount';
import { GlobalContext } from '../contexts/Global.context';

const { Header, Content } = Layout;

const LoginPage: FC = () => {
  const [formRef] = Form.useForm();
  const [visibleError, setVisibleError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const account = useAccount();
  const history = useHistory();
  const { getGlobalProfile } = useContext(GlobalContext);

  const handleLogin = useCallback(async data => {
    setLoading(true);
    try {
      const res = await account.loginWithEmail(data.email, data.password);
      if (res === 'failed') {
        message.error('Ocorreu um erro inesperado');
        return;
      }
      getGlobalProfile();
      if (account.isAuthenticated()) {
        setLoading(false);
        history.replace('/admin');
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setVisibleError(true);
      setErrorMessage(error.message);
    }
  }, [account, getGlobalProfile, history]);

  return (
    <Layout className="full-height">
      <Header className="header" />
      <Content>
        <Row align="middle" justify="center" className="h100">
          <Card title="JJ Mentoria - Painel administrativo">
            <Form
              form={formRef}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              layout="vertical"
              onFinish={handleLogin}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Digite seu email!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Senha"
                name="password"
                rules={[{ required: true, message: 'Digite sua senha!' }]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={loading}
                >
                  Entrar
                </Button>
              </Form.Item>
              {visibleError && (
                <Alert
                  type="error"
                  message={errorMessage}
                  closable
                  afterClose={(): void => setVisibleError(false)}
                />
              )}
            </Form>
          </Card>
        </Row>
      </Content>
    </Layout>
  );
};

export default LoginPage;
