import { gql, useMutation } from '@apollo/client';

const CREATE_ACCOUNT_BY_ADMIN_PANEL = gql`
  mutation CreateAccount (
    $email: String!,
    $password:  String!,
    $name:  String!,
  ) {
    createAccountByAdminPanel (
      email: $email,
      password: $password,
      name: $name,
    ){
      uid
    }
  }
`;

interface CreateAccountInput {
  email: string;
  password: string;
  name: string;
}

interface CreateAccountOutput {
  uid: string;
}

export function useCreateAccount() {
  return useMutation<
    CreateAccountOutput, CreateAccountInput
  >(CREATE_ACCOUNT_BY_ADMIN_PANEL);
}
