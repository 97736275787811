import {
  FC, ReactNode, useCallback, useState,
} from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
  Input,
  message,
  PageHeader,
  PageHeaderProps,
  Layout,
  Button,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { ButtonType } from 'antd/lib/button';
import { PlusOutlined } from '@ant-design/icons';
import { SearchProps } from 'antd/lib/input';
import { setParam } from '../hooks/useSearchParam';

interface EditDataWrapperProps extends SearchProps, PageHeaderProps {
  title: string;
  searchKey?: string;
  sideButton?: ReactNode,
}

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

const EditDataWrapper: FC<EditDataWrapperProps> = ({
  children, placeholder, loading, title, searchKey, sideButton,
}) => {
  const [searchValue, setSearchValue] = useState((new URLSearchParams(window.location.search)).get(searchKey || '') || '');
  const history = useHistory();
  const handleSearch = useCallback(() => {
    try {
      if (searchValue) {
        history.replace(setParam(searchValue, searchKey || ''));
      } else {
        message.error('Campo inválido!');
      }
    } catch (error) {
      console.error(error);
      message.error(error.message);
    }
  }, [history, searchKey, searchValue]);

  return (
    <div style={{
      maxWidth: '1120px', margin: '0 auto',
    }}
    >
      <PageHeader
        title={title}
        onBack={() => history.goBack()}
      />
      <Content
        style={{
          minHeight: '500px',
        }}
      >
        {searchKey && (
          <>
            <Row>
              <Col span={10} md={8}>
                <Title level={5}>{`Buscar por ${searchKey}`}</Title>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={15} md={8}>
                <Search
                  placeholder={placeholder}
                  onChange={e => setSearchValue(e.target.value)}
                  onSearch={handleSearch}
                  value={searchValue}
                  loading={loading}
                />
              </Col>
              {sideButton && (
                <Col>
                  {sideButton}
                </Col>
              )}
            </Row>
            <Divider />
          </>
        )}
        {children}
      </Content>
    </div>
  );
};

export default EditDataWrapper;
