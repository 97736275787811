import React, {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import moment, { Moment } from 'moment';
import {
  Button,
  DatePicker,
  Divider,
  Input,
  message, Row, Select, Space, Spin, Switch, Typography,
} from 'antd';
import { LoadingOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import {
  useVideoDetails, useUpdateVideoDetails,
} from '../../api/videos';
import EditDataWrapper from '../../components/EditDataWrapper';
import { getParam } from '../../hooks/useSearchParam';
import PrivateComponent from '../../components/PrivateComponent';
import { useJJPlusCategories, useJJPlusPlaylists } from '../../api/jjplus';
import { usePermissions } from '../../api/permissions';
import { ContentInLessonTag } from '../../components/ContentInLessonTag';

interface EditVideoPageProps { }

const { Text, Link } = Typography;
const { Option } = Select;

const EditVideoPage: FC<EditVideoPageProps> = () => {
  const videoId = getParam('id');

  const {
    data: videoDetailsData,
    loading: queryLoading,
    refetch: refetchVideoDetailsQuery,
  } = useVideoDetails(videoId);
  const updateVideoDetailsMutation = useUpdateVideoDetails();
  const {
    data: categoriesData,
    loading: categoriesLoading,
  } = useJJPlusCategories();
  const {
    data: playlistsData,
    loading: playlistsLoading,
  } = useJJPlusPlaylists();
  const {
    data: permissionData,
    loading: permissionLoading,
  } = usePermissions();

  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [url, setUrl] = useState('');
  const [liveChatLink, setliveChatLink] = useState<string>();
  const [liveStartsAt, setLiveStartsAt] = useState<Date>();
  const [isJJPlus, setIsJJPlus] = useState(false);
  const [category, setCategory] = useState('');
  const [playlist, setPlaylist] = useState('');
  const [permissions, setPermissions] = useState<string[]>([]);

  const [categoriesList, setCategoriesList] = useState<string[]>([]);
  const [playlistList, setPlaylistList] = useState<string[]>([]);

  const [mutationLoading, setMutationLoading] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [newPlaylist, setNewPlaylist] = useState('');

  useEffect(() => {
    if (videoDetailsData && !queryLoading && !permissionLoading) {
      const { videoDetails } = videoDetailsData;
      setTitle(videoDetails.title);
      setSubtitle(videoDetails.subtitle);
      setUrl(videoDetails.url);
      setliveChatLink(videoDetails.liveChatLink);
      setLiveStartsAt(videoDetails.liveStartsAt);
      setIsJJPlus(Boolean(videoDetails.jjPlus));
      setCategory(videoDetails.jjPlus?.category ?? '');
      setPlaylist(videoDetails.jjPlus?.playlist ?? '');
      setPermissions(videoDetails.jjPlus?.permissions ?? []);
    }
  }, [permissionLoading, queryLoading, videoDetailsData]);

  useEffect(() => {
    if (categoriesData) {
      setCategoriesList(categoriesData.categories);
    }
  }, [categoriesData]);
  useEffect(() => {
    if (playlistsData) {
      setPlaylistList(playlistsData.playlists);
    }
  }, [playlistsData]);

  const updateDisabled = useMemo(() => {
    if (!videoDetailsData) {
      return false;
    }
    const { videoDetails } = videoDetailsData;
    const titleChanged = title !== videoDetails.title;
    const subtitleChanged = subtitle !== videoDetails.subtitle;
    const urlChanged = url !== videoDetails.url;
    const liveChatLinkChanged = liveChatLink !== videoDetails.liveChatLink;
    const liveStartsAtChanged = liveStartsAt !== videoDetails.liveStartsAt;
    const isJJPlusChanged = isJJPlus !== Boolean(videoDetails.jjPlus);
    const categoryChanged = category !== videoDetails.jjPlus?.category;
    const playlistChanged = playlist !== videoDetails.jjPlus?.playlist;
    const permissionsChanged = !(videoDetails.jjPlus?.permissions.reduce((r, p) => r && permissions.includes(p), true)
      && permissions.reduce((r, p) => r && Boolean(videoDetails.jjPlus?.permissions.includes(p)), true));
    const updateEnabled = titleChanged
      || subtitleChanged
      || urlChanged
      || isJJPlusChanged
      || liveChatLinkChanged
      || liveStartsAtChanged
      || (isJJPlus ? categoryChanged || playlistChanged || permissionsChanged : false);
    return !updateEnabled;
  }, [videoDetailsData, title, subtitle, url, liveChatLink, liveStartsAt, isJJPlus, category, playlist, permissions]);

  const handleUpdateObject = useCallback(() => {
    if (!videoDetailsData) {
      return {};
    }
    const { videoDetails } = videoDetailsData;
    const titleChanged = title !== videoDetails.title;
    const subtitleChanged = subtitle !== videoDetails.subtitle;
    const urlChanged = url !== videoDetails.url;
    const liveChatLinkChanged = liveChatLink !== videoDetails.liveChatLink;
    const liveStartsAtChanged = liveStartsAt !== videoDetails.liveStartsAt;
    const isJJPlusChanged = isJJPlus !== Boolean(videoDetails.jjPlus);
    const categoryChanged = category !== videoDetails.jjPlus?.category;
    const playlistChanged = playlist !== videoDetails.jjPlus?.playlist;
    const permissionsChanged = !(videoDetails.jjPlus?.permissions.reduce((r, p) => r && permissions.includes(p), true)
      && permissions.reduce((r, p) => r && Boolean(videoDetails.jjPlus?.permissions.includes(p)), true));
    const obj = {
      ...(titleChanged && { title }),
      ...(subtitleChanged && { subtitle }),
      ...(urlChanged && { url }),
      ...(liveChatLinkChanged && { liveChatLink }),
      ...(liveStartsAtChanged && { liveStartsAt }),
    } as Record<string, any>;
    if (isJJPlusChanged && !isJJPlus) {
      obj.jjPlus = {
        permissions: [],
        category: '',
        playlist: '',
      };
    }
    if (isJJPlus && (permissionsChanged || categoryChanged || playlistChanged)) {
      obj.jjPlus = {
        permissions,
        category,
        playlist,
      };
    }
    return obj;
  }, [videoDetailsData, title, subtitle, url, liveChatLink, liveStartsAt, isJJPlus, category, playlist, permissions]);

  const handleUpdate = useCallback(async () => {
    const updateObject = handleUpdateObject();
    if (Object.keys(updateObject).length === 0) {
      message.error('Nenhuma alteração realizada.');
      return;
    }
    if (updateObject.jjPlus && (updateObject.jjPlus.permissions.length === 0 || !updateObject.jjPlus.category || !updateObject.jjPlus.playlist)) {
      message.error('Dados JJ+ não preenchidos');
      return;
    }
    setMutationLoading(true);
    try {
      await updateVideoDetailsMutation({
        _id: videoId,
        ...updateObject,
      });
      if (refetchVideoDetailsQuery) {
        await refetchVideoDetailsQuery({ _id: videoId });
      }
      message.success('Dados salvos com sucesso.');
    } catch (error) {
      console.error(error);
    } finally {
      setMutationLoading(false);
    }
  }, [handleUpdateObject, videoId, refetchVideoDetailsQuery, updateVideoDetailsMutation]);

  return (
    <EditDataWrapper
      title="Editar videos"
      searchKey="id"
      placeholder="Pesquisar por id"
      loading={queryLoading}
    >
      {videoDetailsData && (
        <Space direction="vertical">
          <Row>
            Título:
            <Text
              style={{ marginLeft: '1rem', flex: 1 }}
              editable={{
                onChange: setTitle,
              }}
            >
              {title}
            </Text>
          </Row>
          <Row>
            Subtítulo:
            <Text
              style={{ marginLeft: '1rem', flex: 1 }}
              editable={{
                onChange: setSubtitle,
              }}
            >
              {subtitle}
            </Text>
          </Row>
          <Row>
            Link da Aula:
            <Link
              style={{ marginLeft: '1rem', flex: 1 }}
              editable={{
                onChange: setUrl,
              }}
              href={url}
              target="_blank"
            >
              {url}
            </Link>
          </Row>
          <Row>
            Link do Chat:
            <Link
              style={{ marginLeft: '1rem', flex: 1 }}
              editable={{
                onChange: setliveChatLink,
              }}
              href={liveChatLink}
              target="_blank"
            >
              {liveChatLink}
            </Link>
          </Row>
          <Row>
            Horário da Live
            <DatePicker
              style={{ marginLeft: 10 }}
              format="DD-MM-YYYY HH:mm:ss"
              allowClear={false}
              defaultValue={
                videoDetailsData.videoDetails.liveStartsAt
                  ? moment(videoDetailsData.videoDetails.liveStartsAt)
                  : undefined
              }
              value={liveStartsAt ? moment(liveStartsAt) : undefined}
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              onChange={e => setLiveStartsAt(e ? e.toDate() : undefined)}
            />
          </Row>
          <Row>
            JJ+:
            <Switch checked={isJJPlus} onChange={checked => setIsJJPlus(checked)} style={{ marginLeft: '1rem' }} />
          </Row>
          {isJJPlus && (
            <>
              <Row align="middle">
                Categoria:
                {categoriesLoading ? (
                  <LoadingOutlined style={{ marginLeft: '1rem' }} />
                ) : (
                  <Select
                    style={{ width: 240, marginLeft: '1rem' }}
                    placeholder="Categoria"
                    onChange={value => setCategory(value)}
                    defaultValue={category}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <PrivateComponent clearences={['admin']}>
                          <Divider style={{ margin: '4px 0' }} />
                          <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                            <Input style={{ flex: 'auto' }} value={newCategory} onChange={event => setNewCategory(event.target.value)} />
                            <Button
                              type="link"
                              onClick={() => {
                                setCategoriesList(prev => [...prev, newCategory]);
                                setNewCategory('');
                              }}
                            >
                              <PlusOutlined />
                              {' '}
                              Adicionar
                            </Button>
                          </div>
                        </PrivateComponent>
                      </div>
                    )}
                  >
                    {categoriesList && categoriesList.map(c => <Option key={c} value={c}>{c}</Option>)}
                  </Select>
                )}
              </Row>
              <Row align="middle">
                Playlist:
                {playlistsLoading ? (
                  <LoadingOutlined style={{ marginLeft: '1rem' }} />
                ) : (
                  <Select
                    style={{ width: 240, marginLeft: '1rem' }}
                    placeholder="Playlist"
                    onChange={value => setPlaylist(value)}
                    defaultValue={playlist}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <PrivateComponent clearences={['admin']}>
                          <Divider style={{ margin: '4px 0' }} />
                          <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                            <Input style={{ flex: 'auto' }} value={newPlaylist} onChange={event => setNewPlaylist(event.target.value)} />
                            <Button
                              type="link"
                              onClick={() => {
                                setPlaylistList(prev => [...prev, newPlaylist]);
                                setNewPlaylist('');
                              }}
                            >
                              <PlusOutlined />
                              {' '}
                              Adicionar
                            </Button>
                          </div>
                        </PrivateComponent>
                      </div>
                    )}
                  >
                    {playlistList && playlistList.map(p => <Option key={p} value={p}>{p}</Option>)}
                  </Select>
                )}
              </Row>
              <Row align="middle">
                Visibilidade:
                {permissionLoading ? (
                  <LoadingOutlined style={{ marginLeft: '1rem' }} />
                ) : (
                  <Select
                    placeholder="Visibilidade"
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    defaultValue={videoDetailsData?.videoDetails.jjPlus?.permissions ?? []}
                    value={permissions}
                    onChange={value => setPermissions(value)}
                  >
                    {permissionData && permissionData.listPermissions.map(p => (
                      <Option
                        key={p._id}
                        value={p._id}
                        title={`${p.name} - ${p.description}`}
                      >
                        {p.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Row>
            </>
          )}
          <ContentInLessonTag contentId={videoId} contentType="Video" />
          <Row className="py-1">
            <Button
              type="primary"
              icon={<SaveOutlined />}
              onClick={handleUpdate}
              loading={mutationLoading}
              disabled={updateDisabled}
            >
              Salvar alterações
            </Button>
          </Row>
        </Space>
      )}
    </EditDataWrapper>
  );
};

export default EditVideoPage;
