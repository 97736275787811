import React, {
  createContext, FC, useCallback, useEffect,
} from 'react';
import { message } from 'antd';
import { ApolloError, QueryLazyOptions } from '@apollo/client';
import { ProfileOutput, useLazyProfile } from '../api/profile';

interface GlobalContextInterface {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getGlobalProfile: (options?: QueryLazyOptions<Record<string, any>> | undefined) => void;
  profile: { data?: ProfileOutput; loading: boolean, called: boolean, error?: ApolloError };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetchProfile: (force?: any) => void;
}

const defaultData = {
  getGlobalProfile: () => undefined,
  profile: {
    data: undefined, loading: false, called: false, error: undefined,
  },
  refetchProfile: () => undefined,
};

export const GlobalContext = createContext<GlobalContextInterface>(defaultData);

export const GlobalStorage: FC = ({ children }) => {
  const [getGlobalProfile, {
    error: profileError, data, loading, called,
  }] = useLazyProfile();

  const refetchProfile = useCallback((force = false) => {
    if ((!called && !loading) || force) {
      getGlobalProfile();
    }
  }, [called, getGlobalProfile, loading]);

  useEffect(() => {
    if (profileError) {
      getGlobalProfile();
    }
  }, [profileError, getGlobalProfile]);

  return (
    <GlobalContext.Provider
      value={{
        getGlobalProfile,
        profile: {
          data, loading, called, error: profileError,
        },
        refetchProfile,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
