import {
  useEffect,
  useMemo,
  useCallback,
  useState,
} from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  Button, Modal, Row, Select, Spin,
} from 'antd';
import { useCoursesManyInfo } from '../../../api/courses';
import { Profile, useManageUserCourse, useProfileByEmail } from '../../../api/profile';

type AddUserCourseProps = {
  user: Profile;
}

const { Option } = Select;

export function AddUserCourse({
  user,
}: AddUserCourseProps) {
  const [coursesManyInfoQuery, {
    data,
    loading,
  }] = useCoursesManyInfo();
  const {
    loading: queryLoadingProfile,
    refetch: refetchUserProfileByEmail,
  } = useProfileByEmail(user.email);
  const { handleAddCourseToUser } = useManageUserCourse();
  const [modalVisible, setModalVisible] = useState(false);
  const [courseToAdd, setCourseToAdd] = useState('');
  const [mutationLoading, setMutationLoading] = useState(false);

  useEffect(() => {
    if (modalVisible) {
      coursesManyInfoQuery({
        variables: {
          ids: [],
        },
      });
    }
  }, [coursesManyInfoQuery, modalVisible]);

  const courses = useMemo(() => {
    if (data && !loading) {
      return data.coursesManyInfo;
    }
    return [];
  }, [data, loading]);

  const handleModalOk = useCallback(async (courseId: string) => {
    if (!user || !courseToAdd) {
      return;
    }
    try {
      setMutationLoading(true);
      await handleAddCourseToUser({
        profileId: user._id,
        courseId,
      });
      if (refetchUserProfileByEmail && !queryLoadingProfile) {
        await refetchUserProfileByEmail({ email: user.email });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setMutationLoading(false);
      setModalVisible(false);
    }
  }, [courseToAdd, handleAddCourseToUser, queryLoadingProfile, refetchUserProfileByEmail, user]);

  const filterOption = (input: string, option?: { label: string; value: string | null }) => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  };

  const options: { label: string; value: string | null }[] = courses.map((it, _) => {
    return {
      value: it._id,
      label: it.title,
    };
  });

  return (
    <>
      <Button
        type="dashed"
        icon={<PlusOutlined />}
        onClick={() => setModalVisible(true)}
      >
        Adicionar curso
      </Button>
      <Modal
        title="Selecione o curso que deseja adicionar"
        visible={modalVisible}
        onOk={() => handleModalOk(courseToAdd)}
        okText="Adicionar"
        cancelText="Cancelar"
        confirmLoading={mutationLoading}
        onCancel={() => setModalVisible(false)}
      >
        <Row justify="center">
          {loading && <Spin />}
          {courses.length > 0 && (
            <Select
              showSearch
              filterOption={filterOption}
              defaultValue={courseToAdd}
              options={options}
              onChange={v => setCourseToAdd(String(v))}
              style={{ width: '100%' }}
              placeholder="Selecione..."
            />
          )}
        </Row>
      </Modal>
    </>
  );
}
