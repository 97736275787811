import { message } from 'antd';
import { Profile } from '../api/profile';

// eslint-disable-next-line import/prefer-default-export
export function hasPrivilege({ roles }: Profile, clearences: string[]): boolean {
  const hasClearence = clearences.reduce((isCleared, clearence) => roles?.includes(clearence) || isCleared, false);
  if (roles && hasClearence) {
    return true;
  }
  return false;
}

export function hasAnyPrivilege({ roles }: Profile): boolean {
  if (roles && roles.length > 0) {
    return true;
  }
  return false;
}
