import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useCallback } from 'react';

// export interface MockExamTemplate {
//   template: string;
//   title: string;
// }

export type MockExamTemplateType = {
  _id: string,
  title: string,
  subtitle: string,
  startsAt: string,
  questions: string[],
  finishesAt: string,
  timeToDo: number,
  answersAt: string,
  rankingAt: string,

}

interface GetMockExamTemplatesByIdInput {
  ids?: string[];
}

interface GetMockExamTemplatesByIdOutput {
  getMockExamTemplatesById: MockExamTemplateType[];
}

const GET_MOCK_EXAM_TEMPLATES_BY_ID = gql`
  query GetMockExamTemplatesById (
    $ids: [ObjectId!]
  ) {
    getMockExamTemplatesById (
      ids: $ids
    ) {
      _id
      title
      subtitle
      startsAt
      questions
      finishesAt
      timeToDo
      answersAt
      rankingAt
    }
  }
`;

export function useGetMockExamTemplatesById(ids?: string[]) {
  return useQuery<GetMockExamTemplatesByIdOutput, GetMockExamTemplatesByIdInput>(GET_MOCK_EXAM_TEMPLATES_BY_ID, {
    variables: {
      ids,
    },
  });
}
