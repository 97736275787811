import {
  Row, Col, Typography, Select,
  SelectProps,
  DatePicker,
  DatePickerProps,
} from 'antd';
import { FC, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { FrequencyCard } from './components/FrequencyCard';
import { InstitutionsCard } from './components/InstitutionsCard';
import { TrophiesCard } from './components/TrophiesCard';
import { WeekActivities } from './components/week-activities/WeekActivities';
import { TypicalWeekCard } from './components/TypicalWeek';
import { Profile } from '../../api/profile';
import { useCoursesManyInfo } from '../../api/courses';

const sectionTitleStyle = {
  fontSize: '28px',
  fontWeight: 400,
  margin: '20px 0px',
};

export const PerformanceDashboard: FC<{profile: Profile}> = ({
  profile,
}) => {
  const [courseId, setCourseId] = useState(profile.courses.length ? profile.courses[0].course : '');

  const [coursesManyInfoquery, { data: coursesManyInfoData }] = useCoursesManyInfo();

  useEffect(() => {
    coursesManyInfoquery({
      variables: {
        ids: profile.courses.map(it => it.course),
      },
    });
  }, [coursesManyInfoquery, profile.courses]);

  const profileId = profile._id;

  const [startDate, setStartDate] = useState<Moment>(moment().startOf('month'));
  const [endDate, setEndDate] = useState<Moment>(moment());

  const options: SelectProps['options'] = profile.courses.map(it => {
    const course = coursesManyInfoData?.coursesManyInfo.find(e => e._id === it.course);

    return {
      value: course?._id,
      label: course?.title ?? '',
    };
  });

  const handleStart: DatePickerProps['onChange'] = date => {
    if (date) {
      setStartDate(date);
      if (date.isSameOrAfter(endDate)) {
        setEndDate(date.clone().add(1, 'months').endOf('month'));
      }
    }
  };

  const handleEnd: DatePickerProps['onChange'] = date => {
    if (date && date.isAfter(startDate)) {
      setEndDate(date);
    }
  };

  return (
    <>
      <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Select
          style={{ width: '300px', margin: '16px 0px' }}
          placeholder="Curso"
          value={courseId}
          options={options}
          onChange={value => setCourseId(value)}
        />
        <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
          <DatePicker
            style={{ margin: '0px 4px' }}
            value={startDate || null}
            onChange={handleStart}
          />
          <DatePicker
            style={{ margin: '0px 4px' }}
            value={endDate || null}
            onChange={handleEnd}
          />
        </Row>
      </Row>
      {profileId.length && courseId.length ? (
        <>
          <Typography style={sectionTitleStyle}>Objetivos e constância</Typography>
          <Row>
            <Col style={{ flexGrow: 1, padding: '6px' }}>
              <FrequencyCard profileId={profileId} courseId={courseId} />
              <div style={{ height: '12px' }} />
              <TypicalWeekCard profileId={profileId} courseId={courseId} />
            </Col>
            <Col style={{ flexGrow: 1, padding: '6px' }}>
              <InstitutionsCard profileId={profileId} courseId={courseId} />
              <div style={{ height: '12px' }} />
              <TrophiesCard profileId={profileId} courseId={courseId} />
            </Col>
          </Row>
          <Typography style={sectionTitleStyle}>Desempenho do aluno</Typography>
          <WeekActivities
            key={`${startDate.toDate().toISOString()}`}
            profileId={profileId}
            courseId={courseId}
            startDate={startDate.toDate()}
            endDate={endDate.toDate()}
          />
        </>
      ) : (<></>)}
    </>
  );
};
