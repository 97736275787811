import moment from 'moment';

export const getMomentObjectFromInterval = (interval?: number) => {
  if (interval) {
    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0);
    const targetTimeStamp = todayStart.getTime() + interval;
    return moment(new Date(targetTimeStamp));
  } return undefined;
};

export const getIntervalFromMomentObject = (momentObject: moment.Moment) => {
  const momentCopy = momentObject.clone();
  const startOfTargetDay = momentCopy.startOf('date');
  const diff = momentObject.diff(startOfTargetDay);
  return diff;
};
