import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import notificationClient from '../client/notifications-apollo';

interface FindBroadcastsInput {
  filter: {
    title?: string;
    body?: {
      authorName?: string;
      message: string;
    };
    link?: string;
    dueDateGte?: Date;
    dueDateLte?: Date;
    topics?: string[];
  }
}

interface FindBroadcastsOutput {
  findBroadcasts: {
    _id: string;
    authorPicture?: string;
    title: string;
    body: {
      authorName?: string;
      message: string;
    };
    link?: string;
    thumbnail?: string;
    dueDate: Date;
    topics: string[];
    createdAt?: Date;
  }[];
}

const FIND_BROADCASTS = gql`
  query FindBroadcasts($filter: BroadcastFilter!) {
    findBroadcasts(filter: $filter) {
      _id
      authorPicture
      title
      body {
        authorName
        message
      }
      link
      thumbnail
      dueDate
      topics
      createdAt
    }
  }
`;

interface CreateBroadcastInput {
  broadcast: {
    authorPicture?: string;
    title: string;
    body: {
      authorName?: string;
      message: string;
    };
    link?: string;
    thumbnail?: string;
    dueDate: Date;
    topics: string[];
  }
}

interface CreateBroadcastOutput {
  createBroadcast: string;
}

const CREAT_BROADCAST_NOTIFICATION = gql`
  mutation CreateBroadcast($broadcast: BroadcastInputType!) {
    createBroadcast(broadcast: $broadcast)
  }
`;

export function useBroadcastNotifications() {
  const findBroadcasts = useLazyQuery<FindBroadcastsOutput, FindBroadcastsInput>(FIND_BROADCASTS, {
    client: notificationClient,
  });
  const [createBroadcastMutation] = useMutation<CreateBroadcastOutput, CreateBroadcastInput>(CREAT_BROADCAST_NOTIFICATION, {
    client: notificationClient,
  });

  const handleCreateBroadcastNotification = useCallback(async (broadcast: CreateBroadcastInput['broadcast']) => {
    const res = await createBroadcastMutation({
      variables: {
        broadcast,
      },
    });
    return res.data?.createBroadcast;
  }, [createBroadcastMutation]);

  return {
    findBroadcasts,
    handleCreateBroadcastNotification,
  };
}
