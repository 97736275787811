import { Select, SelectProps } from 'antd';
import { FC, useState } from 'react';

export const StatusFilter: FC<{value?: string, handleChange: (e?: string) => void }> = ({
  value,
  handleChange,
}) => {
  const options : SelectProps['options'] = [
    {
      value: 'pending',
      label: <span>Recebido</span>,
    },
    {
      value: 'canceled',
      label: <span>Cancelado</span>,
    },
    {
      value: 'finished',
      label: <span>Respondido</span>,
    },
  ];

  return (
    <Select
      style={{ width: '140px' }}
      placeholder="Status"
      value={value}
      options={options}
      onChange={handleChange}
      allowClear
    />
  );
};
