import React, {
  FC, useCallback,
} from 'react';
import {
  Col, Typography,
} from 'antd';
import { ApolloQueryResult } from '@apollo/client';
import { Comment, QuestionDetailsInput, QuestionDetailsOutput } from '../../../api/question';
import { useUpdateQuestionComment } from '../../../api/comment';
import EditableMarkdown from '../../../components/EditableMarkdown';

interface EditCommentProps {
  comment?: Comment;
  questionId: string;
  refetchQuestionDetalisQuery?: ((variables?: Partial<QuestionDetailsInput> | undefined)
    => Promise<ApolloQueryResult<QuestionDetailsOutput>>) | undefined;
}

const { Title } = Typography;

const EditComment: FC<EditCommentProps> = ({
  comment, questionId, refetchQuestionDetalisQuery,
}) => {
  const updateQuestionCommentMutation = useUpdateQuestionComment();

  const handleUpdate = useCallback(async (commentData: string) => {
    await updateQuestionCommentMutation({
      _id: questionId,
      comment: commentData,
    });
    if (refetchQuestionDetalisQuery) {
      await refetchQuestionDetalisQuery({ _id: questionId });
    }
  }, [questionId, refetchQuestionDetalisQuery, updateQuestionCommentMutation]);

  return (
    <>
      <Col>
        <Title level={3}>
          Comentário
        </Title>
        <EditableMarkdown
          allowMarkdownShortcuts
          contentId={questionId}
          imagesStorageInfo={{ imagesFolder: 'images/comments' }}
          onSave={handleUpdate}
        >
          {comment?.body || ''}
        </EditableMarkdown>
      </Col>
    </>
  );
};

export default EditComment;
