import { gql, QueryResult, useQuery } from '@apollo/client';

export interface InstitutionExam {
  year: number;
  template: string;
  complement: string;
  examType: string;
}
export interface Institution {
  name: string;
  exams: InstitutionExam[];
}
export interface InstitutionList {
  uf: string;
  institutions: Institution[];
}

interface GetInstitutionListOutput {
  getInstitutionListAdmin: InstitutionList[];
}

const GET_INSTITUTION_LIST = gql`
  query GetInstitutionListAdmin {
    getInstitutionListAdmin {
      uf
      institutions {
        name
        exams {
          year
          template
        }
      }
    }
  }
`;

export function useGetInstitutionList(): QueryResult<GetInstitutionListOutput> {
  return useQuery<GetInstitutionListOutput>(GET_INSTITUTION_LIST);
}
