import { FC } from 'react';
import { Col, Row, Typography } from 'antd';
import { StudentAnswersGroupedByWeekObjectType } from '../../../../api/user-dashboard';

type WeekBarDisplayProps = {
  data: StudentAnswersGroupedByWeekObjectType[];
  action: (value: number) => void;
  selectedBar: number;
}

export const WeekBarDisplay: FC<WeekBarDisplayProps> = ({
  data,
  action,
  selectedBar,
}) => {
  const maxTotal = Math.max(...data.map(d => d.totalQuestions));

  const sortedData = [...data].sort((a, b) => a.week - b.week);

  return (
    <div style={{ width: '100%' }}>
      <Row style={{
        overflow: 'hidden',
        overflowX: 'auto',
        width: '100%',
        flexWrap: 'nowrap',
        margin: '8px 0px',
      }}
      >
        {data.length ? (
          sortedData.map(it => {
            return (
              <div
                key={it.week}
                style={{
                  margin: '0px 16px',
                  textAlign: 'center',
                }}
              >
                <Col
                  onClick={() => action(it.week)}
                  style={{
                    width: '40px',
                    height: '200px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: `${(it.totalQuestions / maxTotal) * 100}%`,
                      backgroundColor: selectedBar === it.week ? '#415F91' : '#C9E6FF',
                      borderRadius: '100px',
                    }}
                    className="bar"
                  />
                </Col>
                <Typography style={{ fontSize: '14px', fontWeight: 600 }}>{it.totalQuestions}</Typography>
                <Typography style={{ fontSize: '11px', fontWeight: 500 }}>{`W${it.week}`}</Typography>
              </div>
            );
          })
        ) : (
          <Row style={{
            height: '200px',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          >
            Sem dados para a data selecionda
          </Row>
        )}
      </Row>
    </div>
  );
};
