import {
  gql, useMutation, useQuery,
} from '@apollo/client';
import { message } from 'antd';
import { useCallback } from 'react';

export type Lesson = {
  _id: string;
  lessonTitle: string;
  classification: string[];
  classificationTree: string;
}

interface CourseLessonsInput {
  courseId: string;
}

interface CourseLessonsOutput {
  courseLessons: Lesson[];
}

const COURSE_LESSONS = gql`
  query CourseLessons(
    $courseId: ObjectId!
  ) {
    courseLessons(courseId: $courseId) {
      _id
      lessonTitle
      classification
    }
  }
`;

interface CreateLessonOutput {
  createLesson: string;
}

interface CreateLessonInput {
  courseId: string;
}

const CREATE_LESSON = gql`
  mutation CreateLesson(
    $courseId: ObjectId!
  ) {
    createLesson(
      courseId: $courseId
    )
  }
`;

export interface DeleteLessonOutput {
  deleteLesson: boolean;
}

export interface DeleteLessonInput {
  lessonId: string;
}

const DELETE_LESSON = gql`
  mutation DeleteLesson($lessonId: ObjectId!) {
    deleteLesson(lessonId: $lessonId)
  }
`;

export function useLessons(courseId: string) {
  const courseLessons = useQuery<CourseLessonsOutput, CourseLessonsInput>(COURSE_LESSONS, {
    variables: {
      courseId,
    },
    skip: !courseId,
  });

  const [createNewLesson] = useMutation<CreateLessonOutput, CreateLessonInput>(CREATE_LESSON);
  const handleCreateNewLesson = useCallback(async () => {
    const response = await createNewLesson({
      variables: {
        courseId,
      },
    });
    return response.data?.createLesson;
  }, [courseId, createNewLesson]);

  const [deleteLesson] = useMutation<DeleteLessonOutput, DeleteLessonInput>(DELETE_LESSON);
  const handleDeleteLessonMutation = useCallback(async (lessonId: string) => {
    const response = await deleteLesson({
      variables: {
        lessonId,
      },
    });
    return response.data?.deleteLesson;
  }, [deleteLesson]);

  return {
    courseLessons,
    handleCreateNewLesson,
    handleDeleteLessonMutation,
  };
}
export interface LessonDetailsOutput {
  lessonDetails: Lesson;
}

export interface LessonDetailsInput {
  _id: string;
}

const LESSON_DETAILS = gql`
  query LessonDetails($_id: ObjectId!) {
    lessonDetails(_id: $_id) {
      _id
      lessonTitle
      classification
      classificationTree
    }
  }
`;

export function useLessonDetails(_id: string) {
  return useQuery<LessonDetailsOutput, LessonDetailsInput>(LESSON_DETAILS, {
    variables: {
      _id,
    },
    skip: !_id,
    onError: error => {
      message.error(error.message);
    },
  });
}

export interface UpdateLessonDetailsInput {
  _id: string;
  lessonTitle?: string;
  classification?: string[];
}

interface UpdateLessonDetailsOutput {
  updateLessonDetails: boolean;
}

const UPDATE_LESSON_DETAILS = gql`
  mutation UpdateLessonDetails(
    $_id: ObjectId!,
    $lessonTitle: String,
    $classification: [String!],
  ) {
    updateLessonDetails(
      _id: $_id,
      lessonTitle: $lessonTitle,
      classification: $classification
    )
  }
`;

export function useUpdateLessonDetails() {
  const [updateLessonDetails] = useMutation<UpdateLessonDetailsOutput, UpdateLessonDetailsInput>(UPDATE_LESSON_DETAILS, {
    onError: error => {
      message.error(error.message);
    },
  });
  const handleUpdateLessonDetails = useCallback(async ({
    _id,
    lessonTitle,
    classification,
  }: UpdateLessonDetailsInput) => {
    const res = await updateLessonDetails({
      variables: {
        _id,
        lessonTitle,
        classification,
      },
    });
    return res.data?.updateLessonDetails;
  }, [updateLessonDetails]);
  return handleUpdateLessonDetails;
}

type UpdateLessonContentsInputType = {
  lessonId: string,
  contentType: 'videos' | 'texts' | 'mindmaps' | 'flashcards' | 'questions' | 'theoreticalReviewVideos',
  contentIds: string[],
}

type UpdateLessonContentsOutputType = {
  updateLessonContents: boolean,
}

const UPDATE_LESSON_CONTENTS = gql`
    mutation UpdateLessonContents (
      $lessonId: ObjectId!,
      $contentType: LessonContentType!,
      $contentIds: [ObjectId!]!,
    ) {
      updateLessonContents (
        lessonId: $lessonId,
        contentType: $contentType,
        contentIds: $contentIds,
      )
    }
`;

export function useUpdateLessonContents() {
  const [updateLessonContents] = useMutation<
    UpdateLessonContentsOutputType, UpdateLessonContentsInputType
  >(UPDATE_LESSON_CONTENTS);

  const handleUpdateLessonContents = async (
    {
      lessonId,
      contentType,
      contentIds,
    }: UpdateLessonContentsInputType,
  ) => {
    try {
      const ok = await updateLessonContents({
        variables: {
          lessonId,
          contentType,
          contentIds,
        },
      });
      if (!ok) {
        throw new Error('Query failed');
      }
    } catch (e) {
      console.error(e);
    }
  };

  return {
    handleUpdateLessonContents,
  };
}

interface FindLessonsWithContentIdInput {
  contentId: string;
}

export type BaseContentType = 'Video' | 'Mindmap' | 'Text' | 'Flashcard';

interface FindLessonsWithContentIdOutput extends Record<BaseContentType, {
  lessonTitle: string;
  courses: string[];
  lessonId: string;
}[]> { }

export function useFindLessonsWithContentId<T extends BaseContentType>(contentId: string, contentType: T) {
  const FIND_LESSONS_WITH_CONTENT = gql`
    query FindLessonWithContentId($contentId: ObjectId!) {
      ${contentType}: findLessonsWith${contentType}Id(contentId: $contentId) {
        lessonTitle
        courses
        lessonId
      }
    }
  `;

  return useQuery<FindLessonsWithContentIdOutput, FindLessonsWithContentIdInput>(FIND_LESSONS_WITH_CONTENT, {
    variables: {
      contentId,
    },
    skip: !contentId,
  });
}

const COPY_LESSON_CONTENTS = gql`
  mutation CopyLessonContents(
    $lessonId: ObjectId!,
    $courseId: ObjectId!,
    $classification: [String!]!,
    $copyOptions: CopyOptions!
    ) {
      copyLessonContents (
        lessonId: $lessonId,
        courseId: $courseId,
        classification: $classification,
        copyOptions: $copyOptions,
      )
    }
`;

export type CopyOptions = {
  videos?: boolean;
  texts?: boolean;
  mindmaps?: boolean;
  flashcards?: boolean;
  questions?: boolean;
  theoreticalReviewContent?: boolean;
}

type CopyLessonContentsInputType = {
  lessonId: string,
  courseId: string,
  classification: string[],
  copyOptions: CopyOptions,
};

type CopyLessonContentsOutputType = {
  copyLessonContents: string,
};

export function useCopyLessonContents() {
  const [copyLessonContents] = useMutation<
    CopyLessonContentsOutputType, CopyLessonContentsInputType
  >(COPY_LESSON_CONTENTS, {
    onError: () => message.error('Erro na replicação...'),
  });

  const handleCopyLessonContents = useCallback(async ({
    lessonId,
    courseId,
    classification,
    copyOptions,
  }: CopyLessonContentsInputType) => {
    await copyLessonContents({
      variables: {
        lessonId,
        courseId,
        classification,
        copyOptions,
      },
    });
  }, [copyLessonContents]);

  return {
    handleCopyLessonContents,
  };
}
