import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { ApolloQueryResult } from '@apollo/client';
import {
  Button,
  Space,
  Typography,
  Collapse,
  DatePicker,
  Row,
  Table,
  Col,
  message,
} from 'antd';
import moment from 'moment';
import {
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import {
  CourseManyInfo,
  CoursesManyInfoInput,
  CoursesManyInfoOutput,
  ExamsObjectType,
  useUpdateCourse,
} from '../../../api/courses';
import { useGetMockExamTemplatesById } from '../../../api/exams';
import { generateRandomKey } from '../../../utils/generate-random-key';
import { getMomentObjectFromInterval } from '../../../utils/time-utils';
import { AddExamInput } from './AddExamInput';

type ExamsTableProps = {
  course: CourseManyInfo;
  refetch?: (variables?: Partial<CoursesManyInfoInput> | undefined) => Promise<ApolloQueryResult<CoursesManyInfoOutput>>;
}

const { Text } = Typography;
const { Panel } = Collapse;

const rowStatusClassNames = {
  add: 'bg-add',
  delete: 'bg-delete',
  edit: 'bg-edit',
};

interface ExamEditedType extends ExamsObjectType {
  action?: 'add' | 'delete' | 'edit';
}

export type TemplateOutput = {
  label: string,
  timeToDo: number,
  startsAt: string,
  finishesAt: string,
  _id: string,
} | undefined;

export function ExamsTable({
  course: { exams: examsWithNoKeys, _id: courseId }, refetch,
}: ExamsTableProps) {
  const exams = useMemo(() => {
    return examsWithNoKeys.map(x => ({ ...x, key: generateRandomKey() }));
  }, [examsWithNoKeys]);
  const [examsState, setExamsState] = useState<ExamEditedType[]>(exams);
  const { data } = useGetMockExamTemplatesById();
  const [editingRecord, setEditingRecord] = useState<ExamEditedType>({} as ExamEditedType);
  const [dirtyInputs, setDirtyInputs] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [blockEdit, setBlockEdit] = useState(false);
  const handleUpdateCourse = useUpdateCourse();

  const alreadyAdded = useMemo(() => {
    return examsState.map(x => x._id);
  }, [examsState]);

  const getOriginalRecord = useCallback((id: string) => {
    const selected = exams.filter(x => x._id === id);
    if (selected.length === 1) {
      return selected[0];
    } return undefined;
  }, [exams]);

  const getActionFromSave = useCallback((currItem?: ExamEditedType) => {
    const editingRecordCopy = { ...editingRecord };
    delete editingRecordCopy.key;
    const currItemCopy = { ...currItem };
    delete currItemCopy.key;
    const hasChanged = JSON.stringify(currItemCopy) !== JSON.stringify(editingRecordCopy);
    if (editingRecord.action === 'add') {
      return 'add';
    }
    if (!hasChanged) {
      return undefined;
    }
    return 'edit';
  }, [editingRecord]);

  const getTemplateById = useCallback((id: string): TemplateOutput => {
    if (data) {
      const selected = data.getMockExamTemplatesById.filter(item => item._id === id);
      if (selected.length === 1) {
        const template = selected[0];
        return {
          label: `${template.title} - ${template.subtitle}`,
          timeToDo: template.timeToDo,
          startsAt: template.startsAt,
          finishesAt: template.finishesAt,
          _id: template._id,
        };
      }
    }
    return undefined;
  }, [data]);

  useEffect(() => {
    setExamsState(exams);
  }, [exams]);

  const edit = useCallback((item: ExamEditedType) => {
    setEditingRecord(item);
  }, []);

  const handleChangeStartsAt = useCallback((value: moment.Moment | null) => {
    if (!value) {
      return;
    }
    setEditingRecord(prev => {
      return {
        ...prev,
        startsAt: value.toISOString(),
      };
    });
  }, []);

  const handleChangeFinishesAt = useCallback((value: moment.Moment | null) => {
    if (!value) {
      return;
    }
    setEditingRecord(prev => {
      return {
        ...prev,
        finishesAt: value.toISOString(),
      };
    });
  }, []);

  const cancel = useCallback(() => {
    setExamsState(records => {
      const result = [...records];
      if (editingRecord.action === 'add') {
        return result;
      }
      const index = records.findIndex(r => r.key === editingRecord.key);
      const original = getOriginalRecord(editingRecord._id);
      result.splice(index, 1, {
        ...(original || editingRecord),
        action: undefined,
      });
      return result;
    });
    setEditingRecord({} as ExamEditedType);
  }, [editingRecord, getOriginalRecord]);

  const save = useCallback(() => {
    setExamsState(records => {
      const result = [...records];
      const index = records.findIndex(r => r.key === editingRecord.key);

      result.splice(index, 1, {
        _id: editingRecord._id,
        startsAt: moment(editingRecord.startsAt).toISOString(),
        finishesAt: moment(editingRecord.finishesAt).toISOString(),
        template: editingRecord.template,
        key: generateRandomKey(),
        type: editingRecord.type,
        action: getActionFromSave(result[index]),
      });

      return result;
    });
    setEditingRecord({} as ExamEditedType);
    setDirtyInputs(true);
  }, [editingRecord, getActionFromSave]);

  const flagDeletion = useCallback(() => {
    setExamsState(records => {
      const result = [...records];
      if (editingRecord.action === 'add') {
        return result.filter(item => item.key !== editingRecord.key);
      }
      const index = records.findIndex(r => r.key === editingRecord.key);
      result.splice(index, 1, {
        ...editingRecord,
        action: 'delete',
      });
      return result;
    });
    setEditingRecord({} as ExamEditedType);
    setDirtyInputs(true);
  }, [editingRecord]);

  const addNewExam = useCallback((examToAdd: ExamsObjectType) => {
    setExamsState(records => {
      const result = [...records];
      result.push({
        ...examToAdd,
        action: 'add',
      });
      result.sort((a, b) => {
        if (a.startsAt && b.startsAt) {
          return new Date(a.startsAt).getTime() - (new Date(b.startsAt)).getTime();
        } return 1;
      });
      return result;
    });
    setDirtyInputs(true);
  }, []);

  const handleUndo = useCallback(() => {
    setDirtyInputs(false);
    setExamsState(exams);
  }, [exams]);

  const noActionsSelected = useMemo(() => {
    return !examsState.map(exam => exam.action).some(x => !!x);
  }, [examsState]);

  const editingRecordStartDateIsAfterFinishDate = useMemo(() => {
    return moment(editingRecord.startsAt).isAfter(moment(editingRecord.finishesAt));
  }, [editingRecord.finishesAt, editingRecord.startsAt]);

  const handleUpdate = useCallback(async () => {
    setUpdating(true);
    setBlockEdit(true);
    try {
      await handleUpdateCourse({
        input: {
          _id: courseId,
          exams: examsState
            .filter(exam => Boolean(exam.action))
            .map(exam => ({
              _id: exam._id,
              finishesAt: exam.finishesAt,
              startsAt: exam.startsAt,
              template: exam.template,
              type: exam.type,
              action: exam.action,
            }))
          ,
        },
      });
      if (refetch) {
        await refetch({
          ids: [courseId],
        });
      }
      setDirtyInputs(false);
      message.success('Cronograma do curso atualizado com sucesso.');
    } catch (err) {
      console.error(err);
    } finally {
      setBlockEdit(false);
      setUpdating(false);
    }
  }, [courseId, examsState, handleUpdateCourse, refetch]);

  const columns = useMemo(() => {
    return [
      {
        title: 'Modelo',
        dataIndex: 'template',
        key: 'template',
        width: '50%',
        render: (_: any, { type, _id: mockId }: ExamEditedType) => {
          if (type === 'smart_exam') {
            return <Text>Simulado inteligente</Text>;
          }
          if (type === 'exam') {
            return <Text>Prova na íntegra</Text>;
          }
          if (getTemplateById(mockId)?.label) {
            return <Text>{getTemplateById(mockId)?.label}</Text>;
          }
          return <Text style={{ color: 'red' }}>Simulado sem modelo</Text>;
        },
        sorter: (a: ExamEditedType, b: ExamEditedType, order?: 'ascend' | 'descend' | null) => {
          if (order && order === 'ascend') {
            return a.type < b.type ? 1 : -1;
          }
          return b.type < a.type ? -1 : 1;
        },
      },
      {
        title: 'Inicia em',
        dataIndex: 'startsAt',
        key: 'startsAt',
        width: '15%',
        render: (_: any, {
          key, startsAt, type, template,
        }: ExamEditedType) => {
          if (key === editingRecord.key) {
            if (type !== 'mock') {
              return (
                <DatePicker
                  defaultValue={moment(startsAt)}
                  style={{ backgroundColor: editingRecordStartDateIsAfterFinishDate ? '#FFC9CC' : '' }}
                  value={moment(editingRecord.startsAt)}
                  format="DD/MM/YYYY"
                  // disabledDate={d => !d || d.isAfter(editingRecord.finishesAt)}
                  onChange={handleChangeStartsAt}
                />
              );
            }
          }
          return (
            <Text>
              {moment(template ? getTemplateById(template)?.startsAt : startsAt).format('DD/MM/YYYY')}
            </Text>
          );
        },
        sorter: (a: ExamEditedType, b: ExamEditedType, order?: 'ascend' | 'descend' | null) => {
          if (order && order === 'ascend') {
            return moment(a.startsAt).isAfter(moment(b.startsAt)) ? 1 : -1;
          }
          return moment(b.startsAt).isAfter(moment(a.startsAt)) ? -1 : 1;
        },
      },
      {
        title: 'Termina em',
        dataIndex: 'finishesAt',
        key: 'finishesAt',
        width: '15%',
        render: (_: any, {
          key, finishesAt, type, template, _id,
        }: ExamEditedType) => {
          if (key === editingRecord.key) {
            if (type !== 'mock') {
              return (
                <DatePicker
                  defaultValue={moment(finishesAt)}
                  value={moment(editingRecord.finishesAt)}
                  disabledDate={d => !d || d.isBefore(editingRecord.startsAt)}
                  format="DD/MM/YYYY"
                  onChange={handleChangeFinishesAt}
                />
              );
            }
          }
          return (
            <Text>
              {moment(template ? getTemplateById(template)?.finishesAt : finishesAt).format('DD/MM/YYYY')}
            </Text>
          );
        },
        sorter: (a: ExamEditedType, b: ExamEditedType, order?: 'ascend' | 'descend' | null) => {
          if (order && order === 'ascend') {
            return moment(a.finishesAt).isAfter(moment(b.finishesAt)) ? 1 : -1;
          }
          return moment(b.finishesAt).isAfter(moment(a.finishesAt)) ? -1 : 1;
        },
      },
      {
        title: 'Duração',
        dataIndex: 'timeToDo',
        key: 'timeToDo',
        width: '10%',
        render: (_: any, { _id }: ExamEditedType) => {
          const timeToDo = getTemplateById(_id)?.timeToDo;
          return timeToDo ? (<Text>{getMomentObjectFromInterval(timeToDo)?.format('HH:mm')}</Text>) : '-';
        },
        sorter: (a: ExamEditedType, b: ExamEditedType, order?: 'ascend' | 'descend' | null) => {
          const aTimeToDo = getTemplateById(a._id)?.timeToDo;
          const bTimeToDo = getTemplateById(b._id)?.timeToDo;
          if (order && order === 'ascend') {
            return (aTimeToDo || 0) < (bTimeToDo || 0) ? 1 : -1;
          }
          return (bTimeToDo || 0) < (aTimeToDo || 0) ? -1 : 1;
        },
      },
      {
        title: 'Ação',
        dataIndex: 'action',
        width: '10%',
        render: (_: any, item: ExamEditedType) => {
          if (item.key === editingRecord.key) {
            return (
              <Space>
                {item.type !== 'mock' && (
                  <Button
                    icon={<CheckOutlined />}
                    onClick={save}
                    className="color-success"
                    disabled={editingRecordStartDateIsAfterFinishDate}
                  />
                )}
                <Button icon={<CloseOutlined />} onClick={cancel} />
                <Button danger icon={<DeleteOutlined />} onClick={flagDeletion} />
              </Space>
            );
          }
          return <Button icon={<EditOutlined />} disabled={!!editingRecord.key || blockEdit} onClick={() => edit(item)} />;
        },
      },
    ];
  }, [
    blockEdit, cancel, edit, editingRecord.finishesAt, editingRecord.key, editingRecord.startsAt,
    editingRecordStartDateIsAfterFinishDate, flagDeletion, getTemplateById, handleChangeFinishesAt,
    handleChangeStartsAt, save]);

  return (
    <>
      <Collapse accordion>
        <Panel
          key="only"
          header={(
            <Row justify="space-between">
              <Text>Provas</Text>
            </Row>
          )}
        >
          <Col className="mb-2" style={{ marginBottom: '40px' }}>
            <AddExamInput
              allTemplates={data?.getMockExamTemplatesById || []}
              onConfirm={addNewExam}
              alreadyAdded={alreadyAdded}
              getTemplateById={getTemplateById}
              disabled={blockEdit}
            />
          </Col>
          <Table
            size="small"
            pagination={false}
            columns={columns}
            sortDirections={['ascend', 'descend']}
            dataSource={examsState}
            rowClassName={({ action }) => (action ? rowStatusClassNames[action] : '')}
          />
        </Panel>
      </Collapse>
      <Row style={{ marginBottom: 15 }}>
        <Space className="mt-2">
          <Button
            type="ghost"
            icon={<CloseOutlined />}
            disabled={!dirtyInputs || updating || noActionsSelected}
            onClick={handleUndo}
          >
            Desfazer
          </Button>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            disabled={!dirtyInputs || updating || noActionsSelected}
            onClick={handleUpdate}
            loading={updating}
          >
            Salvar
          </Button>
        </Space>
      </Row>
    </>
  );
}
