import {
  useState, useCallback, useEffect, useContext,
} from 'react';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { auth } from '../client/firebase';
import { GlobalContext } from '../contexts/Global.context';
import { hasAnyPrivilege, hasPrivilege } from '../utils/profile-utils';

export interface AccountHook {
  loadingCredentials: boolean;
  loginWithEmail: (email: string, password: string) => Promise<string>;
  logout: () => Promise<void>;
  isAuthenticated: (clearences?: string[], shouldNotify?: boolean) => boolean;
}

export default function useAccount(): AccountHook {
  const [uid, setUid] = useState<string>(auth.currentUser?.uid || '');
  const history = useHistory();
  const apollo = useApolloClient();
  const [loadingAuth, setLoadingAuth] = useState(true);

  const {
    profile,
    getGlobalProfile,
  } = useContext(GlobalContext);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(next => {
      if (next && next.uid) {
        setUid(next.uid);
      } else {
        setUid('');
      }
      setLoadingAuth(false);
    });

    return () => unsubscribe();
  }, []);

  const loginWithEmail = useCallback(async (email: string, password: string) => {
    try {
      const credentials = await auth.signInWithEmailAndPassword(email, password);
      if (credentials.user) {
        await auth.updateCurrentUser(credentials.user);
        setUid(credentials.user.uid);
        history.replace('/admin');
        return 'success';
      }
      return 'failed';
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        throw Error('Senha incorreta!');
      }
      if (error.code === 'auth/user-not-found') {
        throw Error('Usuário não encontrado!');
      }
      return error.code;
    }
  }, [history]);

  const logout = useCallback(async () => {
    try {
      await auth.signOut();
      await apollo.clearStore();
      setUid('');
      history.replace('/login');
    } catch (error) {
      message.error('Error ao fazer logout!');
    }
  }, [apollo, history]);

  const isAuthenticated = useCallback((clearences?: string[], shouldNotify = true) => {
    if (!uid) {
      return false;
    }

    if (!profile.data) {
      return false;
    }

    if (uid && profile.data.profile) {
      if (!clearences) {
        if (hasAnyPrivilege(profile.data.profile)) {
          return true;
        }
        return false;
      }
      if (hasPrivilege(profile.data.profile, clearences)) {
        return true;
      }
      if (shouldNotify) {
        message.error('Você não tem permissão para acessar esse recurso.');
      }
      return false;
    }
    return false;
  }, [profile.data, uid]);

  // Carregar perfil se usuário já estiver autenticado.
  useEffect(() => {
    if (
      uid
      && !profile.called
      && !profile.loading
      && !profile.data
    ) {
      getGlobalProfile();
    }
  }, [getGlobalProfile, isAuthenticated, profile.called, profile.data, profile.loading, uid]);

  return {
    loginWithEmail,
    logout,
    isAuthenticated,
    loadingCredentials: loadingAuth || profile.loading || (!profile.called && Boolean(uid)),
  };
}
