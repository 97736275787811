import { DeploymentUnitOutlined, EditOutlined } from '@ant-design/icons';
import {
  List,
  Card,
  Col,
  Row,
  Spin,
  Space,
} from 'antd';
import { Link } from 'react-router-dom';
import EditDataWrapper from '../../components/EditDataWrapper';
import PrivateComponent from '../../components/PrivateComponent';
import { useCourses } from '../../hooks/useCourses';
import { setParam } from '../../hooks/useSearchParam';

type CoursesPageProps = {}

export function CoursesPage(props: CoursesPageProps) {
  const { courses, loading } = useCourses();
  return (
    <EditDataWrapper
      title="Cursos"
    >
      {loading ? (<Spin />) : (
        <List
          dataSource={courses}
          renderItem={item => (
            <List.Item
              actions={[
                <PrivateComponent clearences={['admin']}>
                  <Link
                    to={{
                      pathname: 'editar-curso',
                      ...setParam(item._id, 'id'),
                    }}
                  >
                    <Space>
                      <EditOutlined />
                      Editar
                    </Space>
                  </Link>
                </PrivateComponent>,
                <Link
                  to={{
                    pathname: 'aulas',
                    ...setParam(item._id, 'curso'),
                    state: {
                      title: item.title,
                    },
                  }}
                >
                  <Space>
                    <DeploymentUnitOutlined />
                    Aulas
                  </Space>
                </Link>,
              ]}
            >
              {item.title}
            </List.Item>
          )}
        />
      )}
    </EditDataWrapper>
  );
}
